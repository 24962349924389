import { Button, Grid, Hidden, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../component/Loader';
import Layout from '../../component/partials/Layout';
import InternalComment from '../../component/partials/InternalComment';
import api from '../../config/api';
import HelloSignClass from '../../utils/hellosign';
import DDView from './DDView';
import HeaderApplication from './HeaderApplication';
import LIView from './LIView';
import Snackbar from '../../component/Snackbar';
import { setApplicationRdx, setLoadingApplicationRdx } from '../../redux/actions/application';
import ApplicationTraceEmail from '../../component/ApplicationTraceEmail';

const UserInformationButton = (props) => {
  
	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
	}

	const alternateCopyToClipboard = (email) => {
		const textArea = document.createElement("textarea");
		textArea.value = email;
		document.body.appendChild(textArea);
		textArea.focus({ preventScroll: true });
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	}

	const handleCopyToClipboard = (email) => {
		try {

		if (navigator.clipboard) {
      navigator.clipboard.writeText(email);
		} else {
		  alternateCopyToClipboard(email);
		}
    setOpenSnackBar(true);
	  } catch (err) {
			console.log("Unable to copy to clipboard",err);
		}
  }

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return ['(', match[1], ') ', match[2], '-', match[3]].join('');
		}
		return null;
	};
	return (
		<div className='user__information'>
			<Button variant='contained' size='small' color='primary' onClick={() => props.history.goBack()} disableRipple>
				Back to dashboard
			</Button>
			{props.parent != null && (
				<Fragment>
					<p className='user-name' style={{ marginTop: 40 }}>
						Parent Information
					</p>
					<div className='user-information'>
						<p style={{ marginTop: 20 }} className='p-name'>
							{props.parent.firstName} {props.parent.lastName}
						</p>
						<p style={{ marginTop: 10 }}>
							<span className='p-street-address'>{props.parent.address}</span>
						</p>
						{(props.parent.city || props.parent.state || props.parent.postalCode) && (
							<p style={{ marginTop: 10 }}>
								<span className='p-locality'>
									{props.parent.city}, {props.parent.state}, {props.parent.postalCode}
								</span>
							</p>
						)}
						{props.parent.phone && (
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<PhoneIcon style={{ color: '#7a9903' }} fontSize='small' /> <span style={{ marginLeft: 10 }}>{formatPhoneNumber(props.parent.phone)}</span>
							</div>
						)}
						<Hidden only={['xs']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<Tooltip title={"Copy to Clipboard"} aria-label='add'>
									<span className='u-email' style={{ marginLeft: 10, cursor: "pointer" }} onClick={()=>{handleCopyToClipboard(props.parent.email)}}>
										{props.parent.email}
									</span>
								</Tooltip>
								<Snackbar open={openSnackBar} severity={'success'} autoHideDuration={3000} text={'Email Successfully Copied to Clipboard'} handleClose={handleCloseSnackBar} />
							</div>
						</Hidden>
						<Hidden only={['sm', 'md', 'lg', 'xl']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<span className='u-email' style={{ marginLeft: 10 }}>
									{props.parent.email}
								</span>
							</div>
						</Hidden>
					</div>
				</Fragment>
			)}
		</div>
	);
};

const ApplicationForm = ({setApplicationRdx, setLoadingApplicationRdx, ...props}) => {
	const [titleStatus, setTitleStatus] = useState('');
	const [titleApplication, setTitleApplication] = useState('');
	const [status, setStatus] = useState([]);
	const [reason, setReason] = useState([]);
	const [reasonIncomplete, setReasonIncomplete] = useState([]);
	const [reasonRejected, setReasonRejected] = useState([]);
	const [content, setContent] = useState([]);
	const [application, setApplication] = useState({});
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [valueStatus, setValueStatus] = useState(null);
	const [openReasonModal, setOpenReasonModal] = useState(false);
	const [isCurrentApplicationPeriod, setIsCurrentApplicationPeriod] = useState(true);

	const { period: currentPeriod } = props.account.periodSettings;

	const traceEmailRef = React.useRef({});

	useEffect(() => {
		setIsCurrentApplicationPeriod(currentPeriod === application.period);
	}, [application]);

	useEffect(() => {
		title();		
		getApplicationById();		
		getStatus();		
		getApplication();
		getTitleApplication();
		getReasonIncomplete();
		getReasonRejected();
	}, []);

	useEffect(() => {
		getApplication();
	}, [reason, status]);

	const getApplicationById = () => {
		const { id } = props.match.params || null;
		const { adminType } = props.match.params || '';

		setLoadingApplicationRdx(adminType);
		api.GetApplicationById(props.account.tokenAuth, { id }).then(async (response) => {
			const { status, message, data } = response;
			if (status != 201 || status == 600) {
				setError(message);
			} else {				
				setValueStatus(data.status);
				setApplication(data);
				
				setApplicationRdx(data, adminType);

				const result = await traceEmailRef.current.reload();		
			}		
			setLoading(false);
		});
	};

	const getValueStatus = (value) => {
		setValueStatus(value);
	};

	const getApplication = () => {
		const { id } = props.match.params || null;
		const { applicationType } = props.match.params || '';
		const { adminType } = props.match.params || '';

		switch (applicationType.toUpperCase()) {
			case 'DD':
				setContent(<DDView adminType={adminType} pathname={props.history.location.state} history={props.history} status={status} reason={reason} account={props.account} id={id} applicationType='DD' />);
				break;
			case 'LI':
				setContent(
					<LIView adminType={adminType} application={application} pathname={props.history.location.state} status={status} reason={reason} account={props.account} id={id} applicationType='LI' history={props.history} />,
				);
				break;

			default:
				break;
		}
	};

	const getTitleApplication = () => {
		const { applicationType } = props.match.params || '';

		switch (applicationType.toUpperCase()) {
			case 'DD':
				setTitleApplication('Disabled/Displaced Application');
				break;
			case 'LI':
				setTitleApplication('Low Income Application');
				break;

			default:
				break;
		}
	};

	const title = () => {
		if (props.history.location.state) {
			if (props.history.location.state.url === '/') return setTitleStatus('Site Admin');
			if (props.history.location.state.url === '/admin/application') return setTitleStatus('Site Admin');
			if (props.history.location.state.url === '/admin-processor/application') return setTitleStatus('Admin Processor');
			if (props.history.location.state.url === '/followup/application') return setTitleStatus('Follow Up Processor');
			if (props.history.location.state.url === '/general/application') return setTitleStatus('General Processor');
		} else {
			props.history.push('/');
		}
	};

	const getStatus = async () => {
		let role = '';
		if (props.history.location.state) {
			if (props.history.location.state.url === '/') role = 3;
			if (props.history.location.state.url === '/admin/application') role = 3;
			if (props.history.location.state.url === '/admin-processor/application') role = 4;
			if (props.history.location.state.url === '/followup/application') role = 5;
			if (props.history.location.state.url === '/general/application') role = 6;
		}
		const response = await api.GetStatusByRole(props.account.tokenAuth, { role: role });
		if (response.data) setStatus(response.data);
	};

	const getReasonIncomplete = () => {
		api
			.IncompletedReasons(props.account.tokenAuth)
			.then((res) => {
				setReasonIncomplete(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getReasonRejected = () => {
		api
			.RejectedReasons(props.account.tokenAuth)
			.then((res) => {
				setReasonRejected(res.data);
			})
			.catch((err) => console.log(err));
	};

	const downloadDocument = () => {
		const { signatureRequestId } = application;

		const HelloSign = new HelloSignClass();

		HelloSign.signatureRequestId = signatureRequestId;
		HelloSign.token = props.account.tokenAuth;

		HelloSign.DownloadSignedDocument();
	};

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const openReasonsModalFn = (value) => {
		setOpenReasonModal(value);
	};

	return (
		<Layout showButton={false} history={props.history} title={titleStatus} period={currentPeriod}>
			<div className='application-page application-form-page application-view-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<div className='user__column'>
							<UserInformationButton history={props.history} parent={application.account} />							
							<InternalComment id={application.applicationId} comment={application.inProcessNote}/>							
							<ApplicationTraceEmail application={application} traceEmailRef={traceEmailRef}/>
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<div className='form__column'>
							<h3 className='section-title'>{titleApplication}</h3>
							{!loading ? (
								<Fragment>
									<div className='application-form__content'>
										{props.location.state.statusId && isCurrentApplicationPeriod && (
											<HeaderApplication
												getValueStatus={getValueStatus}
												pathname={props.history.location.state}
												status={status}
												statusName={application.statuslabel}
												history={props.history}
												reasonIncomplete={reasonIncomplete}
												reasonRejected={reasonRejected}
												application={application}
												openReasonModal={openReasonModal}
												getApplicationById={getApplicationById}
												openReasonsModalFn={openReasonsModalFn}
											/>
										)}
										
										{valueStatus != null && ((valueStatus === 7 && application.rejectedReasons.length > 0) || valueStatus === 6 || (valueStatus === 2 && application.timesSubmitted > 1 && application.rejectedReasons.length > 0)) && (
											<div style={{ marginBottom: 30 }}>
												<Alert severity='warning'>
													{props.history.location.state.url === '/followup/application' && isCurrentApplicationPeriod && valueStatus === 6 && (
														<Button variant='contained' color='primary' size='small' style={{ float: 'right' }} startIcon={<EditIcon />} onClick={() => openReasonsModalFn(!openReasonModal)}>
															Edit Reasons
														</Button>
													)}
													Reasons:
													<Fragment>
														<ul style={{ listStyleType: 'square', listStylePosition: 'inside', marginTop: 15, width: '100%' }}>
															{application.rejectedReasons.length > 0 &&
																application.rejectedReasons.map((x, i) => {
																	return (
																		<li key={i} style={{ paddingBottom: 10 }}>
																			<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
																		</li>
																	);
																})}
														</ul>
													</Fragment>
													{application.rejectedNotes != null && (
														<Fragment>
															<AlertTitle>Note:</AlertTitle>
															<span>{application.rejectedNotes}</span>
														</Fragment>
													)}
												</Alert>
											</div>
										)}
										{valueStatus != null && ((valueStatus === 7 && application.incompletedReasons.length > 0) || valueStatus === 4 || (valueStatus === 2 && application.timesSubmitted > 1 && application.incompletedReasons.length > 0)) && (
											<div style={{ marginBottom: 40 }}>
												<Alert severity='warning'>
													{props.history.location.state.url === '/followup/application' && isCurrentApplicationPeriod && valueStatus === 4 && (
														<Button variant='contained' color='primary' size='small' style={{ float: 'right' }} startIcon={<EditIcon />} onClick={() => openReasonsModalFn(!openReasonModal)}>
															Edit Reasons
														</Button>
													)}
													<Fragment>
														Reasons:
														<Fragment>
															<ul style={{ listStyleType: 'square', listStylePosition: 'inside', marginTop: 15, width: '100%' }}>
																{application.incompletedReasons.length > 0 &&
																	application.incompletedReasons.map((x, i) => {
																		return (
																			<li key={i} style={{ paddingBottom: 10 }}>
																				<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
																			</li>
																		);
																	})}
															</ul>
														</Fragment>
														{application.incompleteNotes != null && (
															<Fragment>
																<AlertTitle>Note:</AlertTitle>
																<span>{application.incompleteNotes}</span>
															</Fragment>
														)}
													</Fragment>														
												</Alert>
											</div>
										)}
										{content}
									</div>
								</Fragment>
							) : (
								<Loader loading />
							)}
						</div>
						{props.location.state && !loading && !error && props.location.state.statusId !== 7 && (
							<div className='application-buttons'>
								<Button variant='contained' color='primary' startIcon={<CloudDownloadIcon />} onClick={downloadDocument} disableRipple>
									Download signed document
								</Button>
							</div>
						)}
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

const mapDispatchToProps = {
	setApplicationRdx, setLoadingApplicationRdx
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
