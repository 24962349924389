import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import lodash from 'lodash';

import { Grid, Button, CircularProgress, TextField, FormHelperText } from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Alert from '../../component/partials/Alerts/Alert';

import API from '../../config/api';
import { useMemo } from 'react';
import SelectActiveInactive from '../StatsPerSchool/SelectActiveInactive';
import ExportButton from '../StatsPerSchool/ExportButton';

const tableColumns = [{ title: 'School Name', field: 'name' }];
const tableOptions = { showTitle: false, pageSize: 10, actionsColumnIndex: -1, search: true, toolbarButtonAlignment: 'left' };
const tableLocalization = { header: { actions: '' } };

const CreateSchoolComponent = ({ onChange, onCreate }) => {
	const [createAlert, setCreateAlert] = useState(false);
	const [schoolField, setSchoolField] = useState('');
	const [error, setError] = useState('');

	const handleOnChange = (event) => {
		const { value } = event.target;
		setError('');
		onChange(value);
		setSchoolField(value);
	};
	const handleRegisterSchool = () => {
		if (lodash.isEmpty(schoolField.trim())) return setError('School field is required');
		if (schoolField.trim().length < 5) return setError('School field must be greater than 5 characters');
		setCreateAlert(true);
	};

	const handleConfirmRegister = () => {
		setCreateAlert(false);
		setSchoolField('');
		onCreate(schoolField);
	};

	return (
		<>
			<Grid item md={6}>
				<TextField label='School Name' value={schoolField} onChange={handleOnChange} variant='outlined' size='small' error={!!error} helperText={error} fullWidth />
			</Grid>
			<Grid item>
				<Button variant='contained' color='primary' size='small' onClick={handleRegisterSchool}>
					Create School
				</Button>
			</Grid>
			<Alert
				open={createAlert}
				title='Create School'
				text='Are you sure you want to register this school?'
				onConfirmButtonText='Create'
				onConfirm={handleConfirmRegister}
				onCancel={() => setCreateAlert(false)}
			/>
		</>
	);
};

const Schools = ({ account }) => {
	const [loading, setLoading] = useState(true);
	const [deleteAlert, setDeleteAlert] = useState(false);
	const [reActiveAlert, setReActiveAlert] = useState(false);
	const [selectedSchool, setSelectedSchool] = useState(null);
	const [errorMsg, setErrorMsg] = useState('');
	const [tableData, setTableData] = useState([]);
	const [allSchools, setAllSchools] = useState([]);
	const [schoolsWithApps, setSchoolsWithApps] = useState([]);
	const [deleteAlertMessage, setDeleteAlertMessage] = useState({
		title: 'Delete School',
		message: 'This school does not have applications associated with it. This school can be safely deleted. \n Would you like to continue?',
		btnText: 'Delete',
		btnColor: 'secondary',
	});
	const [filterActiveInactive, setFilterActiveInactive] = useState(-1);
	const [deactivateAlert, setDeactivateAlert] = useState(false);

	useEffect(() => {
		async function getSchools() {
			const res = await API.GetSchoolNames(account.tokenAuth, '?allWithApps=true');
			if (res.status !== 201) return setLoading(false);

			const { schools, schoolsWithApps: sApps } = res.data;

			setTableData(schools);
			setAllSchools(schools);
			setSchoolsWithApps(sApps);
			setLoading(false);
		}
		getSchools();
	}, []);

	const tableActions = useMemo(
		(rowInfo) => {
			return [
				(rowData) => {
					const { active } = rowData;

					return {
						icon: () =>
							active ? (
								<>
									<Button onClick={() => handleDeactivate(rowData)} variant='contained' size='small' color='gray' disableRipple style={{ marginRight: 30 }}>
										Deactivate
									</Button>
								</>
							) : (
								<>
									<Button onClick={() => handleReactivate(rowData)} variant='contained' size='small' color='primary' disableRipple>
										Reactivate
									</Button>
									<button style={{ background: 'transparent', marginLeft: 10, border: 'none', cursor: 'pointer' }} onClick={(e) => {
										e.stopPropagation();
										handleDelete(rowData);
									}}>
										<DeleteForeverIcon color='secondary' fontSize='small' />
									</button>
								</>
							),
					};
				},
			];
		},
		[tableData, schoolsWithApps],
	);

	const handleReactivate = (rowData) => {
		setReActiveAlert(true);
		setSelectedSchool(rowData.schoolId)
	}

	const handleDeactivate = (rowData) => {
		setSelectedSchool(rowData.schoolId);
		toggleDeactivateAlert();
	};

	const handleDelete = (rowData) => {
		const hasApps = schoolsWithApps.find((s) => s.school === rowData.name);

		if (hasApps) {
			setDeleteAlertMessage({
				title: 'Delete School',
				message: 'You have open applications. All applications must be removed before a school can be deleted.',
				btnText: 'OK',
				btnColor: 'primary',
			});
		} else {
			setDeleteAlertMessage({
				title: 'Delete School',
				message: 'This school does not have applications associated with it. This school can be safely deleted. \n Would you like to continue?',
				btnText: 'Delete',
				btnColor: 'secondary',
			});
		}

		setSelectedSchool(rowData.schoolId);
		toggleDeleteAlert();
	};

	const handleDeleteSchool = () => {
		toggleDeleteAlert();
		const school = tableData.find((d) => d.schoolId === selectedSchool);
		const hasApps = schoolsWithApps.some((s) => s.school === school.name);

		if (hasApps) {
			return
		}

		setLoading(true);

		API.DeleteSchool(account.tokenAuth, { schoolId: selectedSchool, setInactive: false }).then((res) => {
			setTableData(tableData.filter((d) => d.schoolId !== selectedSchool));
			setAllSchools(allSchools.filter((d) => d.schoolId !== selectedSchool));
			setLoading(false);
		});
	};
	
	const handleDeactivateSchool = () => {
		toggleDeactivateAlert();
		setLoading(true);

		API.DeleteSchool(account.tokenAuth, { schoolId: selectedSchool, setInactive: true }).then((res) => {
			setTableData(
				tableData.map((d) => {
					if (d.schoolId === selectedSchool) d.active = false;
					return d;
				}),
			);
			setAllSchools(
				allSchools.map((d) => {
					if (d.schoolId === selectedSchool) d.active = false;
					return d;
				}),
			);
			setLoading(false);
		});
	};

	const handleReactiveSchool = () => {
		toggleReactiveAlert();
		setLoading(true);
		API.ReactiveSchool(account.tokenAuth, selectedSchool).then((res) => {
			setTableData(
				tableData.map((d) => {
					if (d.schoolId === selectedSchool) d.active = true;
					return d;
				}),
			);
			setAllSchools(
				allSchools.map((d) => {
					if (d.schoolId === selectedSchool) d.active = true;
					return d;
				}),
			);
			setLoading(false);
		});
	};

	const handleRegisterSchool = (school) => {
		setLoading(true);
		API.RegisterSchool(account.tokenAuth, school).then((res) => {
			if (res.status === 201) {
				setTableData((prevState) => [res.data, ...prevState]);
				setAllSchools((prevState) => [res.data, ...prevState]);
			} else if (res.message) setErrorMsg(res.message);
			setLoading(false);
		});
	};
	const toggleDeleteAlert = () => setDeleteAlert((prevState) => !prevState);
	const toggleDeactivateAlert = () => setDeactivateAlert((prevState) => !prevState);
	const toggleReactiveAlert = () => setReActiveAlert((prevState) => !prevState);
	const handleChangeActiveInactive = (value) => {
		setFilterActiveInactive(value);

		if (value === -1) {
			setTableData(allSchools);
		} else if (value === 1) {
			setTableData(allSchools.filter((s) => s.active));
		} else {
			setTableData(allSchools.filter((s) => !s.active));
		}
	};
	const getStatsFromTable = () => {
		return tableData.map((s) => ({ ...s, active: s.active ? 'Active' : 'Inactive' }));
	}
	const exportTableColumns = [
		...tableColumns.map((c) => ({ ...c, Status: c.field === 'active' })),
		{
			title: 'Status',
			field: 'active',
		}
	]

	return (
		<div className='profile-page__user-information-tab schools'>
			<h3 className='section-title'>School List</h3>
			<Grid container spacing={2}>
				<CreateSchoolComponent onChange={() => setErrorMsg('')} onCreate={handleRegisterSchool} />
				<div style={{ marginLeft: 'auto', zIndex: 99 }}>
					<ExportButton getStatsData={getStatsFromTable} columns={exportTableColumns} fileName='schools' />
				</div>
				<div className='schools-table'>
					<FormHelperText error>{errorMsg}</FormHelperText>
					{!loading && (
						<MaterialTable
							columns={tableColumns}
							data={tableData}
							actions={tableActions}
							options={tableOptions}
							localization={tableLocalization}
							components={{
								Toolbar: (props) => (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div style={{ marginLeft: 'auto', minWidth: 95 }}>
											<SelectActiveInactive value={filterActiveInactive} onChange={(e) => handleChangeActiveInactive(e)} />
										</div>
										<MTableToolbar {...props} />
									</div>
								),
							}}
						/>
					)}
					{loading && <CircularProgress size={40} color='primary' />}
				</div>
			</Grid>

			<Alert open={deleteAlert} title={deleteAlertMessage.title} text={deleteAlertMessage.message} confirmButtonText={deleteAlertMessage.btnText} onConfirm={handleDeleteSchool} onCancel={toggleDeleteAlert} iconContainerClassName='danger' confirmButtonColor={deleteAlertMessage.btnColor} />
			<Alert open={deactivateAlert} title='Inactive School' text='You are about to deactivate this school. Are you sure?' onConfirmButtonText='Delete' onConfirm={handleDeactivateSchool} onCancel={toggleDeactivateAlert} />
			<Alert
				open={reActiveAlert}
				title='Reactivate School'
				text='Are you sure you want to reactivate this school?'
				onConfirmButtonText='Reactivate'
				onConfirm={handleReactiveSchool}
				onCancel={toggleReactiveAlert}
			/>
		</div>
	);
};

export default connect((s) => ({ account: s.account }))(Schools);
