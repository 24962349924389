import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';

const SelectActiveInactive = ({ value, onChange, ...props}) => {
	const data = [
		{ value: -1, 'label': 'All' },
		{ value: 1, 'label': 'Active' },
		{ value: 0, 'label': 'Inactive' },
	]

	const handleChange = (e) => {
		const { value } = e.target;
		if (onChange) onChange(value);
	};

	return (
		<FormControl className='MuiFormControl__filter-select' disabled={data.length > 0 ? false : true} variant='outlined' size='small' fullWidth>
			<InputLabel id='select-filters-label'>Select status</InputLabel>
			<Select labelId='select-filters-label' id='select-filters' label='Select status' onChange={(e) => handleChange(e)} value={value}>
				{data.map((x, i) => {
					return (
						<MenuItem key={i} value={x.value} name={x.label}>
							{x.label}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default SelectActiveInactive;
