import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Button, FormControl, FormControlLabel, TextField, Checkbox, FormGroup, CircularProgress, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import API from '../../../config/api';

import MediaManager from '../../../component/MediaManager';

const Prerequisites = (props) => {
	const getFiles = (applicationId, fileKey) => {
		const data = { applicationId, fileKey },
			{ token } = props;

		const viewFile = window.open();

		API.GetFilesApplications(token, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	return (
		<Fragment>
			<FormControl component='fieldset' style={{ color: '#707070' }}>
				<div className='prerequisite-box'>
					<h3 className='strong-text'>2A: A qualified student must meet one of the following prerequisites</h3>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingBottom: 20 }}>
						<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.a1 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
						<p style={{ paddingLeft: 25 }}>Student has an IEP, MET or ISP from an Arizona public school (for preschool and grades K-12)</p>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingBottom: 20 }}>
						<p>{props.prerequisites && props.prerequisites.a2 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</p>
						<p style={{ paddingLeft: 15 }}>Student has a 504 plan from an Arizona public school (for grades K-12 only)</p>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
						<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.a3 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
						<p style={{ paddingLeft: 25 }}>
							Student was placed at one time in the Arizona foster care system (for grades K-12 only and to be verified by the Arizona Department of Child Safety (DCS))
						</p>
					</div>
				</div>
				<div className='prerequisite-box'>
					<h3 className='strong-text'>Section 2B</h3>
					<FormControl className='form-control-checkbox'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
							<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.b1 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
							<p style={{ paddingLeft: 25 }}>
								1 - Has student previously received a Disabled/Displaced scholarship from the Arizona Leadership Foundation? <i>(If No, please proceed to Question 3 below)</i>
							</p>
						</div>
					</FormControl>
					<FormControl className='form-control-checkbox'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
							<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.b2 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
							<p style={{ paddingLeft: 25 }}>
								2 - If your child has received an Arizona Leadership Foundation Disabled / Displaced Scholarship, has their primary disability category changed from the previous school year?
							</p>
						</div>
					</FormControl>
					<span className='instruction'>If yes, please provide a copy of the updated MET/IEP and proceed to Section 3.</span>
					<div className='form-control-fileuploader'>
						{props.version == 30 ? <><MediaManager keyName="b2_blueprint" readOnly="true"  /><br/></>	:
							props.data.files && props.data.files.b2_blueprint && (
								<Button color='primary' onClick={() => getFiles(props.data.applicationId, 'b2_blueprint')}>
									Open File
								</Button>
							)
						}
					</div>
					<FormControl className='form-control-checkbox'>
						<label>3 - Student needs D/D qualification.</label>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
							<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.b31 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
							<p style={{ paddingLeft: 25 }}>Provide a copy of the Student’s IEP, MET, ISP or 504 plan from an Arizona public school with this application.</p>
						</div>
					</FormControl>
					<div className='indented'>
						<div className='form-control-fileuploader'>
							{props.version == 30 ? <><MediaManager keyName="b3_blueprint" readOnly="true"  /><br/></>	:
								props.data.files && props.data.files.b3_blueprint && (
									<Button color='primary' onClick={() => getFiles(props.data.applicationId, 'b3_blueprint')}>
										Open File
									</Button>
								)
							}
						</div>
					</div>
					<p style={{ paddingLeft: 25 }}>{props.prerequisites && props.prerequisites.b311_primaryDisability}</p>
					{/* <span className="or-separator">OR</span> */}
					<FormControl className='form-control-prerequisite_7'>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
							<span style={{ marginLeft: 6 }}>{props.prerequisites && props.prerequisites.b32 ? <CheckIcon style={{ color: '#7a9903' }} /> : <CloseIcon style={{ color: '#6b0805' }} />}</span>
							<p style={{ paddingLeft: 25 }}>Provide additional information to verify student was placed in Arizona foster care (A.R.S. Title 8, Chapter 5)</p>
						</div>
						<div className='indented'>
							<p style={{ paddingLeft: 10 }}>{props.prerequisites && props.prerequisites.b321_ssn}</p>
							<p style={{ paddingLeft: 10 }}>{props.prerequisites && props.prerequisites.b322_previousNamesChild}</p>
						</div>
					</FormControl>
				</div>
			</FormControl>
		</Fragment>
	);
};

export default Prerequisites;
