/* eslint-disable no-unreachable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import Layout from '../../component/partials/Layout';
import { REGEX } from '../../utils/constants';
import Api from '../../config/api';
import MuiAlert from '@material-ui/lab/Alert';
import AlertMessage from '@material-ui/lab/Alert';

import Loader from '../../component/Loader';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const SuccessBox = ({ email }) => (
	<Box className='auth-box-message' style={{ width: 350 }}>
		<Box className='forgot_success_box'>
			<h6>REGISTER</h6>
			<p>
				We have sent you an email to <span style={{ fontWeight: 'bold' }}>{email}</span> with the steps to complete your account
			</p>
		</Box>
		<Box className='button__container'>
			<Link to='/'>
				<Button variant='contained'>Back to login</Button>
			</Link>
		</Box>
	</Box>
);

const Register = (props) => {
	const [emailError, setEmailError] = useState(false);
	const [firstNameError, setFirstNameError] = useState(false);
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
	const [lastNameError, setLastNameError] = useState(false);
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [nameButton, setNameButton] = useState('VALIDATE');
	const [loading, setLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [form, setForm] = useState({
		firstname: '',
		lastname: '',
		email: '',
	});

	const register = () => {
		setDisabledButton(true);
		setNameButton('LOADING...');

		if (form.firstname.length > 120) {
			setFirstNameError(true);
			setFirstNameErrorMessage('First name must have a maximum of 120 characters');
			setNameButton('VALIDATE');
			return;
		}

		if (form.lastname.length > 120) {
			setLastNameError(true);
			setLastNameErrorMessage('Last name must have a maximum of 120 characters');
			setNameButton('VALIDATE');
			return;
		}

		if (!REGEX.emailRegEx.test(form.email.toLowerCase())) {
			setEmailError(true);
			setErrorMessage('Email is incorrect');
			setNameButton('VALIDATE');
		}

		if (_.isEmpty(form.email)) {
			setEmailError(true);
			setErrorMessage('Email is required');
			setNameButton('VALIDATE');
		}

		if (_.isEmpty(form.firstname)) {
			setFirstNameError(true);
			setFirstNameErrorMessage('First Name is required');
			setNameButton('VALIDATE');
		}

		if (_.isEmpty(form.lastname)) {
			setLastNameError(true);
			setLastNameErrorMessage('Last Name is required');
			setNameButton('VALIDATE');
		}

		if (_.isEmpty(form.firstname) || _.isEmpty(form.lastname) || _.isEmpty(form.email) || !REGEX.emailRegEx.test(form.email.toLowerCase())) {
			return setNameButton('VALIDATE');
		}

		setLoading(true);

		const body = {
			firstname: form.firstname,
			lastname: form.lastname,
			email: form.email,
		};
		Api.CreateAccount(body)
			.then((res) => {
				setLoading(false);
				setDisabledButton(false);
				setNameButton('VALIDATE');
				if (res.status !== 201) {
					setAlertMessage(res.message);
					setSuccessMessage(res.message);
					return;
				}
				setIsComplete(true);
				setSuccessMessage(res.message);
				// setForm({
				//     firstname: '',
				//     lastname: '',
				//     email: '',
				// })
			})
			.catch((err) => {
				console.log('Error: ', err);
				setDisabledButton(false);
				setNameButton('VALIDATE');
			});
	};

	const onChangeForm = (e) => {
		const { name, value } = e.target || e;
		setEmailError(false);
		setFirstNameError(false);
		setLastNameError(false);
		setErrorMessage('');
		setSuccessMessage('');
		setDisabledButton(false);
		setAlertMessage('');
		setForm({ ...form, [name]: value });
	};

	return (
		<Layout title='Register' showBackground={false}>
			<Grid item xs={12}>
				<Box className='register_container'>
					{!isComplete ? (
						<Box className='register_container_box'>
							<Typography className='register_container_box_title'>User Infomation</Typography>
							<span className='register_span'></span>
							<form className='register_form' noValidate autoComplete='off' style={{ paddingBottom: 10 }}>
								<TextField
									label='First Name'
									name='firstname'
									value={form.firstname}
									variant='outlined'
									size='small'
									onChange={onChangeForm}
									error={firstNameError}
									helperText={firstNameError ? firstNameErrorMessage : null}
								/>
								<span className='register_span'></span>
								<TextField
									label='Last Name'
									name='lastname'
									value={form.lastname}
									variant='outlined'
									size='small'
									onChange={onChangeForm}
									error={lastNameError}
									helperText={lastNameError ? lastNameErrorMessage : null}
								/>
								<span className='register_span'></span>
								<TextField label='Email' name='email' value={form.email} variant='outlined' size='small' onChange={onChangeForm} error={emailError} helperText={emailError ? errorMessage : null} />
							</form>
							{/* {!!successMessage && <span style={{ color: '#9BB43B' }}>{successMessage}</span>} */}
							{!!alertMessage && (
								<span style={{ marginTop: 20 }}>
									<Alert onClose={() => setAlertMessage('')} severity='info'>
										{alertMessage}
									</Alert>
								</span>
							)}
							<Box className='register_button'>
								{!loading ? (
									<Button variant='contained' disabled={disabledButton} onClick={register}>
										{nameButton}
									</Button>
								) : (
									<div style={{ marginTop: 15 }}>
										<Loader loading={true} />
									</div>
								)}
							</Box>
						</Box>
					) : (
						<SuccessBox email={form.email} />
					)}
				</Box>
			</Grid>
		</Layout>
	);
};

export default Register;
