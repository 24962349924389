import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, TextField, Typography, IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl } from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Api from '../../config/api';
import { REGEX } from '../../utils/constants';
import LayoutLogin from '../../component/partials/LayoutLogin';
import MessageBox from '../../component/partials/Alerts/MessageBox';
import Loader from '../../component/Loader';
import Tooltip from '../../component/Tooltip';
import { isEmpty } from 'lodash';

const Login = (props) => {
	const [disabledButton, setDisabledButton] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [EmailErrorMessage, setEmailErrorMessage] = useState('');
	const [isEmailExist, SetIsEmailExist] = useState(false);
	const [form, setForm] = useState({
		email: '',
		password: '',
	});
	const [values, setValues] = React.useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	});

	useEffect(() => {
		if (props.match.params.email) {
			SetIsEmailExist(true);
			setForm({ ...form, email: props.match.params.email });
		}
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();
		setDisabledButton(true);
		setLoading(true);
		if (!REGEX.emailRegEx.test(form.email.toLowerCase())) {
			setEmailError(true);
			setEmailErrorMessage('Email is incorrect');
			setDisabledButton(false);
			setLoading(false);
			return;
		}
		if (isEmpty(form.email)) {
			setEmailError(true);
			setEmailErrorMessage('Email is required');
			setDisabledButton(false);
			setLoading(false);
			return;
		}
		if (isEmpty(form.password)) {
			setPasswordError(true);
			setPasswordErrorMessage('Password is required');
			setDisabledButton(false);
			setLoading(false);
			return;
		}

		const body = { email: form.email, password: form.password };
		await Api.Login(body)
			.then((res) => {
				const { status, data } = res;
				if (status !== 201) {
					setDisabledButton(false);
					setShowMessage(false);
					setLoading(false);
					setErrorMessage(res.message);
					return;
				}
				if (data.account.isProfileCompleted === false) {
					setShowMessage(true);
					return;
				}
				localStorage.setItem('tokenAuth', data.token);
				props.history.push('/');
			})
			.catch((err) => {
				console.log('err ', err);
				setDisabledButton(false);
				setShowMessage(false);
				setLoading(false);
			});
	};

	const onChangeForm = (e) => {
		const { name, value } = e.target || e;
		setEmailError(false);
		setPasswordError(false);
		setErrorMessage('');
		setEmailErrorMessage('');
		setErrorMessage('');
		setDisabledButton(false);
		setForm({ ...form, [name]: value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleShowMessage = () => {
		setDisabledButton(false);
		setShowMessage(false);
		setLoading(false);
		setForm({
			email: '',
			password: '',
		});
	};

	return (
		<LayoutLogin title='Log In' showButton={false}>
			<Grid item xs={12}>
				<Box className='login_container'>
					<Box className='login_container_box'>
						{!showMessage ? (
							<Fragment>
								<form onSubmit={onSubmit} className='login_form' noValidate autoComplete='off'>
									<TextField
										label='Email'
										name='email'
										value={form.email}
										variant='outlined'
										size='small'
										onChange={onChangeForm}
										error={emailError}
										helperText={emailError ? EmailErrorMessage : null}
									/>
									<span className='login_span'></span>
									{/* <span className='login_span'><Link to="/forgot-email" className="text-decoration-underline"></Link></span> */}

									<div className='login__form--help'>
										<FormControl className='login__form--password' variant='outlined' size='small'>
											<InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
											<OutlinedInput
												id='outlined-adornment-password'
												name='password'
												size='small'
												type={values.showPassword ? 'text' : 'password'}
												onChange={onChangeForm}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
															{values.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												labelWidth={70}
												error={emailError}
											/>
										</FormControl>
										{/* <Tooltip
                                    className='tooltip_space'
                                    tooltipStyle={{
                                        backgroundColor: '#ffffff',
                                        color: 'black',
                                        maxWidth: 280,
                                        fontSize: 12,
                                        border: '1px solid #9bb43b',
                                        padding: 10
                                    }}
                                    title={
                                        <Fragment>
                                            <Typography variant='body1'>Criteria:</Typography>
                                            <br />
                                            <Typography variant='caption'>Must be between 8 - 32 characters long <br />Alphabet uppercase letter (A-Z) include at least 1<br /> Alphabet lowercase letter (a-z) include at least 1<br /> Decimal digit number (0-9) include at least 1 <br />Special characters include at least 1 </Typography>
                                        </Fragment>
                                    }
                                /> */}
									</div>
									{passwordError && (
										<span className='login_span_error' style={{ marginLeft: 15 }}>
											{passwordErrorMessage}
										</span>
									)}

									<span className='login_span'>
										<Link to='/forgot-password' className='text-decoration-underline'>
											Forgot Password
										</Link>
									</span>
									{!!errorMessage && <span className='login_span_error'>{errorMessage}</span>}

									<Box className='login_button'>
										{!loading ? (
											<Fragment>
												<Button type='submit' variant='contained' disabled={disabledButton} onClick={onSubmit}>
													LOG IN
												</Button>
											</Fragment>
										) : (
											<div style={{ marginTop: 15 }}>
												<Loader loading={true} />
											</div>
										)}
									</Box>
								</form>
							</Fragment>
						) : (
							<MessageBox title='Login Failed' subtitle={<p style={{ textAlign: 'center' }}>You need to complete you register, please use the link in your email</p>} onClick={handleShowMessage} />
						)}
					</Box>
				</Box>
			</Grid>
		</LayoutLogin>
	);
};

export default Login;
