import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SendIcon from '@material-ui/icons/Send';

import Alert from '../../component/partials/Alerts/Alert';
import ReportModal from '../../component/ReportModal';
import Snackbar from '../../component/Snackbar';

import API from '../../config/api';

const tableColumns = [
	{ title: 'Date Created', field: 'createdAt' },
	{ title: 'Report Name', field: 'name' },
	{ title: 'User', field: 'user' },
];

const tableOptions = { search: false, showTitle: false, draggable: false, toolbar: false, pageSize: 5, paging: false, actionsColumnIndex: -1 };

const tableLocalization = { header: { actions: '' } };

const SendReportButton = ({ onClick }) => (
	<Button size='large' color='primary' variant='contained' startIcon={<SendIcon />} onClick={onClick} disableRipple fullWidth>
		Send Report
	</Button>
);

const Reports = ({ account }) => {
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [snackbarState, setSnackbarState] = useState({ open: false, severity: 'info', text: '' });
	const [selectedReport, setSelectedReport] = useState(null);
	const [reports, setReports] = useState([]);
	const [tableData, setTableData] = useState([]);

	const history = useHistory();

	useEffect(() => getReports(), []);

	const tableActions = [
		{ icon: () => <EditIcon color='primary' fontSize='small' />, tooltip: 'Edit Report', onClick: (event, rowData) => handleView(rowData) },
		{ icon: () => <SendIcon color='disabled' fontSize='small' />, tooltip: 'Send Report', onClick: (event, rowData) => handleEdit(rowData) },
		{ icon: () => <DeleteForeverIcon color='secondary' fontSize='small' />, tooltip: 'Delete Report', onClick: (event, rowData) => handleDelete(rowData) },
	];

	const getReports = () => {
		API.GetDefinitions(account.tokenAuth).then((res) => {
			if (res.status != 201) return setLoading(false);

			initTableData(res.data);
			setLoading(false);
		});
	};

	const initTableData = (data) => {
		let tableData = [];
		const dateFormat = 'MM/DD/YYYY';

		const setDate = (date) => moment.utc(date).tz("America/Phoenix").format(dateFormat);

		data.map((d) => tableData.push({ ...d, createdAt: setDate(d.createdAt) }));

		setTableData(tableData);
		setReports(data);
	};

	const handleView = (rowData) => {
		const report = reports.filter((d) => d.reportId == rowData.reportId)[0];
		history.push(`/admin/report/${rowData.reportId}`, { data: { ...report } });
	};

	const handleEdit = (rowData) => {
		const selectedReport = reports.filter((d) => d.reportId == rowData.reportId)[0];
		setSelectedReport(selectedReport);
		setOpenModal(true);
	};

	const handleDelete = (rowData) => {
		const selectedReport = reports.filter((d) => d.reportId == rowData.reportId)[0];
		setSelectedReport(selectedReport);
		toggleAlert();
	};

	const handleSend = async () => {
		setOpenModal(false);
		setSnackbarState({ open: true, severity: 'info', text: 'Sending Report...' });

		const sendReport = await API.SendReport(account.tokenAuth, selectedReport);
		if (sendReport.status != 201) setSnackbarState({ ...snackbarState, severity: 'error', text: 'Send Report Failed' });

		setSnackbarState({ open: true, severity: 'success', text: `Report "${selectedReport.name}" sent` });
	};

	const handleUpdateSelectedReport = (report) => {
		let _reports = [...reports];
		const index = _reports.findIndex((d) => d.reportId == report.reportId);
		_reports[index] = report;
		initTableData(_reports);
	};

	const handleDeleteReport = () => {
		const { reportId } = selectedReport;
		API.DeleteReport(account.tokenAuth, { reportId }).then((res) => {
			initTableData(reports.filter((d) => d.reportId != reportId));
			toggleAlert();
		});
	};

	const toggleModal = () => setOpenModal(!openModal);

	const toggleAlert = () => setOpenAlert(!openAlert);

	return (
		<div className='profile-page__user-information-tab reports'>
			<h3 className='section-title'>Saved Reports</h3>
			<Grid container>
				{/* <Button variant="contained" color="primary" onClick={toggleModal}>Create Report</Button> */}
				<div className='reports-table'>
					{!loading ? (
						<MaterialTable columns={tableColumns} data={tableData} actions={tableActions} options={tableOptions} localization={tableLocalization} />
					) : (
						<CircularProgress size={40} color='primary' />
					)}
				</div>
			</Grid>
			<ReportModal
				open={openModal}
				title='Send Report'
				selectedReport={selectedReport}
				confirmButton={<SendReportButton onClick={handleSend} />}
				// onSetSelectedReport={(report) => setSelectedReport(report)}
				// onUpdateSelectedReport={handleUpdateSelectedReport}
				onClose={toggleModal}
				readOnly
			/>

			<Alert open={openAlert} title='Delete Report' text='Are you sure you want to delete this report?' onConfirmButtonText='Delete' onConfirm={handleDeleteReport} onCancel={toggleAlert} />

			<Snackbar open={snackbarState.open} severity={snackbarState.severity} text={snackbarState.text} autoHideDuration={null} handleClose={() => setSnackbarState({ ...snackbarState, open: false })} />
		</div>
	);
};

export default connect((s) => ({ account: s.account }))(Reports);
