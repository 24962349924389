import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
// import IdleComponent from "./IdleComponent";

const Layout = (props) => {
	return (
		<div className='page-wrapper'>
			<div className='page-content'>
				{props.showMenu ? <Header history={props.history} /> : null}
				{props.showBanner ? (
					<Banner
						title={props.title}
						showButton={props.showButton}
						showBackButton={props.showBackButton}
						showBackground={props.showBackground}
						backgroundBanner={props.backgroundBanner}
						bannerContentRole={props.bannerContentRole}
						history={props.history}
						match={props.match}
						period={props.period}
					/>
				) : null}
				<Container className='main-container'>
					{/* <IdleComponent history={props.history} /> */}
					{props.children}
				</Container>
			</div>
			{props.showFooter ? <Footer /> : null}
		</div>
	);
};

Layout.propTypes = {
	showMenu: PropTypes.bool,
	showBanner: PropTypes.bool,
	showFooter: PropTypes.bool,
	showButton: PropTypes.bool,
	showBackButton: PropTypes.bool,
	showBackground: PropTypes.bool,
	backgroundBanner: PropTypes.string,
	bannerContentRole: PropTypes.number,
	title: PropTypes.string,
	history: PropTypes.any,
	match: PropTypes.object,
};

Layout.defaultProps = {
	showMenu: true,
	showBanner: true,
	showFooter: true,
	showButton: true,
	showBackButton: false,
	showBackground: true,
	backgroundBanner: '/assets/img/bg1.jpg',
	title: 'Parent / Guardian',
};

export default Layout;
