import React, { useState, useEffect } from 'react';
import { Grid, Button, TextField, Select, FormControl, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Alert from '@material-ui/lab/Alert';

import API from '../../config/api';

import Loader from '../../component/Loader';

const QuestionFormControl = ({ index, data, questions, selectedQuestions, onQuestionChange }) => {
	const [selected, setSelected] = useState(data.questionId);
	const [text, setText] = useState(data.answer);

	useEffect(() => {
		onQuestionChange({ [data.securityQuestionId]: { questionId: selected, answer: text } });
	}, [selected, text]);

	const handleSelectChange = (event) => {
		setText('');
		setSelected(event.target.value);
	};

	const handleTextChange = (event) => setText(event.target.value);

	const setAllowedQuestions = () => {
		let allowedQuestions = [];

		questions.map((d) => {
			if (d.questionId == selected || !selectedQuestions.includes(d.questionId)) allowedQuestions.push(d);
		});

		return allowedQuestions;
	};

	const allowedQuestions = setAllowedQuestions();

	return (
		<FormControl variant='outlined'>
			<label>Question {index}:</label>
			<Select value={selected} onChange={handleSelectChange}>
				{allowedQuestions.map((d) => (
					<MenuItem key={d.questionId} value={d.questionId}>
						{d.question}
					</MenuItem>
				))}
			</Select>
			<TextField value={text} inputProps={{ maxLength: 50 }} onChange={handleTextChange} variant='outlined' required />
		</FormControl>
	);
};

const SecurityQuestions = ({ account, token }) => {
	const [initLoading, setIinitLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [complete, setComplete] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [accountQuestions, setAccountQuestions] = useState([]);
	const [securityQuestions, setSecurityQuestions] = useState([]);

	useEffect(() => getQuestions(), []);

	useEffect(() => {
		if (complete) setTimeout(() => setComplete(false), 3000);
	}, [complete]);

	const getQuestions = () => {
		API.GetQuestion().then((res) => setQuestions(res.data));
		API.GetAllQuestionsByAccountId(token, { accountId: account.accountId }).then((res) => {
			// set securityQuestions for the first time
			let _securityQuestions = {};
			const { data } = res;

			data.map((d) => {
				const { securityQuestionId, questionId, answer } = d;
				_securityQuestions[securityQuestionId] = { questionId, answer };
			});

			setSecurityQuestions(_securityQuestions);
			setAccountQuestions(res.data);
			setIinitLoading(false);
		});
	};

	const handleSecurityQuestions = (obj) => {
		setSecurityQuestions({ ...securityQuestions, ...obj });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let body = { accountId: account.accountId };
		body['securityQuestions'] = Object.values(securityQuestions);

		setLoading(true);

		API.UpdateQuestions(token, body).then((res) => {
			setLoading(false);
			setComplete(true);
		});
	};

	const setSelectedQuestions = () => {
		let selectedQuestions = [];
		const values = Object.values(securityQuestions);

		values.map((d) => selectedQuestions.push(d.questionId));

		return selectedQuestions;
	};

	const selectedQuestions = setSelectedQuestions();

	return (
		<div className='profile-page__security-questions--tab'>
			<h3 className='section-title'>Profile: Change your Security Questions</h3>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={7}>
					{!initLoading ? (
						<form onSubmit={handleSubmit}>
							<FormControl component='fieldset' fullWidth>
								{accountQuestions.map((d, i) => (
									<QuestionFormControl key={d.securityQuestionId} index={++i} data={d} questions={questions} selectedQuestions={selectedQuestions} onQuestionChange={handleSecurityQuestions} />
								))}
							</FormControl>
							<div className='form-action-buttons'>
								{!loading ? (
									<Button color='primary' type='submit' startIcon={<SaveIcon />} disableRipple>
										Save
									</Button>
								) : (
									<Loader loading={true} />
								)}
								{complete && <Alert severity='success'>Your changes have been saved</Alert>}
							</div>
						</form>
					) : (
						<Loader loading={true} />
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default SecurityQuestions;
