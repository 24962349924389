import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';

const SelectPeriods = ({ data, onChange, ...props}) => {

	const { period: current } = props.account.periodSettings;	
	const { period } = props.filters;	

	data = data.map((item) => {
		return { ...item, value: item.period };
	});
	//data = [...data, { period: 'All Periods', year: null, value: 'all' }];

	const [selectedPeriod, setSelectedPeriod] = useState(period || current);

	const handleChange = (e) => {
		const { value } = e.target;
		setSelectedPeriod(value);
		if (onChange) onChange(value);
	};
	return (
		<FormControl className='MuiFormControl__filter-select' disabled={data.length > 0 ? false : true} variant='outlined' size='small' fullWidth>
			<InputLabel id='select-filters-label'>{data.length > 0 ? 'Select Period' : 'There are no periods'}</InputLabel>
			<Select labelId='select-filters-label' id='select-filters' label={data.length > 0 ? 'Select Period' : 'There are no periods'} onChange={(e) => handleChange(e)} value={selectedPeriod}>
				{data &&
					data.length > 0 &&
					data.map((x, i) => {
						return (
							<MenuItem key={i} value={x.value} name={x.period}>
								{x.period === current ? x.period + ' (Current)' : x.period}
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
};

const mapStateProps = (state) => {
	return { 
		account: state.account,
		filters: state.filters 
	};
};

export default connect(mapStateProps)(SelectPeriods);
