import React from 'react';

import ThousandSeparator from './ThousandSeparator';

const Status = ({ data }) => (
	<div className='metrics-status'>
		<div className='metrics-status__item'>
			<p className='metrics__value metrics__value--light'>
				<ThousandSeparator value={data?.underReview} />
			</p>
			<p className='metrics-status__name'>Under Review</p>
		</div>
		<div className='metrics-status__item'>
			<p className='metrics__value metrics__value--green'>
				<ThousandSeparator value={data?.approved} />
			</p>
			<p className='metrics-status__name'>Approved</p>
		</div>
		<div className='metrics-status__item'>
			<p className='metrics__value metrics__value--red'>
				<ThousandSeparator value={data?.notApproved} />
			</p>
			<p className='metrics-status__name'>Rejected</p>
		</div>
	</div>
);

export default Status;
