import { Button, Grid } from '@material-ui/core';
import JoditEditor from 'jodit-react';
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../../component/partials/Layout';
import Api from '../../config/api';
import { setAccount } from '../../redux/actions/account';

const Editor = (props) => {
	const [siteSetting, setSiteSetting] = useState({
		isClosedSubmission: false,
		noteClosedSubmission: '',
		codeOpenSubmission: '',
	});
	const [showErrorMessage, setShowErrorMessage] = useState({
		alert: '',
		message: '',
	});
	const noteClosedSubmission = useRef(null);

	useEffect(() => {
		if (props.account.siteSettings != null) setSiteSetting(props.account.siteSettings);
		if (props.account.siteSettings != null && props.account.siteSettings.codeOpenSubmission !== null) {
			localStorage.setItem('code', props.account.siteSettings.codeOpenSubmission);
		}
	}, []);

	const config = {
		toolbarAdaptive: false,
		buttons: 'source,|,bold,underline,italic,ul,ol,fontsize,link,undo,\n,cut,copy,paste,|,hr',
	};

	const updateSetting = () => {
		const settings = siteSetting;
		console.log('Settings ', settings);
		Api.UpdateSettings(props.account.tokenAuth, settings)
			.then((res) => {
				console.log(res);
				if (res.status !== 201) {
					setShowErrorMessage({ alert: 'error', message: res.message });
					return;
				}
				const siteSettings = settings;
				props.setAccount({ ...props.account, siteSettings });
				setShowErrorMessage({ alert: 'success', message: res.message });
				setTimeout(() => {
					setShowErrorMessage({ alert: '', message: '' });
				}, 2500);
			})
			.catch((err) => console.log(err));
	};

	return (
		<Layout showButton={false} history={props.history} title='Editor'>
			<div className='user-profile-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<JoditEditor
							ref={noteClosedSubmission}
							value={siteSetting.noteClosedSubmission ? siteSetting.noteClosedSubmission : ''}
							config={config}
							tabIndex={1}
							onBlur={(newContent) => setSiteSetting({ ...siteSetting, noteClosedSubmission: newContent })}
							onChange={(newContent) => {}}
						/>
					</Grid>
					<Grid item xs={12} lg={7} style={{ marginTop: 20 }}>
						<Button variant='contained' color='primary' onClick={updateSetting}>
							Submit
						</Button>
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

const mapDispatchToProps = {
	setAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
