import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

import DateRangePickerComponent from '../../component/DateRangePickerComponent';
import Layout from '../../component/partials/Layout';

import Summary from './Summary';
import SingleMetric from './SingleMetric';
import Skeleton from './Skeleton';

import API from '../../config/api';

const initialDateRange = [moment().startOf('year').toDate(), moment().endOf('year').toDate()];

const Metrics = ({ history, account }) => {
	const [loading, setLoading] = useState(true);
	const [dateRange, setDateRange] = useState(initialDateRange);
	const [metrics, setMetrics] = useState({ globals: {}, lowIncome: {}, disabled: {} });

	const { period } = account.periodSettings;

	useEffect(() => getMetrics(), [dateRange]);

	const getMetrics = () => {
		const formatDate = (date) => moment(date).format('YYYY/MM/DD');
		const data = { startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]) };

		setLoading(true);

		API.GetMetrics(account.tokenAuth, data).then((res) => {
			const { status, data } = res;
			if (status != 201) return; // set error alert
			setMetrics(data);
			setLoading(false);
		});
	};

	const handleDateRangeChange = (dates) => {
		if (!dates) return setDateRange(initialDateRange);
		setDateRange(dates);
	};

	return (
		<Layout showMenu showBanner showFooter showBackButton={true} history={history} title='Admin Processor' period={period}>
			<div className='metrics'>
				<div className='metrics__header'>
					<h3 className='section-title'>Metrics</h3>
					<div className='metrics-daterange'>
						<div className='metrics-daterange__filter-group'>
							<label>Reporting:</label>
							<DateRangePickerComponent value={dateRange} onChange={handleDateRangeChange} />
						</div>
					</div>
				</div>
				{!loading ? (
					<div className='metrics__container'>
						<div className='metrics__top-section'>
							<Summary metrics={metrics} />
						</div>
						<div className='metrics__bottom-section'>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<SingleMetric metrics={metrics.lowIncome} title='Low Income Submissions' />
								</Grid>
								<Grid item xs={12} md={6}>
									<SingleMetric metrics={metrics.disabled} title='Disabled/Displaced Submissions' />
								</Grid>
							</Grid>
						</div>
					</div>
				) : (
					<Skeleton />
				)}
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return { account: state.account };
};

export default connect(mapStateToProps)(Metrics);
