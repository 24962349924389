import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import StudentInformationView from './ApplicationReadOnlyComponent/StudentInformationView';
import Prerequisites from './ApplicationReadOnlyComponent/Prerequisites';
import FinancialInformationView from './ApplicationReadOnlyComponent/FinancialInformationView';
// import DDAgreementsView from './ApplicationReadOnlyComponent/DDAgreementsView'
import DDAgreementsView from './ApplicationReadOnlyComponent/DDAgreementsView';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ApplicationNotFound from './ApplicationNotFound';

import api from '../../config/api';

const DDView = (props) => {
	const [application, setApplication] = useState({});
	const [studentName, setStudentName] = useState('Application Student');

	useEffect(() => {
		getApplicationById();
	}, []);

	const getApplicationById = () => {
		api.GetApplicationById(props.account.tokenAuth, { id: props.id }).then((response) => {
			const { status, message, data } = response;
			setApplication(data);
			setStudentName(data.students && data.students.length > 0 ? data.students[0].firstName + ' ' + data.students[0].lastName : 'Application Student');
		});
	};

	return (
		<Fragment>
			{!props.error ? (
				<Fragment>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>1. Student Information</h3>
						</div>
						<div className='form__section--body'>
							<StudentInformationView 
								applicationId={application.applicationId} 
								students={application.students} 
								applicationType={application.applicationType} 								
								applicationPeriod={application.period}
								applicationPreviousPeriod={application.previousPeriod}
								token={props.account.tokenAuth} 
								version={application.version||10}
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>2. Prerequisites</h3>
						</div>
						<div className='form__section--body'>
							<Grid container>
								<Grid item xs={12} lg={7}>
									<Prerequisites 
										data={application} 
										prerequisites={application.prerequisites} 
										token={props.account.tokenAuth}
										version={application.version||10} 
									/>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>3. Household Members and Financial Information</h3>
						</div>
						<div className='form__section--body'>
							<FinancialInformationView
								financialInformation={application.financialMembers}
								account={props.account}
								token={props.account.tokenAuth}
								files={application.files}
								id={application.applicationId}
								previousYear={!!application.previousPeriod ? application.previousPeriod.split('/')[0]:''}
								version={application.version||10} 
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>4. Information Release Form for Disabled/Displaced Students</h3>
						</div>
						<div className='form__section--body'>
							<Grid container>
								<Grid item xs={12} lg={7}>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
										<span style={{ marginLeft: 6 }}>{application.hasPermitShareInfo ? <CheckIcon /> : <CloseIcon />}</span>
										<p style={{ paddingLeft: 25 }}>
											For purposes of the Disabled/Displaced Scholarship Program, I permit the Arizona Department of Revenue and/or the Arizona Department of Child Safety and /or other School Tuition
											Organizations (STOs) to provide information regarding the eligibility status and scholarship limitation information, including related documents for {studentName} to the
											following School Tuition Organization:
										</p>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>5. Agreement Statements</h3>
						</div>
						<div className='form__section--body'>
							<DDAgreementsView
								submittedAgreements={application.submittedAgreements}
								agreements={application.agreements}
								blueprintSubmitted={application.blueprintSubmitted}
								isAgreeStatements={application.isAgreeStatements}
							/>
						</div>
					</div>
				</Fragment>
			) : (
				<ApplicationNotFound message={props.error} />
			)}
		</Fragment>
	);
};

export default DDView;
