import React, { useState, Fragment } from 'react';
import { Button, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import FileUploader from '../../../component/FileUploader';

import API from '../../../config/api';

const AttachmentBox = ({ setUploaded, onViewFile }) => (
	<div className='simple-attachment__box'>
		<div className='attachment__box--header'>
			<div className='left-content'>
				<h5>
					<CheckCircleIcon color='primary' />
					File uploaded
				</h5>
				<Button style={{ paddingLeft: 10 }} color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={onViewFile} disableRipple>
					View file
				</Button>
			</div>
			<IconButton color='secondary' size='small' onClick={(_) => setUploaded(false)}>
				<DeleteForeverIcon />
			</IconButton>
		</div>
	</div>
);

const Uploader = ({ applicationId, token, disabled, onChange }) => (
	<FileUploader id='b2_blueprint' token={token} applicationId={applicationId} fileKey='b2_blueprint' onChange={onChange} disabled={disabled} fullWidth />
);

const B2BlueprintFile = ({ applicationId, token, disabled, files }) => {
	const [uploaded, setUploaded] = useState(files.b2_blueprint);

	const handleChange = (file) => setUploaded(true);

	const handleViewFile = () => {
		const data = { applicationId, fileKey: 'b2_blueprint' };

		const viewFile = window.open();

		API.GetFilesApplications(token, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	return (
		<Fragment>
			{!uploaded ? <Uploader applicationId={applicationId} token={token} disabled={disabled} onChange={handleChange} /> : <AttachmentBox setUploaded={setUploaded} onViewFile={handleViewFile} />}
		</Fragment>
	);
};

export default B2BlueprintFile;
