import React from 'react';
import { Grid, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const ApplicationBox = ({ data, type }) => (
	<div className='application__box'>
		<div className='box__left-column'>
			<div className='box__section'>
				<p className='name'>{data.student.name}</p>
			</div>
			<div className='box__section d-sm-none'>
				<p>
					Created at: <span>{data.created}</span> - Modfied: <span>{data.modified}</span>
				</p>
			</div>
			<div className='box__section'>
				<p>
					Type: <span>{data.type}</span>
				</p>
				<p>
					Parent/Guardian: <span>{data.parent_guardian}</span>
				</p>
				<p className='status'>Application Status: {data.status}</p>
			</div>
		</div>

		<div className='box__right-column'>
			<div className='box__section d-none d-sm-block'>
				<p>
					Created at: <span>{data.created}</span> - Modfied: <span>{data.modified}</span>
				</p>
			</div>
			<div className='box__section'>
				<p>
					School: <span>{data.school}</span>
				</p>
				<p>
					City: <span>{data.city}</span>
				</p>
				<p>
					Grade: <span>{data.grade}</span>
				</p>
			</div>
		</div>

		<div className='box__actions-column'>
			<Grid container>
				<Grid item xs={6} sm={6} md={6} lg={12}>
					{type === 'submitted' ? (
						<Button variant='contained' color='primary' startIcon={<VisibilityIcon />} disableRipple>
							View
						</Button>
					) : (
						<Button variant='contained' color='primary' startIcon={<EditIcon />} disableRipple>
							Edit
						</Button>
					)}
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={12}>
					<Button color='secondary' startIcon={<DeleteForeverIcon />} className='centered' disableRipple>
						Delete
					</Button>
				</Grid>
			</Grid>
		</div>
	</div>
);

export default ApplicationBox;
