import { Backdrop, Box, Button, Checkbox, Fade, FormControl, FormGroup, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Fragment, useEffect, useState } from 'react';
import Alert from '../../component/partials/Alerts/Alert';

import api from '../../config/api';
import { isEmpty, values } from 'lodash';
import ApplicationTraceModal from '../../component/ApplicationTraceEmail/ApplicationTraceModal';

import {STATUS, STATUS_LABEL} from '../../utils/constants';
import { ContactSupportOutlined } from '@material-ui/icons';

const ConfirmApplication = ({ checkReason, confirmChangeStatus, loadingButton, toggleAlertReason, note }) => {
	const [noteValue, setNoteValue] = useState('');

	useEffect(() => {
		if (note) setNoteValue(note);
	}, []);

	const handleNoteStatus = (e) => {
		const { value } = e.target || e;
		setNoteValue(value);
	};

	const handleConfirmStatus = () => confirmChangeStatus(noteValue);

	const getButtonSubmit = () => {
		if (checkReason.indexOf(35) !== -1 || checkReason.indexOf(36) !== -1) {
			if (noteValue.length > 0)
				return (
					<Button disabled={false} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm
					</Button>
				);
			else
				return (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm
					</Button>
				);
		}

		if (checkReason.indexOf(35) === -1 || checkReason.indexOf(36) === -1) {
			if (checkReason.length > 0)
				return (
					<Button disabled={false} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm
					</Button>
				);
			else
				return (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm
					</Button>
				);
		}
	};

	return (
		<>
			<TextField
				style={{ width: '100%', textAlign: 'left', transform: 'translateZ(0px)' }}
				id='outlined-multiline-static-reasons'
				label='Note:'
				multiline
				rows={4}
				value={noteValue}
				variant='outlined'
				onChange={handleNoteStatus}
				inputProps={{ maxLength: 1200 }}
			/>

			<p style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '5px', fontSize: '85%', color: '#949494', fontStyle: 'italic' }}>{`You have ${
				1200 - noteValue.length
			} of 1200 characters remaining.`}</p>

			<div className='alert__buttons'>
				<Button className='cancel' variant='contained' fullWidth disableRipple onClick={() => toggleAlertReason()}>
					Cancel
				</Button>
				{loadingButton ? (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple>
						Loading...
					</Button>
				) : noteValue.length > 1200 ? (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm
					</Button>
				) : (
					getButtonSubmit()
				)}
			</div>
		</>
	);
};

const ConfirmEmailApplication = ({application, dataStatus,confirmChangeStatus,loadingButton,closeDialog, note}) => {
	const traceModalRef = React.useRef({});
	const [processing, setProcessing]=useState(false);

	const handleConfirmStatus = async () =>{
		setProcessing(true);
		const result = await traceModalRef.current.handleSendEmail();		
		confirmChangeStatus(note);
		setProcessing(false);
	}

	return (
		<>
			<ApplicationTraceModal traceModalRef={traceModalRef} application={application} newStatus={dataStatus.newStatus}/>
			<div className='alert__buttons'>
				<Button className='cancel' variant='contained' fullWidth disableRipple onClick={() => closeDialog()}>
					Do Not Send Email
				</Button>
				{loadingButton ? (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple>
						Loading...
					</Button>
				) : (
					<Button disabled={processing} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmStatus}>
						Confirm and Send Email
					</Button>
				)}
			</div>
		</>
	);

};

const ConfirmStandAloneEmailApplication = ({application, dataStatus, confirmEmailSended,loadingButton,closeDialog}) => {
	const traceModalRef = React.useRef({});
	const [processing, setProcessing]=useState(false);

	const handleConfirmSend = async () =>{
		setProcessing(true);
		const result = await traceModalRef.current.handleSendEmail();		
		confirmEmailSended();
		setProcessing(false);
	}

	return (
		<>
			<ApplicationTraceModal traceModalRef={traceModalRef} application={application} newStatus={dataStatus.newStatus}/>
			<div className='alert__buttons'>
				<Button className='cancel' variant='contained' fullWidth disableRipple onClick={() => closeDialog()}>
					Not send email
				</Button>
				{loadingButton ? (
					<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple>
						Loading...
					</Button>
				) : (
					<Button disabled={processing} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={handleConfirmSend}>
						Send Email
					</Button>
				)}
			</div>
		</>
	);

};

const HeaderApplication = (props) => {
	const [titleStatus, setTitleStatus] = useState('');
	const [assignName, setAssignName] = useState('');
	const [processorId, setProcessorId] = useState('');
	const [statusId, setStatusId] = useState('');
	const [success, setSuccess] = useState('');
	const [error, setError] = useState('');
	const [data, setData] = useState({});
	const [dataStatus, setDataStatus] = useState({});
	const [openAlert, setOpenAlert] = useState(false);
	const [openEmail, setOpenEmail] = useState(false);	
	const [openStandAloneEmail, setOpenStandAloneEmail] = useState(false);	
	const [openAlertStatus, setOpenAlertStatus] = useState(false);
	const [openReason, setOpenReason] = useState(false);
	const [icon, setIcon] = useState('question');
	const [title, setTitle] = useState('success');
	//const [showReason, setShowReason] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [radioValue, setRadioValue] = useState(0);
	const [successAsign, setSuccessAsign] = useState(false);
	const [successStatus, setSuccessStatus] = useState(false);
	const [valueStatus, setValueStatus] = useState(0);
	const [resetStatus, setResetStatus] = useState('');
	const [loadingButton, setLoadingButton] = useState(false);
	const [checkReason, setCheckReason] = useState([]);
	const [noteValue, setNoteValue] = useState('');

	useEffect(() => {
		if (props.pathname.assignName) setAssignName(props.pathname.assignName);
		if (props.pathname.processorId) setProcessorId(props.pathname.processorId);
		if (props.pathname.statusId) setStatusId(props.pathname.statusId);
		if (props.pathname.statusId) setValueStatus(props.pathname.statusId);
		if (props.pathname.status && props.pathname.status != null) {
			setTitleStatus(props.pathname.status);
		} else {
			setTitleStatus(props.statusName);
		}
		if (props.pathname.statusId) setResetStatus(props.pathname.statusId);
	}, [props.pathname]);

	useEffect(() => {
		if (props.application && props.application.status === STATUS.NOT_APPROVED) {
			setNoteValue(props.application.rejectedNotes);
		}
		if (props.application && props.application.status === STATUS.NOT_APPROVED && props.application.rejectedReasons && props.application.rejectedReasons.length > 0) {
			let checks = [];
			props.application.rejectedReasons.map((r, i) => {
				checks.push(r.id);
			});
			setCheckReason(checks);
		}

		if (props.application && props.application.status === STATUS.ACTION_NEEDED) {
			setNoteValue(props.application.incompleteNotes);
			let checks = [];
			props.application.incompletedReasons.map((r, i) => {
				checks.push(r.id);
			});
			setCheckReason(checks);
		}


		if (props.application && props.application.status === STATUS.INCOMPLETE) {
			setNoteValue(props.application.incompleteNotes);
		}
		if (props.application && props.application.status === STATUS.INCOMPLETE && props.application.incompletedReasons && props.application.incompletedReasons.length > 0) {
			let checks = [];
			props.application.incompletedReasons.map((r, i) => {
				checks.push(r.id);
			});
			setCheckReason(checks);
		}

		if (props.application && props.application.status !== STATUS.INCOMPLETE && props.application && props.application.status !== STATUS.NOT_APPROVED && props.application.status !== STATUS.ACTION_NEEDED) {
			setNoteValue('');
			setCheckReason([]);
		}
	}, [props.application]);

	useEffect(() => {
		if (props.openReasonModal) setOpenReason(props.openReasonModal);
	}, [props.openReasonModal]);

	const toggleAlert = () => setOpenAlert(!openAlert);
	const toggleAlertStatus = () => setOpenAlertStatus(!openAlertStatus);
	const toggleAlertEmail = () => {		
		setDataStatus({});
		setValueStatus(resetStatus);	
		setOpenEmail(!openEmail);
	}
	const toggleAlertStandAloneEmail =() => {
		setOpenStandAloneEmail(!openStandAloneEmail);
	}
	const toggleAlertReason = () => {
		setValueStatus(resetStatus);
		setOpenReason(!openReason);
		props.openReasonsModalFn(!openReason);
	};

	const confirmAssign = (e, applicationId) => {
		const { value } = e.target || e;
		if (value) {
			setData({
				applicationId: applicationId,
				processorId: value,
			});
			assignParent({
				applicationId: applicationId,
				processorId: value,
			});
		}
	};

	const assignParent = (data) => {
		api
			.AssignToGeneralProcessor(props.pathname.token, data)
			.then((r) => {
				if (r.status !== 201) {
					setSuccess(r.message);
					setIcon('error');
					setTitle('Error');
					return;
				}
				setSuccessAsign(true);
				const showSuccess = setTimeout(() => {
					setSuccessAsign(false);
					setProcessorId(r.data.processorAccountAssignedId);
					setAssignName(r.data.assignedProcessorName);
				}, 1500);
			})
			.catch((err) => {
				console.log(err);
				setSuccess('');
			});
	};

	const changeStatus = (e, applicationId) => {
		const { value } = e.target || e;
		setValueStatus(value);

		switch (value) {
			case STATUS.INCOMPLETE:
				setDataStatus({
					role: props.pathname.role,
					applicationId: applicationId,
					newStatus: value,
				});
				setIcon('question');
				//setOpenReason(true);
				props.openReasonsModalFn(true);
				//setShowReason(false);
				break;
			case STATUS.NOT_APPROVED:
				setDataStatus({
					role: props.pathname.role,
					applicationId: applicationId,
					newStatus: value,
				});
				setIcon('question');
				//setOpenReason(true);
				if(props.pathname.url == '/followup/application'){
					setOpenEmail(true);
				}else{
					props.openReasonsModalFn(true);
				}				
				//setShowReason(false);
				
				break;

			case STATUS.ACTION_NEEDED:
				setDataStatus({
					role: props.pathname.role,
					applicationId: applicationId,
					newStatus: value,
				});
				setIcon('question');
				if(props.pathname.url == '/followup/application'){
					setOpenEmail(true);
				}else{
					props.openReasonsModalFn(true);
				}				
				//setShowReason(true);
				break;

			default:					
				api
					.ChangeStatus(props.pathname.token, {
						role: props.pathname.role,
						applicationId: applicationId,
						newStatus: value,
					})
					.then((r) => {
						if (r.status !== 201) {
							setError(r.message);
							setIcon('error');
							setTitle('Error');
							return;
						}
						setResetStatus(r.data.status);
						setStatusId(r.data.status);
						setSuccessStatus(true);
						setCheckReason([]);
						setNoteValue('');
						const showSuccess = setTimeout(() => {
							setSuccessStatus(false);
							setTitleStatus(r.data.statuslabel);
							props.getValueStatus(r.data.status);
						}, 1500);
					})
					.catch((err) => {
						console.log(err);
						setSuccessStatus(false);
					});
				break;
		}
	};

	const confirmChangeStatus = (notes) => {
		setLoadingButton(true);
		let data = {};
		if (isEmpty(dataStatus)) {
			data = {
				role: props.pathname.role,
				applicationId: props.application.applicationId,
				newStatus: props.application.status,
				reasons: checkReason,
				notes,
			};
		} else {
			data = { ...dataStatus, reasons: checkReason, notes };
		}
		//console.log('notes :>> ', notes);
		api
			.ChangeStatus(props.pathname.token, data)
			.then((r) => {
				if (r.status !== 201) {
					setError(r.message);
					setIcon('error');
					setTitle('Error');
					setOpenAlertStatus(false);
					setLoadingButton(false);
					return;
				}
				setIcon('success');
				setTitle('Success');
				setSuccess('Change application status was success');
				setStatusId(r.data.status);
				setResetStatus(r.data.status);
				setTitleStatus(r.data.statuslabel);
				setCheckReason([]);
				setNoteValue('');
				setOpenReason(false);
				props.openReasonsModalFn(false);
				setOpenAlertStatus(false);
				setOpenEmail(false);
				props.getApplicationById();
				setLoadingButton(false);
				/*if((r.data.status === STATUS.ACTION_NEEDED || r.data.status === STATUS.NOT_APPROVED) && props.pathname.url == '/followup/application'){
					setOpenStandAloneEmail(true);
				}*/
			})
			.catch((err) => {
				console.log(err);
				setSuccess('');
				setError('');
				setCheckReason([]);
				setNoteValue('');
				setOpenReason(false);
				props.openReasonsModalFn(false);
				setOpenAlertStatus(false);
				setLoadingButton(false);
			});
		setIcon('question');
	};

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleChangeStatus = (e) => {
		const { value } = e.target || e;
		setRadioValue(value);
	};

	const handleChangeCheckbox = (id) => () => {
		if (checkReason.length < 1) return setCheckReason([id]);
		if (checkReason.length > 0) {
			let index = checkReason.indexOf(id);
			if (index === -1) {
				setCheckReason([...checkReason, id]);
			} else {
				let filter = checkReason.filter((x) => x !== id);
				setCheckReason(filter);
			}
		}
	};

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const closeSuccess = () => {		
		if(props.pathname.url.includes('/followup/application') == true){
			setSuccess('');
			return;
		}
		else{
			if (dataStatus.newStatus === 4 || dataStatus.newStatus === 6) {
				setSuccess('');
				props.history.goBack();
			} else {
				setSuccess('');
			}
		}
	};

	const headerContent = (url, statusId) => {
		let statusByAdminProcessors = props.status.filter((x) => x.statusId !== 7);
		let content = null;
		switch (url) {
			case '/general/application':
				if (statusId === 5 || statusId === 6 || statusId === 7) {
					return (content = null);
				} else {
					return (content = (
						<Fragment>
							<p>Change Application Status:</p>
							<FormControl variant='outlined' style={{ width: 180 }} size='small'>
								<Select
									labelId='reassign-to'
									id='demo-simple-select-outlined'
									value={props.status.findIndex((d) => d.statusId === valueStatus) === -1 ? 0 : valueStatus}
									onChange={(e) => changeStatus(e, props.pathname.applicationId)}
									disabled={successStatus}>
									{props.status.findIndex((d) => d.statusId === valueStatus) === -1 && (
										<MenuItem disabled={true} value={0}>
											-- Change Application Status --
										</MenuItem>
									)}
									{props.status.map((r, i) => {
										return (
											<MenuItem key={i} disabled={valueStatus === r.statusId ? true : false} value={r.statusId}>
												{r.statusLabel}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Fragment>
					));
				}
			case '/followup/application':
				if ((statusId === 7 || valueStatus === 7) || (statusId === 6 || valueStatus === 6)) {
					return (content = null);
				} else {
					return (content = (
						<Fragment>
							<p>Change Application Status:</p>
							<FormControl variant='outlined' style={{ width: 180 }} size='small'>
								<Select
									labelId='reassign-to'
									id='demo-simple-select-outlined'
									value={props.status.findIndex((d) => d.statusId === valueStatus) === -1 ? 0 : valueStatus}
									// value={valueStatus}
									onChange={(e) => changeStatus(e, props.pathname.applicationId)}
									disabled={successStatus}>
									{props.status.findIndex((d) => d.statusId === valueStatus) === -1 && (
										<MenuItem disabled={true} value={0}>
											-- Change Application Status --
										</MenuItem>
									)}
									{props.status.map((r, i) => {
										return (
											<MenuItem key={i} disabled={valueStatus === r.statusId ? true : false} value={r.statusId}>
												{r.statusLabel}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Fragment>
					));
				}
			case '/admin-processor/application':
				return (content = (
					<Fragment>
						<p>Change Application Status:</p>
						<FormControl variant='outlined' style={{ width: 180 }} size='small'>
							<Select
								labelId='reassign-to'
								id='demo-simple-select-outlined'
								value={statusByAdminProcessors.findIndex((d) => d.statusId === valueStatus) === -1 ? 0 : valueStatus}
								onChange={(e) => changeStatus(e, props.pathname.applicationId)}
								disabled={successStatus}>
								{statusByAdminProcessors.findIndex((d) => d.statusId === valueStatus) === -1 && (
									<MenuItem disabled={true} value={0}>
										-- Change Application Status --
									</MenuItem>
								)}
								{statusByAdminProcessors.map((r, i) => {
									return (
										<MenuItem key={i} disabled={valueStatus === r.statusId ? true : false} value={r.statusId}>
											{r.statusLabel}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Fragment>
				));
			default:
				return content;
		}
	};

	
	return (
		<div className='form__section'>
			{props.pathname && props.pathname.url === '/admin-processor/application' ? (
				<div className='reassign-section'>
					<p>
						Assign to: <span>{assignName}</span>
					</p>
					<Box display='flex' flexDirection='row' justifyContent='flex-start' alignContent='center' alignItems='center'>
						<FormControl variant='outlined' style={{ width: 180 }} size='small'>
							<InputLabel id='reassign-to'>Reassign to...</InputLabel>
							<Select
								labelId='reassign-to'
								label='Reassign to...'
								id='demo-simple-select-outlined'
								value={processorId}
								onChange={(e) => confirmAssign(e, props.pathname.applicationId)}
								disabled={successAsign}>
								{props.pathname.assignationTable.map((r, i) => {
									return (
										<MenuItem key={i} disabled={processorId === r.accountId ? true : false} value={r.accountId}>
											{r.processorName}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						{successAsign && (
							<span style={{ color: '#7a9903', marginLeft: 5 }}>
								<CheckCircleOutlineIcon fontSize='medium' />
							</span>
						)}
					</Box>
				</div>
			) : null}
			<div className='status-section'>
				<div className='status-section-info'>
					<div>
						<p>Application Status:</p>
						<span>{titleStatus}</span>
					</div>
					<div className='status-section-info-select'>
						{headerContent(props.pathname.url, props.pathname.statusId)}
						{successStatus && (
							<span style={{ color: '#7a9903', marginLeft: 5 }}>
								<CheckCircleOutlineIcon fontSize='medium' />
							</span>
						)}
					</div>
				</div>
			</div>

			<Alert open={openAlert} title='Assign Member' text='Are you sure you want to assign this member?' confirmButtonText='Confirm' onConfirm={assignParent} onCancel={toggleAlert} />
			<Alert
				icon='question'
				open={openAlertStatus}
				title='Change Application Status'
				text={`Are you sure you want to change to status to ${STATUS_LABEL[dataStatus.newStatus]}?`}
				confirmButtonText='Confirm'
				onConfirm={() => confirmChangeStatus()}
				onCancel={toggleAlertStatus}
			/>
			<Alert icon={icon} open={!!success} title={title} text={success} confirmButtonText='Done' onConfirm={() => closeSuccess()} />
			<Alert icon={icon} open={!!error} title={title} text={error} confirmButtonText='Close' onConfirm={() => setError('')} />


			{/* Modal for change status selecting reasons */}
			<Modal open={openReason} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
				<Fade in={openReason}>
					<div className='app-alert__container-status'>
						<div className='alert__body'>
							<div className='alert__icon'>
								<HelpOutlineIcon />
							</div>
							<h2>Set reasons and confirm status change</h2>
							{valueStatus === 4 && (
								<div style={{ textAlign: 'left', paddingBottom: 20 }}>
									{props.reasonIncomplete.map((r, i) => {
										return (
											<ExpansionPanel key={i} expanded={expanded === 'reasonpanel-' + i} onChange={handleChange('reasonpanel-' + i)}>
												<ExpansionPanelSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls='reasonpanelbh-content'
													id='reasonpanelbh-header'
													style={{ backgroundColor: '#9bb43b', color: 'white', fontFamily: 'HelveticaMedium', fontSize: 14 }}>
													<p>{r.reasontype}</p>
												</ExpansionPanelSummary>
												{r.reasons.map((x, c) => {
													return (
														<ExpansionPanelDetails key={c} style={{ fontFamily: 'HelveticaThin', fontSize: 14 }}>
															<FormControl component='fieldset'>
																<FormGroup>
																	<FormControlLabel
																		control={<Checkbox checked={checkReason.indexOf(x.reasonIncompletedId) === -1 ? false : true} onChange={handleChangeCheckbox(x.reasonIncompletedId)} />}
																		label={<span dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>}
																	/>
																</FormGroup>
															</FormControl>
														</ExpansionPanelDetails>
													);
												})}
											</ExpansionPanel>
										);
									})}
								</div>
							)}

							{valueStatus == 6 && (
								<div style={{ textAlign: 'left', paddingBottom: 20 }}>
									{props.reasonRejected.map((r, i) => {
										return (
											<ExpansionPanel key={i} expanded={expanded === 'reasonpanel-' + i} onChange={handleChange('reasonpanel-' + i)}>
												<ExpansionPanelSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls='reasonpanelbh-content'
													id='reasonpanelbh-header'
													style={{ backgroundColor: '#9bb43b', color: 'white', fontFamily: 'HelveticaMedium', fontSize: 14 }}>
													<p>{r.reasontype}</p>
												</ExpansionPanelSummary>
												{r.reasons.map((x, c) => {
													return (
														<ExpansionPanelDetails key={c} style={{ fontFamily: 'HelveticaThin', fontSize: 14 }}>
															<FormControl component='fieldset'>
																<FormGroup>
																	<FormControlLabel
																		control={<Checkbox checked={checkReason.indexOf(x.reasonRejectedId) === -1 ? false : true} onChange={handleChangeCheckbox(x.reasonRejectedId)} />}
																		label={<span dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>}
																	/>
																</FormGroup>
															</FormControl>
														</ExpansionPanelDetails>
													);
												})}
											</ExpansionPanel>
										);
									})}
								</div>
							)}						

							<ConfirmApplication checkReason={checkReason} confirmChangeStatus={confirmChangeStatus} loadingButton={loadingButton} toggleAlertReason={toggleAlertReason} note={noteValue} />
						</div>
					</div>
				</Fade>
			</Modal>

			{/* Modal for trace email */}
			<Modal open={openEmail} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
				<Fade in={openEmail}>
					<div className='app-alert__container-status'>
						<div className='alert__body'>
							<div className='alert__icon'>
								<HelpOutlineIcon />
							</div>
							<h2>Change Application Status</h2>

							<ConfirmEmailApplication application={props.application} dataStatus={dataStatus} confirmChangeStatus={confirmChangeStatus} loadingButton={loadingButton} closeDialog={toggleAlertEmail} note={noteValue}/>														
						</div>
					</div>
				</Fade>
			</Modal>

			<Modal open={openStandAloneEmail} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
				<Fade in={openStandAloneEmail}>
					<div className='app-alert__container-status'>
						<div className='alert__body'>
							<div className='alert__icon'>
								<HelpOutlineIcon />
							</div>
							<h2>Send Notification Email</h2>

							<ConfirmStandAloneEmailApplication application={props.application} dataStatus={dataStatus} confirmEmailSended={toggleAlertStandAloneEmail} loadingButton={loadingButton} closeDialog={toggleAlertStandAloneEmail}/>
						</div>
					</div>
				</Fade>
			</Modal>			
		</div>
	);
};

export default HeaderApplication;
