import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormControl, FormControlLabel, RadioGroup, Radio, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Modal from '../../component/Modal';

const RadioButton = (props) => <Radio color='primary' icon={<span className='radio__light-icon' />} checkedIcon={<span className='radio__light-icon checked' />} {...props} />;

const LIApplication = ({}) => (
	<Fragment>
		<p>
			Family must meet the <span className='text-decoration-underline'>income requirements</span> and one of the following:
		</p>
			<ul className='requirements-list bullet-list'>
				<li>
					Attended an Arizona public school as a full-time student for at least 90 days of the prior fiscal year or one full semester and then transferred from the public school to a private school;
					Those students who transfer to a qualified school after the first full semester are eligible to receive a scholarship that same academic year.
				</li>
				<li>Is enrolling in a private school kindergarten. The student must be 5 years old as of September 1st.</li>
				<li>Is enrolling in a private preschool program for students with disabilities; Student must have an IEP, MET or ISP issued by an Arizona public school.</li>
				<li>Is a dependent of a member of the armed forces of the United States who is stationed in Arizona pursuant to military orders.</li>
				<li>
					Received a <u>low-income corporate scholarship</u> in a prior year and the child continued to attend a private school in subsequent years; OR Received an{' '}
					<u>original individual</u> scholarship or a <u>switcher individual scholarship</u> in a prior year and the child continued to attend a private school in subsequent years.
				</li>
				<li>Student was <b>homeschooled in a prior school year and then directly enrolled in a qualified private school.</b> The student is also eligible if the transition occurs within the same academic year, as long as the transition is from homeschooling directly to the qualified private school.</li>
				<li>Student moved to Arizona from <b>out of state, or internationally, and enrolled directly</b> in a qualified private school.</li>
				<li>Student participated in the <b>Empowerment Scholarship Account (ESA) program</b> and did not renew the account or accept the scholarship, in order to accept an STO scholarship.</li>
			</ul>
			<Link to={{ pathname: '/application/create', state: { type: 'LI' } }} className='text-decoration-none'>
				<Button variant='contained' color='secondary' startIcon={<AddIcon />} fullWidth disableRipple>
					Start new LI application
				</Button>
			</Link>
	</Fragment>
);

const DDAPlication = ({}) => (
	<Fragment>
		<ul className='requirements-list bullet-list' style={{ marginTop: 0 }}>
			<li>Student has an IEP, MET or ISP from an Arizona public school (for preschool and grades K-12); OR</li>
			<li>Student has a 504 plan from an Arizona public school (for grades K-12 only); OR</li>
			<li>Student was placed at one time in the Arizona foster care system (for grades K-12 only and to be verified by the Arizona Department of Child Safety (DCS))</li>
		</ul>
		<Link to={{ pathname: '/application/create', state: { type: 'DD' } }} className='text-decoration-none'>
			<Button variant='contained' color='secondary' startIcon={<AddIcon />} fullWidth disableRipple>
				Start new DD application
			</Button>
		</Link>
	</Fragment>
);

const NewApplicationModal = ({ open, onClose }) => {
	const [radioValue, setRadioValue] = useState('low_income');

	const handleChange = (event) => setRadioValue(event.target.value);

	return (
		<Modal open={open} onClose={onClose}>
			<div className='new-application__modal'>
				<h3 className='section-title'>Select type of Application</h3>
				<FormControl component='fieldset'>
					<RadioGroup name='application_type' value={radioValue} onChange={handleChange}>
						<div className={`radio__container ${radioValue !== 'low_income' ? 'inactive' : ''}`}>
							<FormControlLabel value='low_income' control={<RadioButton />} label='Low Income Corporate Scholarship Application (A.R.S § 43-1183)' />
						</div>
						<div className={`radio__container ${radioValue !== 'disabled' ? 'inactive' : ''}`}>
							<FormControlLabel value='disabled' control={<RadioButton />} label='Disabled/Displaced Student Scholarship Application' />
						</div>
					</RadioGroup>
				</FormControl>
				{radioValue === 'low_income' ? <LIApplication /> : <DDAPlication />}
			</div>
		</Modal>
	);
};

export default NewApplicationModal;
