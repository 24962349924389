import { SAVE_COLUMNS, SET_FILTER_ID, SET_SELECTED_PERIOD } from '../constants';

const initialState = {};

export default function filter(state = initialState, action) {	
	switch (action.type) {
		case SAVE_COLUMNS:
			return {				
				...state,
				...action.data,
			};
		case SET_FILTER_ID:
			return {
				...state,
				...action.data,				
			};
		case SET_SELECTED_PERIOD:
			return {
				...state,
				period: action.period,
			}
		default:
			return state;
	}
}
