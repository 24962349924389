import React, { useState, Fragment } from 'react';
import { Grid, Box, Button, TextField, Typography, useScrollTrigger, FormHelperText } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Tooltip from '../../component/Tooltip';
import Loader from '../../component/Loader';
import { REGEX } from '../../utils/constants';
import { isEmpty, isEqual } from 'lodash';
import Alert from '@material-ui/lab/Alert';

import Api from '../../config/api';

import _ from 'lodash';

import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
	root: {},
	margin: {},
	withoutLabel: {},
	textField: {
		width: '330px',
	},
}));

const Password = (props) => {
	const classes = useStyles();
	const [smt, setSmt] = useState(false);
	const [snackbar, setSnackbar] = useState(false);

	const [passwordError, setPasswordError] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [currentPassword, setCurrentPassword] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [values, setValues] = React.useState({
		password: '',
		newpassword: '',
		confirmpassword: '',
		showPassword: false,
	});

	const [saveDisabled, setSaveDisabled] = React.useState(true);

	function isEmptyOrSpaces(str) {
		return str === null || str.match(/^ *$/) !== null;
	}

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		setSaveDisabled(false);
		setPasswordError(false);
		setSuccess(false);
		setAlertMessage('');
		setCurrentPassword(false);
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const validate = async () => {
		setLoading(true);
		setSuccess(false);

		if (isEmpty(values.password)) {
			setPasswordError(true);
			setPasswordMessage('Password is required');
			setLoading(false);
			return;
		}

		if (values.password.length > 32) {
			setPasswordError(true);
			setPasswordMessage('Password must be 8 - 32 characters long');
			setLoading(false);
			return;
		}

		if (isEmpty(values.newpassword)) {
			setPasswordError(true);
			setPasswordMessage('New password is required');
			setLoading(false);
			return;
		}
		if (values.newpassword.length > 32) {
			setPasswordError(true);
			setPasswordMessage('New password must be 8 - 32 characters long');
			setLoading(false);
			return;
		}
		if (isEqual(values.password, values.newpassword)) {
			setPasswordError(true);
			setPasswordMessage('The new password must be different to current password');
			setLoading(false);
			return;
		}

		if (!isEqual(values.newpassword, values.confirmpassword)) {
			setPasswordError(true);
			setPasswordMessage('The passwords are different');
			setLoading(false);
			return;
		}
		if (!REGEX.passwordRegEx.test(values.newpassword)) {
			setPasswordError(true);
			setPasswordMessage('Password criteria has not been met. Hover over question mark for requirements.');
			setLoading(false);
			return;
		}
		Api.UpdatePassword(props.token, { ogpassword: values.password, password: values.newpassword, repassword: values.confirmpassword, account_id: props.account.accountId })
			.then((res) => {
				if (res.status !== 201) {
					setLoading(false);
					setSuccess(false);
					setAlertMessage(res.message);
					if (res.field === 'currentPassword') setCurrentPassword(true);
					return;
				}
				setValues({
					password: '',
					newpassword: '',
					confirmpassword: '',
					showPassword: false,
				});
				setLoading(false);
				setAlertMessage('');
				setSuccess(true);
			})
			.catch((err) => {
				console.log('Error: ', err);
				setLoading(false);
				setSuccess(true);
			});
	};

	const [open, setOpen] = React.useState(false);
	const [openError, setOpenError] = React.useState(false);
	const [openErrorMatch, setOpenErrorMatch] = React.useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		setOpenError(false);
		setOpenErrorMatch(false);
	};

	return (
		<div className='profile-page__password--tab'>
			<h3 className='section-title'>Profile: Change Password</h3>
			<div className='page__password--form'>
				<div className='page__password--input'>
					<FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
						<InputLabel error={values.passValid} htmlFor='outlined-adornment-password'>
							Current Password
						</InputLabel>
						<OutlinedInput
							error={values.passValid}
							id='outlined-adornment-password'
							label='Current Password'
							type={values.showPassword ? 'text' : 'password'}							
							onChange={handleChange('password')}							
							value={values.password}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{ maxLength: 32 }}
						/>
					</FormControl>
				</div>
				<span className='form__space'></span>
				<div className='page__password--input'>
					<FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
						<InputLabel error={values.newPassValid} htmlFor='outlined-adornment-password'>
							New Password
						</InputLabel>
						<OutlinedInput
							error={values.newPassValid}
							id='outlined-adornment-password'
							label='New Password'
							type={values.showPassword ? 'text' : 'password'}							
							onChange={handleChange('newpassword')}							
							value={values.newpassword}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{ maxLength: 32 }}
						/>
						<FormHelperText id='my-helper-text' style={{ color: 'red' }}>
							{passwordError ? passwordMessage : null}
						</FormHelperText>
					</FormControl>
					<Tooltip
						className='tooltip_space'
						tooltipStyle={{
							backgroundColor: '#ffffff',
							color: 'black',
							maxWidth: 280,
							fontSize: 12,
							border: '1px solid #9bb43b',
							padding: 10,
						}}
						title={
							<Fragment>
								<Typography variant='body1'>Criteria:</Typography>
								<br />
								<Typography variant='caption'>
									Must be between 8 - 32 characters long <br />
									Alphabet uppercase letter (A-Z) include at least 1<br /> Alphabet lowercase letter (a-z) include at least 1<br /> Decimal digit number (0-9) include at least 1 <br />
									Special characters include at least 1{' '}
								</Typography>
							</Fragment>
						}
					/>
				</div>
				<span className='form__space'></span>
				<div className='page__password--input'>
					<FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
						<InputLabel error={values.confPassValid} htmlFor='outlined-adornment-password'>
							Confirm Password
						</InputLabel>
						<OutlinedInput
							error={values.confPassValid}
							id='outlined-adornment-password'
							label='Confirm Password'
							type={values.showPassword ? 'text' : 'password'}
							value={values.confirmpassword}
							onChange={handleChange('confirmpassword')}
							labelWidth={100}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
										{values.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{ maxLength: 32 }}
						/>
						{/* <FormHelperText id="my-helper-text">{success ? 'Password was updated!' : null}</FormHelperText> */}
					</FormControl>
				</div>
				<div className='page__password--input'>
					{!!alertMessage && (
						<span style={{ marginTop: 20 }}>
							<Alert onClose={() => setAlertMessage('')} severity='error'>
								{alertMessage}
							</Alert>
						</span>
					)}
					{success && (
						<span style={{ marginTop: 20 }}>
							<Alert onClose={() => setSuccess(false)} severity='success'>
								Password was updated!
							</Alert>
						</span>
					)}
				</div>
				<div className='password__button--box' display='flex'>
					{!loading ? (
						<Button color='primary' classes={{ disabled: classes.disabledButton }} onClick={validate} disabled={saveDisabled}>
							<SaveIcon />
							<div style={{ paddingLeft: 8, paddingTop: 5 }}>SAVE</div>
						</Button>
					) : (
						<div style={{ marginTop: 15 }}>
							<Loader loading={true} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Password;
