import React, { Component, useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default class NotAllowed extends Component {
	render() {
		return (
			<Fragment>
				<Grid container style={{ backgroundColor: 'white' }}>
					<Grid container direction='column' justify='center' alignItems='center'>
						<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
							<div style={{ marginBottom: 40 }}>
								<img src={'/assets/img/logo.jpg'} />
							</div>
							<div style={{ color: '#41462d', marginBottom: 20 }}>
								<Typography variant='h4' style={{ fontWeight: 'bolder' }}>
									Access Denied
								</Typography>
							</div>
							<div style={{ color: '#4f5243', textAlign: 'center' }}>
								<Typography variant='h6'>The page that you are trying to reach is unavailable</Typography>
								<Typography variant='h6'>Please return to the home page</Typography>
							</div>
							<br />
							<Button style={{ color: '#41462d' }} onClick={() => window.location.replace('/')} startIcon={<ChevronLeftIcon />}>
								<h2>Back to Home</h2>
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}
