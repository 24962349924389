// Material UI
import { Button, Hidden, Tooltip } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
// Icon
import PhoneIcon from '@material-ui/icons/Phone';
import Snackbar from '../../component/Snackbar';
import React, { useState, Fragment } from 'react';

const UserInformation = (props) => {

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
	}
	const alternateCopyToClipboard = (email) => {
		const textArea = document.createElement("textarea");
		textArea.value = email;
		document.body.appendChild(textArea);
		textArea.focus({ preventScroll: true });
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	};

	const handleCopyToClipboard = (email) => {
		try {

		if (navigator.clipboard) {
      		navigator.clipboard.writeText(email);
		} else {
		  alternateCopyToClipboard(email);
		}
    	setOpenSnackBar(true);
	  } catch (err) {
			console.log("Unable to copy to clipboard",err);
		}
  	};	

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return ['(', match[1], ') ', match[2], '-', match[3]].join('');
		}
		return null;
	};
	return (
		<div className='user__information'>
			<Button variant='contained' size='small' color='primary' onClick={() => props.history.goBack()} disableRipple>
				Back to dashboard
			</Button>
			{props.parent != null && (
				<Fragment>
					<p className='user-name' style={{ marginTop: 40 }}>
						Parent Information
					</p>
					<div className='user-information'>
						<p style={{ marginTop: 20 }} className='p-name'>
							{props.parent.firstName} {props.parent.lastName}
						</p>
						<p style={{ marginTop: 10 }}>
							<span className='p-street-address'>{props.parent.address}</span>
						</p>
						{(props.parent.city || props.parent.state || props.parent.postalCode) && (
							<p style={{ marginTop: 10 }}>
								<span className='p-locality'>
									{props.parent.city}, {props.parent.state}, {props.parent.postalCode}
								</span>
							</p>
						)}
						{props.parent.phone && (
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<PhoneIcon style={{ color: '#7a9903' }} fontSize='small' /> <span style={{ marginLeft: 10 }}>{formatPhoneNumber(props.parent.phone)}</span>
							</div>
						)}
						<Hidden only={['xs']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<Tooltip title={"Copy to Clipboard"} aria-label='add'>
									<span className='u-email' style={{ marginLeft: 10, cursor: "pointer" }} onClick={()=>{handleCopyToClipboard(props.parent.email)}}>
										{props.parent.email}
									</span>
								</Tooltip>
								<Snackbar open={openSnackBar} severity={'success'} autoHideDuration={3000} text={'Email Successfully Copied to Clipboard'} handleClose={handleCloseSnackBar} />
							</div>
						</Hidden>
						<Hidden only={['sm', 'md', 'lg', 'xl']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<span className='u-email' style={{ marginLeft: 10 }}>
									{props.parent.email}
								</span>
							</div>
						</Hidden>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default UserInformation;
