import * as actionTypes from '../constants';

const initialState = {
	data: [],
	loading: false,
	updating: false,
	error: false,
	errorMessage: '',
};

export default function statusEmail(state = initialState, action) {
	switch (action.type) {
		case actionTypes.LOADED_STATUS_EMAIL_LIST:
			return {
				...state,
				loading: action.loading,
				data:[...action.emailList]
			};
		
		case actionTypes.LOADING_STATUS_EMAIL_LIST:
			return {
				...state,
				loading: action.loading,
			};

		case actionTypes.UPDATING_STATUS_EMAIL_LIST:
		return {
				...state,
				updating: action.updating,
			};

		case actionTypes.UPDATED_STATUS_EMAIL_LIST:
			return {
				...state,
				updating: action.updating,
				data:[...action.emailList]
			};

		case actionTypes.ERROR_STATUS_EMAIL_LIST:
			return {
				...state,
				loading: action.loading,
				updating: action.updating,
				error: action.error,
				errorMessage: action.errorMessage
			};


		default:
			return state;
	}
}
