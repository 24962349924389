import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, IconButton, Fade, Backdrop, Button } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import CloseIcon from '@material-ui/icons/Close';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import { connect } from 'react-redux';
import { setFilters } from '../../../redux/actions/filters';
import Api from '../../../config/api';
import Loader from '../../Loader';
import { remove } from 'lodash';
import Alert from '../Alerts/Alert';

const SelectColumns = (props) => {
	const siteAdminFilter = `SiteAdminFilter_${props.role}`;
	const [columns, setColumns] = useState([]);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState('');

	const saveReduxAndLocalStorage = (filter) => {
		props.setFilters(filter);
		localStorage.setItem(siteAdminFilter, JSON.stringify(filter));
	};

	const checkedColumns = async () => {
		let list = [...props.allColumns];

		if (list.length > 0) {
			list = list.map((x, i) => {
				const isChecked = props.filters.columns.some((f) => f.name === x.name);
				return {
					columnId: x.columnId,
					dataType: x.dataType,
					label: x.label,
					name: x.name,
					reference: x.reference,
					checked: isChecked,
				};
			});
		}

		setColumns(list);
	};

	useEffect(() => {
		checkedColumns();
	}, [props.filters.columns]);

	const handleChange = (event) => {
		const { name, checked } = event.target;
		const columnsFound = columns.filter((f) => f.name === name);
		if (columnsFound.length > 0) {
			const list = columns.map((x, i) => {
				return {
					columnId: x.columnId,
					dataType: x.dataType,
					label: x.label,
					name: x.name,
					reference: x.reference,
					checked: columnsFound[0].name === x.name ? checked : x.checked,
				};
			});
			setColumns(list);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		props.flagSaveNewFilterFromSaveColumn({ ...props.filters });
		const isColumnChecked = columns.filter((x, i) => x.checked === true);
		let updateFilter = {};

		let orderedColumns = props.filters.columns;
		const addColumns = isColumnChecked.filter((f) => !orderedColumns.some((s) => s.name === f.name));
		const removeColumns = orderedColumns.filter((f) => !isColumnChecked.some((s) => s.name === f.name));

		if (removeColumns.length > 0) {
			for (let i = orderedColumns.length - 1; i >= 0; i--) {
				const oCol = orderedColumns[i];
				const exist = removeColumns.some((s) => s.name == oCol.name);
				if (exist) orderedColumns.splice(i, 1);
			}
		}
		orderedColumns = [...orderedColumns, ...addColumns];

		orderedColumns.forEach((o) => delete o.checked);

		if (props.filters && props.filters.default) {
			setLoading(false);
			saveReduxAndLocalStorage({ ...props.filters, filterId: null, name: '', columns: [...orderedColumns] });
			props.openSaveFilterModal();
			props.openSaveColumns(false);
		} else {
			updateFilter = {
				filterId: props.filters.filterId,
				name: props.filters.name ? props.filters.name : 'Default',
				columns: orderedColumns,
				role: props.role,
				pageSize: props.filters.options.pageSize,
			};
			console.log(props.filters, updateFilter);
			props.flagSaveNewFilterFromSaveColumn({ ...props.filters });
			Api.UpdateFilter(props.account.tokenAuth, updateFilter)
				.then((res) => {
					if (res.status !== 201) {
						setLoading(false);
						return;
					}
					setSuccess('The columns were updated');
					setLoading(false);
					saveReduxAndLocalStorage({ ...props.filters, filterId: props.filters.filterId, name: props.filters.name ? props.filters.name : 'Default', columns: orderedColumns });
					props.openSaveColumns(true);
				})
				.catch((err) => console.log(err));
		}
	};

	const handleCloseAlert = () => {
		setSuccess('');
	};

	return (
		<Fragment>
			<Modal open={props.open} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
				<Fade in={props.open}>
					<div className='app-modal__container-column'>
						<div className='modal__header'>
							<button type='button' className='close-modal__button' onClick={() => props.openSaveColumns()}>
								Close <CloseIcon />
							</button>
						</div>
						<div className='modal__body'>
							<h3 className='section-title'>Select Columns</h3>
							<FormControl component='fieldset'>
								<FormGroup>
									{columns.length > 0 &&
										columns.map((x, i) => {
											return <FormControlLabel key={i} control={<Checkbox checked={x.checked} onChange={handleChange} name={x.name} />} label={x.label} />;
										})}
								</FormGroup>
							</FormControl>
							<div className='buttons-container'>
								{!loading ? (
									<Fragment>
										<Button type='button' size='large' startIcon={<CloseIcon />} className='close-btn' onClick={() => props.openSaveColumns()} disableRipple fullWidth>
											Cancel
										</Button>
										<Button type='submit' size='large' color='primary' variant='contained' startIcon={<SaveIcon />} disableRipple fullWidth onClick={() => handleSubmit()}>
											{props.filters && props.filters.default ? 'Save' : 'Update'}
										</Button>
									</Fragment>
								) : (
									<Loader loading />
								)}
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
			<Alert icon='success' open={!!success} title={'Update Columns'} text={success} confirmButtonText='Done' onConfirm={() => handleCloseAlert()} />
		</Fragment>
	);
};

SelectColumns.propTypes = {};
const mapStateToProps = (state) => {
	return {
		account: state.account,
		filters: state.filters,
	};
};

const mapDispatchToProps = {
	setFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectColumns);
