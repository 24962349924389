import React, { useState, useEffect } from 'react';

import { BorderedPanel, BorderedPanelHeader } from '../../component/BorderedPanel';
import StudentsList from './StudentsList';
import StudentForm from './StudentForm';

import API from '../../config/api';

const StudentInformation = ({ application, account, onAutosaveLoading, onAutosaveSuccess }) => {
	const [students, setStudents] = useState([...application.students]);
	const [criteria, setCriteria] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const [deletedStudent, setDeletedStudent] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	const { previousPeriod: applicationPreviousPeriod } = application;
	
	// set criteria
	useEffect(() => getCriteria(), []);

	const getCriteria = () => {
		API.GetCriteria(account.tokenAuth).then((res) => {
			let data = res.data.map((item) => {
				item.description = item.description.replace('{:previousPeriod}', applicationPreviousPeriod);
				return item;
			});

			if(application.version <= 20){
				//only show new creiterias for new version
				data = data.filter(f=>f.criteriaId<8);				
			}
			
			setCriteria(data);
		});		
	};

	const handleUpdateStudents = (student) => {
		const filterLength = students.filter((d) => d.studentId == student.studentId).length;
		if (filterLength == 0) setStudents([...students, student]);
	};

	const handleUpdateSelectedStudent = (student) => {
		let _students = [...students];
		const index = _students.findIndex((d) => d.studentId == student.studentId);
		_students[index] = student;
		setStudents(_students);
	};

	const handleEdit = (student, edit) => {
		setSelectedStudent(student);
		setIsEdit(edit);
	};

	const handleDelete = (studentId) => {
		setStudents(students.filter((d) => d.studentId != studentId));
		setDeletedStudent(studentId);
	};

	const studentsCount = students.length;

	return (
		<BorderedPanel>
			{/* <BorderedPanelHeader title="One student per application" /> */}
			<StudentsList application={application} account={account} students={students} selectedStudent={selectedStudent} criteria={criteria} onEdit={handleEdit} onDelete={handleDelete} />
			<StudentForm
				account={account}
				editMode={isEdit}
				application={application}
				criteria={criteria}
				selectedStudent={selectedStudent}
				deletedStudent={deletedStudent}
				studentsCount={studentsCount}
				onEdit={handleEdit}
				onUpdateStudents={handleUpdateStudents}
				onUpdateSelectedStudent={handleUpdateSelectedStudent}
				onAutosaveLoading={onAutosaveLoading}
				onAutosaveSuccess={onAutosaveSuccess}
			/>
		</BorderedPanel>
	);
};

export default StudentInformation;
