import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const Chart = ({ metrics }) => {
	const dataColors = { green: '#9BB43B', red: '#A0201C' };
	const labelStyle = { fill: '#fff', fontSize: '5px', fontFamily: 'AvenirBold' };

	return (
		<div className='metrics__piechart'>
			{metrics.globals.submissions != '0' ? (
				<PieChart
					data={[
						{ title: 'DD', value: metrics.disabled.submissions || 0, color: dataColors.green },
						{ title: 'LI', value: metrics.lowIncome.submissions || 0, color: dataColors.red },
					]}
					totalValue={metrics.globals.submissions}
					label={({ dataEntry }) => `${dataEntry.title} ${Math.round(dataEntry.percentage)}%`}
					labelStyle={labelStyle}
				/>
			) : (
				<span className='no-data'>No data</span>
			)}
		</div>
	);
};

export default Chart;
