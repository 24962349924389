import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, Button, Backdrop, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Loader from '../../Loader';
import { green } from '@material-ui/core/colors';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const ConfigureProcessingStatusModal = ({showModal, rowData, handleClose, handleSave}) => {
	const classes = useStyles();
	const [statusValue, setStatusValue] = useState(rowData?.processingStatusId || 0);
	const [saving, setSaving] = useState(false);
	const [onHoldNote, setOnHoldNote] = useState(rowData?.processingAdminNotes || '');
	const [notAwardedNote, setNotAwardedNote] = useState(rowData?.processingNotAwardedNotes || '');
	const [disableSave, setDisableSave] = useState(true);


	useEffect(() => {
		if(showModal){
			setSaving(false);			
			setOnHoldNote(rowData?.processingAdminNotes || '');
			setNotAwardedNote(rowData?.processingNotAwardedNotes || '');
			setStatusValue(rowData?.processingStatusId || 0);
			setDisableSave(true);
		}
	}, [showModal]);

	const closeModal = () => {
		handleClose();		
		setSaving(false);
	};

	const evaluateDisabledOnHoldSave = (note, status) => {

		const changedStatus = status != (rowData?.processingStatusId || 0);
		const changedNote = note != (rowData?.processingAdminNotes || '');

		let disabled = true;

		if((changedStatus || changedNote) && (note.length > 0 && note.length <= 1200)){
			disabled = false;
		}

		setDisableSave(disabled);
	};

	const evaluateDisabledNotAwardedSave = (note, status) => {

		const changedStatus = status != (rowData?.processingStatusId || 0);
		const changedNote = note != (rowData?.processingNotAwardedNotes || '');

		let disabled = true;

		if((changedStatus || changedNote) && (note.length > 0 && note.length <= 1200)){
			disabled = false;
		}

		setDisableSave(disabled);
	};


	const handleOnChangeStatus = (e) => {
		setStatusValue(e.target.value);	
		console.log(e.target.value);
		if(e.target.value == 1){
			evaluateDisabledOnHoldSave(onHoldNote,e.target.value);
		}
		if(e.target.value == 0 || e.target.value == 2){
			const changedStatus = e.target.value != (rowData?.processingStatusId || 0);
			setDisableSave(!changedStatus);
		}
		if(e.target.value == 3){
			evaluateDisabledNotAwardedSave(notAwardedNote,e.target.value);	
		}
	};

	const handleOnChangeOnHoldNote = (e) => {
		let { value } = e.target || e;
		value = value.substring(0,1200);
		setOnHoldNote(value);

		evaluateDisabledOnHoldSave(value,statusValue);
	};

	const handleOnChangeNotAwardedNote = (e) => {
		let { value } = e.target || e;
		value = value.substring(0,1200);
		setNotAwardedNote(value);

		evaluateDisabledNotAwardedSave(value,statusValue);
	};

	const saveStatus = (row) => {
		setSaving(true);
		
		let note = '';		
		if(statusValue == 1){
			note= onHoldNote;
		}
		if(statusValue == 3){
			note= notAwardedNote;
		}

		handleSave(statusValue, note, row.applicationId);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={showModal}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<div className='backdrop__modal'>
						<div className='backdrop__title'>
							<h2>Configure processing status</h2>
						</div>
						<Divider />
						<div className='backdrop__body'>
							<div className='backdrop__body-content'>

								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl variant='outlined' fullWidth size='small'>
											<InputLabel id='demo-simple-select-outlined-label'>Select Processing Status</InputLabel>
											<Select
												labelId='demo-simple-select-outlined-label'
												label='Select Processing Status'
												id='processing-select-outlined'
												onChange={(e) => handleOnChangeStatus(e)}
												disabled={saving}
												value={statusValue}>
													<MenuItem key={0} value='0'>
														No assignment
													</MenuItem>
													<MenuItem key={1} disabled={statusValue === 1 ? true : false} value={1}>
														On hold
													</MenuItem>
													<MenuItem key={2} disabled={statusValue === 2 ? true : false} value={2}>
														Awarded
													</MenuItem>
													<MenuItem key={3} disabled={statusValue === 3 ? true : false} value={3}>
														Not Awarded
													</MenuItem>																										
											</Select>
										</FormControl>
									</Grid>
									{statusValue == 1 && (									
										<Grid item xs={12}>
											<TextField id='outlined-multiline-static' label='On hold Reason' multiline value={onHoldNote} rows={4} variant='outlined' onChange={(e) => handleOnChangeOnHoldNote(e)} disabled={saving} />
											<p style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '5px', fontSize: '85%', color: '#949494', fontStyle: 'italic' }}>{`You have ${
												1200 - onHoldNote.length
											} of 1200 characters remaining.`}</p>							
										</Grid>
									)}
									{statusValue == 3 && (									
										<Grid item xs={12}>
											<TextField id='outlined-multiline-static' label='Not Awarded Reason' multiline value={notAwardedNote} rows={4} variant='outlined' onChange={(e) => handleOnChangeNotAwardedNote(e)} disabled={saving} />
											<p style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '5px', fontSize: '85%', color: '#949494', fontStyle: 'italic' }}>{`You have ${
												1200 - notAwardedNote.length
											} of 1200 characters remaining.`}</p>							
										</Grid>
									)}
								</Grid>						
							</div>
							<Divider />
							<div className='backdrop__buttons'>
								<Button variant='contained' color='secondary' onClick={() => closeModal()}>
									Cancel
								</Button>
								{saving ?(									
									<Button disabled={true} className='confirm' variant='contained' color='primary' fullWidth disableRipple>
										Saving ...
									</Button>
									
								):(
									<Button disabled={disableSave} className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={() => saveStatus(rowData)}>
										Save
									</Button>
								)}
							</div>
						</div>
					</div>
				</Grid>
			</Backdrop>
		</>
	);
};

export default ConfigureProcessingStatusModal;