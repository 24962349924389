import React from 'react';
import { Grid } from '@material-ui/core';

import Status from './Status';
import Chart from './Chart';
import List from './List';
import ThousandSeparator from './ThousandSeparator';

const MetricsSummary = ({ metrics }) => (
	<Grid container spacing={3}>
		<Grid item xs={12} md={5} className='metrics__flex-container'>
			<div className='metrics__panel metrics__panel--less-padding'>
				<div className='metrics__title metrics__title--no-margin'>
					Submissions Received:
					<p className='metrics__value metrics__value--red'>
						<ThousandSeparator value={metrics.globals.submissions} />
					</p>
				</div>
			</div>
			<div className='metrics__panel'>
				<div className='metrics__title'>Submissions By Application Status:</div>
				<Status data={metrics.globals.status} />
			</div>
		</Grid>
		<Grid item xs={12} md={2} className='metrics__flex-container'>
			<div className='metrics__panel'>
				<div className='metrics__title'>Top Cities:</div>
				<List data={metrics.globals.topCities || []} />
			</div>
		</Grid>
		<Grid item xs={12} md={2} className='metrics__flex-container'>
			<div className='metrics__panel'>
				<p className='metrics__title'>Top Income Range:</p>
				<List data={metrics.globals.topIncome || []} />
			</div>
			<div className='metrics__panel'>
				<p className='metrics__title'>Top ZIP Codes:</p>
				<List data={metrics.globals.topZipCodes || []} />
			</div>
		</Grid>
		<Grid item xs={12} md={3} className='metrics__flex-container'>
			<div className='metrics__panel'>
				<div className='metrics__title'>Submission Types:</div>
				<Chart metrics={metrics} />
			</div>
		</Grid>
	</Grid>
);

export default MetricsSummary;
