import React, { useState, useEffect } from 'react';
import { Grid, FormControlLabel, Checkbox, Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import API from '../../config/api';

const DDInformationRelease = ({ application, account, changeName }) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [shared, setShared] = useState(application.hasPermitShareInfo);

	// handle success alert
	useEffect(() => {
		if (success) setTimeout(() => setSuccess(false), 1500);
	}, [success]);

	const handleChange = (event) => {
		const { checked } = event.target;
		setShared(checked);
		updateShared(checked);
	};

	const updateShared = (shared) => {
		const { applicationId } = application,
			{ tokenAuth } = account;
		const data = { shared, applicationId };

		setLoading(true);

		API.SetSharingPermissions(tokenAuth, data).then((res) => {
			setLoading(false);
			setSuccess(true);
		});
	};
	return (
		<div className='form__section'>
			<div className='form__section--header'>
				<h3 style={{ whiteSpace: 'nowrap' }}>4. Information Release Form for Disabled/Displaced Students</h3>
				{success && !loading && (
					<Button size='small' color='primary' endIcon={<SaveIcon />} className='text-transform-none' style={{ whiteSpace: 'nowrap' }} disableRipple>
						Auto-saved
					</Button>
				)}
				{loading && <CircularProgress size={15} color='primary' />}
			</div>

			<div className='form__section--body'>
				<div className='section__student-information-release'>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={7}>
							<FormControlLabel
								className='icon-position-top'
								control={<Checkbox color='primary' checked={shared} />}
								label={`For purposes of the Disabled/Displaced Scholarship Program, I permit the Arizona Department of Revenue and/or the Arizona Department of Child Safety and /or other School Tuition Organizations (STOs) to provide information regarding the eligibility status and scholarship limitation information, including related documents for ${changeName} to the following School Tuition Organization:`}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} lg={5}>
							<div className='contact-information'>
								<p>Arizona Leadership Foundation</p>
								<p>Phone: (602) 320-2844</p>
								<p>Fax: (855) 340-9399</p>
								<p>Email: ddapply@arizonaleader.org</p>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default DDInformationRelease;
