import config from '../../config'; // load configurations file

import { BASE_URL, FETCH_TIMEOUT } from './constants';

export const Headers = (token, customBase) => {
	let base = customBase || { 'Content-Type': 'application/json' };
	base.Key = config.app.key;
	if (token) base.Authorization = token;
	return base;
};

export function Request(url, object) {
	return new Promise((resolve, reject) => {
		let timer = setTimeout(() => reject(new Error('Request timed out')), FETCH_TIMEOUT);
		fetch(url, object)
			.then((res) => res.json())
			.then((res) => {
				clearTimeout(timer);
				resolve(res);
			})
			.catch((err) => {
				clearTimeout(timer);
				reject(err);
			});
		// .finally(() => clearTimeout(timer));
	});
}

export default function FetchRequest(endpoint, method, token, data, customBase) {
	let url = `${BASE_URL}${endpoint}`;
	const headers = Headers(token, customBase);
	const dataParse = data instanceof FormData ? data : JSON.stringify(data);
	let body = null;

	if (method !== 'GET') body = dataParse;
	else {
		if (!(data == null)) {
			url = new URL(url);
			Object.keys(data).forEach((key) => url.searchParams.append(key, data[key]));
		}
	}

	return Request(url, { method, headers, body });
}
