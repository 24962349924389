import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import Layout from '../../component/partials/Layout';
import UserInformation from './UserInformation';
import Management from './Management';
import SiteSetting from './SiteSetting';
import Reports from './Reports';
import Schools from './Schools';
import ChangePeriod from './ChangePeriod';
import GrossIncome from './GrossIncome';

const components = { SiteSetting, Management, ChangePeriod, Reports, Schools, GrossIncome };

const ManagementApplication = (props) => {
	const [tab, setTab] = useState('Management');

	const NavigationItem = ({ target, children }) => {
		const isActive = target === tab;

		return <li className={`navigation__item ${isActive ? 'active' : ''}`}>{children}</li>;
	};

	const changeTab = (tabName) =>{
		setTab(tabName)
	};

	const {period} = props.account.periodSettings||{};
	const Content = components[tab];

	return (
		<Layout showButton={false} history={props.history} title='Management Application' period={period}>
			<div className='user-profile-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<Link to='/' className='text-decoration-none'>
							<Button variant='contained' size='small' color='primary' disableRipple>
								Back to dashboard
							</Button>
						</Link>
						<div className='user-profile__navigation'>
							<ul className='navigation__list'>
								<NavigationItem target='Management'>
									<button type='button' onClick={() => setTab('Management')}>
										Management
									</button>
								</NavigationItem>
								<NavigationItem target='ChangePeriod'>
									<button type='button' onClick={() => setTab('ChangePeriod')}>
										Start New Period
									</button>
								</NavigationItem>
								<NavigationItem target='Reports'>
									<button type='button' onClick={() => setTab('Reports')}>
										Reports
									</button>
								</NavigationItem>
								<NavigationItem target='Schools'>									
									<button type='button' onClick={() => setTab('Schools')}>
										Schools
									</button>
								</NavigationItem>
								<NavigationItem target='GrossIncome'>
									<button type='button' onClick={() => setTab('GrossIncome')}>
									 	Gross Income
									</button>
								</NavigationItem>
							</ul>
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<Content account={props.account} token={props.account.tokenAuth} history={props.history} change={changeTab} />
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

export default connect(mapStateToProps)(ManagementApplication);
