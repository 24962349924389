import React from 'react';
import { Tooltip as MuiTooltip, Grow, Fade, Zoom } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';

const TooltipCompleteAccount = (props) => {
	const MuiTooltipContainer = withStyles((theme) => ({
		tooltip: { ...props.tooltipStyle },
	}))(MuiTooltip);

	const transitions = { Grow, Fade, Zoom };
	return (
		<MuiTooltipContainer
			open={props.open}
			className={props.className}
			title={props.title}
			placement={props.placement}
			arrow={props.arrow}
			// disableFocusListener
			TransitionComponent={transitions[props.transitionEffect]}
			// disableTouchListener
		>
			<span style={{ color: props.iconColor }}>{props.icon}</span>
		</MuiTooltipContainer>
	);
};

TooltipCompleteAccount.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.any,
	icon: PropTypes.any,
	placement: PropTypes.string,
	iconColor: PropTypes.string,
	arrow: PropTypes.bool,
	transitionEffect: PropTypes.oneOf(['Grow', 'Fade', 'Zoom']),
};

TooltipCompleteAccount.defaultProps = {
	icon: <HelpIcon />,
	placement: 'right',
	iconColor: '#9bb43b',
	transitionEffect: 'Grow',
	open: true,
};

export default TooltipCompleteAccount;
