import * as actionTypes from '../constants';

const initialState = {
	data: [],
	loading: [],
};

export default function applicationRdx(state = initialState, action) {
	switch (action.type) {
		case actionTypes.LOADED_APPLICATION:
			return {
				...state,
				loading: {[action.adminType]:action.loading},
				data: {[action.adminType]:{...action.application}}
			};
		case actionTypes.LOADING_APPLICATION:
		return {
				...state,
				loading: {[action.adminType]:action.loading},
			};
		default:
			return state;
	}
}
