import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import FileUploader from '../../../component/FileUploader';

const AttachmentBox = ({ onViewFile, onDeleteFile }) => (
	<div className='attachment__box'>
		<span className='attachment__box--file-link' onClick={onViewFile}>
			View File
		</span>
		<IconButton color='secondary' size='small' title='Remove file' onClick={onDeleteFile}>
			<DeleteForeverIcon />
		</IconButton>
	</div>
);

const Uploader = ({ selectedMember, applicationId, token, fileKey, onChange }) => {
	let opts = { token, applicationId, fileKey };
	if (selectedMember?.financialId) opts.financialId = selectedMember.financialId;

	return <FileUploader id={fileKey} {...opts} onChange={onChange} fullWidth />;
};

const IncomeFileUploader = ({ applicationId, selectedMember, token, fileKey, onSetAttachment, onViewFile, onDeleteFile }, ref) => {
	const [uploaded, setUploaded] = useState(false);

	useImperativeHandle(ref, () => ({
		clearAttachment: () => setUploaded(false),
	}));

	useEffect(() => {
		setUploaded(selectedMember && selectedMember[`${fileKey}_key`]);
	}, [selectedMember]);

	const handleChange = (file) => {
		setUploaded(true);
		onSetAttachment(`${fileKey}_key`, file.fields.Key);
	};

	const handleDeleteFile = async () => {
		onDeleteFile(fileKey);
		setUploaded(false);
		onSetAttachment(`${fileKey}_key`, null);
	};

	if (!uploaded) return <Uploader selectedMember={selectedMember} applicationId={applicationId} token={token} fileKey={fileKey} onChange={handleChange} />;
	return <AttachmentBox selectedMember={selectedMember} onViewFile={(_) => onViewFile(fileKey)} onDeleteFile={handleDeleteFile} />;
};

export default forwardRef(IncomeFileUploader);
