import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';
import Loader from '../../component/Loader';

import { getIncomes } from '../../redux/actions/income';

const IncomeTable = ({ account, income, getIncomes }) => {
	useEffect(() => {
		if (income.data.length == 0) {
			getIncomes(account.tokenAuth);
		}
	}, []);

	return (
		<>
			<h4 style={{ paddingTop: 20, textAlign: 'center' }}>185 % OF FEDERAL REDUCED PRICE MEALS GUIDELINE</h4>
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<table style={{ paddingTop: 20 }}>
					<tbody>
						<tr style={{ backgroundColor: '#a0201c' }}>
							<td style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}># in Household</td>
							<td style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}>
								Annual Family Gross <br /> Income Allowable
							</td>
						</tr>
						{income.loading?(
							<tr key={0}>
								<td colSpan={2} style={{ textAlign: 'center' }} >
									<Loader loading={true} />
								</td>
							</tr>						
						) : income.data.map((item) => (
								<tr key={item.householdSize} style={{ textAlign: 'center' }} >
									<td style={{ padding: 8 }}>{item.householdSize}</td>
									<td>{item.income}</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</Box>
		</>
	);
};

const mapStateToProps = (s) => {
	return {
		income: s.income, 
		account: s.account
	};
};

const mapDispatchToProps = {
	getIncomes,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeTable);

