import { Backdrop, Button, Divider, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const AddNoteComponent = (props) => {
	const classes = useStyles();
	const [note, setNote] = useState('');

	useEffect(() => {
		if (props.inProcess.note && props.inProcess.note.length > 0) {
			setNote(props.inProcess.note);
		}
	}, [props.inProcess.note]);

	const handleSaveInProcess = (note) => {
		props.handleSaveInProcess(note);
		// setNote('');
		closeModal();
	};

	const handleOnChange = (e) => {
		const { value } = e.target || e;
		setNote(value);
	};

	const closeModal = () => {
		props.handleClose();
		setNote('');
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={props.showModal}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<div className='backdrop__modal'>
						<div className='backdrop__title'>
							<h2>{props.isEditNote === true ? 'Edit Comment' : 'Do you want to add a comment?'}</h2>
						</div>
						<Divider />
						<div className='backdrop__body'>
							<div className='backdrop__body-content'>
								<TextField id='outlined-multiline-static' label='Comment (optional)' multiline value={note} rows={4} variant='outlined' onChange={(e) => handleOnChange(e)} 
										InputProps={{
            								readOnly: props.readOnly,
										}}/>
								<p style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '5px', fontSize: '85%', color: '#949494', fontStyle: 'italic' }}>{`You have ${
									1200 - note.length
								} of 1200 characters remaining.`}</p>
							</div>
						</div>
						<Divider />
						<div className='backdrop__buttons'>
							<Button disabled={note.length > 1200 ? true : false} variant='contained' color='primary' onClick={() => handleSaveInProcess(note)}>
								Continue
							</Button>
						</div>
					</div>
				</Grid>
			</Backdrop>
		</>
	);
};

AddNoteComponent.propTypes = {
	inProcess: PropTypes.any,
};

AddNoteComponent.defaultProp = {
	inProcess: false,
};

export default AddNoteComponent;
