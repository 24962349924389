import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux';

import { Grid, Button, TextField, InputAdornment, FormControl, CircularProgress, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CurrencyFormat from 'react-currency-format';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import API from '../../config/api';
import { REGEX } from '../../utils/constants';

import Loader from '../../component/Loader';
import { errorField } from '../../utils/helpers';

const { currencyRegEx } = REGEX;

const CurrencyInput = (props) => {
	const { inputRef, value, ...other } = props;
	return <CurrencyFormat {...other} ref={(ref) => inputRef(ref ? ref.inputElement : null)} value={value} type='tel' thousandSeparator />;
};


const ReadOnlyGrossIncome = ({incomes, loading, handleEdit}) => {

	return (
		<>
			<table className="income-table" style={{ width:'100%' }} >
				<thead style={{ backgroundColor: '#a0201c' }}>
					<tr>
						<th style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}># in Household</th>
						<th style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}>Gross Income</th>								
					</tr>
				</thead>
				<tbody>
					{loading?(
						<tr key={0}>
							<td colSpan={2} style={{ textAlign: 'center' }} >
								<Loader loading={true} />
							</td>
						</tr>						
					) : incomes.map((item) => (
							<tr key={item.householdSize} style={{ textAlign: 'center' }} >
								<td style={{ padding: 8 }}>{item.householdSize}</td>
								<td>{item.income}</td>
							</tr>
						))
					}
				</tbody>
			</table>		
			<div className='form-action-buttons'>
				<Button color='primary' startIcon={<EditIcon />} onClick={handleEdit}>
					Edit Gross Income information
				</Button>
			</div>
		</>
	);
};

const EditableGrossIncome = ({account, handleEdit, getIncomes, ...props}) => {
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);

	const [incomes, setIncomes] = useState([...props.incomes]);

	const handleChange =(e,idx) => {
		const value = e.target.value;
		setIncomes(incomes.map((x,i)=>{
					return {
					...x, 
					income: (i===idx)?value:x.income
				};				
			}
		));
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const data = {
			incomes: incomes.map(i =>  {
				return {
					...i,
					income: i.income.replace(currencyRegEx, '')
				};
			})
		};

		await API.UpdateIncomes(account.tokenAuth, data).then(async (res) => {
			setLoading(false);

			if (res.status !== 201) return setFormError({ field: res.field, message: res.message });
			//change status in redux			
			getIncomes(account.tokenAuth);
			handleEdit();
		});
	};

	const errors = errorField(formError);

	return (
		<form onSubmit={handleSubmit}>
			<FormControl component='fieldset' fullWidth>
				<table className="income-table" style={{ width:'100%' }} >
					<thead style={{ backgroundColor: '#a0201c' }}>
						<tr>
							<th style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}># in Household</th>
							<th style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}>Gross Income</th>								
						</tr>
					</thead>
					<tbody>
						{incomes.map((item, idx) => (
							<tr key={item.householdSize} style={{ textAlign: 'center' }} >
								<td style={{ padding: 8 }}>{item.householdSize}</td>
								<td>
									<TextField 
										value={item.income} inputProps={{ maxLength: 12, style:{ textAlign: 'right' } }} 
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
											inputComponent: CurrencyInput,
										}}
										error={errors[`income|${idx}`]}
										required
										size="small"
										variant="outlined"
										onChange={e => handleChange(e,idx)}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{formError && (
					<Alert severity='error' onClose={() => setFormError(null)} style={{ marginTop: 20 }}>
						{formError.message}
					</Alert>
				)}		
				<div className='form-action-buttons'>
					{!loading ? (
						<Button color='primary' type='submit' startIcon={<SaveIcon />} disableRipple>
							Save
						</Button>
						) : (
						<Loader loading={true} />
					)}
					<Button color='secondary' endIcon={<CloseIcon />} onClick={handleEdit} disableRipple>
						Close
					</Button>
				</div>
			</FormControl>
		</form>
	);
};

const GrossIncome = ({ account }) => {
	const [isEdit, setIsEdit] = useState(false);
	const [income, setIncome] = useState({
										data:[],loading:true
									});


	const getIncomes = async function(token){
		await API.GetFullIncomeTable(token).then(async (res) => {
			if (res.status !== 201) return;
			setIncome({
				data:res.data,
				loading:false
			});
		});
	};

	useEffect(() => {
		if (income.data.length == 0) {
		 	getIncomes(account.tokenAuth);
		}
	}, []);

	const handleEdit = () => setIsEdit(!isEdit);

	return (
		<div className='profile-page__user-information-tab gross-income'>
			<h3 className='section-title'>Annual Family Gross Income Allowable</h3>
			<Grid container spacing={2}>
				<Grid item xs={8}>											
					{!isEdit ? (
						<ReadOnlyGrossIncome incomes={income.data} loading={income.loading} handleEdit={handleEdit} />
					) : (
						<EditableGrossIncome incomes={income.data} account={account} handleEdit={handleEdit} getIncomes={getIncomes} />
					)}
				</Grid>				
			</Grid>			
		</div>
	)
};

const mapStateToProps = (s) => {
	return {
		account: s.account
	};
};

export default connect(mapStateToProps, null)(GrossIncome);

	;

