import React from 'react';
// import { withRouter, Link } from 'react-router-dom';
import { Grid, Container, Button, Box } from '@material-ui/core';
// import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import PropTypes from 'prop-types';

const BackButton = ({ goBack }) => (
	<button type='button' className='goBack-button' onClick={goBack}>
		<ArrowBackIcon />
	</button>
);

const index = (props) => {
	const setContent = () => {
		let content = null;
		const { bannerContentRole } = props;

		switch (bannerContentRole) {
			case 3:
				content = (
					<div>
					<Button className='button_banner' style={{ marginRight: '10px' }} variant='contained' startIcon={<EqualizerIcon style={{ color: '#113c60' }} />} onClick={() => navigate(`/${props.match.params.adminType}/stats-per-school`)}>
						Stats per school
					</Button>
					<Button className='button_banner' color='default' variant='contained' startIcon={<SettingsIcon style={{ color: '#a0201c' }} />} onClick={() => navigate('/admin/management')}>
						MANAGEMENT
					</Button>
					</div>
				);
				break;
			case 4:
				content = (
					<Button className='metrics-button' size='medium' endIcon={<AssessmentIcon />} onClick={() => navigate(`/${props.match.params.adminType}/metrics`)} disableRipple>
						Metrics
					</Button>
				);
				break;
		}

		return content;
	};

	const navigate = (url) => props.history.push(url);

	return (
		<Grid container className='banner_box'>
			<Box className='banner_background' style={{ backgroundImage: `url(${props.showBackground ? props.backgroundBanner : null})` }}>
				<Container className='main-container'>
					<Box className='title_box'>
						<div>
							<p className='title'>{props.title}</p>
							{props.period && <p className='period-title'>{props.period}</p>}
						</div>
						{props.showButton ? setContent() : null}
						{props.showBackButton ? <BackButton goBack={props.history.goBack} /> : null}
					</Box>
				</Container>
			</Box>
		</Grid>
	);
};

index.propTypes = {
	showBackground: PropTypes.bool,
	showButton: PropTypes.bool,
	backgroundBanner: PropTypes.string,
	title: PropTypes.string,
};

export default index;
