import React, { useEffect, useRef, useState, Fragment } from 'react';

import { Box, Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment-timezone';
import CopyToClipboard from 'react-copy-html-to-clipboard';
import { connect } from 'react-redux';
import AddNoteComponent from '../../component/partials/AddNoteComponent';
import Alert from '../../component/partials/Alerts/Alert';
import Layout from '../../component/partials/Layout';
// Working with the table
import MaterialTableComponent from '../../component/partials/MaterialTableComponent';
import AssignProcessorModal from '../../component/partials/MaterialTableComponent/AssignProcessorModal';
import ConfigureProcessingStatusModal from '../../component/partials/MaterialTableComponent/ConfigureProcessingStatusModal';
import CheckBoxColumn from '../../component/partials/MaterialTableComponent/CheckBoxColumn';
import ProcessorColumn from '../../component/partials/MaterialTableComponent/ProcessorColumn';
import ProcessingStatusColumn from '../../component/partials/MaterialTableComponent/ProcessingStatusColumn';
import Filters from '../../component/partials/MaterialTableComponent/Filters';
import SelectColumns from '../../component/partials/MaterialTableComponent/SelectColumns';
import SelectFilters from '../../component/partials/MaterialTableComponent/SelectFilters';
import SaveFilterModal from '../../component/SaveFilterModal';
import Api from '../../config/api';
import { setFilterId, setFilters, setSelectedPeriod } from '../../redux/actions/filters';
import { setSetting } from '../../redux/actions/settings';
import ExportButton from './ExportButton';
import SelectPeriods from './SelectPeriods';
import statusRole from './statusRole.json';

let queryMaterialTable = null;

const SiteAdmin = (props) => {
	const { adminType } = props.match.params || null;
	const reportId = Number(props.match.params.reportId) || null;
	const role = statusRole.roles[adminType].value;
	const siteAdminFilter = !reportId ? `SiteAdminFilter_${role}` : `SiteAdminFilter_report_${role}`;

	const [openCollapse, setOpenCollapse] = useState({ open: false, id: null });
	const [dataTable, setDataTable] = useState([]);
	const [tableActions, setTableActions] = useState([]);
	const [filterList, setFilterList] = useState([]);
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [openColumns, setOpenColumns] = useState(false);
	const [openReportModal, setOpenReportModal] = useState(false);
	const [resetFilter, setResetFilter] = useState(null);
	const [columns, setColumns] = useState([]);
	const [allColumns, setAllColumns] = useState([]);
	const [options, setOptions] = useState({
		search: true,
		toolbar: true,
		sorting: true,
		headerStyle: { position: 'sticky', top: 0 },
		maxBodyHeight: '650px',
		filtering: JSON.parse(localStorage.getItem(siteAdminFilter))
			? JSON.parse(localStorage.getItem(siteAdminFilter)).options
				? JSON.parse(localStorage.getItem(siteAdminFilter)).options.filtering
				: false
			: false,
		pageSize: 10,
		pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
		actionsColumnIndex: -1,
		/*tableLayout: "fixed",
		columnResizable: true,*/
	});
	const [value, setValue] = useState(null);
	const [filterValueText, setFilterValueText] = useState(null);
	const [copy, setCopy] = useState({
		id: '',
		copied: false,
	});
	const [loadingCheck, setLoadingCheck] = useState(false);
	const [openModalChecked, setOpenModalChecked] = useState(false);
	const [openAssignModal, setOpenAssignModal] = useState(false);
	const [inProcessCheck, setInProcessCheck] = useState({
		id: '',
		columnId: '',
		checked: null,
		note: '',
	});
	const [openProcessingModal, setOpenProcessingModal] = useState(false);
	const [data, setData] = useState({});
	const [successAssign, setSuccessAssign] = useState();
	const [success, setSuccess] = useState('');
	const [cleanValue, setCleanValue] = useState('');
	const [showSelect, setShowSelect] = useState();
	const [assignationTable, setAssignationTable] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [status, setStatus] = useState([]);
	const [assignInfo, setAssignInfo] = useState([]);
	const [assignValue, setAssignValue] = useState('');
	const [processingInfo, setProcessingInfo] = useState(null);
	const [applicationId, setApplicationId] = useState(0);
	const [openAlert, setOpenAlert] = useState(false);
	const [closeStop, setCloseStop] = useState(false);
	const [send, setSend] = useState({});
	const [openAlertStatus, setOpenAlertStatus] = useState(false);	
	/* reload data and clear filters and paginations */
	const [reloadTable, setReloadTable] = useState(false);
	/* reload data and mantain filters and paginations */
	const [reloadTableData, setReloadTableData] = useState(false);
	const [disabledSelectedFilter, setDisabledSelectedFilter] = useState(false);
	const [selectedReport, setSelectedReport] = useState(null);
	const [deleteAlert, setDeleteAlert] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [isEditNote, setIsEditNote] = useState(false);
	const [periodList, setPeriodList] = useState([]);
	
	const { period: currentPeriod } = props.account.periodSettings;	

	const selectFilterInput = useRef();
	
	useEffect(() => {
		getPeriods();
		getAssignation();
		getAllColumns();		
	}, []);

	useEffect(() => {
		props.setFilters({});
		setColumns([]);
		getFilterList();
		getStatus();
	}, [role]);

	useEffect(() => {
		if (value) {
			getFilter(value);
		}
	}, [value]);
	
	const saveReduxAndLocalStorage = (filter) => {
		localStorage.removeItem(siteAdminFilter);
		localStorage.setItem(siteAdminFilter, JSON.stringify(filter));
		props.setFilters(filter);		
	};

	const toggleAlert = (rowData) => {
		setApplicationId(rowData.applicationId);
		setOpenAlert(!openAlert);
	};

	const resendEmail = () => {
		Api.SendSignatureReminder(props.account.tokenAuth, { applicationId: applicationId })
			.then((r) => {
				if (r.status !== 201) {
					setCloseStop(false);
					setOpenAlert(false);
					return;
				}
				setCloseStop(true);
				setSend({
					id: applicationId,
					show: r.data.notificationSended,
				});
				setOpenAlert(false);
			})
			.catch((err) => {
				console.log(err);
				setOpenAlert(false);
			});
	};

	const getPeriods = () => {
		Api.GetPeriods(props.account.tokenAuth)
			.then((res) => {
				if (res.status !== 201) return;

				setPeriodList(res.data);		
				if (role === 6 || res.data.filter(e => e.period === props.filters.period).length === 0) {
					props.setSelectedPeriod(currentPeriod);	
				}				
			})
			.catch((err) => console.log(err));
	};

	const changePeriod = async (e) => {
		props.setSelectedPeriod(e);
		getFilter();
		setReloadTable(!reloadTable);	
	};

	const getAllColumns = () => {
		Api.GetColumns(props.account.tokenAuth)
			.then((res) => {
				if (res.status !== 201) {
					console.log('Error');
					return;
				}

				setAllColumns(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getFilterList = () => {
		if (reportId) return getFilter(reportId);

		Api.GetFiltersList(props.account.tokenAuth, { role: role })
			.then((res) => {
				if (res.status !== 201) {
					return;
				}
				setFilterList([]);
				setFilterList(res.data);
				const defaultValue = res.data;
				const lsFilter = localStorage.getItem(siteAdminFilter);
				if (!lsFilter) {
					saveReduxAndLocalStorage({
						filterId: defaultValue[0].filterId,
						columns: defaultValue[0].columns,
						name: defaultValue[0].name,
						default: defaultValue[0].isDefault,
						options: { ...props.filters.options, pageSize: defaultValue[0].pageSize },
					});
					setValue(defaultValue[0].filterId);
				} else {
					const filter = JSON.parse(localStorage.getItem(siteAdminFilter));
					const newColumns = res.data;
					const index = newColumns.map((x, i) => x.filterId).indexOf(filter.filterId);

					if (index === -1) {
						saveReduxAndLocalStorage({
							filterId: defaultValue[0].filterId,
							columns: defaultValue[0].columns,
							name: defaultValue[0].name,
							default: defaultValue[0].isDefault,
							options: { ...props.filters.options, pageSize: defaultValue[0].pageSize },
						});
						setValue(defaultValue[0].filterId);
						//setReloadTable(!reloadTable);
					} else {
						saveReduxAndLocalStorage({
							...filter,
							columns: newColumns[index].columns,
							default: defaultValue[0].filterId === filter.filterId ? true : false,
							options: { ...props.filters.options, pageSize: defaultValue[index].pageSize },
						});
						setValue(filter.filterId);
						//setReloadTable(!reloadTable);
					}
				}
			})
			.catch((err) => console.log(err));
	};

	const renderSchools = async () => {
		const results = await Api.GetSchoolNames(props.account.tokenAuth);
		if (!results || !results.data) return;
		return results.data.map((x) => x.name);
	};

	const getFilter = (filter) => {
		const filterId = filter == null ? props.filters.filterId : filter;

		if (props.filters && filterId != null) {
			Api.GetFilters(props.account.tokenAuth, { filterId })
				.then((res) => {	
					setLoading(false);				
					if (res.status !== 201) {
						setShowError(true);
						return;
					}

					if (res.data && res.data.columns.length > 0) {
						const list = res.data.columns.map((x, i) => {
							let column = {
								title: x.label,
								field: x.name,
								orderBy: x.orderBy,
								filterComponent: (e) => <Filters {...e} token={props.account.tokenAuth} renderSchools={renderSchools} column={x} role={role} status={status} assignationTable={assignationTable} />,
								render: (rowData) => formatColumn(rowData, x),
							};

							if (x.settings && x.settings.size) {
								const { size } = x.settings;
								const textAlign = x.settings.textAlign || 'left';

								column.cellStyle = {
									width: size.width,
									minWidth: size.minWidth,
									maxWidth: size.maxWidth,
									textAlign: textAlign,
								};
								column.headerStyle = {
									width: size.width,
									minWidth: size.minWidth,
									maxWidth: size.maxWidth,
								};
							}

							if (x.dataType == 'numeric' || x.dataType == 'money') {
								column.customSort = (a, b) => {
									const aValue = Number((a[x.name] || 0).toString().replace(/[^0-9.-]+/g, ''));
									const bValue = Number((b[x.name] || 0).toString().replace(/[^0-9.-]+/g, ''));
									return aValue - bValue;
								};
							}
							if (x.dataType == 'grade') {
								column.customSort = (a, b) => {
									const av = a[x.name] || '';
									const bv = b[x.name] || '';
									let aValue = av == '' ? -2 : av == 'PK' ? -1 : av == 'K' ? 0 : Number(av);
									let bValue = bv == '' ? -2 : bv == 'PK' ? -1 : bv == 'K' ? 0 : Number(bv);
									return aValue - bValue;
								};
							}
							if (x.dataType == 'date') {
								column.customSort = (a, b) => moment(a[x.name]).valueOf() - moment(b[x.name]).valueOf();
							}
							if (x.dataType == 'bool') {
								column.customSort = (a, b) => (a[x.name] || 'none') - (b[x.name] || 'none');
							}

							return column;
						});
						/* adjust last columns width */
						let totalWidth = 0;
						for (let i = 0; i < list.length; i++) {
							const column = list[i];
							const value = column.cellStyle && column.cellStyle.minWidth ? +column.cellStyle.minWidth.replace(/\D+/g, '') : 0;
							totalWidth += value;
						}

						if (totalWidth <= 1000 && window.innerWidth > 1000) {
							list[list.length - 1].cellStyle = {
								width: '100%',
							};
						}

						let filtering = false;
						let lsFilter = JSON.parse(localStorage.getItem(siteAdminFilter));

						if (lsFilter && lsFilter.options) {
							filtering = lsFilter.options.filtering;
						}

						if (reportId) {
							const { columns, name } = res.data,
								{ deliveryDate, deliveryFrequency, emailRecipients, endDate, startDate } = res.data.report;
							const report = { columns, name, deliveryDate, deliveryFrequency, emailRecipients, endDate, startDate, reportId };
							setFilterList([res.data]);
							setSelectedReport(report);
						}

						const pageSize = res.data.pageSize || 10;

						saveReduxAndLocalStorage({
							filterId: res.data.filterId,
							name: res.data.name,
							columns: res.data.columns,
							options: { ...options, filtering: filtering, pageSize: pageSize },
							default: res.data.isDefault || false,
						});

						if (options.filtering != filtering || options.pageSize != pageSize) setOptions({ ...options, filtering: filtering, pageSize: pageSize });
						setColumns([...list]);

						if (filter != null) {
							setReloadTable(!reloadTable);
						}
						setShowError(false);						
					} else {
						saveReduxAndLocalStorage({
							filterId: res.data.filterId,
							name: res.data.name,
							columns: [],
						});
						setShowError(true);
						setColumns([]);
					}
				})
				.catch((err) => console.log(err));
		}
	};

	const openSaveFilterModal = () => {
		!reportId ? setOpen(!open) : setOpenReportModal(!openReportModal);
	};

	const openSaveColumns = (reload) => {
		setOpenColumns(!openColumns);
		if (reload) {
			getFilter(props.filters.filterId);
		}
		return false;
	};

	const openSaveReportModal = () => {
		setOpen(false);
		setOpenReportModal(!openReportModal);
	};

	const flagSaveNewFilterFromSaveColumn = (reset) => {
		setResetFilter(reset === 0 ? null : reset);
	};

	const showFilter = () => {
		setOptions({ ...options, filtering: !options.filtering });
		saveReduxAndLocalStorage({ ...props.filters, options: { ...options, filtering: !options.filtering } });
	};

	const getStatus = () => {
		const statuses = statusRole.roles[adminType].statuses;
		const values = statuses.map((id) => {
			const response = statusRole.statuses.find((s) => s.id === id);
			return { statusId: response.id, statusLabel: response.label };
		});
		setStatus(values);
	};

	const handleView = (rowData) => {
		const params = {
			url: `/${adminType}/application`,
			type: rowData.applicationType,

			statusId: rowData.statusId,
			status: rowData.status,
			assignName: rowData.assignedProcessorName,
			processorId: rowData.processorId,
			applicationId: rowData.applicationId,
			assignationTable: assignationTable,
			token: props.account.tokenAuth,
			role: role,
		};
		props.history.push(`/${adminType}/application/${rowData.applicationType.toLowerCase()}/view/${rowData.applicationId}`, { ...params });
	};

	const handleEdit = (rowData) => {
		const params = {
			url: `/${adminType}/application`,
			type: rowData.applicationType,

			statusId: rowData.statusId,
			status: rowData.status,
			assignName: rowData.assignedProcessorName,
			processorId: rowData.processorId,
			applicationId: rowData.applicationId,
			assignationTable: assignationTable,
			token: props.account.tokenAuth,
			role: role,
			mode: 'edit',
		};
		props.history.push(`/${adminType}/application/${rowData.applicationType.toLowerCase()}/edit/${rowData.applicationId}`, { ...params });
	};

	const handleDelete = (rowData) => {};

	const handleOpenHistory = (rowData) => {
		props.history.push(`/${adminType}/applications/history/${rowData.applicationType}/${rowData.applicationId}`);
	};

	const formatToCopy = (data) => {
		const title = `<p>Application Reason for <b>${data.applicant}</b></p><br />`;
		const list = data.reasons.map((x, i) => `<li>${x}</li>`);
		const formatHTML = `${title}<ul>${list.join('')}</ul>`;
		return formatHTML;
	};

	const copied = (id) => {
		setCopy({
			id: id,
			copied: true,
		});
	};

	const handleIsEdit = (value) => {
		setIsEditNote(value);
	};

	const handleChangeChecked = (id, columnId, value, note) => {
		const copyRows = props.filters.columns;
		const getRow = copyRows.filter((x) => x.columnId === columnId);
		setLoadingCheck(true);
		switch (value) {
			case true:
				handleIsEdit(false);
				setInProcessCheck({ ...inProcessCheck, note: '' });
				const data = {
					applicationId: id,
					inProcess: false,
					inProcessNote: note,
				};
				console.log(props.filters.columns,getRow[0].inProcessNote);
				Api.UpdateInProcess(props.account.tokenAuth, data)
					.then((res) => {
						if (res.status !== 201) return;
						getRow[0].inProcess = res.data.inProcess;
						getRow[0].inProcessNote = res.data.inProcessNote;
						setOpenModalChecked(false);
						setLoadingCheck(false);
						setInProcessCheck({ id: '', columnId: '', checked: false, note: '' });
						setReloadTable(!reloadTable);
						setFilterList([]);
						getFilterList();
					})
					.catch((err) => console.log(err));
				break;
			case false:
				setInProcessCheck({ id: id, columnId: columnId, checked: true, note: note });
				setOpenModalChecked(!openModalChecked);
				setLoadingCheck(false);
				break;
			case null:
				setInProcessCheck({ id: id, columnId: columnId, checked: true, note: note });
				setOpenModalChecked(!openModalChecked);
				setLoadingCheck(false);
				break;
			default:
				break;
		}
	};

	const handleSaveInProcess = (note) => {
		if(currentPeriod != props.filters.period){ 
				setOpenModalChecked(false);
				setInProcessCheck({ id: '', columnId: '', checked: false, note: '' });
			return
		};
		const copyRows = props.filters.columns;
		const getRow = copyRows.filter((x) => x.columnId === inProcessCheck.columnId);
		const data = {
			applicationId: inProcessCheck.id,
			inProcess: inProcessCheck.checked,
			inProcessNote: note,
		};
		Api.UpdateInProcess(props.account.tokenAuth, data)
			.then((res) => {
				if (res.status !== 201) return;
				getRow[0].inProcess = res.data.inProcess == null ? false : true;
				getRow[0].inProcessNote = res.data.inProcessNote;
				setReloadTable(!reloadTable);
				setFilterList([]);
				getFilterList();
				setOpenModalChecked(false);
				setInProcessCheck({ id: '', columnId: '', checked: false, note: '' });
			})
			.catch((err) => console.log(err));
	};

	const handleModal = (id, columnId, checked, note, isEdit) => {
		setOpenModalChecked(true);
		setIsEditNote(isEdit);
		setInProcessCheck({ id: id, columnId: columnId, checked: checked, note: note });
	};

	const handleClose = () => {
		setOpenModalChecked(false);
		setInProcessCheck({ id: '', columnId: '', checked: false, note: '' });
		// setInProcessCheck({id: '', columnId: '', checked: false, note: ''});
	};

	const handleOpenAssignModal = (value) => {
		setAssignInfo(value);
		setOpenAssignModal(!openAssignModal);
	};

	const handleCloseOpenAssignModal = () => {
		setOpenAssignModal(!openAssignModal);
	};

	const handleOpenProcessingModal = (value) => {		
		setProcessingInfo(value);
		setOpenProcessingModal(!openProcessingModal);
	};

	const handleCloseProcessingModal = () => {
		setProcessingInfo(null);
		setOpenProcessingModal(!openProcessingModal);
	};

	const handleSaveProcessingModal = (status, note, applicationId) => {
		const data = {status: status, note: note, applicationId: applicationId};
		Api.SetProcessingStatus(props.account.tokenAuth, data)
			.then((r) => {
				if (r.status !== 201) {
					return;
				}
				setOpenProcessingModal(false);
				setReloadTableData(!reloadTableData);
			})
			.catch((err) => {
				console.log(err);
				setOpenProcessingModal(false);
			});
	};

	const getAssignation = () => {
		const token = props.account.tokenAuth;

		Api.GetGeneralProcessors(token)
			.then((r) => {
				if (r.status !== 201) {
					return;
				}
				setAssignationTable(r.data);
			})
			.catch((err) => console.log(err));
	};

	const confirmAssign = (e, applicationId) => {
		const { value } = e.target || e;
		setData({
			applicationId: applicationId,
			processorId: value,
		});
		assignParent({
			applicationId: applicationId,
			processorId: value,
		});		
	};

	const assignParent = (data) => {
		Api.AssignToGeneralProcessor(props.account.tokenAuth, data)
			.then((r) => {
				if (r.status !== 201) {
					return;
				}
				setOpenAssignModal(false);
				setSuccessAssign(data.applicationId);
				setReloadTableData(!reloadTableData);
				setAssignValue(data.processorId);
				//setFilterList([]);
				//getFilterList();
			})
			.catch((err) => {
				console.log(err);
				setOpen(false);
				setSuccess('');
			});
	};

	const formatColumn = (rowData, columnData) => {
		const stripHTML = (text) => text.replace(/(<([^>]+)>)/gi, '');

		let column = rowData[columnData.name];

		switch (columnData.dataType) {
			case 'bool':
				if (columnData.name === 'inProcess') {
					const content = (
						<>
							{role === 5 ? (
								<CheckBoxColumn
									isInProcess={columnData.name}
									rowData={rowData}
									columnData={columnData}
									handleChangeChecked={handleChangeChecked}
									handleModal={handleModal}
									loadingCheck={loadingCheck}
								/>
							) : (
								<p style={{ fontSize: 14 }}>{rowData.inProcess === false || rowData.inProcess == null ? 'No' : 'Yes'}</p>
							)}
						</>
					);
					column = content;					
				} else{
					column = column == null ? '' : column ? <p style={{ fontSize: 14 }}>Yes</p> : <p style={{ fontSize: 14 }}>No</p>;
				}
				break;
			case 'date':
				if(!!column){
					if (columnData.name == 'dateOfBirth'){
						const dateOfBirth = column.split('T')[0];
						column = moment(dateOfBirth).format('MM/DD/YYYY');
					}else{
						column = moment(column).format('MM/DD/YYYY');	
					}
				}					
				break;
			case 'search':
				if (typeof column == 'string') {
					column = stripHTML(column);
				}

				if (columnData.name == 'reasons') {
					if (typeof column == 'object' && column && column.length > 0) {
						const content = (
							<>
								<div className='icon-copy'>
									<CopyToClipboard text={() => formatToCopy(rowData)} options={{ asHtml: true }} onCopy={() => copied(column.applicantId)}>
										<Button size='small' startIcon={<FileCopyIcon size='small' />}>
											{copy.copied && copy.id === column.applicationId ? 'Text Copied!' : 'Copy to Clipboard'}
										</Button>
									</CopyToClipboard>
								</div>
								<div className='followup-processor__content'>
									<div className='reason--content'>
										<ul style={{ listStyle: 'square', listStylePosition: 'inside' }}>
											{column.map((d, i) => {
												const li_style = i < column.length - 1 ? { marginBottom: 10 } : null;
												return (
													<li key={i} style={li_style}>
														{<span style={{ marginLeft: -5, fontSize: 14 }}>{stripHTML(d)}</span>}
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</>
						);
						column = content;
					}
				}

				if (columnData.name == 'reasonNotes' || columnData.name == 'criteria' || columnData.name == 'school' || columnData.name == 'inProcessNote') {
					const content = (
						<div className='followup-processor__content'>
							<div className='reason--content' style={{ fontSize: 14 }}>
								{column}
							</div>
						</div>
					);
					column = content;
				}

				if (columnData.name == 'email') {
					const content = <p style={{ wordBreak: 'break-all', fontSize: 14 }}>{column}</p>;
					column = content;
				}

				break;

			case 'processor':
				if (columnData.name === 'assignedProcessorName') {
					const content = (
						<ProcessorColumn
							rowData={rowData}
							role={role}
							handleOpenAssignModal={handleOpenAssignModal}
						/>
					);
					column = content;
				}
				break;

			case 's-processing':
				if (columnData.name === 'processingStatus') {
					const content = (
						<ProcessingStatusColumn
							rowData={rowData}
							role={role}
							handleOpenProcessingModal={handleOpenProcessingModal}
						/>
					);
					column = content;
				}
				break;

			default:
				break;
		}

		return column;
	};

	const normalizeFilter = (filters, orderBy, orderDirection) => {
		const filterLocal = props.filters;
		if (filters.length > 0) {
			for (let i = 0; i < filters.length; i++) {
				const filter = filters[i];

				filterLocal.columns = filterLocal.columns.map((c) => {
					let filterTableData = filter.column.tableData.filterValue == 'none' || filter.column.tableData.value == '' ? null 
											: filter.column.tableData.filterValue == 'unassigned' ? '' : filter.column.tableData.filterValue;
					if (c.name === filter.column.field) c.filterValue = filterTableData;
					return c;
				});
			}
		} else {
			filterLocal.columns = filterLocal.columns.map((x, i) => {
				let filterTableData = filterLocal.columns[i].filterValue == 'none' || filterLocal.columns[i].filterValue == '' ? null 
											: filterLocal.columns[i].filterValue == 'unassigned' ? '' : filterLocal.columns[i].filterValue;
				if (filterTableData) x.filterValue = filterTableData;
				return x;
			});
		}

		if (orderBy) {
			filterLocal.columns = filterLocal.columns.map((c) => {
				if (c.name === orderBy.field) c.orderBy = orderDirection;
				else c.orderBy = null;
				return c;
			});
		}

		return filterLocal;
	};

	const getTableData = async (page, pageSize, filters, orderBy, orderDirection, filtersToolbar) => {
		
		const filterLocal = normalizeFilter(filters, orderBy, orderDirection);

		const filterInfo = {
			filterId: filterLocal.filterId,
			role: role,
			columns: filterLocal.columns ? filterLocal.columns : null,
			page: page,
			pageSize: pageSize,
			search: filtersToolbar ? filtersToolbar.search : '',
			status: filtersToolbar ? filtersToolbar.status : '',
			period: role === 6 ? currentPeriod : props.filters.period,
		};

		if (columns.length == 0) return { status: 201, message: 'Get data from applications', data: { data: { dataset: [], page: 1, pageSize: 10, records: '0', totalPages: '0', totalRecords: '0' } } };

		const remoteData = await Api.GetDataFilters(props.account.tokenAuth, filterInfo);
		return remoteData;
	};

	const tableQueryChanged = (query) => {
		const { page, pageSize, filters, orderBy, orderDirection, search: filtersToolbar } = query;
		queryMaterialTable = { page, pageSize, filters, orderBy, orderDirection, filtersToolbar };
	};

	const getAllTableData = async () => {
		if (!queryMaterialTable) return null;

		const { page, pageSize, filters, orderBy, orderDirection, filtersToolbar } = queryMaterialTable;

		const filterLocal = normalizeFilter(filters, orderBy, orderDirection);

		const filterInfo = {
			filterId: filterLocal.filterId,
			role: role,
			columns: filterLocal.columns ? filterLocal.columns : null,
			page: 0,
			pageSize: 0,
			search: filtersToolbar ? filtersToolbar.search : '',
			status: filtersToolbar ? filtersToolbar.status : '',
			period: role === 6 ? currentPeriod : props.filters.period,
		};

		const remoteData = await Api.GetDataFilters(props.account.tokenAuth, filterInfo);
		return [
			{
				columns: filterInfo.columns,
				dataset: remoteData.data.dataset,
			},
		];
	};

	const changePageSize = (pageSize) => {
		saveReduxAndLocalStorage({ ...props.filters, options: { ...options, pageSize: pageSize } });		
	};

	const handleAfterGetTableData = (data) => {
		setLoading(false);
		setDisabledSelectedFilter(false);
	};
		
	const moveColumn = (array, from, to) => {
		if( to === from ) return array;
	  
		var target = array[from];                         
		var increment = to < from ? -1 : 1;
	  
		for(var k = from; k != to; k += increment){
		  array[k] = array[k + increment];
		}
		array[to] = target;
		return array;
	};

	const handleColumnDrag = (sourceIndex, destinationIndex) => {
		 const columns = moveColumn([...props.filters.columns], sourceIndex, destinationIndex);
		saveReduxAndLocalStorage({ ...props.filters, columns: columns });
	};

	const handleUpdateSelectedReport = (report) => {
		const filter = { ...filterList[0], name: report.name };
		setFilterList([filter]);
		setSelectedReport(report);
	};

	const selectFilter = (e) => {
		setDisabledSelectedFilter(true);
		const { value } = e.target;
		const newFilter = filterList;
		const index = newFilter.map((x, i) => x.filterId).indexOf(value);

		setLoading(true);
		setColumns([]);
		getFilter(filterList[index].filterId);		
	};

	const removeFilter = () => {
		setOpenAlertStatus(!openAlertStatus);
	};

	const confirmDelete = async () => {
		await Api.RemoveFilter(props.account.tokenAuth, { filterId: props.filters.filterId }).then((r) => {
			if (r.status !== 201) {
				return;
			}
			localStorage.removeItem(siteAdminFilter);
			props.setFilters({});
			setColumns([]);
			setFilterList([]);
			setValue(null);
			setReloadTable(!reloadTable);
		});
		await getFilterList();
		setOpenAlertStatus(false);
	};

	const closeModal = async () => {
		localStorage.removeItem(siteAdminFilter);
		setReloadTable(!reloadTable);
		setFilterList([]);
		await getFilterList();
	};

	const deleteApplication = (data) => {
		setDeleteId(data.applicationId);
		setDeleteAlert(true);
	};

	const confirmDeleteApplication = async () => {
		if (deleteId == null) return console.log('Id is required');
		const body = { applicationId: deleteId, delete: true };

		await Api.DeleteParentApplication(props.account.tokenAuth, body)
			.then((res) => {
				setDeleteId(null);
				setValue(null);
				setReloadTable(!reloadTable);
			})
			.catch((err) => console.log(err));
		setDeleteAlert(false);
	};

	const cleanDelete = () => {
		setDeleteId(null);
		setDeleteAlert(false);
	};

	const pageTitles = ['', '', '', 'Site Admin', 'Admin Processor', 'Follow-Up Processor', 'General Processor'];
	const canExport = (role === 3 || role === 5);
	return (
		<Layout showMenu showBanner showFooter bannerContentRole={role} history={props.history} match={props.match} title={pageTitles[role]} period={currentPeriod}>
			<Grid xs={12} item>
				<Grid xs={12} item>
					<Grid container spacing={3} direction='row' justify='space-between' alignItems='center' className='site-admin-container'>
						<Grid xs={12} md={canExport ? 6 : 8} item>
							<div className='applications__column'>
								<div className='unsubmitted-applications application__section'>
									<h3 className='section-title'>{!reportId ? 'Submitted Applications' : 'Edit Report'}</h3>
								</div>
							</div>
						</Grid>
						<Grid xs={12} md={canExport ? 6 : 4} item>
							<Grid container direction='row' justify='space-between' alignItems='center' className='site-admin-container'>
								<Grid item xs={12} sm={canExport ? 6 : 12} md={canExport ? 5 : 12} lg={canExport ? 7 : 12} xl={canExport ? 8 : 12}>
									<SelectFilters
										data={filterList}
										selectFilterInput={selectFilterInput}
										selectFilter={selectFilter}
										value={value}
										reportId={reportId}
										role={role}
										openAlertStatus={openAlertStatus}
										removeFilter={removeFilter}
										confirmDelete={confirmDelete}
										disabled={disabledSelectedFilter}
									/>
								</Grid>
								{canExport && (
									<Grid item className='export-button' xs={12} sm={6} md={7} lg={5} xl={4}>
										<ExportButton remoteData={getAllTableData} fileName='stats-export' />
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} item style={{ marginTop: 20 }} className='site-admin-container--table'>
					{loading ? (
						<Grid container direction='column' justify='center' alignItems='center'>
							<CircularProgress />
						</Grid>
					) : showError ? (
						<Grid container direction='column' justify='center' alignItems='center'>
							<div>
								<InfoOutlinedIcon style={{ /*fontSize: 100,*/ color: 'lightgray', marginTop: 60 }} />
							</div>
							<div style={{ paddingTop: 20 }}>There are no records to show you right now. There may be no entries or your filters may be too tightly defined.</div>
						</Grid>
					) : (
						columns.length > 0 &&
						props.filters.filterId && (
							<Fragment>
								<div className='filter-table'>
									<MaterialTableComponent
										remoteData={getTableData}
										columns={columns}
										options={options}
										onColumnDragged={handleColumnDrag}
										onAfterRemoteData={handleAfterGetTableData}
										status={status}
										send={send}
										role={role}
										showFilter={showFilter}
										setOpenColumns={setOpenColumns}
										openSaveFilterModal={openSaveFilterModal}
										handleView={handleView}
										handleEdit={handleEdit}
										handleDelete={handleDelete}
										handleOpenHistory={handleOpenHistory}
										toggleAlert={toggleAlert}
										reloadTable={reloadTable}
										reloadTableData={reloadTableData}
										isEditReport={reportId}
										deleteApplication={deleteApplication}
										onQueryChanged={tableQueryChanged}
										onChangePageSize={changePageSize}
										componentPeriod={role === 6 ? <></> : <SelectPeriods data={periodList} onChange={(e) => changePeriod(e)} />}																				
									/>
								</div>
							</Fragment>
						)
					)}
					{props.filters.columns && (
						<SelectColumns
							open={openColumns}
							role={role}
							filterId={props.filters && props.filters.filterId != null ? props.filters.filterId : null}
							openSaveColumns={openSaveColumns}
							openSaveFilterModal={openSaveFilterModal}
							flagSaveNewFilterFromSaveColumn={flagSaveNewFilterFromSaveColumn}
							allColumns={allColumns}
						/>
					)}
					<SaveFilterModal
						open={open}
						role={role}
						openReportModal={openReportModal}
						resetFilter={resetFilter}
						openSaveFilterModal={openSaveFilterModal}
						openSaveReportModal={openSaveReportModal}
						flagSaveNewFilterFromSaveColumn={flagSaveNewFilterFromSaveColumn}
						getFilterList={getFilterList}
						selectedReport={selectedReport}
						onUpdateSelectedReport={handleUpdateSelectedReport}
						closeModal={closeModal}
					/>
					<AddNoteComponent
						token={props.account.tokenAuth}
						showModal={openModalChecked}
						handleClose={handleClose}
						inProcess={inProcessCheck}
						handleSaveInProcess={handleSaveInProcess}
						isEditNote={isEditNote}
						readOnly={currentPeriod != props.filters.period}
					/>
					<AssignProcessorModal
						token={props.account.tokenAuth}
						showModal={openAssignModal}
						handleClose={handleCloseOpenAssignModal}
						rowData={assignInfo}
						confirmAssign={confirmAssign}
						assignValue={assignValue}
						assignationTable={assignationTable}
					/>
					<ConfigureProcessingStatusModal
						showModal={openProcessingModal}
						handleClose={handleCloseProcessingModal}
						rowData={processingInfo}
						handleSave={handleSaveProcessingModal}
					/>
					<Alert
						icon='question'
						open={openAlert}
						title='Send Email'
						text='A reminder notification will be sent from the indicated application, to proceed by clicking confirm or you can cancel the operation if you wish'
						confirmButtonText='Confirm'
						onConfirm={resendEmail}
						onCancel={() => setOpenAlert(false)}
					/>
					<Alert
						icon='question'
						open={deleteAlert}
						title='Delete'
						text='Are you sure you would like to delete this application?'
						confirmButtonText='Confirm'
						onConfirm={confirmDeleteApplication}
						onCancel={() => cleanDelete()}
					/>
				</Grid>
			</Grid>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
		settings: state.settings,
		filters: state.filters,
	};
};

const mapDispatchToProps = {
	setSetting,
	setFilters,
	setFilterId,
	setSelectedPeriod
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteAdmin);
