import React from 'react';
import { FormControl } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const LIAgreementsView = (props) => (
	<div className='section__student-agreements-statements'>
		<FormControl component='fieldset' style={{ color: '#707070' }}>
			<div className='agreements-statements-box'>
				<h3 className='strong-text'>I agree to the following:</h3>
				<ul className='agreements__list bullet-list'>
					<li>
						Financial aid awarded to the applicant(s) must be used as allowed by Arizona law solely for tuition expenses at a qualified private school of the applicant’s choice. Excess scholarship
						funds cannot be rolled over to the next school year. Any portion unused must be returned by the school to the Arizona Leadership Foundation for reallocation.
					</li>
					<li>
						The applicant is not currently receiving Empowerment Scholarship Account (ESA) funding from the Arizona Department of Education. Funding from the ESA program is on a quarterly basis and
						begins in the quarter following acceptance to the program. Once ESA funding begins, any outstanding STO scholarship money must be refunded by the private school to the STO.
					</li>
					<li>Arizona Leadership Foundation has absolute and sole discretion in awarding financial aid.</li>
					<li>
						Financial aid is distributed without regard to race, ethnicity, color, national origin, gender/gender-identity, disability, religion, sexual orientation. Financial aid is only for the
						current academic year and continuing aid is based upon the applicant’s eligibility and funds available.
					</li>
					<li>
						A school tuition organization cannot award, restrict or reserve scholarships solely on the basis of donor recommendation. A taxpayer may not claim a tax credit if the taxpayer agrees to
						swap donations with another taxpayer to benefit either taxpayer’s own dependent.
					</li>
					<li>
						Household family income may not exceed 185% of the federally mandated free or reduced lunch program. Applicant(s) qualifies on the family’s current annual household income and I will
						provide IRS 1040 pages 1 & 2 and if more than 4 dependents the Federal Supporting Statement Form 1040 - Dependents. I will submit proof of income If my income is below the income threshold
						required for filing taxes.
					</li>
				</ul>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 20, paddingTop: 20 }}>
					<span style={{ marginLeft: 6 }}>{props.isAgreeStatements ? <CheckIcon /> : <CloseIcon />}</span>
					<p style={{ paddingLeft: 25 }}>I agree with the statements above</p>
				</div>
			</div>
		</FormControl>
	</div>
);

export default LIAgreementsView;
