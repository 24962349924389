import React, { Fragment } from 'react';
import moment from 'moment';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, FormControl, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import API from '../../../config/api';

import MediaManager from '../../../component/MediaManager';

const StudentInformationView = (props) => {
	const setGender = (gender) => {
		let result;
		switch (gender) {
			case 'M':
				result = 'Male';
				break;
			case 'F':
				result = 'Female';
				break;
		}
		return result;
	};

	const handleViewFile = (studentId, value) => {
		const { applicationId, token } = props;
		const data = { applicationId, studentId, fileKey: value };
		const viewFile = window.open();

		API.GetFilesApplications(token, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const renderHtml = (html) => {
		return { __html: html };
	};

		const renderMediaUploader = formState =>{		
		return (
			<Grid item xs={12}>
				{formState.criteriaId == 1 && (							
					<MediaManager title="Upload Attendance Verification" keyName="attendance_verification" readOnly="true"  />		
				)}
				{formState.criteriaId == 3 && (
					<MediaManager title="Preschool Application" keyName="preschool_application" readOnly="true"   />
				)}
				{formState.criteriaId == 4 && (
					<MediaManager title="Upload Military Order" keyName="military_order_station" readOnly="true"   />
				)}
				{(formState.criteriaId == 5 || formState.criteriaId == 7) && (
					<MediaManager title="Upload School Tuition Organization Award Verification" keyName="award_verification" readOnly="true"   />
				)}
				{((formState.hasDisability && formState.grade == 'PK') || formState.criteriaId == 3) && (
					<MediaManager title="Upload IEP or MET" keyName="pk_has_disability" readOnly="true"  />
				)}
				{formState.criteriaId == 8 && (							
					<MediaManager title="County Homeschool Withdrawal Form" keyName="homeschool_withdrawal" readOnly="true" />		
				)}			
				{formState.criteriaId == 9 && (							
					<MediaManager title="Out of State School Enrollment" keyName="out_enrollment" readOnly="true" />		
				)}			
				{formState.criteriaId == 10 && (							
					<MediaManager title="ESA Letter" keyName="esa_letter" readOnly="true" />		
				)}		
			</Grid>
		);
	};

	const renderFileUploader = r =>{
		return (
			<Fragment>
			{r.criteriaId == 1 && (
				<Grid item xs={12} style={{ marginTop: 15 }}>
					<FormControl fullWidth>
						<label>Attendance Verification</label>
						<p className='title'>
							<div className='simple-attachment__box'>
								<div className='attachment__box--header'>
									<div className='left-content'>
										<h5>
											<CheckCircleIcon color='primary' /> File uploaded
										</h5>
										<Button
											color='primary'
											className='view-button'
											startIcon={<VisibilityIcon color='primary' />}
											onClick={() => handleViewFile(r.studentId, 'attendance_verification')}
											disableRipple>
											View file
										</Button>
									</div>
								</div>
							</div>
						</p>
					</FormControl>
				</Grid>
			)}
			{r.criteriaId == 3 && (
				<Grid item xs={12} style={{ marginTop: 15 }}>
					<FormControl fullWidth>
						<label>Preschool Application</label>
						<p className='title'>
							<div className='simple-attachment__box'>
								<div className='attachment__box--header'>
									<div className='left-content'>
										<h5>
											<CheckCircleIcon color='primary' /> File uploaded
										</h5>
										<Button
											color='primary'
											className='view-button'
											startIcon={<VisibilityIcon color='primary' />}
											onClick={() => handleViewFile(r.studentId, 'preschool_application')}
											disableRipple>
											View file
										</Button>
									</div>
								</div>
							</div>
						</p>
					</FormControl>
				</Grid>
			)}
			{r.criteriaId == 4 && (
				<Grid item xs={12} style={{ marginTop: 15 }}>
					<FormControl fullWidth>
						<label>Military Order</label>
						<p className='title'>
							<div className='simple-attachment__box'>
								<div className='attachment__box--header'>
									<div className='left-content'>
										<h5>
											<CheckCircleIcon color='primary' /> File uploaded
										</h5>
										<Button
											color='primary'
											className='view-button'
											startIcon={<VisibilityIcon color='primary' />}
											onClick={() => handleViewFile(r.studentId, 'military_order_station')}
											disableRipple>
											View file
										</Button>
									</div>
								</div>
							</div>
						</p>
					</FormControl>
				</Grid>
			)}
			{(r.criteriaId == 5 || r.criteriaId == 7) && (
				<Grid item xs={12} style={{ marginTop: 15 }}>
					<FormControl fullWidth>
						<label>School Tuition Organization Award Verification</label>
						<p className='title'>
							<div className='simple-attachment__box'>
								<div className='attachment__box--header'>
									<div className='left-content'>
										<h5>
											<CheckCircleIcon color='primary' /> File uploaded
										</h5>
										<Button
											color='primary'
											className='view-button'
											startIcon={<VisibilityIcon color='primary' />}
											onClick={() => handleViewFile(r.studentId, 'award_verification')}
											disableRipple>
											View file
										</Button>
									</div>
								</div>
							</div>
						</p>
					</FormControl>
				</Grid>
			)}
			{((r.hasDisability && r.grade == 'PK') || r.criteriaId == 3) && (
				<Grid item xs={12} style={{ marginTop: 15 }}>
					<FormControl fullWidth>
						<label>IEP or MET</label>
						<p className='title'>
							<div className='simple-attachment__box'>
								<div className='attachment__box--header'>
									<div className='left-content'>
										<h5>
											<CheckCircleIcon color='primary' /> File uploaded
										</h5>
										<Button
											color='primary'
											className='view-button'
											startIcon={<VisibilityIcon color='primary' />}
											onClick={() => handleViewFile(r.studentId, 'pk_has_disability')}
											disableRipple>
											View file
										</Button>
									</div>
								</div>
							</div>
						</p>
					</FormControl>
				</Grid>
			)}
			</Fragment>
		);
	};

	return (
		<Fragment>
			{props.applicationType === 'DD' ? (
				<div className='DDStudentView'>
					<p className='title'>
						Student:{' '}
						<span className='sub-title'>
							{' '}
							{props.students.length > 0 && props.students[0].firstName} {props.students.length > 0 && props.students[0].lastName}
						</span>
					</p>
					<p className='title'>
						Gender: <span className='sub-title'>{props.students.length > 0 && setGender(props.students[0].gender)}</span>
					</p>
					<p className='title'>
						Date of Birth: <span className='sub-title'>{props.students.length > 0 && props.students[0].dateOfBirth != null ? moment.utc(props.students[0].dateOfBirth).format('MM/DD/YYYY') : ''}</span>
					</p>
					<p className='title'>
						Private School: <span className='sub-title'>{props.students.length > 0 && props.students[0].school}</span>
					</p>
					<p className='title'>
						Grade: <span className='sub-title'>{props.students.length > 0 && props.students[0].grade}</span>
					</p>
					<p className='title'>
						Name of the school your child attended last school year: <span className='sub-title'>{props.students.length > 0 && props.students[0].lastYearSchool}</span>
					</p>
					<p className='title'>
						Additional personal or economic factors: <span className='sub-title'>{props.students.length > 0 && props.students[0].additionalFactors}</span>
					</p>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 20, marginTop: 20 }}>
						<p style={{ fontSize: 16, color: '#707070' }}>
							Does the applicant have an Empowerment Scholarship Account (ESA) from the Arizona Department of Education for the current school year ({props.applicationPeriod})?
						</p>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 20 }}>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: 20 }}>
							{props.students[0].hasApplicantESA === true ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
							<span style={{ marginLeft: 10 }}>Yes</span>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
							{!props.students[0].hasApplicantESA ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
							<span style={{ marginLeft: 10 }}>No</span>
						</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
						<p style={{ fontSize: 16, color: '#707070' }}>Would your student be interested in being featured as a student spotlight, sent to donors and featured on our website?</p>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 20, marginTop: 15 }}>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: 20 }}>
							{props.students[0].isSpotlightStudent === true ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
							<span style={{ marginLeft: 10 }}>Yes</span>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
							{!props.students[0].isSpotlightStudent ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
							<span style={{ marginLeft: 10 }}>No</span>
						</div>
					</div>
				</div>
			) : props.students != null ? (
				props.students.map((r, i) => {
					return (
						<ExpansionPanel key={i} className='LIStudentView' defaultExpanded={true}>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
								<Typography className=''>Student {i + 1}</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<div className='DDStudentView'>
									<p className='title'>
										Student:{' '}
										<span className='sub-title'>
											{' '}
											{r.firstName} {r.lastName}
										</span>
									</p>
									<p className='title'>
										Gender: <span className='sub-title'>{setGender(r.gender)}</span>
									</p>
									<p className='title'>
										Date of Birth: <span className='sub-title'>{moment.utc(r.dateOfBirth).format('MM/DD/YYYY')}</span>
									</p>
									<p className='title'>
										Grade: <span className='sub-title'>{r.grade}</span>
									</p>
									{r.grade == 'PK' && (
										<p className='title'>
											Has disability: <span className='sub-title'>{r.hasDisability ? 'Yes' : 'No'}</span>
										</p>
									)}
									{/* {
                                                    r.hasDisability &&
                                                    <p className='title'>
                                                        <div className="simple-attachment__box">
                                                            <div className="attachment__box--header">
                                                                <div className="left-content">
                                                                    <h5>
                                                                        <CheckCircleIcon color="primary" />
                                                                        File uploaded
                                                                    </h5>
                                                                    <Button color="primary" className="view-button" startIcon={<VisibilityIcon color="primary" />} onClick={handleViewFile('pk_has_disability')} disableRipple>View file</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p>
                                                } */}
									<p className='title'>
										Private School in {props.applicationPeriod}: <span className='sub-title'>{r.school}</span>
									</p>
									<p className='title'>
										City: <span className='sub-title'>{r.city}</span>
									</p>
									<p className='title'>
										Did student attend a different school last year?: <span className='sub-title'>{r.isLastYearDifferentSchool ? 'Yes' : 'No'}</span>
									</p>
									{r.isLastYearDifferentSchool && (
										<Fragment>
											<p className='title'>
												Previous School Name: <span className='sub-title'>{r.lastYearSchool}</span>
											</p>
											<p className='title'>
												Previous School City: <span className='sub-title'>{r.lastYearCity}</span>
											</p>
											<p className='title'>
												Previous School State: <span className='sub-title'>{r.lastYearState}</span>
											</p>
										</Fragment>
									)}
									<p className='title'>
										Leadership and Extracurricular Activities: <br />
										<span className='sub-title'>{r.achievements}</span>
									</p>
									<p className='title'>
										Additional Personal or Economic Factors: <br />
										<span className='sub-title'>{r.involvements}</span>
									</p>
									<p className='title'>
										Academic Merit: <br />
										<span className='sub-title'>{r.merits}</span>
									</p>
									<p className='title'>
										Would your student be interested in being featured as a student spotlight, sent to donors and featured on our website?: <br />
										<span className='sub-title'>{r.isSpotlightStudent ? 'Yes' : 'No'}</span>
									</p>
									<p className='title'>
										Criteria Eligibility: <br />
										<span style={{ marginTop: 10, display: 'block' }} dangerouslySetInnerHTML={renderHtml(r.criteriashortdescription.replace('{:previousPeriod}',props.applicationPreviousPeriod))} />
									</p>
									
									{props.version >= 30 ? renderMediaUploader(r):renderFileUploader(r)}

								</div>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					);
				})
			) : null}
		</Fragment>
	);
};

export default StudentInformationView;
