import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const ApplicationNotFound = ({ message }) => (
	<div className='application--not-found'>
		<h3>{message}</h3>
		<Link to='/'>
			<Button variant='contained' color='primary'>
				Go to applications list
			</Button>
		</Link>
	</div>
);

ApplicationNotFound.propTypes = {
	message: PropTypes.string.isRequired,
};

export default ApplicationNotFound;
