import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, FormControl, Grid, FormLabel, FormGroup, FormControlLabel, FormHelperText, Switch, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAccount } from '../../redux/actions/account';
import JoditEditor from 'jodit-react';

import Api from '../../config/api';

const Management = (props) => {
	const [showErrorMessage, setShowErrorMessage] = useState({
		alert: '',
		message: '',
	});
	const [siteSetting, setSiteSetting] = useState({
		isClosedSubmission: false,
		noteClosedSubmission: '',
		codeOpenSubmission: '',
		codeOpenDDSubmission: '',
	});
	const [code, setCode] = useState({li:null,dd:null});

	useEffect(() => {
		if (props.account.siteSettings != null) setSiteSetting(props.account.siteSettings);
		if (props.account.siteSettings != null) {
			const codeSettings = {li: props.account.siteSettings.codeOpenSubmission||'', dd: props.account.siteSettings.codeOpenDDSubmission||''};
			setCode(codeSettings);
			localStorage.setItem('code', codeSettings);
		}
	}, []);

	const handleSwitch = (event) => {
		const isClosedSubmission = !siteSetting.isClosedSubmission;
		setSiteSetting({ ...siteSetting, isClosedSubmission });
	};

	const handleChange = () => {
		// const {name, value} = e.target;
		// console.log(name, value);
		// setSiteSetting({...siteSetting, [name]: value});
	};

	const updateSetting = () => {
		const settings = siteSetting;

		Api.UpdateSettings(props.account.tokenAuth, settings)
			.then((res) => {
				console.log(res);
				if (res.status !== 201) {
					setShowErrorMessage({ alert: 'error', message: res.message });
					return;
				}
				const siteSettings = settings;
				props.setAccount({ ...props.account, siteSettings });
				setShowErrorMessage({ alert: 'success', message: res.message });
				setTimeout(() => {
					setShowErrorMessage({ alert: '', message: '' });
				}, 2500);
			})
			.catch((err) => console.log(err));
	};

	const noteClosedSubmission = useRef(null);
	const [content, setContent] = useState('');

	const config = {
		toolbarAdaptive: false,
		buttons: 'source,|,bold,underline,italic,ul,ol,fontsize,link,align,undo,\n,cut,copy,paste,|,hr',
	};

	const generateCode = () => {
		let result = '';
		let letters = '';
		let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		let numbers = '1234567890';

		for (var i = 0; i < 2; i++) {
			letters += characters.charAt(Math.floor(Math.random() * 10));
		}
		for (var i = 0; i < 4; i++) {
			result += numbers.charAt(Math.floor(Math.random() * 10));
		}

		const code = letters + result;
		return code;
	};

	const updateLICode = () => {
		const liCode = generateCode();				
		updateCode('LI',liCode);
	};

	const updateDDCode = () => {
		const ddCode = generateCode();		
		updateCode('DD',ddCode);
	};

	const updateCode = (type, code) => {

		const newCode = {li: siteSetting.codeOpenSubmission, dd: siteSetting.codeOpenDDSubmission};

		if(type == 'LI'){
			setSiteSetting({ ...siteSetting, codeOpenSubmission: code });
		}else{
			setSiteSetting({ ...siteSetting, codeOpenDDSubmission: code });
		}
		
		Api.UpdateSettings(props.account.tokenAuth, siteSetting)
			.then((res) => {
				console.log(res);
				if (res.status !== 201) {
					setShowErrorMessage({ alert: 'error', message: res.message });
					return;
				}

				if(type == 'LI'){
					setCode({...newCode, li:code});
					localStorage.setItem('code', {...newCode, li:code});
				}else{
					setCode({...newCode, dd:code});
					localStorage.setItem('code', {...newCode, dd:code});
				}				
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className='profile-page__user-information-tab'>
			<h3 className='section-title'>Open/Close Submissions</h3>
			<Grid container>
				<Grid container direction='row' justify='space-between' alignItems='center'>
					<Grid item xs={12} sm={12}>
						<FormControl component='fieldset'>
							<p>Do you want to block the application submissions?</p>
							<FormGroup>
								<FormControlLabel
									control={<Switch color='primary' checked={siteSetting.isClosedSubmission} onChange={handleSwitch} name='isClosedSubmission' />}
									label={siteSetting.isClosedSubmission ? 'Locked' : 'Unlocked'}
								/>
							</FormGroup>
						</FormControl>					
					</Grid>
				</Grid>
				{siteSetting.isClosedSubmission &&
				<Grid container direction='row' justify='space-between' alignItems='center' 
							style={{
									backgroundColor: '#fbfbfb',
    								padding: '10px'
							}}>
					<Grid item xs={12} sm={1}></Grid>			
					<Grid item xs={12} sm={4}>
						<label style={{
							textAlign: 'center',
							display: 'block',
							color: '#878787',
							marginBottom: '9px'
						}}>Low Income App Unlock Code</label>						
						{props.account.siteSettings && siteSetting.codeOpenSubmission !== null && (
							<Fragment>
								<div
									style={{
										justifyContent: 'center',
										flexDirection: 'row',
										alignItems: 'center',
										display: 'flex',
										backgroundColor: '#fce0e0',
										height: 80,
										border: 'chocolate 0.8px dashed',
										marginBottom: 15,
										borderRadius: 6,
									}}>
									<p style={{ fontSize: 35, opacity: 0.4, paddingTop: 15 }}>{code.li||''}</p>
								</div>
								<div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
									<Button variant='contained' color='primary' onClick={updateLICode}>
										Generate LI Code
									</Button>
								</div>								
							</Fragment>
						)}					
					</Grid>		
					<Grid item xs={12} sm={2}></Grid>			
					<Grid item xs={12} sm={4}>
						<label style={{
							textAlign: 'center',
							display: 'block',
							color: '#878787',
							marginBottom: '9px'
						}}>Disabled/Displaced App Unlock Code</label>
						{props.account.siteSettings && siteSetting.codeOpenDDSubmission !== null && (
							<Fragment>
								<div
									style={{
										justifyContent: 'center',
										flexDirection: 'row',
										alignItems: 'center',
										display: 'flex',
										backgroundColor: '#e0e4fc',
										height: 80,
										border: '0.8px dashed #1ebcd2',
										marginBottom: 15,
										borderRadius: 6,
									}}>									
									<p style={{ fontSize: 35, opacity: 0.4, paddingTop: 15 }}>										
										{code.dd||''}
									</p>
								</div>
								<div style={{ justifyContent: 'center', flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
									<Button variant='contained' color='primary' onClick={updateDDCode}>
										Generate DD Code
									</Button>
								</div>
							</Fragment>
						)}
					</Grid>
					<Grid item xs={12} sm={1}></Grid>			
				</Grid>
				}
				<Grid container direction='row' justify='space-between' alignItems='center'>
					<Grid item xs={12} style={{ marginTop: 20 }}>
						<JoditEditor
							ref={noteClosedSubmission}
							value={siteSetting.noteClosedSubmission.toString()}
							config={config}
							tabIndex={1}
							onBlur={(newContent) => setSiteSetting({ ...siteSetting, noteClosedSubmission: newContent })}
							onChange={(newContent) => {}}
						/>
					</Grid>
					<Grid item xs={12} lg={7} style={{ marginTop: 20 }}>
						<Button variant='contained' color='primary' onClick={updateSetting}>
							Save Changes
						</Button>
					</Grid>
					<Grid item xs={12} lg={7} style={{ marginTop: 20 }}>
						{showErrorMessage.alert != '' && (
							<Alert severity={showErrorMessage.alert} onClose={() => setShowErrorMessage({ ...showErrorMessage, alert: '' })}>
								{showErrorMessage.message}
							</Alert>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

const mapDispatchToProps = {
	setAccount,
};

Management.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Management);
