import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import configureStore from '../../redux';
// import { getAll } from '../../redux/actions/account';
import { withRouter } from 'react-router-dom';

// const store = configureStore();

const UserInformation = ({ renderButton, account }) => (
	<div className='user__information'>
		<p className='user-name'>
			{account.firstName} {account.lastName}
		</p>
		<div className='user-information'>
			<p>Registered as Parent/Guardian</p>
			<p>
				Email: <span>{account.email}</span>
			</p>
			<p>
				Phone: <span>{account.phone}</span>
			</p>
		</div>
		{renderButton || (
			<Link to='/user/profile' className='text-decoration-none'>
				<Button variant='contained' size='small' color='primary' disableRipple>
					Profile
				</Button>
			</Link>
		)}
	</div>
);

UserInformation.propTypes = {
	user: PropTypes.object, // TODO: .isRequired
	renderButton: PropTypes.element,
};

UserInformation.defaultProps = {
	renderButton: null,
};

export default withRouter(connect((s) => ({ account: s.account }))(UserInformation));
