import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import DDStudentInformation from './DDStudentInformation';
import DDPrerequisites from './DDPrerequisites';
import DDFinancialInformation from './DDFinancialInformation';
import DDFinancialInformationV2 from './DDFinancialInformationV2';
import DDInformationRelease from './DDInformationRelease';
import DDAgreements from './DDAgreements';
import Loader from '../../component/Loader';
import HeaderApplication from './HeaderApplication';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Alert, AlertTitle } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import API from '../../config/api';
import HelloSignClass from '../../utils/hellosign';

import { setApplicationRdx, setLoadingApplicationRdx } from '../../redux/actions/application';
import { connect } from 'react-redux';

const DDApplication = ({ account, pathname, status, reason, id, history, applicationType, adminType, applicationLoaded, setApplicationRdx, setLoadingApplicationRdx }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [dataVersion, setDataVersion] = useState(true);
	const [application, setApplication] = useState({});
	const [isSing, setIsSign] = useState(false);
	const [reasonIncomplete, setReasonIncomplete] = useState([]);
	const [reasonRejected, setReasonRejected] = useState([]);
	const [changeName, setChangeName] = useState('');
	const [valueStatus, setValueStatus] = useState(null);
	const [openReasonModal, setOpenReasonModal] = useState(false);

	const [localStatus, setLocalStatus] = useState(status);

	useEffect(() => {
		getApplication();
		getReasonIncomplete();
		getReasonRejected();
		setLocalStatus(status);
	}, []);

	const getApplication = () => {
		setLoadingApplicationRdx(adminType);
		API.GetApplicationById(account.tokenAuth, { id }).then((response) => {
			const { status, message, data } = response;
			if (status != 201) {
				setError(message);
				return;
			}else{

				const newApp = { ...data };
				setApplication(newApp);
				setApplicationRdx(newApp,adminType);
				setDataVersion(newApp.version||10);

				setIsSign(newApp.signatureRequestId);
				setChangeName(newApp && newApp.students.length > 0 ? newApp.students[0].firstName + ' ' + newApp.students[0].lastName : 'Application Student');
				setValueStatus(newApp.status);
				applicationLoaded(data);

			}
			setLoading(false);								
		});
	};

	const getReasonIncomplete = () => {
		API.IncompletedReasons(account.tokenAuth)
			.then((res) => {
				setReasonIncomplete(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getReasonRejected = () => {
		API.RejectedReasons(account.tokenAuth)
			.then((res) => {
				setReasonRejected(res.data);
			})
			.catch((err) => console.log(err));
	};

	const downloadDocument = () => {
		const { tokenAuth } = account;

		if (isSing != null) {
			const HelloSign = new HelloSignClass();

			HelloSign.signatureRequestId = isSing;
			HelloSign.token = tokenAuth;

			HelloSign.DownloadSignedDocument();
		}
	};

	const getName = (value) => setChangeName(value.name);

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const getValueStatus = (value) => {
		setValueStatus(value);
	};

	const openReasonsModalFn = (value) => {
		setOpenReasonModal(value);
	};
	//console.log(localStatus);
	let content;

	if (!error)
		content = (
			<Fragment>
				<HeaderApplication
					getValueStatus={getValueStatus}
					pathname={pathname}
					status={localStatus}
					statusName={application.statuslabel}
					history={history}
					reasonIncomplete={reasonIncomplete}
					reasonRejected={reasonRejected}
					application={application}
					openReasonModal={openReasonModal}
					getApplicationById={getApplication}
					openReasonsModalFn={openReasonsModalFn}
				/>

				{valueStatus != null && ((valueStatus === 7 && application.rejectedReasons.length > 0) || valueStatus === 6 || (valueStatus === 2 && application.timesSubmitted > 1 && application.rejectedReasons.length > 0)) && (
					<div style={{ marginBottom: 30 }}>
						<Alert severity='warning'>
							{adminType === 'followup' && valueStatus === 6 && (
								<Button variant='contained' color='primary' size='small' style={{ float: 'right' }} startIcon={<EditIcon />} onClick={() => openReasonsModalFn(!openReasonModal)}>
									Edit Reasons
								</Button>
							)}
							Reason:
							<Fragment>
								<ul style={{ listStyleType: 'square', listStylePosition: 'inside', marginTop: 15, width: '100%' }}>
									{application.rejectedReasons.length > 0 &&
										application.rejectedReasons.map((x, i) => {
											return (
												<li key={i} style={{ paddingBottom: 10 }}>
													<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
												</li>
											);
										})}
								</ul>
							</Fragment>
							{application.rejectedNotes != null && (
								<Fragment>
									<AlertTitle>Note:</AlertTitle>
									<span>{application.rejectedNotes}</span>
								</Fragment>
							)}
						</Alert>
					</div>
				)}
				{valueStatus != null && ((valueStatus === 7 && application.incompletedReasons.length > 0) || valueStatus === 4 || (valueStatus === 2 && application.timesSubmitted > 1 && application.incompletedReasons.length > 0)) && (
					<div style={{ marginBottom: 40 }}>
						<Alert severity='warning'>
							{adminType === 'followup' && valueStatus === 4 && (
								<Button variant='contained' color='primary' size='small' style={{ float: 'right' }} startIcon={<EditIcon />} onClick={() => openReasonsModalFn(!openReasonModal)}>
									Edit Reasons
								</Button>
							)}
							Reason:
							<Fragment>
								<ul style={{ listStyleType: 'square', listStylePosition: 'inside', marginTop: 15, width: '100%' }}>
									{application.incompletedReasons.length > 0 &&
										application.incompletedReasons.map((x, i) => {
											return (
												<li key={i} style={{ paddingBottom: 10 }}>
													<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
												</li>
											);
										})}
								</ul>
							</Fragment>
							{application.incompleteNotes != null && (
								<Fragment>
									<AlertTitle>Note:</AlertTitle>
									<span>{application.incompleteNotes}</span>
								</Fragment>
							)}
						</Alert>
					</div>
				)}

				<DDStudentInformation application={application} account={account} applicationType={applicationType} onESAChange={() => {}} getName={getName} />
				<DDPrerequisites application={application} account={account} />
				
				{dataVersion <= 10 ? (
					<DDFinancialInformation application={application} account={account} />
				): dataVersion <= 20 ? (
					<DDFinancialInformationV2 application={application} account={account} />					
				): (
					// <p>COMPONENTE PARA LA VERSION 30</p>
					<DDFinancialInformationV2 application={application} account={account} />					
				)}
								

				<DDInformationRelease application={application} account={account} changeName={changeName} />
				<DDAgreements application={application} account={account} />
				<Box className='application-buttons' display='flex' flexDirection='column' justifyContent='flex-start'>
					<Link to={`/${adminType}/applications`}>
						<Button variant='contained' color='primary' startIcon={<SaveIcon />} fullWidth disableRipple>
							Save and exit
						</Button>
					</Link>
					{isSing != null && (
						<Button style={{ width: 280, marginTop: 15 }} variant='contained' color='primary' startIcon={<CloudDownloadIcon />} onClick={downloadDocument} disableRipple>
							Download signed document
						</Button>
					)}
				</Box>
			</Fragment>
		);
	else content = <h3>{error}</h3>;

	return <div className='application-dd-form'>{!loading ? content : <Loader loading={true} />}</div>;
};

const mapDispatchToProps = {
	setApplicationRdx, setLoadingApplicationRdx
};

export default connect(null, mapDispatchToProps)(DDApplication);
