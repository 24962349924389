import React, { useState, UseEffect } from 'react';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const SiteSetting = (props) => {
	const [checked, setChecked] = useState([0]);
	const [addChecked, setAddChecked] = useState([0]);
	const [removeChecked, setRemoveChecked] = useState([0]);

	const handleToggleAdd = (value) => () => {
		const currentIndex = addChecked.indexOf(value);
		console.log('Add ', value, currentIndex);
		const newChecked = [...addChecked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setAddChecked(newChecked);
	};

	const handleToggleRemove = (value) => () => {
		const currentIndex = removeChecked.indexOf(value);
		console.log('normal', value, currentIndex);
		const newChecked = [...removeChecked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setRemoveChecked(newChecked);
	};

	return (
		<div className='profile-page__user-information-tab'>
			<h3 className='section-title'>Site Setting</h3>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<p>Add Information</p>
					<List style={{ border: '1px solid lightgray' }}>
						{[0, 1, 2, 3].map((value) => {
							const labelId = `checkbox-list-label-${value}`;

							return (
								<ListItem key={value} role={undefined} dense button onClick={handleToggleAdd(value)}>
									<ListItemIcon>
										<Checkbox edge='start' checked={addChecked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
									</ListItemIcon>
									<ListItemText id={labelId} primary={`Line item ${value + 1}`} />
								</ListItem>
							);
						})}
					</List>
					<Button variant='contained' color='primary' style={{ marginTop: 10 }}>
						Add items
					</Button>
				</Grid>
				<Grid item xs={12} lg={6}>
					<p>Remove Information</p>
					<List style={{ border: '1px solid lightgray' }}>
						{[0, 1, 2, 3].map((value) => {
							const labelId = `checkbox-list-label-${value}`;

							return (
								<ListItem key={value} role={undefined} dense button onClick={handleToggleRemove(value)}>
									<ListItemIcon>
										<Checkbox edge='start' checked={removeChecked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
									</ListItemIcon>
									<ListItemText id={labelId} primary={`Line item ${value + 1}`} />
								</ListItem>
							);
						})}
					</List>
					<Button variant='contained' color='primary' style={{ marginTop: 10 }}>
						Remove items
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

SiteSetting.propTypes = {};

export default SiteSetting;
