const templates = {

	tplActionNeeded: `		
		<p>Thank you for applying to the Arizona Leadership Foundation for a <strong>«SCHOLARSHIP_TYPE»</strong>. We have reviewed your application but <strong>will not</strong> be able to grant <strong>«STUDENT_NAME»</strong> at <strong>«SCHOOL»</strong> a scholarship as the application is <strong>Incomplete</strong> for the reason(s) listed below.</p>
		<p>Reason(s) application was not considered complete are as follows:</p>
		<p>«REASONS»</p>
		<p>Notes: «NOTES»</p>
		<p>Please log back into your Parent Portal and select the green <strong>MAKE CORRECTIONS</strong> button for the application(s) with the status: <strong>Action Needed - Incomplete</strong>. Select the green edit pen to open any section or upload additional documentation for the missing item(s) listed. Once all corrections have been made <u>you must scroll to the bottom to <strong>RESIGN/SUBMIT</strong> the application</u>.</p>
		<p><u>The deadline to Make Corrections and RESUBMIT your application is June 17th, 2024 at midnight</u> to be considered for a scholarship. Thank You.</p>
		<p><strong>Click this link to:</strong> <a href="«URL_ACCTION_APP»" target="_blank">Make Changes, Sign and Resubmit</a> the application.</p>
		<p>If you have any questions, please contact <a href="mailto:support@arizonaleader.org">support@arizonaleader.org</a>.</p>
		<p>Please include your applicant students NAME and SCHOOL.</p>
		`,
	tplNotApproved: `
		<p>Thank you for applying to the Arizona Leadership Foundation for a <strong>«SCHOLARSHIP_TYPE»</strong>. We have reviewed your application but <strong>will not</strong> be able to grant <strong>«STUDENT_NAME»</strong> at <strong>«SCHOOL»</strong> a scholarship as the application is <strong>Not Approved</strong> for the reason(s) listed below.</p>
		<p>Reason(s) application was not considered complete are as follows:</p>
		<p>«REASONS»</p>
		<p>Notes: «NOTES»</p>		
		<p>If you have any questions, please contact <a href="mailto:support@arizonaleader.org">support@arizonaleader.org</a>.</p>
		<p>Please include your applicant students NAME and SCHOOL.</p>
	`,

};

module.exports = templates;