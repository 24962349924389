const errorField = (obj) => {
	let result = {};
	if (obj) result = { [obj.field]: true };
	return result;
};

const removeHtmlFromString = (Reasons) => {
  if(Array.isArray(Reasons)) {
		Reasons = Reasons.map(reason => reason.replaceAll(/<\/?[^>]+(>|$)/gi, ""));
	}
	return Reasons;
}

export { errorField, removeHtmlFromString };
