import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DraftsIcon from '@material-ui/icons/Drafts';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import DeleteIcon from '@material-ui/icons/Delete';

import Alert from '../../component/partials/Alerts/Alert';
import API from '../../config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',    
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
	marginBottom:'10px',
  },
}));

function ApplicationTraceEmailList({		
		emailList,
		emailItem,
		onSelected = () => {},
		onDeleted = () => {},
		onDeleting = () => {},
		account,
		readOnly = false
}) {
 	const classes = useStyles();

 	const [selectedItem, setSelectedItem] = useState(emailItem);
	const [deleteItem, setDeleteItem] = useState(null);
	const [openAlert, setOpenAlert] = useState(false);
	const [deleting, setDeleting] = useState(false);
	
	useEffect(() => {
		if(emailList.length > 0){	
			if(emailItem && emailItem.emailId !== 0 && emailList.some(f=>f.emailId === emailItem.emailId)){				
				setSelectedItem(emailItem);				
			}else{
				setSelectedItem(emailList[0]);
			}
		}
	}, [emailList, emailItem])

	useEffect(() => {
		if(selectedItem) // call component event
			onSelected(selectedItem);
	}, [selectedItem])


	const DeleteStatusEmail = async (emailId) => {
		setDeleting(true);
		const reqData = {emailId: emailId};
		return API.DeleteStatusEmail(account.tokenAuth,reqData).then((res) => {		
			if (res.status !== 201) {
				setDeleting(false);
				return;
			}

			onDeleted(res.data);
			setDeleting(false);	
		});
	};

	const toggleAlert = () => {
		 setOpenAlert(!openAlert);
		 if(!openAlert == false) 
		 	setDeleteItem(null);
	}

	const handleListItemClick = (event, item) => {
		setSelectedItem(item);
	};

	const handleOpenDeleteAlert = item => {
		setDeleteItem(item);
		toggleAlert();
	};

	const handleDeleteItem = () => {
		onDeleting();
		setDeleting(true);
		DeleteStatusEmail(deleteItem.emailId);
		toggleAlert();		
	};


  return (
	<>	  
		<List className={classes.root} component="nav">
			{emailList.map((item, idx) => (
				<>
				<ListItem 
					button
					selected={selectedItem && (selectedItem.emailId === item.emailId)}
					onClick={(event) => handleListItemClick(event, item)}
					disabled={deleting}
					key={item.emailId}
				>
					<ListItemIcon>					
						{!!item.readAt? (
							<Tooltip title={`Email read at ${moment.utc(item.readAt).tz("America/Phoenix").format('MM/DD/YYYY HH:mm:ss ')}`}>
								<DraftsIcon />
							</Tooltip>					
						) : !!item.sentAt? (
							<Tooltip title="Email unread">
								<MailOutlineIcon/>
							</Tooltip>
						) : (					
							<Tooltip title="New Email">
								<FiberNewIcon/>
							</Tooltip>
						)}					
					</ListItemIcon>
					
					<ListItemText
						primary={!!item.sentAt? 
							`Sent at ${moment.utc(item.sentAt).tz("America/Phoenix").format('MM/DD/YYYY HH:mm:ss ')}`:
							`Last update ${moment.utc(item.updatedAt).tz("America/Phoenix").format('MM/DD/YYYY HH:mm:ss ')}`
						}
						secondary={
							<React.Fragment>
							<Typography
								component="span"
								variant="body2"
								className={classes.inline}
								color="textPrimary"
							>
								Email
							</Typography>
							{` — ${item.body.replace(/<[^>]+>/g, '').substring(0,48)}…`}
							</React.Fragment>
						}
					/>

					{readOnly === false && !!item.sentAt === false &&
					<ListItemSecondaryAction>
						<IconButton edge="end" aria-label="delete" onClick={e=>handleOpenDeleteAlert(item)} disabled={deleting}>
						<DeleteIcon />
						</IconButton>
					</ListItemSecondaryAction>
					}
				</ListItem>
				{emailList.length - 1 !== idx && <Divider variant="inset" component="li" />}
				</>
			))}
    	</List>
  		<Alert open={openAlert} title='Delete Email' text='Are you sure you want to delete the selected email item?' confirmButtonText='Delete' onConfirm={handleDeleteItem} onCancel={toggleAlert} />
	</>
  );
}

const mapStateToProps = (s) => {
	return {
		account: s.account
	};
};

export default connect(mapStateToProps)(ApplicationTraceEmailList);