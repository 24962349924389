import React from 'react';
import { Grid, Container } from '@material-ui/core';

const Footer = () => {
	return (
		<div className='footer'>
			<div className='footer_info'>
				<Container fixed>
					<Grid container direction='row' justify='center' alignItems='center'>
						<div className='description-column'>
							<p>&copy; 2020 Arizona Leadership Foundation. All Rights Reserved</p>
						</div>
					</Grid>
				</Container>
			</div>
		</div>
	);
};

export default Footer;
