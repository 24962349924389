import { SET_SETTING } from '../constants';

const initialState = {};

export default function account(state = initialState, action) {
	switch (action.type) {
		case SET_SETTING:
			return {
				...action.data,
			};
		default:
			return state;
	}
}
