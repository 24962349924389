import React from 'react';
import PropTypes from 'prop-types';
import BounceLoader from 'react-spinners/BounceLoader';

const Loader = ({ loading, text, size, color }) => (
	<div className='loader__container'>
		<BounceLoader size={size} color={color} loading={loading} />
		<span>{text}</span>
	</div>
);

Loader.propTypes = {
	loading: PropTypes.bool.isRequired,
	text: PropTypes.string,
	size: PropTypes.number,
	color: PropTypes.string,
};

Loader.defaultProps = {
	text: 'Loading...',
	size: 30,
	color: '#9bb43b',
};

export default Loader;
