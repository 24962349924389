import React, { useState } from 'react'


import { Button} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

function UploaderBoxEmpty() {
	return (
		<div className='simple-attachment__box'>
			<div className='attachment__box--header'>
				<div className='left-content'>
					<Button color='primary' className='view-button no-files' startIcon={<NotInterestedIcon color='primary' />}  disableRipple>						
						NO FILES WERE UPLOLADED
					</Button>
				</div>
				<div className="right-content">			
				</div>
			</div>		
		</div>
	)
}

export default UploaderBoxEmpty;
