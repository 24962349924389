import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton, OutlinedInput, Grid, ListItemText, Checkbox, Input  } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const Toolbar = (props) => {

	// const ITEM_HEIGHT = 48;
	// const ITEM_PADDING_TOP = 8;
	// const MenuProps = {
	//   	PaperProps: {
	// 		style: {
	// 		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
	// 		width: 300,
	// 		},
	// 	},
	// };

	const [status, setStatus] = useState(props.status || []);
	//const [statusSelected, setStatusSelected] = useState([]);
	const [toolbarState, setToolbarState] = useState({ search: '', status: '' });

	const onSearchRef = useRef(null);
	//const onStatusRef = useRef()

	useEffect(() => setStatus(props.status || []), [props.status]);

	useEffect(() => {
		const timeout = setTimeout(() => onSearchRef.current && props.onSearchChanged(toolbarState), 1000);
		return () => clearTimeout(timeout);
	}, [toolbarState]);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		props.onChangeSearch(value);
	};

	// const IsAllStatusSelected = () => {
	// 	const allStatus = status.map((d) => d.statusLabel);
	// 	return JSON.stringify(statusSelected) == JSON.stringify(allStatus);		
	// }

	// const handleColumnsChange = (e) => {
	// 	const { value } = e.target;
	// 	let selected = [];
	// 	if(value.includes("All")){
	// 		if(IsAllStatusSelected()){
	// 			setStatusSelected([]);					
	// 		}else{
	// 			const allStatus = status.map((d) => d.statusLabel);
	// 			setStatusSelected(allStatus);
	// 			selected = allStatus;				
	// 		}			
	// 	}
	// 	else{
	// 		setStatusSelected(value);
	// 		selected = value;	
	// 	}   
		
	// 	props.onChangeColumnsSelected(selected);	
	// 	console.log(onStatusRef.current);
  	// };

	return (
		<div className='table-toolbar'>
			<div className='table-toolbar__periods'>
				<Grid container spacing={1} xs={12}>
					<Grid item xs={12}>
						{props.componentPeriod}
					</Grid>
				</Grid>
			</div>
			<div className='table-toolbar__right_container'>
				<div className='table-toolbar__container'>
					<Grid container spacing={1} xs={12}>
						<Grid item xs={4}>
							{props.componentActiveInactiveSchool}
						</Grid>
						<Grid item xs={6}>
							<FormControl variant='outlined' size='small' fullWidth>
								<InputLabel htmlFor='outlined-adornment-password'>Search</InputLabel>
								<OutlinedInput
									label='Search'
									name='search'
									color='primary'
									type='text'
									onChange={handleSearchChange}
									style={{width:'100%'}}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton edge='end'>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									}
									fullWidth
								/>
							</FormControl>							
						</Grid>
						{/* <Grid item xs={5}>
							<FormControl variant='outlined' size={'small'} fullWidth>
								<InputLabel id='toolbar-status-label'>Columns Status</InputLabel>
								<Select 
									label='Application Status' 
									name='status' 
									onChange={handleColumnsChange} 
									multiple
									value={statusSelected} 									 
									renderValue={(selected) => selected.join(', ')}
									MenuProps={MenuProps}
									style={{width:'200px'}}
									ref={onStatusRef}
								>
									<MenuItem value='All'>
											<Checkbox checked={IsAllStatusSelected()} />
											<ListItemText primary="All Application Status" />
										</MenuItem>
									{status.map((d) => (
										<MenuItem key={d.statusId} value={d.statusLabel}>
											<Checkbox checked={statusSelected.indexOf(d.statusLabel) > -1} />
											<ListItemText primary={d.statusLabel} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid> */}
						<Grid item xs={2}>
							<div className='toolbar-columns'>
								{props.componentMToolbar}
							</div>							
						</Grid>
					</Grid>
				</div>

				{/* <div className='table-toolbar__actions'>
					<props.components.Actions actions={props.actions && props.actions.filter((a) => a.position === 'toolbar')} components={props.components} />
				</div> */}
			</div>
		</div>
	);
};

export default connect((s) => ({ account: s.account }))(Toolbar);
