import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import Layout from '../../component/partials/Layout';
import UserInformation from './UserInformation';
import Password from './Password';
import SecurityQuestions from './SecurityQuestions';
import FinancialInformation from './FinancialInformation';

const components = { UserInformation, Password, SecurityQuestions, FinancialInformation };

const UserProfile = (props) => {
	const [tab, setTab] = useState('UserInformation');

	const NavigationItem = ({ target, children }) => {
		const isActive = target === tab;

		return <li className={`navigation__item ${isActive ? 'active' : ''}`}>{children}</li>;
	};

	const toggleTab = (tab) => setTab(tab);

	const Content = components[tab];

	return (
		<Layout showBackground={false} showButton={false} history={props.history} title='Admin Process'>
			<div className='user-profile-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<Link to='/' className='text-decoration-none'>
							<Button variant='contained' size='small' color='primary' disableRipple>
								Back to dashboard
							</Button>
						</Link>
						<div className='user-profile__navigation'>
							<ul className='navigation__list'>
								<NavigationItem target='UserInformation'>
									<button type='button' onClick={() => toggleTab('UserInformation')}>
										User Information
									</button>
								</NavigationItem>
								<NavigationItem target='Password'>
									<button type='button' onClick={() => toggleTab('Password')}>
										Password
									</button>
								</NavigationItem>
								<NavigationItem target='SecurityQuestions'>
									<button type='button' onClick={() => toggleTab('SecurityQuestions')}>
										Security Questions
									</button>
								</NavigationItem>
								{/* <NavigationItem target="FinancialInformation">
                                    <button type="button" onClick={() => toggleTab('FinancialInformation')}>Financial Information</button>
                                </NavigationItem> */}
							</ul>
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<Content account={props.account} token={props.account.tokenAuth} />
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

export default connect((s) => ({ account: s.account }))(UserProfile);
