import { SAVE_COLUMNS, SET_FILTER_ID, SET_SELECTED_PERIOD } from '../constants';

export function setFilters(data) {
	return {
		type: SAVE_COLUMNS,
		data,
	};
}

export function setFilterId(data) {
	return {
		type: SET_FILTER_ID,
		data,
	};
}

export function setSelectedPeriod(period) {	
	return {
		type: SET_SELECTED_PERIOD,
		 period,
	};
}