import React, {  useState, useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';

import { Grid, CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import MaterialTable, {MTableToolbar} from 'material-table';
import Layout from '../../component/partials/Layout';
import Toolbar from './Toolbar';
import ExportButton from './ExportButton';
import SelectPeriods from './SelectPeriods';
import statusRole from '../SiteAdmin/statusRole.json';
import Api from '../../config/api';
import {  setSelectedPeriod } from '../../redux/actions/filters';
import SelectActiveInactive from './SelectActiveInactive';

const StatsPerSchool = ({ history, account, match, filters }) => { 
	const { adminType } = match.params || null;
	const dataRef = useRef([]);
	const role = statusRole.roles[adminType].value;
	const {period: currentPeriod} = account.periodSettings;
	const { period: selectedPeriod } = filters;

	const [period, setPeriod] = useState(selectedPeriod || currentPeriod);

	const [loading, setLoading] = useState(false);
	const [showError, setShowError] = useState(false);
	const [status, setStatus] = useState([]);
	const [periodList, setPeriodList] = useState([]);

	const [statsData, setStatsData] = useState([]);
	const [statsColumns, setStatsColumns] = useState([]);
	const [filterActiveInactive, setFilterActiveInactive] = useState(-1);

	const tableRef = useRef();
	
	const getStats = () => {
		setLoading(true);				
		Api.GetStatsPerSchool(account.tokenAuth, { period })
		.then((res) => {	
				setLoading(false);
				if (res.status !== 201) {
					setShowError(true);
					return;
				}

				const { schoolsStats, schools } = res.data;
				const schoolsStatsWithSchoolName = schoolsStats.map((s) => {
					const sch = schools.find((school) => school.name === s.school);
					const status = sch ? sch.active === true ? 'Active' : 'Inactive' : 'Active';

					return {
						...s,
						status: status,
					};
				});

				dataRef.current = schoolsStatsWithSchoolName;

				if (filterActiveInactive === -1) {
					setStatsData(dataRef.current);
				} else if (filterActiveInactive === 1) {
					setStatsData(schoolsStatsWithSchoolName.filter((s) => s.status === 'Active'));
				} else {
					setStatsData(schoolsStatsWithSchoolName.filter((s) => s.status === 'Inactive'));
				}
		})
		.catch((err) => console.log(err));
	};

	const getStatsFromTable = () => {
		return tableRef.current?.dataManager?.sortedData;
	}

	const getStatus = () => {
		const statuses = statusRole.roles[adminType].statuses;
		const values = statuses.map((id) => {
			const response = statusRole.statuses.find((s) => s.id === id);
			return { statusId: response.id, statusLabel: response.label };
		});
		setStatus(values);
	};

	const getPeriods = () => {
		Api.GetPeriods(account.tokenAuth)
			.then((res) => {
				if (res.status !== 201) return;
				setPeriodList(res.data);		
			})
			.catch((err) => console.log(err));
	};

	useEffect(()=>{
		getPeriods();
		getStatus();
		getStats();
		setStatsColumns(tableRef.current?.dataManager?.columns);
	},[period]);


	const handleChangePeriod = async (period) => {
		setSelectedPeriod(period);
		setPeriod(period);
	};
	
	const handleChangeSearch = async (term) => {		
		tableRef.current.setState({ searchText: term});
		tableRef.current.dataManager.changeSearchText(term);
		tableRef.current.onSearchChangeDebounce(term);
	};

	const handleChangeColumns = async (pp,col, hidden) => {	
		setStatsColumns(tableRef.current?.dataManager?.columns);

		setTimeout(() => {
			pp.onColumnsChanged(col, hidden);
		}, 10); 
	}

	const changedColumnsPosition = async (pp,col, hidden) => {	
		setStatsColumns(tableRef.current?.dataManager?.columns);
		// setTimeout(() => {
		// 	pp.onColumnsChanged(col, hidden);
		// }, 10); 
	}
	const handleChangeActiveInactive = (value) => {
		setFilterActiveInactive(value);

		if (value === -1) {
			setStatsData(dataRef.current);
		} else if (value === 1) {
			setStatsData(dataRef.current.filter((s) => s.status === 'Active'));
		} else {
			setStatsData(dataRef.current.filter((s) => s.status === 'Inactive'));
		}
	}
	
	const canExport = (role === 3 || role === 5);
	const componentPeriod = role === 6 ? <></> : <SelectPeriods data={periodList} period={period} onChange={(e) => handleChangePeriod(e)} />;
	const componentActiveInactiveSchool = <SelectActiveInactive value={filterActiveInactive} onChange={(e) => handleChangeActiveInactive(e)} />;

	return(
		<Layout showMenu showBanner showFooter showBackButton={true} history={history} title='Admin Processor' period={currentPeriod}>
			<Grid xs={12} item>
				<Grid xs={12} item>
					<Grid container spacing={3} direction='row' justify='space-between' alignItems='center' className='site-admin-container'>
						<Grid xs={12} md={canExport ? 9 : 12} item>
							<div className='applications__column'>
								<div className='unsubmitted-applications application__section'>
									<h3 className='section-title'>Stats per School</h3>
								</div>
							</div>
						</Grid>
						{canExport && (
							<Grid xs={12} md={3} item>
								<Grid item className='export-button'>
									<ExportButton getStatsData={getStatsFromTable} columns={statsColumns} fileName='stats-export' />
								</Grid>
							</Grid>
						)}					
					</Grid>
				</Grid>

				<Grid xs={12} item style={{ marginTop: 20 }} className='site-admin-container--table'>
					{loading ? (
						<Grid container direction='column' justify='center' alignItems='center'>
							<CircularProgress />
						</Grid>
					) : showError ? (
						<Grid container direction='column' justify='center' alignItems='center'>
							<div>
								<InfoOutlinedIcon style={{ /*fontSize: 100,*/ color: 'lightgray', marginTop: 60 }} />
							</div>
							<div style={{ paddingTop: 20 }}>There are no records to show you right now. There may be no entries or your filters may be too tightly defined.</div>
						</Grid>
					) : (
						<div className='filter-table'>
							<MaterialTable 
								tableRef={tableRef}
								title={''}
								options={{
									paging: false,
									search: false,
									toolbar: true,
									sorting: true,
									headerStyle: { position: 'sticky', top: 0 },
									maxBodyHeight: '650px',
									filtering: false,
									columnsButton:true 
								}}
								icons={{ SortArrow: forwardRef((props, ref) => <span {...props} ref={ref} />) }}
								columns={[
									{ 
										title: 'School Name', 
										field: 'school', 
										width: '310px'
									},
									{ title: 'New', field: 'new' },
									{ title: 'Review in progress', field: 'reviewInProgress' },
									{ title: 'Incomplete', field: 'incomplete' },
									{ title: 'Approved', field: 'approved' },
									{ title: 'Not approved', field: 'notApproved' },
									{ title: 'Action needed-incomplete', field: 'actionNeededIncomplete' },
									{ title: 'Current status', field: 'status' }
								]}								
								components={{
									Toolbar: props => (										
									<div>
										<Toolbar {...props} 
											status={status} 
											onChangeSearch={handleChangeSearch} 
											componentPeriod={componentPeriod} 
											componentActiveInactiveSchool={componentActiveInactiveSchool}
											componentMToolbar={
												<MTableToolbar {...props} 													
													onColumnsChanged={(col, hidden)=> 
														handleChangeColumns(props,col, hidden)
													}
 												/>
											}/>										
									</div>
									),
								}}
								data={statsData}
								onColumnDragged={changedColumnsPosition}
							/>
						</div>
					)
					}
				</Grid>

			</Grid>
		</Layout>
	)
 };

const mapStateToProps = (state) => {
	return {
		account: state.account,
		filters: state.filters,
	};
};

const mapDispatchToProps = {
	setSelectedPeriod
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsPerSchool);
