import React from 'react';
import { Grid } from '@material-ui/core';

import Status from './Status';
import List from './List';
import ThousandSeparator from './ThousandSeparator';

const SingleMetric = ({ metrics, title }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} className='metrics__flex-container'>
			<div className='metrics__panel metrics__panel-less-padding'>
				<div className='metrics__title metrics__title--bold metrics__title--no-margin'>{title}</div>
			</div>
		</Grid>
		<Grid item xs={12} className='metrics__flex-container'>
			<div className='metrics__panel metrics__panel--less-padding'>
				<div className='metrics__title metrics__title--no-margin'>
					Submissions Received:
					<p className='metrics__value metrics__value--red'>
						<ThousandSeparator value={metrics.submissions} />
					</p>
				</div>
			</div>
		</Grid>
		<Grid item xs={12} className='metrics__flex-container'>
			<div className='metrics__panel'>
				<div className='metrics__title'>Submissions By Application Status:</div>
				<Status data={metrics.status} />
			</div>
		</Grid>
		<Grid item xs={12} md={6} className='metrics__flex-container'>
			<div className='metrics__panel'>
				<div className='metrics__title'>Top Cities:</div>
				<List data={metrics.topCities || []} />
			</div>
		</Grid>
		<Grid item xs={12} md={6} className='metrics__flex-container'>
			<div className='metrics__panel metrics__panel--less-margin'>
				<p className='metrics__title'>Top Income Range:</p>
				<List data={metrics.topIncome || []} />
			</div>
			<div className='metrics__panel'>
				<p className='metrics__title'>Top ZIP Codes:</p>
				<List data={metrics.topZipCodes || []} />
			</div>
		</Grid>
	</Grid>
);

export default SingleMetric;
