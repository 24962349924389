import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _uniqueId from 'lodash/uniqueId';
import API from '../../config/api';

function UploaderMedia({referenceId, keyName, disabled, onUploading, onUploaded, token}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [file, setFile] = useState(null);
	const [id] = useState(_uniqueId('uploader-media-'+referenceId));
	
	const addMedia = async data => {

		API.AddMedia(token, data).then((res) => {

			onUploaded(res.data);
			setLoading(false);
			setFile(null);
		});
	};

	const handleUpload = async (file) => {
		const acceptedTypes = [
			'',
			'image/png',
			'image/jpeg',
			'application/pdf',
			'image/gif',
			'image/heic',
			'image/heif',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		];

		if (!file || !acceptedTypes.includes(file.type)) return setError('File extension not allowed.');
		else if(file.size > 8389000) return setError('File size exceeds 8 MiB.');
		else setError(null);

		setFile(file);
		setLoading(true);
		
		onUploading(file);
		
		// Request file upload permissions		
		let data = { referenceId: referenceId, keyName: keyName, fileName: file.name };
		const apiResponse = await API.GetUploadMediaData(token, data);

		// Upload file to S3
		uploadFile(apiResponse.data, file);
	};

	const uploadFile = async (data, file) => {		
		const { url, fields } = data;
		const entries = Object.entries(fields);

		const formData = new FormData();
		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		formData.append('file', file);

		await fetch(url, { method: 'POST', body: formData });

		//console.log(file, fields);

		//Update Media in data storage
		await addMedia({
			key: keyName,
			fileName: file.name,			
			fileType: file.type,
			fileSize: file.size,
			s3FileKey: fields.Key,
			referenceId: referenceId
		});					
	};

		const setClasses = () => {
		let classes = '';

		if (loading) classes += 'uploading ';
		if (disabled) classes += 'disabled ';

		return classes;
	};

	const classes = setClasses();

	return (
		<div className={`file-uploader full-width ${classes}`}>
			<input type='file' id={id} onClick={e => (e.target.value = null)} onChange={(e) => handleUpload(e.target.files[0])} disabled={disabled} accept='.png, .jpg, .jpeg, .pdf, .gif, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .heic, .heif' />
			<label htmlFor={id}>
				<div className='button'>{!loading ? 'Choose file' : 'Uploading...'}</div>
				<div className='file-name--holder'>{!file ? 'Upload your file' : file.name}</div>
			</label>
			{error && <span className='error-label'>{error}</span>}
		</div>

	)
}

UploaderMedia.propTypes = {
	referenceId: PropTypes.number.isRequired,
	keyName: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	onUploading: PropTypes.func,
	onUploaded: PropTypes.func,
	token: PropTypes.string.isRequired
};

UploaderMedia.defaultProps = {	
	disabled: false,	
	onUploading: () => { console.log("UploaderMedia: Unhandled onUploading function"); },
	onUploaded: () => { console.log("UploaderMedia: Unhandled onUploaded function"); },
};

export default UploaderMedia;
