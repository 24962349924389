import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Typography } from '@material-ui/core';

const CheckBoxColumn = (props) => {
	
	const { period: currentPeriod } = props.account.periodSettings;	

	return (
		<>
			{props.isInProcess === 'inProcess' ? (
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
					<Checkbox
						checked={props.rowData.inProcess}
						disabled={props.disabled || props.loadingCheck}
						onChange={() => !props.disabled && props.handleChangeChecked(props.rowData.applicationId, props.columnData.columnId, props.rowData.inProcess, props.rowData.inProcessFollowNote)}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						disabled = {currentPeriod!=props.filters.period}
					/>
					{props.rowData.inProcess && props.rowData.inProcess === true && (
						<Button
							style={{ fontSize: 10, cursor: 'pointer', textAlign: 'center' }}
							onClick={() => props.handleModal(props.rowData.applicationId, props.columnData.columnId, props.rowData.inProcess, props.rowData.inProcessFollowNote, true)}>
							View Comment
						</Button>
					)}
				</div>
			) : (
				<Typography>{`${props.rowData.spotlighted !== true ? 'False' : 'True'}`}</Typography>
			)}
		</>
	);
};


const mapStateProps = (state) => {
	return { 
		account: state.account,
		filters: state.filters 
	};
};

export default connect(mapStateProps)(CheckBoxColumn);

