import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';

import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';

const LayoutLogin = (props) => {
	return (
		<div className='page-wrapper'>
			<div className='page-content'>
				{props.showMenu ? <Header history={props.history} /> : null}
				{props.showBanner ? <Banner title={props.title} showBackground={props.showBackground} backgroundBanner={props.backgroundBanner} showButton={props.showButton} /> : null}
				<Container className='main-container' fixed>
					{props.children}
				</Container>
			</div>
			{props.showFooter ? <Footer /> : null}
		</div>
	);
};

LayoutLogin.propTypes = {
	showMenu: PropTypes.bool,
	showBanner: PropTypes.bool,
	showFooter: PropTypes.bool,
	showButton: PropTypes.bool,
	showBackground: PropTypes.bool,
	backgroundBanner: PropTypes.string,
	title: PropTypes.string,
	history: PropTypes.any,
};

LayoutLogin.defaultProps = {
	showMenu: true,
	showBanner: true,
	showFooter: true,
	showButton: true,
	showBackground: true,
	backgroundBanner: '/assets/img/bg1.jpg',
	title: 'Parent / Guardian',
};

export default LayoutLogin;
