import { SET_ACCOUNT, GET_ALL } from '../constants';

export function setAccount(data) {
	return {
		type: SET_ACCOUNT,
		data,
	}; 
}

export function getAll() {
	return {
		type: GET_ALL,
	};
}
