import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField, InputLabel } from '@material-ui/core';
import Alert from './Alerts/Alert';
import Api from '../../config/api';
import { BorderTopSharp } from '@material-ui/icons';

const InternalComment = ({id, comment, account }) => {

	const [internalComment, setInternalComment] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState('');
	const [title, setTitle] = useState('');
	
	useEffect(() => {
		if(comment && comment.length > 0) setInternalComment(comment);
	}, [comment]);

	const handleChange = (e) => {
		const { value } = e.target || e;
		setInternalComment(value);
	};

	const handleCloseAlert = () => {
		setSuccess('');
	};

	const handleSaveInternalComment = () => {
		setLoading(true);
		const reqData = {applicationId:id,comments:internalComment};
		Api.UpdateInternalComments(account.tokenAuth,reqData)
			.then((res) => {
				if (res.status !== 201) {
					setLoading(false);
					return;
				}

				setSuccess('Internal comments updated');
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div class='user__comments'>
			<FormControl component='fieldset' fullWidth style={{ marginBottom: '30px'}}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<hr/>
						<h3 style={{ paddingTop: '20px', paddingBottom: '20px', color: '#9bb43b' }}>Comments (Internal)</h3>
						<TextField
							label=''
							name='comments'
							value={internalComment}
							color='primary'
							variant='outlined'
							className='multiline'
							rows={15}
							onChange={handleChange}
							fullWidth
							multiline						
						/>
						<p style={{ textAlign: 'right', paddingRight: '10px', paddingTop: '5px', fontSize: '73%', color: '#949494', fontStyle: 'italic' }}>{`You have ${
									1200 - internalComment.length
								} of 1200 characters remaining.`}</p>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify="flex-end">
						<Button style={{ marginRight: '10px'}} disabled={internalComment.length > 1200 ? true : false} variant='contained' color='primary' onClick={() => handleSaveInternalComment()}>
								Save Comment
						</Button>	
						</Grid>					
					</Grid>
				</Grid>
				<Alert icon='success' open={!!success} title='Internal Comment' text={success} confirmButtonText='Done' onConfirm={() => handleCloseAlert()} />				
			</FormControl>
			
		</div>
	)
}

export default connect((s) => ({ account: s.account }))(InternalComment);
