import React, { Fragment, useRef, useState } from 'react';

import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LaunchIcon from '@material-ui/icons/Launch';

import ReactExport from 'react-data-export';
import CSVStringify from 'csv-stringify';

import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import ModalLoading from '../SiteAdmin/ModalLoading';

import { sortBy } from "lodash";

const ExportButton = (props) => {
	
	const [completed, setCompleted] = useState(0);
	const [excelData, setExcelData] = useState([]);
	const [exportLoading, setExportLoading] = useState(false);
	const [exportMessage, setExportMessage] = useState('');
	const [open, setOpen] = useState(false);
	const openSelect = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(1);

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

	const exportCsv = async (data, columns) => {
		let lines = [];
		let colName = [];
		let arr = [];
			columns.filter(f=> !!f.hidden == false).map((x, i) => {
				arr.push(x.title);
				colName.push(x.field);
			});
		lines.push(arr);
		const sleep = (m) => new Promise((r) => setTimeout(r, m));
		setExportLoading(true);
		setExportMessage('Generating CSV');
		setCompleted(0);

		for (let i = 0; i < data.length; i++) {
			let percent = ((i / data.length) * 100).toFixed(0);
			if (percent % 10 == 0) {
				await sleep(50);
				setCompleted(percent);
			}
		}

		
			data.map((x, i) => {
				const lineTemp = [];
				for (let colValues in colName) {
					let value = x[colName[colValues]];
					lineTemp.push(value ? value : '');
				}
				lines.push(lineTemp);
			});

		CSVStringify(lines, (error, string) => {
			if (error) {
				console.warn(error);
				return;
			}

			var blob = new Blob([string], { type: 'text/csv;charset=utf-8' });
			let dataURI = (window.URL || window.webkitURL).createObjectURL(blob);
			let anchor = document.createElement('a');

			if (typeof anchor.download == 'string') {
				anchor.href = dataURI;
				anchor.download = `${props.fileName}-` + moment.utc().tz("America/Phoenix").format('MM/DD/YYYY') + '.csv';
				document.body.appendChild(anchor);
				anchor.click();
			} else {
				window.open(encodeURI(dataURI));
			}
		});
		setCompleted(100);
		setExportLoading(false);
		setExportMessage('');
		setExcelData([]);
	};

	const exportExcel = async (data, columns) => {
		const sleep = (m) => new Promise((r) => setTimeout(r, m));
		setExportLoading(true);
		setExcelData([]);
		setExportMessage('Generating Excel');
		setCompleted(0);

		for (let i = 0; i < data.length; i++) {
			let percent = ((i / data.length) * 100).toFixed(0);
			if (percent % 10 == 0) {
				await sleep(50);
				setCompleted(parseInt(percent));
			}
		}

		const alternatingColor = ['ffffff', 'e7f7a8'];
		let line = [];
		let col = [];
		let colName = [];

		columns.filter(f=> !!f.hidden == false).map((x, i) => {			
				colName.push(x.field);
				col.push({
					title: x.title,
					style: {
						fill: { patternType: 'solid', fgColor: { rgb: '9bb43b' } },
						font: { bold: true },
						alignment: { horizontal: 'center' },
					},
				});
			});

		data.map((x, i) => {
				let color = alternatingColor[i % alternatingColor.length];
				const lineTemp = [];
				for (let colValues in colName) {
					let value = x[colName[colValues]] != null ? x[colName[colValues]].toString() : '-';
					lineTemp.push({
						value: value ? value : ' - ',
						style: { fill: { patternType: 'solid', fgColor: { rgb: color } } },
					});
				}
				line.push(lineTemp);
			});

		const multiDataSet = [
			{
				columns: col,
				data: line,
			},
		];

		if (!isEmpty(multiDataSet)) {
			setExcelData(multiDataSet);
		}

		setCompleted(100);
		setExportLoading(false);
		setExportMessage('');
	};

	const handleClick = async () => {
		setExportLoading(true);
		setExportMessage('Getting data ...');
		
		const data = props.getStatsData();		
		const columns = sortBy([...props.columns], ["tableData.columnOrder"]);

		if (selectedIndex === 0) {
			exportCsv(data, columns);
		} else {
			exportExcel(data, columns);
		}
	};

	const handleMenuItemClick = async (event, index) => {
		setExportLoading(true);
		setExportMessage('Getting data ...');

		const data = props.getStatsData();
		const columns = sortBy([...props.columns], ["tableData.columnOrder"]);

		setSelectedIndex(index);
		if (index === 0) {
			exportCsv(data, columns);
		} else {
			exportExcel(data, columns);
		}
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleCloseSelect = (event) => {
		if (openSelect.current && openSelect.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const handleClose = () => {
		setExportLoading(false);
	};

	const options = ['Export CSV', 'Export Excel'];

	return (
		<Fragment>
			<ModalLoading exportLoading={exportLoading} handleClose={handleClose} exportMessage={exportMessage} completed={completed} />

			<div className='button-box'>
				{excelData.length > 0 && (
					<ExcelFile filename={`${props.fileName}-${moment.utc().tz("America/Phoenix").format('MM/DD/YYYY')}`} hideElement>
						<ExcelSheet dataSet={excelData} name='Applications' />
					</ExcelFile>
				)}
				<Grid container direction='column' alignItems='center'>
					<Grid item xs={12}>
						<ButtonGroup variant='contained' color='primary' ref={openSelect} aria-label='split button'>
							<Button onClick={() => handleClick()} disabled={exportLoading}>
								<LaunchIcon fontSize='small' /> <span style={{ marginTop: 5, marginLeft: 8 }}>{exportLoading ? 'Loading...' : options[selectedIndex]}</span>
							</Button>
							<Button
								color='primary'
								size='small'
								aria-controls={open ? 'split-button-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-label='select merge strategy'
								aria-haspopup='menu'
								onClick={handleToggle}>
								<ArrowDropDownIcon />
							</Button>
						</ButtonGroup>
						<Popper open={open} anchorEl={openSelect.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										position: 'relative',
										left: '-22px',
										width: 183,
										backgroundColor: '#ffffff',
										zIndex: 1,
										transformOrigin: placement === 'bottom' ? 'left top' : 'center bottom',
									}}>
									<Paper>
										<ClickAwayListener onClickAway={handleCloseSelect}>
											<MenuList id='split-button-menu'>
												{options.map((option, index) => (
													<MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
														{option}
													</MenuItem>
												))}
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</Grid>
				</Grid>
			</div>
		</Fragment>
	);
};

ExportButton.propTypes = {
	dataTable: PropTypes.array,
	fileName: PropTypes.string,
};

ExportButton.defaultPropTypes = {
	dataTable: [],
	fileName: 'stats-export',
};

export default ExportButton;
