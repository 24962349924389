import React, { Fragment, useState, useEffect } from 'react';

import StudentInformationView from './ApplicationReadOnlyComponent/StudentInformationView';
import FinancialInformationView from './ApplicationReadOnlyComponent/FinancialInformationView';
import LIAgreementsView from './ApplicationReadOnlyComponent/LIAgreementsView';
import ApplicationNotFound from './ApplicationNotFound';

import api from '../../config/api';

const LIView = (props) => {
	const [application, setApplication] = useState(null);

	useEffect(() => {
		getApplicationById();
	}, []);

	const getApplicationById = () => {
		api.GetApplicationById(props.account.tokenAuth, { id: props.id }).then((response) => {
			const { status, message, data } = response;
			setApplication(data);
		});
	};

	if(!application) return null;

	return (
		<Fragment>
			{!props.error ? (
				<Fragment>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>1. Student Information</h3>
						</div>
						<div className='form__section--body'>
							<StudentInformationView 
								applicationId={application.applicationId} 
								students={application.students} 
								applicationType={application.applicationType} 								
								applicationPeriod={application.period}
								applicationPreviousPeriod={application.previousPeriod}
								token={props.account.tokenAuth} 
								version={application.version||10}
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>2. Household Members and Financial Information</h3>
						</div>
						<div className='form__section--body'>
							<FinancialInformationView
								applicationType={props.applicationType}
								financialInformation={application.financialMembers}
								account={props.account}
								token={props.account.tokenAuth}
								files={application.files}
								id={application.applicationId}
								previousYear={!!application.previousPeriod ? application.previousPeriod.split('/')[0]:''}
								version={application.version||10}
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>3. Agreement Statements</h3>
						</div>
						<div className='form__section--body'>
							<LIAgreementsView
								submittedAgreements={application.submittedAgreements}
								agreements={application.agreements}
								blueprintSubmitted={application.blueprintSubmitted}
								isAgreeStatements={application.isAgreeStatements}
							/>
						</div>
					</div>
				</Fragment>
			) : (
				<ApplicationNotFound message={props.error} />
			)}
		</Fragment>
	);
};

export default LIView;
