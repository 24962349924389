import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux';

import API from '../../config/api';
import ApplicationTraceEmailItem from './ApplicationTraceEmailItem';

import Loader from '../../component/Loader';
import {tplActionNeeded, tplNotApproved} from './tpl';

const ACTION_NEEDED = 7;
const NOT_APPROVED = 6;

function ApplicationTraceModal({traceModalRef,application, newStatus, account}) {
	const myRef = React.useRef({});
	const [loading, setLoading] = useState(false);
	const [emailList, setEmailList] = useState([]);
	const [emailItem, setEmailItem] = useState(null);

	useEffect(() => {
		loadData();
	}, [application]);

	const loadData = async () => {
		if(!!application.applicationId){
			return getStatusEmailList(application.applicationId);
		}		
	};

	const getStatusEmailList = async applicationId => {
		setLoading(true);
		const reqData = {applicationId: applicationId, limit: 1};
		return API.GetStatusEmailList(account.tokenAuth,reqData).then((res) => {
			if (res.status !== 201) {
				setLoading(false);
				return;
			}

			setEmailList(res.data);
			if(res.data.length === 0){
				loadBaseTemplate(); 
			}else{
				const emailItem = res.data[0];
				console.log(res.data);
				if(!!emailItem.sentAt == false){
					setEmailItem(res.data[0]);						
				}else{
					loadBaseTemplate(); 
				}
				
			}
			setLoading(false);
		});
	};

	const loadBaseTemplate = () => {
		const __statusTemplate = newStatus === ACTION_NEEDED?tplActionNeeded:tplNotApproved;
		const newItem = {
			emailId: 0,
			body: __statusTemplate,
			applicationId: application.applicationId 
		}
		setEmailList([newItem]);
		setEmailItem(newItem);		
	};

	const handleUpdateItem = (itemUpdated, newList) => {		
			setEmailList(newList);
			setEmailItem(itemUpdated);			
	};

	const handleSendEmail = async () => {
		await myRef.current.handleSendEmail();
	};

	if(traceModalRef) {
		traceModalRef.current.handleSendEmail = handleSendEmail;	
	}

	
	return (
		<>
			{loading && <Loader loading={true} />}
			{emailItem && <ApplicationTraceEmailItem myRef={myRef} emailData={emailItem} autoSave={false} status={application.status} newStatus={newStatus} onUpdate={handleUpdateItem} height={450} mode="preview" />} 		
		</>
	);
}

const mapStateToProps = (s) => {
	return {
		account: s.account
	};
};

export default connect(mapStateToProps)(ApplicationTraceModal);

