import React, {useState} from 'react';
import { Grid, Button } from '@material-ui/core';

import FinancialInformation from '../../component/FinancialInformation/v2';
import Modal from '../../component/Modal';

import IncomeTable from '../../component/IncomeTable';


const DDFinancialInformationV2 = ({ application, account }) => {
	const [openModal, setOpenModal] = useState(false);
	const {period, previousPeriod } = application;
	const current = period == null ? '' : parseInt(period.split('/')[0]);
	const previous = previousPeriod == null ? '' : parseInt(previousPeriod.split('/')[0]);

	return (
		<div className='form__section'>
			<div className='form__section--header'>
				<h3>3. Household Members and Financial Information</h3>
			</div>
			<Modal open={openModal} onClose={() => setOpenModal(false)} autoHeight>
				<IncomeTable/>
			</Modal>
			<div className='form__section--body'>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={7}>
						<div className='section__student-financial'>
							<FinancialInformation application={application} account={account} />
						</div>
					</Grid>
					<Grid item xs={12} lg={5}>
						<div className='income__requirement'>
							<h4>INCOME REQUIREMENT</h4>
							{/* <div className='income_requirement-block'>
								• The family’s income cannot exceed 185% of the income limit required to qualify for Reduced Price Meals under the National School Lunch and Child Nutrition Acts.
							</div> */}
							<div className='income_requirement-block'>
								• Submit {previous} IRS Form 1040 pages 1&2 and if more than 4 dependents the Federal Supporting Statement Form 1040 Dependents. If your income is below the income threshold required for
								filing taxes, submit a proof of income.
							</div>
							{/* <h4>185 % OF FEDERAL REDUCED PRICE MEALS GUIDELINE</h4>
							<Button color='primary' size='large' onClick={() => setOpenModal(true)}>
								View Table
							</Button> */}
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default DDFinancialInformationV2;
