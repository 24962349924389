import React from 'react';

const index = (props) => {
	console.log('Preset ', props);
	return (
		<div>
			<h1>Preset</h1>
		</div>
	);
};

export default index;
