import React, { Component, useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
	render() {
		return (
			<Fragment>
				<Grid container style={{ backgroundColor: 'white' }}>
					<Grid container direction='column' justify='center' alignItems='center'>
						<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
							<span className='jam jam-close-circle-f' style={{ fontSize: 150, color: 'red' }}></span>
							<Typography variant='h3'>Ups, page not found!</Typography>
							<Typography variant='h4'>Please, Try Again</Typography>
							<br />
							<Button onClick={() => window.history.back()}>
								<h2>Go Back</h2>
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}
