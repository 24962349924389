import React, { useState, useEffect, forwardRef, useImperativeHandle, Fragment } from 'react';
import { Button, IconButton, CircularProgress } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import FileUploader from '../FileUploader';

const FILEKEY = 'other_proof_of_income';

const AttachmentBox = ({ selectedMember, loading, onViewFile, onDeleteFile }) => (
	<div className='attachment__box'>
		<div className='attachment__box--header'>
			<h5>Other Proof of Income</h5>
			{!loading ? (
				<IconButton color='secondary' size='small' title='Remove file' onClick={onDeleteFile}>
					<DeleteForeverIcon />
				</IconButton>
			) : (
				<CircularProgress size={18} />
			)}
		</div>
		<div className='attachment__box--body'>
			<p>Nutrition Assistance / Food Stamp Program, Unemployment Program, Social Security Disability, etc.</p>
			{selectedMember?.financialId && (
				<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} disableRipple onClick={onViewFile}>
					View file
				</Button>
			)}
		</div>
	</div>
);

const Uploader = ({ selectedMember, applicationId, token, onChange }) => {
	let opts = { token, applicationId, fileKey: FILEKEY };
	if (selectedMember?.financialId) opts.financialId = selectedMember.financialId;

	return (
		<div className='attachment-section'>
			<h5>Other Proof of Income</h5>
			<p>Nutrition Assistance / Food Stamp Program, Unemployment Program, Social Security Disability, etc.</p>
			<FileUploader id={FILEKEY} {...opts} onChange={onChange} fullWidth />
		</div>
	);
};

const OtherProofIncome = ({ applicationId, selectedMember, token, onSetAttachment, onViewFile, onDeleteFile }, ref) => {
	const [loading, setLoading] = useState(false);
	const [uploaded, setUploaded] = useState(false);

	useImperativeHandle(ref, () => ({
		clearAttachment: () => setUploaded(false),
	}));

	useEffect(() => {
		setUploaded(selectedMember && selectedMember[`${FILEKEY}_key`]);
	}, [selectedMember]);

	const handleChange = (file) => {
		setUploaded(true);
		onSetAttachment(`${FILEKEY}_key`, file.fields.Key);
	};

	const handleDeleteFile = async () => {
		setLoading(true);
		await onDeleteFile(FILEKEY);
		setUploaded(false);
		setLoading(false);
		onSetAttachment(`${FILEKEY}_key`, null);
	};

	return (
		<Fragment>
			{!uploaded ? (
				<Uploader selectedMember={selectedMember} applicationId={applicationId} token={token} onChange={handleChange} />
			) : (
				<AttachmentBox selectedMember={selectedMember} loading={loading} onViewFile={(_) => onViewFile(FILEKEY)} onDeleteFile={handleDeleteFile} />
			)}
		</Fragment>
	);
};

export default forwardRef(OtherProofIncome);
