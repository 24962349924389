import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import { Grid, Button, CircularProgress, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import AlertModal from '../../component/partials/Alerts/Alert';

import { Alert, AlertTitle } from '@material-ui/lab';
import API from '../../config/api';

import { setAccount } from '../../redux/actions/account';
import { setFilters } from '../../redux/actions/filters';

function ChangePeriod({ account, history, change, ...props }) {
	const [loading, setLoading] = useState(false);
	const [proceedAlert, setProceedAlert] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const {nextPeriod, period: currentPeriod} = account.periodSettings || {};

	const [formUpgraded,setFormUpgraded] = useState({
		attendance: false,
		preschool: false,
		tuition: false
	});


	const logout = () => {
		if (localStorage.getItem('tokenAuth')) {
			localStorage.removeItem('tokenAuth');			
			props.dispatch(setFilters({}, 3));
			props.dispatch(setFilters({}, 4));
			props.dispatch(setFilters({}, 5));
			props.dispatch(setFilters({}, 6));
			props.dispatch(setAccount({}));
			history.push('/login');
		}
	};
	
	const startNewPeriod = () => {
		setLoading(true);
		API.StartPeriod(account.tokenAuth)
			.then((res) => {
				if (res.status === 201) {
					logout();
				}
				else if (res.message) setErrorMsg(res.message);								
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const handleFormUpgradedChange = (event, form) => {
		const { checked } = event.target;

		setFormUpgraded({
			...formUpgraded,
			[form]: checked
		});
	};

	return (
		<div className='profile-page__user-information-tab new-period'>
			<h3 className='section-title'>Start New Period</h3>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Alert severity='error' style={{ border: '1px solid #a0201c'}}>
						<AlertTitle><b>IMPORTANT</b></AlertTitle>
							<Fragment>
								<p>At the start of the new period, all current information will be archived 
									under the previous period ({currentPeriod} in this case) 
									and the system will be cleared to receive new applications.</p>
								<br/>
								<p>If you wish to block the application submissions with the intention of
									eventually reopening within the current period please <a href="#" onClick={() => change('Management')} style={{ cursor: 'pointer' }}>click here.</a>
								</p>
								<p>
									<b><i>*** In this process your session will be closed, you should log in again</i></b>
								</p>
						</Fragment>
					</Alert>
				</Grid>
				<Grid item xs={8}>
					<p><b>You should update the following files, indicate if you have already done it:</b></p>
					<div>
						<FormControlLabel control={<Checkbox color='primary' checked={formUpgraded.attendance} />} 
							label='Attendance Verification Form upgraded' onChange={(e)=>handleFormUpgradedChange(e,'attendance')} />
					</div>
					<div>
						<FormControlLabel control={<Checkbox color='primary' checked={formUpgraded.preschool} />} 
							label='Preschool Application Form upgraded' onChange={(e)=>handleFormUpgradedChange(e,'preschool')}/>
					</div>
					<div>	
						<FormControlLabel control={<Checkbox color='primary' checked={formUpgraded.tuition} />} 
							label='School Tuition Organization Award Verification Form upgraded' onChange={(e)=>handleFormUpgradedChange(e,'tuition')}/>
					</div>			
				</Grid>
				<Grid item xs={8}>
					<FormHelperText error>{errorMsg}</FormHelperText>
					{!loading && 
						<Button 
							variant='contained' 
							color='primary' 
							disabled={!(
									formUpgraded.attendance === true &&
									formUpgraded.preschool === true &&
									formUpgraded.tuition === true
								)}
							onClick={()=>setProceedAlert(true)}>
							Start {nextPeriod} Period
						</Button>
					}
					{loading && <CircularProgress size={40} color='primary' />}
					
				</Grid>				
			</Grid>
			<AlertModal icon='warning' open={proceedAlert} title={`Start ${nextPeriod} Period`} text='This action will start a new period, are you sure you would like to proceed?' confirmButtonText='Proceed' cancelButtonText='Cancel' onConfirm={()=>startNewPeriod()} onCancel={()=>setProceedAlert(false)}  />
		</div>
	)
}

export default connect((s) => ({ account: s.account }))(ChangePeriod);

