import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import configureStore from './redux';
import { Provider } from 'react-redux';
import Auth from './config/auth';

import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import ForgotEmail from './views/ForgotEmail';
import PassSecurity from './views/PassSecurity';
// import AdminProcessor from './views/AdminProcessor';
import SiteAdmin from './views/SiteAdmin';
import UserProfile from './views/UserProfile';
import ManagementApplication from './views/ManagementApplication';
// import FollowUpProcessor from './views/FollowUpProcessor';
// import GeneralProcessor from './views/GeneralProcessor';
import NotFound from './component/partials/NotFound';
import { ApplicationForm, ApplicationView } from './views/Application';
import IndexRoute from './routes/IndexRoute';
import ApplicationsHistory from './views/ApplicationsHistory';
import Metrics from './views/Metrics';
import StatsPerSchool from './views/StatsPerSchool';
import Editor from './views/Editor';

import Preset from './views/Preset';


const store = configureStore();
function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Switch>
						<Route exact path='/' component={(e) => Auth.authorize(IndexRoute, e, store, [3, 4, 5, 6])} />
						<Route exact path='/preset-test' component={(e) => Auth.authorize(Preset, e, store, [3, 4, 5, 6])} />

						<Route exact path='/:adminType/applications' component={(e) => Auth.authorize(SiteAdmin, e, store, [3, 4, 5, 6])} />
						<Route exact path='/:adminType/applications/history/:type/:id' component={(e) => Auth.authorize(ApplicationsHistory, e, store, [3, 4, 5, 6])} />
						<Route exact path='/:adminType/metrics' component={(e) => Auth.authorize(Metrics, e, store, [3, 4, 5, 6])} />
						<Route exact path='/:adminType/report/:reportId' component={(e) => Auth.authorize(SiteAdmin, e, store, [3])} />
						<Route exact path='/:adminType/stats-per-school' component={(e) => Auth.authorize(StatsPerSchool, e, store, [3])} />

						{/* <Route exact path='/admin-processor' component={e => Auth.authorize(AdminProcessor, e, store, [4])} />
						<Route exact path='/followup' component={e => Auth.authorize(FollowUpProcessor, e, store, [5])} />
						<Route exact path='/general' component={e => Auth.authorize(GeneralProcessor, e, store, [6])} /> */}

						<Route exact path='/login' component={(e) => Auth.validate(Login, e, store)} />
						<Route exact path='/login/:email' component={(e) => Auth.validate(Login, e, store)} />
						<Route exact path='/register' component={Register} />
						<Route exact path='/forgot-password' component={ForgotPassword} />
						<Route exact path='/forgot-email' component={ForgotEmail} />
						<Route exact path='/account/complete/:code' component={PassSecurity} />
						<Route exact path='/account/reset/:code' component={ResetPassword} />
						<Route exact path='/user/profile' component={(e) => Auth.authorize(UserProfile, e, store, [1])} />
						<Route exact path='/admin/management' component={(e) => Auth.authorize(ManagementApplication, e, store, [3, 4, 5, 6])} />

						{/* TODO: Testing Editor */}
						<Route exact path='/admin/editor' component={(e) => Auth.authorize(Editor, e, store, [3, 4, 5, 6])} />

						<Route exact path='/:adminType/application/:applicationType/view/:id' component={(e) => Auth.authorize(ApplicationView, e, store, [3, 4, 5, 6])} />
						<Route exact path='/:adminType/application/:applicationType/edit/:id' component={(e) => Auth.authorize(ApplicationForm, e, store, [3, 4, 5, 6])} />

						<Route component={NotFound} />
					</Switch>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
