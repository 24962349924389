import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const defaultProps = { locale: 'en-US', format: 'MM/dd/y' };

const DateRangePickerComponent = ({ value, onChange, ...otherProps }) => <DateRangePicker value={value} onChange={onChange} {...defaultProps} {...otherProps} />;

DateRangePickerComponent.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
};

export default DateRangePickerComponent;
