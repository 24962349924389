import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal, Fade, LinearProgress } from '@material-ui/core';
import { lighten, withStyles } from '@material-ui/core/styles';

const ModalLoading = (props) => {
	const BorderLinearProgress = withStyles({
		root: {
			height: 5,
			backgroundColor: lighten('#9bb43b', 0.5),
		},
		bar: {
			backgroundColor: '#7a9903',
		},
	})(LinearProgress);

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={props.exportLoading}
			onClose={props.handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Fade in={props.exportLoading}>
				<div style={{ backgroundColor: '#ffffff', boxShadow: '1px 3px 1px #000000', padding: 55 }}>
					<h2>{props.exportMessage}</h2>
					<br />
					<BorderLinearProgress variant='determinate' value={props.completed} />
				</div>
			</Fade>
		</Modal>
	);
};

ModalLoading.propTypes = {
	exportLoading: PropTypes.bool,
};

ModalLoading.defaultPropTypes = {
	exportLoading: false,
};

export default ModalLoading;
