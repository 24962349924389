import * as types from '../constants';
import API from '../../config/api';

const loadedApplication = (application,adminType) => ({
	type: types.LOADED_APPLICATION,
	adminType: adminType,
	loading: false,
	application,
});

const loadingApplication = adminType => ({
	type: types.LOADING_APPLICATION,
	adminType: adminType,
	loading: true	
});


export const getApplicationRdx = (token, id, adminType) => dispatch => {	
	dispatch(loadingApplication());	
	return API.GetApplicationById(token, {id}).then((response) => {
		const { status, message, data } = response;
		if (status !== 201) return;

		dispatch(loadedApplication(data, adminType));
	});
};

export const setApplicationRdx = (data, adminType) => dispatch => {	
	dispatch(loadedApplication(data, adminType));
};

export const setLoadingApplicationRdx = adminType => dispatch => {	
	dispatch(loadingApplication(adminType));	
};
