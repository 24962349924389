import { SET_ACCOUNT, GET_ALL } from '../constants';

const initialState = {};

export default function account(state = initialState, action) {
	switch (action.type) {
		case SET_ACCOUNT:
			return {
				...action.data,
			};
		case GET_ALL:
			return {
				...state,
			};
		default:
			return state;
	}
}
