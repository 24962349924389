import HelloSign from 'hellosign-embedded';

import API from '../config/api';

class HelloSignUtil {
	constructor() {
		this.client = new HelloSign({ clientId: '98d741aae14079ffeb9aec1c2e89c0ec' });
	}

	RequestSignature = async () => {
		try {
			const { applicationId, name, email, token } = this;
			const signature = await API.RequestSignature(token, { applicationId, name, email });

			if (signature.status != 201) throw { status: signature.status, message: signature.message };

			this.EmbedDocument(signature.data);

			return { status: signature.status };
		} catch (err) {
			return err;
		}
	};

	EmbedDocument = (signature) => {
		const { embeddedUrl, signatureId, signatureRequestId } = signature,
			{ applicationId, token } = this;

		this.client.open(embeddedUrl, {
			allowCancel: true,
			skipDomainVerification: true, // for testing mode
		});

		this.client.on('sign', (data) => {
			API.UpdateSignature(token, { applicationId, signatureId, signatureRequestId });
		});
		this.client.on('finish', () => window.location.replace('/'));
	};

	DownloadSignedDocument = () => {
		const { token, signatureRequestId } = this;

		const viewFile = window.open();

		API.GetSignatureRequestDownload(token, { signatureRequestId }).then((response) => {
			viewFile.location.href = response.data;
			setTimeout(() => viewFile.close(), 3000);
		});
	};
}

// export default new HelloSignUtil()
export default HelloSignUtil;
