import React from 'react';
import { connect } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';


const ProcessorColumn = ({rowData, role, handleOpenAssignModal, ...props}) => {

	const { period: currentPeriod } = props.account.periodSettings;	

	return (
		<div>							
			{rowData.assignedProcessorName != '' ? (
				<Box display='flex' flexDirection='row' justifyContent='center'>
					<Box display='flex' flexDirection='row' alignContent='center' alignItems='center'>
						<p style={{ fontSize: 14 }}>{rowData.assignedProcessorName}</p>

						{(role === 4 && currentPeriod === props.filters.period) && (
							<IconButton aria-label='toggle password visibility' edge='end' onClick={() => handleOpenAssignModal(rowData)}>
								<EditIcon fontSize='small' />
							</IconButton>
						)}
					</Box>
				</Box>
			) : (
				<Box display='flex' flexDirection='row' justifyContent='center'>
					<Box display='flex' flexDirection='row' alignContent='center' alignItems='center'>
						<p style={{ marginBottom: 0, fontSize: 14 }}>{(role === 4 && currentPeriod === props.filters.period) ? 'Add Processor' : 'Not Assigned'}</p>
						{(role === 4 && currentPeriod === props.filters.period) && (
							<IconButton aria-label='toggle password visibility' edge='end' onClick={() => handleOpenAssignModal(rowData)}>
								<AddCircleIcon fontSize='small' />
							</IconButton>
						)}
					</Box>
				</Box>
			)}
		</div>		
	);
};


const mapStateProps = (state) => {
	return { 
		account: state.account,
		filters: state.filters 
	};
};

export default connect(mapStateProps)(ProcessorColumn);

