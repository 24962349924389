// Account
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const GET_ALL = 'GET_ALL';

// Settings
export const SET_SETTING = 'SET_SETTING';

// Columns
export const SAVE_COLUMNS = 'SAVE_COLUMNS';

// Filter
export const SET_FILTER_ID = 'SET_FILTER_ID';

// Set selected period
export const SET_SELECTED_PERIOD = 'SET_SELECTED_PERIOD';

// Income table
export const LOADED_INCOME_TABLE = 'LOADED_INCOME_TABLE';
export const LOADING_INCOME_TABLE = 'LOADING_INCOME_TABLE';

// Application
export const LOADING_APPLICATION = 'LOADING_APPLICATION';
export const LOADED_APPLICATION = 'LOADED_APPLICATION';

// Status Email
export const LOADED_STATUS_EMAIL_LIST = 'LOADED_STATUS_EMAIL_LIST';
export const LOADING_STATUS_EMAIL_LIST = 'LOADING_STATUS_EMAIL_LIST';
export const UPDATING_STATUS_EMAIL_LIST = 'UPDATING_STATUS_EMAIL_LIST';
export const UPDATED_STATUS_EMAIL_LIST = 'UPDATED_STATUS_EMAIL_LIST';
export const ERROR_STATUS_EMAIL_LIST = 'ERROR_STATUS_EMAIL_LIST';


