import * as actionTypes from '../constants';

const initialState = {
	data: [],
	loading: false,
};

export default function income(state = initialState, action) {
	switch (action.type) {
		case actionTypes.LOADED_INCOME_TABLE:
			return {
				...state,
				loading: action.loading,
				data:[...action.incomes]
			};
		case actionTypes.LOADING_INCOME_TABLE:
		return {
				...state,
				loading: action.loading,
			};
		default:
			return state;
	}
}
