import React, { Fragment, forwardRef, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Toolbar from './Toolbar';

const actionIconProps = { color: 'primary', fontSize: 'small' };
const actionIconPropsSecondary = { color: 'secondary', fontSize: 'small' };
const actionIconPropsHistory = { color: '#FF8000', fontSize: 'small' };

const addGrabToScrollToTable = () => {
	const ele = document.querySelectorAll("div[class^='Component-horizontalScrollContainer-'] > div> div")[0];
	if (!ele) return;
	//ele.style.cursor = 'grab';
	let pos = { top: 0, left: 0, x: 0, y: 0 };

	const mouseDownHandler = function (e) {

		var path = e.path || (e.composedPath && e.composedPath());
		if(path && path[2]){
			if(path[2].tagName == 'TBODY'){
				ele.style.cursor = 'grabbing';
				ele.style.userSelect = 'none';

				pos = {
					left: ele.scrollLeft,
					top: ele.scrollTop,
					// Get the current mouse position
					x: e.clientX,
					y: e.clientY,
				};
			
			}else{
				if(path[2].tagName == 'TH'){
					let elms = document.getElementsByTagName("th");
					for(var i = 0; i < elms.length; i++) {
						elms[i].style.position = 'relative';
						elms[i].style.zIndex = '10';
					}

					path[2].style.zIndex = '11';
					path[2].style.backgroundColor ='rgb(243 255 242)';				
					path[2].style.color = 'rgb(17 101 17)';

					if(path[0].getAttribute('data-rbd-draggable-context-id')){
						path[0].style.border ='1px dashed gray';
						path[0].style.padding ='10px';
						path[0].style.position='relative';
						path[0].style.cursor='ew-resize';
						path[0].setAttribute('class','drag-selected');
					}
				}
			}
		}

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};

	const mouseMoveHandler = function (e) {	
		var path = e.path || (e.composedPath && e.composedPath());
		if(path && path[2]){	
			if(path[2].tagName == 'TH'){
				let elms = document.getElementsByTagName("th");
				for(var i = 0; i < elms.length; i++) {
					elms[i].style.backgroundColor ='';				
					elms[i].style.borderTop = '';
					elms[i].style.color = '';				
				}

				path[2].style.backgroundColor ='rgb(243 255 242)';
				path[2].style.borderTop = '1px dotted rgb(155 180 59)';
				path[2].style.color = 'rgb(17 101 17)';						
			}

			if(path[2].tagName == 'TBODY'){
				// How far the mouse has been moved
				const dx = e.clientX - pos.x;
				const dy = e.clientY - pos.y;

				// Scroll the element
				ele.scrollTop = pos.top - dy;
				ele.scrollLeft = pos.left - dx;
			}		
		}
	};

	const mouseUpHandler = function () {

		ele.style.cursor = 'grab';
		ele.style.removeProperty('user-select');

		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);

		let elms = document.getElementsByTagName("th");
			for(var i = 0; i < elms.length; i++) {
			  elms[i].style.backgroundColor = '';
			  elms[i].style.borderTop = '';
			  elms[i].style.color = '';	

			  let selElem = elms[i].getElementsByClassName('drag-selected');
			  if(selElem.length>0) {
					selElem[0].style ='';
					selElem[0].setAttribute('class','');
				}
			}			
	};

	// Attach the handler
	ele.addEventListener('mousedown', mouseDownHandler);
};

const MaterialTableComponent = (props) => {
	const [status, setStatus] = useState(props.status);
	const [componentPeriod, setComponentPeriod] = useState(props.componentPeriod);
	const [tableOption, setOption] = useState(props.options);

	const isCurrentPeriod = props.role === 6 || props.account.periodSettings.period === props.filters.period;

	const tableRef = useRef();

	useEffect(() => {
		setOption(props.options);
	}, [props.options]);

	useEffect(() => {
		setStatus(props.status);
	}, [props.status]);

	useEffect(() => {
		setComponentPeriod(props.componentPeriod);
	}, [props.componentPeriod]);

	useEffect(() => {
		const query = tableRef.current.state.query;

		
			const reloadQuery = {
				...query,
				page: 0,
				filters: [],
				search: null,
				pageSize: tableOption.pageSize,
			};

			tableRef.current.setState({
				...tableRef.current.dataManager.getRenderState(),
				query: reloadQuery,
			});

			tableRef.current.dataManager.changePageSize(tableOption.pageSize);

			for (var item in tableRef.current.state.query.filters) {
				const tableColumn = tableRef.current.state.query.filters[item].column;
				tableRef.current.dataManager.changeFilterValue(tableColumn.tableData.id, null);
			}

			tableRef.current.onQueryChange(reloadQuery);

		addGrabToScrollToTable();
	}, [props.reloadTable]);

	useEffect(()=>{
		const query = tableRef.current.state.query;
		tableRef.current.onQueryChange(query);

		addGrabToScrollToTable();

	},[props.reloadTableData]);	

	const tableLocalization = { header: { actions: 'Actions' } };

	const actions = [
		{ icon: () => <FilterListIcon {...actionIconProps} />, tooltip: 'Show Filters', onClick: () => props.showFilter(), isFreeAction: true },
		{ icon: () => <ViewColumnIcon {...actionIconProps} />, tooltip: 'Select Columns', onClick: () => props.setOpenColumns(true), isFreeAction: true },
		{ icon: () => <SaveIcon {...actionIconProps} />, tooltip: `${props.filters.default ? 'Save' : 'Update'} Filter`, onClick: () => props.openSaveFilterModal(), isFreeAction: true },
		!props.isEditReport && { icon: () => <VisibilityIcon {...actionIconProps} />, tooltip: 'View Application', onClick: (event, rowData) => props.handleView(rowData) },
		!props.isEditReport &&
			props.role !== 3 &&
			props.role !== 6 &&
			isCurrentPeriod &&
			((rowData) => ({
				icon: () => <EditIcon {...actionIconProps} />,
				tooltip: 'Edit Application',
				onClick: (event, rowData) => props.handleEdit(rowData),
				hidden: false/*rowData.statusId === 7*/,
			})),
		!props.isEditReport &&
			props.role === 5 &&
			isCurrentPeriod &&
			((rowData) => ({
				icon: () => <MailOutlineIcon {...actionIconProps} />,
				tooltip: 'Send Email',
				onClick: (event, rowData) => props.toggleAlert(rowData),
				hidden: true/*rowData.statusId !== 7*/,
			})),
		props.role === 3 &&
		isCurrentPeriod &&
			(() => ({
				icon: () => <DeleteIcon {...actionIconPropsSecondary} />,
				tooltip: 'Delete Application',
				onClick: (event, rowData) => props.deleteApplication(rowData),
			})),
		(props.role === 3 || props.role === 5) &&
		 isCurrentPeriod &&
			(() => ({
				icon: () => <HistoryIcon {...actionIconPropsHistory} />,
				tooltip: 'History',
				onClick: (event, rowData) => props.handleOpenHistory(rowData),
			})),
	];

	return (
		<Fragment>
			<MaterialTable
				tableRef={tableRef}
				title={props.title}
				columns={props.columns}
				actions={actions}
				options={tableOption}
				localization={tableLocalization}
				icons={{ SortArrow: forwardRef((props, ref) => <span {...props} ref={ref} />) }}
				components={{ Toolbar: (props) => <Toolbar {...props} status={status} componentPeriod={componentPeriod} />, ...props.components }}
				data={(query) =>
					new Promise((resolve, reject) => {
						const { page, pageSize, filters, orderBy, orderDirection, search } = query;

						props.onQueryChanged({ ...query, pageSize: pageSize });

						const nextPage = page + 1;
						props.remoteData(nextPage, pageSize, filters, orderBy, orderDirection, search).then((res) => {
							if (res.status != 201) return;
							resolve({
								data: res.data.dataset,
								page: res.data.page - 1,
								totalCount: Number(res.data.totalRecords),
							});
							props.onAfterRemoteData(res);
						});
					})
				}
				onColumnDragged={props.onColumnDragged}
				onChangeRowsPerPage={(pageSize) => {
					//setOption({ ...tableOption, pageSize: pageSize });
					props.onChangePageSize(pageSize);
				}}
			/>
		</Fragment>
	);
};

MaterialTableComponent.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.array,
	options: PropTypes.any,
	components: PropTypes.any,
	data: PropTypes.array,
	remoteData: PropTypes.func,
	onAfterRemoteData: PropTypes.func,
	onChangePageSize: PropTypes.func,
};

MaterialTableComponent.defaultProps = {
	title: '',
	columns: [],
	components: {},
	data: [],
	onAfterRemoteData: () => {},
	onChangePageSize: () => {},
};

const mapStateProps = (state) => {
	return { 
		account: state.account,
		filters: state.filters 
	 };
};

export default connect(mapStateProps)(MaterialTableComponent);
