import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />;

const Snackbar = ({ open, severity, text, handleClose, autoHideDuration }) => {
	return (
		<MuiSnackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
			<Alert onClose={handleClose} severity={severity}>
				{text}
			</Alert>
		</MuiSnackbar>
	);
};

Snackbar.propTypes = {
	open: PropTypes.bool.isRequired,
	severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
	text: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	autoHideDuration: PropTypes.number,
};

Snackbar.defaultProps = {
	autoHideDuration: 6000,
};

export default Snackbar;
