export const COLORS = {
	Secondary: '#9BB43B', //green

	Success: '#28a745',
	Danger: '#dc3545',
};

export const REGEX = {
	passwordRegEx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~#$^+=!*()@%&.`|{}\\[\]:;',/<>?])(?=.{8,})/,
	emailRegEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	phoneRegEx: /^[0-9]{10}$/,
	zipRegEx: /^\d{5}$|^\d{5}-\d{4}$/,
	currencyRegEx: /[^0-9.-]+/g,
};

 export const STATUS = {
	DELETED: 0,
	SAVED: 1,
	NEW: 2,
	REVIEW_IN_PROGRESS: 3,
	INCOMPLETE: 4,
	APPROVED: 5,
	NOT_APPROVED: 6,
	ACTION_NEEDED: 7
 };

  export const STATUS_LABEL = [
	'Deleted',
	'Saved',
	'New',
	'Review in Progress',
	'Incomplete',
	'Approved',
	'Not Approved',
	'Action Needed-Incomplete'
  ];