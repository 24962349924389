import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, TextField, Typography } from '@material-ui/core';

import Api from '../../config/api';
import { REGEX } from '../../utils/constants';

import Layout from '../../component/partials/Layout';
import Loader from '../../component/Loader';

const SuccessBox = ({ email }) => (
	<Box className='auth-box-message' style={{ width: 350 }}>
		<Box className='forgot_success_box'>
			<h6>RECOVERY PASSWORD</h6>
			<p>
				We have sent you an email to <span style={{ fontWeight: 'bold' }}>{email}</span> with the steps to recover your password
			</p>
		</Box>
		<Box className='button__container'>
			<Link to='/'>
				<Button variant='contained'>Back to login</Button>
			</Link>
		</Box>
	</Box>
);

const FormBox = ({ handleComplete, handleEmail }) => {
	const [emailError, setEmailError] = useState({ error: false, message: '' });
	const [loading, setLoading] = useState(false);
	const [field, setField] = useState('');

	const handleChange = (event) => {
		const { value } = event.target;

		setField(value);
		setEmailError({ error: false, message: '' });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const isEmail = REGEX.emailRegEx.test(field);

		if (!isEmail) return setEmailError({ error: true, message: 'Enter a valid email' });

		setLoading(true);

		Api.RecoveryPassword({ email: field })
			.then((res) => {
				const { status, message } = res;

				setLoading(false);

				if (status === 400) return setEmailError({ error: true, message });

				handleEmail(field);
				handleComplete(true);
			})
			.catch((err) => console.log('Error: ', err));
	};

	return (
		<Box className='register_container_box'>
			<Typography className='register_container_box_title'>Forgot Password</Typography>
			<span className='register_span'></span>
			<form onSubmit={handleSubmit} className='register_form' autoComplete='off'>
				<TextField label='Email' name='email' variant='outlined' size='small' onChange={handleChange} error={emailError.error} helperText={emailError.message} required />
				<Box className='register_button'>
					{!loading ? (
						<Button type='submit' variant='contained'>
							Submit
						</Button>
					) : (
						<div style={{ marginTop: 15 }}>
							<Loader loading={true} />
						</div>
					)}
				</Box>
			</form>
		</Box>
	);
};

const ForgotPassword = (props) => {
	const [isComplete, setIsComplete] = useState(false);
	const [email, setEmail] = useState('');

	return (
		<Layout title='Log In' showBackground={false}>
			<Grid item xs={12}>
				<Box className='register_container'>{!isComplete ? <FormBox handleComplete={setIsComplete} handleEmail={setEmail} /> : <SuccessBox email={email} />}</Box>
			</Grid>
		</Layout>
	);
};

export default ForgotPassword;
