import React, { useState } from 'react'


import { Button, IconButton, CircularProgress } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import DescriptionIcon from '@material-ui/icons/Description';

import API from '../../config/api';

function UploaderBox({media, readOnly = false, disabled = false, token, onViewedMedia, onDeletedMedia}) {

	const [deleting, setDeleting] = useState(false);

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	const handleViewMedia = () => {
		const data = { id:media.mediaId };

		const viewFile = window.open();
		API.GetDownloadMediaUrl(token, data).then((res) => {
			if(res.data){
				viewFile.location.href = res.data;
				viewFile.focus();
				//call onViewMedia
				onViewedMedia(data.id, res.data);
			}
			else{
				// error when get url
			}
		});
	};

	const handleDeleteMedia = () => {
		if(deleting) return;
		setDeleting(true);
		const data = { id: media.mediaId };
		API.DeleteMedia(token, data).then((res) => {
			//call the onDeleteMedia
			if(res.data.deleted){
				 onDeletedMedia(data.id);				
			}
			setDeleting(false);
		});
	};

	return (
	<div className='simple-attachment__box'>
		<div className='attachment__box--header'>
			<div className='left-content'>
				<Button color='primary' className='view-button' startIcon={<DescriptionIcon color='primary' />} onClick={handleViewMedia} disableRipple>						
					{media.fileName}
				</Button>
			</div>
			<div className="right-content">
				<span className="file-size">
					{formatBytes(media.fileSize)}
				</span>
				
				{!readOnly && (
					<IconButton color='secondary' size='small' title='Remove file' onClick={handleDeleteMedia} disabled={disabled}>					
						{!deleting?(
							<DeleteForeverIcon />
							):(
							<CircularProgress size={18} />	
						)}				
					</IconButton>
				)}				
			</div>
		</div>
		
	</div>
	)
}

export default UploaderBox;