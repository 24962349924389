import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, TextField, Select, MenuItem, InputLabel, Button, FormControl, FormGroup, FormControlLabel, Checkbox, IconButton, Chip, Collapse } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SendIcon from '@material-ui/icons/Send';
import MomentUtils from '@date-io/moment';

import Modal from './Modal';
import Loader from './Loader';

import API from '../config/api';
import { errorField } from '../utils/helpers';
import { REGEX } from '../utils/constants';

const { emailRegEx } = REGEX;

const initialFormState = { reportId: null, name: '', deliveryFrequency: '', deliveryDate: null, startDate: null, endDate: null, emailRecipients: '', columns: [] };
const initialComponentState = { deliveryDate: '', deliveryTime: '', startDate: null, endDate: null, email: '', emails: [], sendReport: false };

const WeeklySelect = ({ componentState, onChange }) => (
	<Select label='Delivery Date' labelId='deliveryDate-label' id='deliveryDate' name='deliveryDate' value={componentState.deliveryDate} onChange={onChange}>
		{moment.weekdays().map((d) => (
			<MenuItem key={d} value={d}>
				{d}
			</MenuItem>
		))}
	</Select>
);

const MonthlySelect = ({ componentState, onChange }) => (
	<Select label='Delivery Date' labelId='deliveryDate-label' id='deliveryDate' name='deliveryDate' value={componentState.deliveryDate} onChange={onChange}>
		{moment.months().map((d) => (
			<MenuItem key={d} value={d}>
				{d}
			</MenuItem>
		))}
	</Select>
);

const ReportModal = ({ account, open, title, filterColumns, selectedReport, allowSendReport, readOnly, confirmButton, onSetSelectedReport, onUpdateSelectedReport, onClose }) => {
	const [openCollapse, setOpenCollapse] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(false);
	const [formSuccessMessage, setFormSuccessMessage] = useState({});
	const [formState, setFormState] = useState(initialFormState);
	const [componentState, setComponentState] = useState(initialComponentState);

	// handle success alert
	useEffect(() => {
		if (formSuccess) setTimeout(() => setFormSuccess(false), 4500);
	}, [formSuccess]);

	// handle deliveryDate
	useEffect(() => {
		let deliveryDate;
		const dateFormat = 'MM/DD/YYYY',
			timeFormat = 'HH:mm';
		const { deliveryFrequency } = formState,
			{ deliveryTime } = componentState;

		const setDate = (date) => moment(`${date} ${deliveryTime}`).format(`${dateFormat} ${timeFormat}`);

		if (!deliveryFrequency) return;

		const now = moment().format(dateFormat);
		const todayWeekDay = moment().day();
		const currentMonth = moment().month();
		const selectedWeekDay = moment().day(componentState.deliveryDate).day();
		const selectedMonth = moment().month(componentState.deliveryDate).month();

		switch (deliveryFrequency) {
			case 'Daily':
				deliveryDate = setDate(now);
				break;
			case 'Weekly':
				if (todayWeekDay <= selectedWeekDay) {
					const date = moment().day(selectedWeekDay).format(dateFormat);
					deliveryDate = setDate(date);
				} else {
					const date = moment().add(1, 'weeks').day(selectedWeekDay).format(dateFormat);
					deliveryDate = setDate(date);
				}
				break;
			case 'Monthly':
				if (currentMonth <= selectedMonth) {
					const date = moment().month(selectedMonth).set('date', 1).format(dateFormat);
					deliveryDate = setDate(date);
				} else {
					const date = moment().add(1, 'years').month(selectedMonth).set('date', 1).format(dateFormat);
					deliveryDate = setDate(date);
				}
				break;
		}
		setFormState({ ...formState, deliveryDate });
	}, [componentState.deliveryTime, componentState.deliveryDate]);

	// handle emailRecipients
	useEffect(() => {
		const emailRecipients = componentState.emails.join();
		setFormState({ ...formState, emailRecipients });
	}, [componentState.emails]);

	// handle edit mode
	useEffect(() => {
		if (!selectedReport) return;

		delete selectedReport.createdAt;
		delete selectedReport.role;
		delete selectedReport.updatedAt;
		delete selectedReport.user;

		const { columns } = selectedReport;

		const formatDeliveryDate = () => moment.utc(selectedReport.deliveryDate).format('MM/DD/YYYY HH:mm');
		const formatStartEndDate = (date) => moment.utc(date).format('MM/DD/YYYY');

		const { deliveryDate, deliveryTime } = getDeliveryDateTime();
		const startDate = selectedReport.startDate ? formatStartEndDate(selectedReport.startDate) : null;
		const endDate = selectedReport.endDate ? formatStartEndDate(selectedReport.endDate) : null;
		const emails = selectedReport.emailRecipients.split(',');

		setFormState({ ...selectedReport, deliveryDate: formatDeliveryDate(), startDate, endDate, columns });
		setComponentState({ ...initialComponentState, deliveryDate, deliveryTime, startDate, endDate, emails });
	}, [selectedReport]);

	// handle filterColumns
	useEffect(() => {
		if (!filterColumns) return;
		setFormState({ ...formState, columns: filterColumns });
	}, [filterColumns]);

	const getDeliveryDateTime = (report) => {
		let result = {};
		const { deliveryFrequency, deliveryDate } = selectedReport;

		const getDate = (format) => moment.utc(deliveryDate).format(format);
		const getTime = () => moment.utc(deliveryDate).format('HH:mm');

		switch (deliveryFrequency) {
			case 'Daily':
				result = { deliveryDate: '', deliveryTime: getTime() };
				break;
			case 'Weekly':
				result = { deliveryDate: getDate('dddd'), deliveryTime: getTime() };
				break;
			case 'Monthly':
				result = { deliveryDate: getDate('MMMM'), deliveryTime: getTime() };
				break;
		}

		return result;
	};

	const handleFormStateChange = (e) => {
		let { name, value } = e.target;
		setFormState({ ...formState, [name]: value });
		setFormError(null);
	};

	const handleComponentStateChange = (e) => {
		const { name, value } = e.target;
		setComponentState({ ...componentState, [name]: value });
		setFormError(null);
	};

	const handleFrequencyChange = (e) => {
		let { name, value } = e.target;
		setFormState({ ...formState, [name]: value, deliveryDate: null });
		setComponentState({
			...componentState,
			deliveryDate: '',
			sendReport: value == 'SendOnce' ? false : componentState.sendReport,
		});
	};

	const handleCheckboxChange = (e) => {
		const { name } = e.target;
		setComponentState({ ...componentState, [name]: !componentState[name] });
	};

	const handleEmailsChange = (e) => {
		const { value } = e.target;

		setComponentState({ ...componentState, email: value });
		setFormError(null);

		if (value.indexOf(',') > -1) handlePushCurrentEmail(value);
	};

	const handlePushCurrentEmail = (value) => {
		let email = value.trim().replace(',', '');
		const isEmail = emailRegEx.test(email);

		if (!isEmail) {
			setFormError({ field: 'emailRecipients', message: 'Invalid email' });
			return setComponentState({ ...componentState, email });
		}

		setComponentState({ ...componentState, email: '', emails: [...componentState.emails, email] });
	};

	const handleDeleteEmail = (email) => () => {
		setComponentState({ ...componentState, emails: componentState.emails.filter((d) => d != email) });
	};

	const handleDateChange = (name, date, value) => {
		setComponentState({ ...componentState, [name]: date });
		setFormState({ ...formState, [name]: value });
	};

	const renderEmails = () => {
		let content = [];
		componentState.emails.map((d, i) => content.push(<Chip key={i} label={d} variant='outlined' color='primary' size='small' onDelete={!readOnly ? handleDeleteEmail(d) : null} />));
		return content;
	};

	const addEmailOnSubmit = (email) => {
		const isEmail = emailRegEx.test(email);

		if (!isEmail) {
			setFormError({ field: 'emailRecipients', message: 'Invalid email' });
			return false;
		}

		let emails = [...componentState.emails];
		emails.push(email);

		setComponentState({ ...componentState, email: '', emails });

		return emails.join();
	};

	const validateDeliveryDate = () => {
		const { deliveryFrequency } = formState,
			{ deliveryDate, deliveryTime } = componentState;

		if (!deliveryFrequency || deliveryFrequency == 'Daily' || deliveryFrequency == 'SendOnce') return true;

		if (!deliveryDate) {
			setFormError({ field: 'deliveryDate', message: `Please select a ${deliveryFrequency == 'Weekly' ? 'Day' : 'Month'}` });
			return false;
		}
		if (!deliveryTime) {
			setFormError({ field: 'deliveryDate', message: 'Please choose the Delivery Time' });
			return false;
		}

		return true;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const sendReport = allowSendReport && componentState.sendReport;
		let data = { ...formState, sendReport };

		// validate email (if exists)
		const email = componentState.email.trim();
		if (email != '') {
			const emailRecipients = addEmailOnSubmit(email);
			if (!emailRecipients) return;
			data.emailRecipients = emailRecipients;
		}

		// validate delivery date & delivery time
		if (!validateDeliveryDate()) return;

		setLoading(true);
		setFormError(null);

		if (formState.deliveryFrequency == 'SendOnce') return sendReportOnce(data);

		// Save Report
		const updateReport = await API.UpdateReport(account.tokenAuth, data);

		if (updateReport.status != 201) {
			setLoading(false);
			setFormError({ field: updateReport.field, message: updateReport.message });
			return;
		}

		setLoading(false);

		setSuccessMessage();

		setFormSuccess(true);
		setFormState({ ...initialFormState, columns: filterColumns });
		setComponentState(initialComponentState);

		if (formState.reportId) {
			// onClose();
			// onSetSelectedReport(null);
			onUpdateSelectedReport(updateReport.data);
		}

		setTimeout(() => onClose(), 2000);
	};

	const sendReportOnce = (data) => {
		API.SendReport(account.tokenAuth, data).then((response) => {
			setLoading(false);
			if (response.status != 201) return setFormError({ field: response.field, message: response.message });
			setFormSuccess(true);
			setTimeout(() => {
				onClose();
				if (!formState.reportId) {
					setFormState({ ...initialFormState, columns: filterColumns });
					setComponentState(initialComponentState);
				}
			}, 2000);
		});
	};

	const setSuccessMessage = () => {
		let dateText = '';

		if (formState.deliveryFrequency === 'Daily') dateText = 'Day';
		if (formState.deliveryFrequency === 'Weekly') dateText = 'Week';
		if (formState.deliveryFrequency === 'Monthly') dateText = 'Month';

		setFormSuccessMessage({ date: dateText, time: componentState.deliveryTime });
	};

	const handleClose = () => {
		onClose();
		// onSetSelectedReport(null);
		// setFormState(initialFormState);
		// setComponentState(initialComponentState);
	};

	const handleCollapse = () => setOpenCollapse(!openCollapse);

	const errors = errorField(formError);

	return (
		<Fragment>
			<Modal open={open} onClose={handleClose} autoHeight>
				<div className='report__modal'>
					<h3 className='section-title'>{title || 'Save Report'}</h3>
					<form onSubmit={handleSubmit}>
						<FormControl component='fieldset' fullWidth>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										label='Report Name'
										name='name'
										color='primary'
										variant='outlined'
										value={formState.name}
										error={errors.name}
										disabled={readOnly}
										onChange={handleFormStateChange}
										inputProps={{ maxLength: 120 }}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl variant='outlined' error={errors.deliveryFrequency} disabled={readOnly} fullWidth>
										<InputLabel id='frequency-label'>Delivery Frequency</InputLabel>
										<Select label='Delivery Frequency' labelId='frequency-label' id='deliveryFrequency' name='deliveryFrequency' value={formState.deliveryFrequency} onChange={handleFrequencyChange}>
											<MenuItem value='Daily'>Daily</MenuItem>
											<MenuItem value='Weekly'>Weekly</MenuItem>
											<MenuItem value='Monthly'>Monthly</MenuItem>
											<MenuItem value='SendOnce'>Send Once</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								{formState.deliveryFrequency && formState.deliveryFrequency != 'SendOnce' && (
									<Fragment>
										{formState.deliveryFrequency != 'Daily' && (
											<Grid item xs={12} sm={6}>
												<FormControl variant='outlined' error={errors.deliveryDate} disabled={readOnly} fullWidth>
													<InputLabel id='deliveryDate-label'>Delivery Date</InputLabel>
													{formState.deliveryFrequency == 'Weekly' && <WeeklySelect componentState={componentState} onChange={handleComponentStateChange} />}
													{formState.deliveryFrequency == 'Monthly' && <MonthlySelect componentState={componentState} onChange={handleComponentStateChange} />}
												</FormControl>
											</Grid>
										)}
										<Grid item xs={12} sm={6}>
											<FormControl variant='outlined' fullWidth>
												<TextField
													type='time'
													name='deliveryTime'
													label='Delivery Time'
													value={componentState.deliveryTime}
													error={errors.deliveryDate || errors.deliveryTime}
													disabled={readOnly}
													InputLabelProps={{ shrink: true }}
													inputProps={{ step: 300 }}
													onChange={handleComponentStateChange}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<div className='date-range__coallpse'>
												<div className='heading' onClick={handleCollapse}>
													<span>Date Range</span>
													{!openCollapse ? <ExpandMoreIcon /> : <ExpandLessIcon />}
												</div>
												<Collapse in={openCollapse}>
													<div className='content'>
														<Grid container spacing={3}>
															<Grid item xs={12} sm={6}>
																<MuiPickersUtilsProvider utils={MomentUtils}>
																	<KeyboardDatePicker
																		error={errors.startDate}
																		autoOk
																		fullWidth
																		disablePast
																		disableToolbar
																		mask='__/__/____'
																		variant='inline'
																		format='MM/DD/YYYY'
																		label='Start Date'
																		inputVariant='outlined'
																		InputAdornmentProps={{ position: 'end' }}
																		value={componentState.startDate}
																		disabled={readOnly}
																		onChange={(date, value) => handleDateChange('startDate', date, value)}
																	/>
																</MuiPickersUtilsProvider>
															</Grid>
															<Grid item xs={12} sm={6}>
																<MuiPickersUtilsProvider utils={MomentUtils}>
																	<KeyboardDatePicker
																		error={errors.endDate}
																		autoOk
																		fullWidth
																		disablePast
																		disableToolbar
																		mask='__/__/____'
																		variant='inline'
																		format='MM/DD/YYYY'
																		label='End Date'
																		inputVariant='outlined'
																		InputAdornmentProps={{ position: 'end' }}
																		value={componentState.endDate}
																		disabled={readOnly}
																		onChange={(date, value) => handleDateChange('endDate', date, value)}
																	/>
																</MuiPickersUtilsProvider>
															</Grid>
														</Grid>
													</div>
												</Collapse>
											</div>
										</Grid>
									</Fragment>
								)}
								<Grid item xs={12}>
									<TextField
										label='Recipient Email'
										error={errors.emailRecipients}
										color='primary'
										variant='outlined'
										value={componentState.email}
										disabled={readOnly}
										onChange={handleEmailsChange}
										InputProps={
											!readOnly
												? {
														endAdornment: (
															<IconButton color='primary' onClick={(_) => handlePushCurrentEmail(componentState.email)}>
																<AddIcon />
															</IconButton>
														),
												  }
												: null
										}
										fullWidth
									/>
									<div className='email-chips__container'>{renderEmails()}</div>
								</Grid>
							</Grid>
							{allowSendReport && formState.deliveryFrequency != 'SendOnce' && (
								<Grid item xs={12}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox color='primary' name='sendReport' checked={componentState.sendReport} />}
											label='I want to send the report when I save the changes'
											onChange={handleCheckboxChange}
										/>
									</FormGroup>
								</Grid>
							)}
							{formError && (
								<Grid item xs={12}>
									<Alert severity='error' onClose={() => setFormError(null)}>
										{formError.message}
									</Alert>
								</Grid>
							)}
							{formSuccess && (
								<Grid item xs={12}>
									<Alert severity='success' onClose={() => setFormSuccess(false)}>
										{formState.deliveryFrequency != 'SendOnce'
											? !formState.reportId
												? `This report will be send every ${formSuccessMessage.date} at ${formSuccessMessage.time}`
												: 'Report Updated'
											: 'Report Sent'}
										{}
									</Alert>
								</Grid>
							)}
						</FormControl>
						<div className='buttons-container'>
							{!loading ? (
								<Fragment>
									<Button type='button' size='large' startIcon={<CloseIcon />} className='close-btn' onClick={handleClose} disableRipple fullWidth>
										Close
									</Button>
									{!formSuccess &&
										(confirmButton || (
											<Button
												type='submit'
												size='large'
												color='primary'
												variant='contained'
												startIcon={formState.deliveryFrequency != 'SendOnce' ? <SaveIcon /> : <SendIcon />}
												disableRipple
												fullWidth>
												{formState.deliveryFrequency != 'SendOnce' ? 'Save' : 'Send'}
											</Button>
										))}
								</Fragment>
							) : (
								<Loader loading />
							)}
						</div>
					</form>
				</div>
			</Modal>
		</Fragment>
	);
};

ReportModal.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string,
	filterColumns: PropTypes.array,
	selectedReport: PropTypes.object,
	allowSendReport: PropTypes.bool,
	readOnly: PropTypes.bool,
	confirmButton: PropTypes.element,
	onSetSelectedReport: PropTypes.func,
	onUpdateSelectedReport: PropTypes.func,
	onClose: PropTypes.func.isRequired,
};

ReportModal.defaultProps = {
	allowSendReport: false,
	readOnly: false,
	onSetSelectedReport: () => {},
	onUpdateSelectedReport: () => {},
};

export default connect((s) => ({ account: s.account }))(ReportModal);
