import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import moment from 'moment-timezone';

import DateRangePickerComponent from '../../../component/DateRangePickerComponent';
import TextFieldAutoComplete from '../../AutoComplete';

const Filters = (props) => {
	const [status, setStatus] = useState(props.status);
	const [dateRange, setDateRange] = useState(null);
	const [successAssign, setSuccessAssign] = useState(props.assignationTable);

	const [valueInput, setValueInput] = useState(null);

	const onSearchRef = useRef(null);

	useEffect(() => {
		if (props.column.dataType) typeInput();

		if (props.column.dataType == 'date' && props.column.filterValue && props.column.filterValue.includes('$')) {
			if (props.column.filterValue == '$') return setDateRange(null);
			const rangeValues = props.column.filterValue.split('$');
			const startDate = moment(rangeValues[0], 'YYYY/MM/DD', true);
			const endDate = moment(rangeValues[1], 'YYYY/MM/DD', true);
			setDateRange([startDate, endDate]);
		} else {
			setDateRange(null);
		}
	}, []);

	useEffect(() => {
		if (valueInput != '') {
			const timeout = setTimeout(() => onSearchRef.current && props.onFilterChanged(props.columnDef.tableData.id, valueInput), 1000);
			return () => clearTimeout(timeout);
		}
	}, [valueInput]);

	const handleSelect = (e) => {
		let { value } = e.target;
		//console.log('value :>> ', value);

		if (!value) {
			props.onFilterChanged(props.columnDef.tableData.id, 'none');
		}
		setValueInput(value);
		onSearchRef.current = true;
	};

	const handleDateRangeChange = (dates) => {
		if (!dates) return handleResetDateRange();

		const formattedDateRange = `${dateFormat(dates[0])}$${dateFormat(dates[1])}`;
		setDateRange(dates);
		setValueInput(formattedDateRange);
		onSearchRef.current = true;
	};

	const handleResetDateRange = () => {
		setDateRange(null);
		props.onFilterChanged(props.columnDef.tableData.id, '$');
	};

	const dateFormat = (date) => moment(date).format('YYYY/MM/DD');

	const typeInput = () => {
		let label = props.column.label;

		switch (props.column.dataType) {
			case 'date':
				return (
					<div className='filterDate'>
						<DateRangePickerComponent value={dateRange} onChange={handleDateRangeChange} />
					</div>
				);
				break;
			case 'type':
				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel>{props.column.label}</InputLabel>
						<Select label={props.column.label} onChange={(e) => handleSelect(e)} defaultValue={props.column.filterValue !== null ? props.column.filterValue : 'none'}>
							<MenuItem value='none'>All</MenuItem>
							<MenuItem value='LI'>Low Income</MenuItem>
							<MenuItem value='DD'>Disabled/Displaced</MenuItem>
						</Select>
					</FormControl>
				);
				break;
			case 'processor':
				return (
					props.column.name === 'assignedProcessorName' && (
						<FormControl variant='outlined' size='small' fullWidth>
							<InputLabel>{props.column.label}</InputLabel>
							<Select label={props.column.label} onChange={(e) => handleSelect(e)} defaultValue={props.column.filterValue !== null ? props.column.filterValue : 'none'}>
								<MenuItem value='none'>All</MenuItem>
								<MenuItem value='unassigned'>Unassigned</MenuItem>
								{successAssign &&
									successAssign.map((r, i) => {
										return (
											<MenuItem key={i} value={r.processorName}>
												{r.processorName}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					)
				);
				break;
			case 'grade':
				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel>{props.column.label}</InputLabel>
						<Select label={props.column.label} onChange={(e) => handleSelect(e)} defaultValue={props.column.filterValue !== null ? props.column.filterValue : 'none'}>
							<MenuItem value='none'>All</MenuItem>
							<MenuItem value='PK'>PK</MenuItem>
							<MenuItem value='K'>K</MenuItem>
							<MenuItem value='1'>1</MenuItem>
							<MenuItem value='2'>2</MenuItem>
							<MenuItem value='3'>3</MenuItem>
							<MenuItem value='4'>4</MenuItem>
							<MenuItem value='5'>5</MenuItem>
							<MenuItem value='6'>6</MenuItem>
							<MenuItem value='7'>7</MenuItem>
							<MenuItem value='8'>8</MenuItem>
							<MenuItem value='9'>9</MenuItem>
							<MenuItem value='10'>10</MenuItem>
							<MenuItem value='11'>11</MenuItem>
							<MenuItem value='12'>12</MenuItem>
						</Select>
					</FormControl>
				);
				break;

			case 'status':
				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel className="filter-header-outlined-label-small">{props.column.label}</InputLabel>
						<Select label={props.column.label} onChange={(e) => handleSelect(e)} defaultValue={(props.column.filterValue || null) !== null ? props.column.filterValue : 'none'}>
							<MenuItem value='none'>All</MenuItem>
							{status &&
								status.map((r, i) => {
									return (
										<MenuItem key={r.statusLabel} value={r.statusLabel}>
											{r.statusLabel}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				);
				break;

			case 's-processing':
				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel className="filter-header-outlined-label-small">{props.column.label}</InputLabel>
						<Select label={props.column.label} onChange={(e) => handleSelect(e)} defaultValue={(props.column.filterValue || null) !== null ? props.column.filterValue : 'none'}>
							<MenuItem value='none'>All</MenuItem>
							<MenuItem value='On hold'>On hold</MenuItem>
							<MenuItem value='Awarded'>Awarded</MenuItem>
							<MenuItem value='Not Awarded'>Not Awarded</MenuItem>
						</Select>
					</FormControl>
				);
				break;

			case 'bool':
				if (props.column.name == 'spotlighted') label = 'Spotlight';
				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel>{label}</InputLabel>
						<Select label={label} onChange={(e) => handleSelect(e)} defaultValue={props.column.filterValue !== null ? props.column.filterValue : 'none'}>
							<MenuItem value='none'>All</MenuItem>
							<MenuItem value='true'>Yes</MenuItem>
							<MenuItem value='false'>No</MenuItem>
						</Select>
					</FormControl>
				);
				break;
			case 'none':
				return <></>;
				break;
			default:
				if (props.column.name == 'houseHoldSize') label = 'Size';
				if (props.column.name == 'school')
					return (
						<TextFieldAutoComplete
							label='School'
							freeSolo
							noOptionsText='School not found. Please contact AZLF.'
							name='school'
							size='small'
							defaultValue={props.column.filterValue}
							onLoad={props.renderSchools}
							onChange={handleSelect}
							inputProps={{ maxLength: 255 }}
						/>
					);

				return (
					<FormControl variant='outlined' size='small' fullWidth>
						<InputLabel htmlFor='outlined-adornment-password'>{label}</InputLabel>
						<OutlinedInput ref={props.filterInput} value={valueInput} defaultValue={props.column.filterValue} label={label} type='text' onChange={(e) => handleSelect(e)} />
					</FormControl>
				);
		}
	};

	return <Fragment>{typeInput()}</Fragment>;
};

Filters.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
};

Filters.defaultProps = {
	type: 'text',
	titleText: 'Search',
	titleSelect: 'Select one',
	titleDate: 'Select Date',
};

export default Filters;
