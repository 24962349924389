// Material UI
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
// import
import { connect } from 'react-redux';
// Components
import Layout from '../../component/partials/Layout';
import Loader from '../../component/Loader';
import Api from '../../config/api';
import UserInformation from './UserInformation';
import DDView from './DDView';
import LIView from './LIView';

const AlertMessage = (props) => {
	const htmlLabel = (label) => {
		return { __html: label };
	};

	return (
		<div style={{ marginBottom: 40, marginTop: 40 }}>
			<Alert severity='warning'>
				<AlertTitle>Reasons: </AlertTitle>
				<Fragment>
					<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
						{props.application.status === 6 &&
							props.application.rejectedReasons != null &&
							props.application.rejectedReasons.length > 0 &&
							props.application.rejectedReasons.map((x, i) => {
								return (
									<li key={i} style={{ paddingBottom: 10 }}>
										<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
									</li>
								);
							})}
						{props.application.status === 4 &&
							props.application.incompletedReasons != null &&
							props.application.incompletedReasons.length > 0 &&
							props.application.incompletedReasons.map((x, i) => {
								return (
									<li key={i} style={{ paddingBottom: 10 }}>
										<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
									</li>
								);
							})}
					</ul>
				</Fragment>
				{props.application.status === 6 && props.application.rejectedNotes != null && (
					<Fragment>
						<AlertTitle>Note:</AlertTitle>
						<span>{props.application.rejectedNotes}</span>
					</Fragment>
				)}
				{props.application.status === 4 && props.application.incompleteNotes != null && (
					<Fragment>
						<AlertTitle>Note:</AlertTitle>
						<span>{props.application.incompleteNotes}</span>
					</Fragment>
				)}
			</Alert>
		</div>
	);
};

const ApplicationsHistory = (props) => {
	const [application, setApplication] = useState(null);
	const [applicationAccount, setApplicationAccount] = useState(null);
	const [titleApplication, setTitleApplication] = useState('');
	const [reasonIncomplete, setReasonIncomplete] = useState([]);
	const [reasonRejected, setReasonRejected] = useState([]);
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState([]);
	const [historyIndex, setHistoryIndex] = useState([]);
	const [selectHistory, setSelectHistory] = useState('');
	const [content, setContent] = useState([]);
	const [studentName, setStudentName] = useState('Application Student');
	const [action, setAction] = useState('');
	const [msg, setMsg] = useState('');

	useEffect(() => {
		if (props.match.params.id) {
			getStatus();
			getApplicationById();
			getReasonIncomplete();
			getReasonRejected();
			getHistory(props.match.params.id);
		} else {
			props.history.push('/');
		}
	}, []);

	useEffect(() => {		
		showApplicationType();		
	}, [application]);

	const getHistory = async (applicationId) => {
		setLoading(true);
		await Api.GetHistoryIndex(props.account.tokenAuth, { applicationId })
			.then((res) => {
				setHistoryIndex(res.data);
				if (res.data.length > 0) {
					setSelectHistory(res.data[0].historyId);
					getInfoHistory(res.data[0].historyId);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err), setLoading(false));
	};

	const handleSelect = (e) => {
		const { value } = e.target;
		setSelectHistory(value);
		getInfoHistory(value);
	};

	const getInfoHistory = async (idHistory) => {
		setLoading(true);
		await Api.GetHistory(props.account.tokenAuth, { id: idHistory })
			.then((res) => {
				setLoading(false);
				if (res.data.dataLog.students != null && res.data.dataLog.students.length > 0) setStudentName(res.data.dataLog.students[0].firstName + ' ' + res.data.dataLog.students[0].lastName);

				let app = res.data.dataLog;

				/* patch new values */
				app.period = app.period||'2020/21';
				app.previousPeriod = app.previousPeriod||'2019/20';
				
				app.students = app.students.map(s=>{
					s.criteriashortdescription = s.criteriashortdescription||'';
					return s;
				});		

				setApplication(app);
				getTypeAction(res.data.triggeredBy, res.data);
			})
			.catch((err) => console.log(err), setLoading(false));
	};

	const getApplicationById = () => {
		const { id } = props.match.params || null;

		Api.GetApplicationById(props.account.tokenAuth, { id }).then((response) => {
			const { status, message, data } = response;
			setApplicationAccount(data.account);
			getTitleApplication(data.applicationType);
		});
	};

	const getTitleApplication = (type) => {
		switch (type.toUpperCase()) {
			case 'DD':
				setTitleApplication('Disabled/Displaced Application');
				break;
			case 'LI':
				setTitleApplication('Low Income Application');
				break;

			default:
				break;
		}
	};

	const getStatus = async () => {
		const { adminType } = props.match.params || null;
		let role = '';
		if (adminType) {
			if (adminType === 'admin') role = 3;
			if (adminType === 'followup') role = 5;
		}
		const response = await Api.GetStatusByRole(props.account.tokenAuth, { role: role });
		if (response.data) setStatus(response.data);
	};

	const getReasonIncomplete = () => {
		Api.IncompletedReasons(props.account.tokenAuth)
			.then((res) => {
				setReasonIncomplete(res.data);
			})
			.catch((err) => console.log(err));
	};

	const getReasonRejected = () => {
		Api.RejectedReasons(props.account.tokenAuth)
			.then((res) => {
				setReasonRejected(res.data);
			})
			.catch((err) => console.log(err));
	};

	const showApplicationType = () => {
		const { id } = props.match.params || null;
		const { type } = props.match.params || '';
		
		switch (type.toUpperCase()) {
			case 'DD':
				application != null && setContent(<DDView history={props.history} account={props.account} id={id} applicationType='DD' application={application} studentName={studentName} />);
				break;
			case 'LI':
				application != null && setContent(<LIView account={props.account} id={id} applicationType='LI' history={props.history} application={application} />);
				break;

			default:
				break;
		}
	};

	const getTypeAction = (value, action) => {
		const typeAction = [
			{
				name: 'lowIncomeStudents',
				title: 'Student',
			},
			{
				name: 'applications',
				title: 'Application',
			},
			{
				name: 'prerequisites',
				title: 'Pre-requisite',
			},
			{
				name: 'disabledStudent',
				title: 'Student',
			},
			{
				name: 'financialMembers',
				title: 'Member',
			},
		];
		const index = typeAction.findIndex((x) => x.name === value);

		if (index) {
			setMsg(`The ${typeAction[index].title} was ${action.action === 'UPDATE' ? 'UPDATED' : ''} ${action.action === 'INSERT' ? 'ADDED' : ''} ${action.action === 'DELETE' ? 'DELETE' : ''}`);
		} else {
			setMsg(`The Application was ${action.action === 'UPDATE' ? 'UPDATED' : ''} ${action.action === 'INSERT' ? 'ADDED' : ''} ${action.action === 'DELETE' ? 'DELETE' : ''}`);
		}
	};

	return (
		<Layout showMenu showBanner showFooter showButton={false} history={props.history} title='Application History'>
			<div className='application-page application-form-page application-view-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<div className='user__column'>{applicationAccount != null && <UserInformation history={props.history} parent={applicationAccount} />}</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<Grid xs={12} item>
							<Grid container spacing={3} direction='row' justify='space-between' alignItems='center' className='site-admin-container'>
								<Grid xs={12} sm={10} md={9} item>
									<div className='applications__column'>
										<div className='unsubmitted-applications application__section'>{application != null && <h3 className='section-title'>{titleApplication}</h3>}</div>
									</div>
								</Grid>
								<Grid xs={12} sm={2} md={3} item>
									<FormControl variant='outlined' size='small' fullWidth>
										<InputLabel id='label-history'>{historyIndex.length > 0 ? 'History' : 'There are no records'}</InputLabel>
										<Select
											labelId='label-history'
											id='label-history-select'
											label={historyIndex.length > 0 ? 'History' : 'There are no records'}
											value={selectHistory}
											onChange={(e) => handleSelect(e)}>
											{historyIndex.length > 0 &&
												historyIndex.map((x, i) => {
													return (
														<MenuItem key={i} value={x.historyId}>
															{moment.utc(x.date).tz("America/Phoenix").format('YYYY/MM/DD HH:mm:ss')}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						{/* header status */}
						{application != null && (
							<div className='form__section' style={{ marginBottom: (application != null && application.status === 6) || (application != null && application.status === 4) ? null : 40 }}>
								<div className='status-section'>
									<div className='status-section-info'>
										<div>
											<p>Application Status:</p>
											<span>{application.statuslabel}</span>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* Log */}
						{application != null && (
							<div style={{ marginBottom: 40, marginTop: 40 }}>
								<Alert variant='filled' severity='info'>
									{msg != '' && msg}
								</Alert>
							</div>
						)}
						{/* Alert status */}
						{(application != null && application.status === 6) || (application != null && application.status === 4) ? <AlertMessage application={application} /> : null}

						{!loading && application != null ? (
								<div className='form__column'>
									<div className='application-form__content'>{application != null && content}</div>
								</div>
							) : null 
						}
						{(!loading && application == null) && (
							<p>No information to display was found</p>
						)}

						{loading  && <Loader loading />}						
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

export default connect(mapStateToProps)(ApplicationsHistory);
