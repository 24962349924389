import * as types from '../constants';
import API from '../../config/api';

const loadedIncomeTable = (incomes) => ({
	type: types.LOADED_INCOME_TABLE,
	loading: false,
	incomes,
});

const loadingIncomeTable = (incomes) => ({
	type: types.LOADING_INCOME_TABLE,
	loading: true	
});


export const getIncomes = (token, data) => dispatch => {	
	dispatch(loadingIncomeTable());
	return API.GetIncomeTable(token, data).then((res) => {
		if (res.status !== 201) return;
		dispatch(loadedIncomeTable(res.data));
	});
};
