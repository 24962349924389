import React,{useState, useEffect} from 'react'
import { connect } from 'react-redux';

import Alert from '../../component/partials/Alerts/Alert';
import API from '../../config/api';

import JoditEditor from 'jodit-react';

import {tplActionNeeded, tplNotApproved} from './tpl';

const ACTION_NEEDED = 7;
const NOT_APPROVED = 6;

function ApplicationTraceEmailItem({myRef, readOnly = false, emailData, autoSave = true, status, newStatus, onUpdate=()=>{},height, account, mode}) {
	const editorJodit = React.useRef(null);
	const jodItButtonWhenSent = [
			{
				name: 'Forward',	
				tooltip: 'Forward current email',
				exec: async (editor) => {	
					myRef.current.handleForward();
				}
			},'|',
			{
				name: 'New',	
				tooltip: 'Create a new email with the base template',
				exec: async (editor) => {	
					myRef.current.handleCreateNew();
				}
			},'|',	
	];

	const jodItButtonWhenNewActionNeeded = [
			{
				name: 'Send',	
				tooltip: 'Send current email',
				exec: async (editor) => {	
					myRef.current.handleSendEmailAlert();
				}
			},'|',
			{
				name: 'New',	
				tooltip: 'Create a new email with the base template',				
				exec: async (editor) => {	
					myRef.current.handleCreateNew();
				}
			},'|',	
	];

	const jodItButtonSend = [
			{
				name: 'Send',	
				tooltip: 'Send current email',
				exec: async (editor) => {	
					myRef.current.handleSendEmailAlert();
				}
			},'|',
	];

	const jodItPreviewEditorConfig = {
		readonly: true,
		toolbarAdaptive: false,
		buttons: [
			{
				id: 'custom-preview',
				name: 'Go Edit',
				tooltip: 'Show email editor',
				exec: (editor) => {
					myRef.current.handleEditorMode();
				}
			},
		],
		activeButtonsInReadOnly: ['Send','Go Edit'],
		height: height||'auto'
	};

	const jodItEditorConfig = {
		readonly: false,
		toolbar: true,
		toolbarAdaptive: false,
		buttons: [
			{
				name: 'Preview',			
				tooltip: 'Preview email with current application data',
				exec: async (editor) => {
					myRef.current.handlePreviewMode();
				}
			},		
			'|','bold','underline','italic','ul','ol','fontsize','link','align','undo','cut','copy','paste','|','hr','|',
			{
				name: 'Reload Template',	
				tooltip: 'Reload base template',
				exec: async (editor) => {	
					myRef.current.handleSetBaseTemplate();
				}
			},'|',
			{
				name: 'Income Tag',	

				tooltip: 'Insert income table tag',
				exec: async (editor) => {	
					editor.selection.insertHTML('«INCOME_TABLE»');
				}
			}
		],				
		activeButtonsInReadOnly: ['Forward','New'],
		height: height||'auto'
	}

	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [emailPreviewTemplate, setEmailPreviewTemplate] = useState('');
	const [previewMode, setPreviewMode] = useState(false);
	const [jodItConfig,setJodItConfig] = useState(jodItEditorConfig);
	const [jodItPreviewConfig,setJodItPreviewConfig] = useState(jodItPreviewEditorConfig);	
	const [openAlert, setOpenAlert] = useState(false);
	const [sending, setSending] = useState(false);

	const [emailItem, setEmailItem] = useState(emailData);

	useEffect(() => {		
		setEmailPreviewTemplate('');
		setPreviewMode(false);	
		setEmailItem(emailData);
		setValue(emailData.body);
		
		if(!!emailData.sentAt === true){
			setJodItConfig({
				...jodItEditorConfig, 
				readonly: true,
				buttons: [...jodItButtonWhenSent, ...jodItEditorConfig.buttons],				
			});				
		}else{			
			if(status === ACTION_NEEDED || status === NOT_APPROVED){
				setJodItConfig({
					...jodItEditorConfig, 
					buttons: [...jodItButtonWhenNewActionNeeded, ...jodItEditorConfig.buttons],					
				});			

				setJodItPreviewConfig({
					...jodItPreviewConfig,
					buttons:[...jodItButtonSend,...jodItPreviewEditorConfig.buttons],
				});
			}else{
				setJodItConfig(jodItEditorConfig);
				setJodItPreviewConfig(jodItPreviewConfig);
			}			
		}	

		if(mode && mode==='preview'){
			setPreviewMode(true);
			loadPreviewStatusEmail(emailItem.applicationId, emailData.body);						
		}		
		
		if(readOnly == true){
			setJodItConfig({
					...jodItEditorConfig,
					toolbar: false,
				});			
		}

	}, [emailData, status]);

	const toggleAlert = () => {
		 setOpenAlert(!openAlert);
	};

	const loadPreviewStatusEmail = async (applicationId,emailBody) => {
		setLoading(true);
		const reqData = {applicationId: applicationId, emailBody: emailBody};
		return API.PreviewEmail(account.tokenAuth,reqData).then((res) => {
			if (res.status !== 201) {
				setLoading(false);
				return;
			}
						
			setEmailPreviewTemplate(res.data);
			setLoading(false);
		});
	};

	const updateStatusEmail = async (applicationId, emailId,emailBody, sendEmail = false) => {
		setUpdating(true);
		const reqData = {applicationId: applicationId, emailId: emailId, emailBody: emailBody, sendEmail: sendEmail, status: newStatus};
		return API.UpdateStatusEmailList(account.tokenAuth,reqData).then((res) => {		
			if (res.status !== 201) {
				setUpdating(false);
				return;
			}
						
			if(emailId === 0){
				if(res.data.length>0){
					onUpdate({...res.data[0]},res.data);
				}
			}else{
				const updatedEmailItem = res.data.filter(f=>f.emailId === emailId);
				if(updatedEmailItem.length > 0){
					onUpdate({...updatedEmailItem[0]},res.data);
				}else{
					onUpdate({...emailData},res.data);	
				}
			}
	
			setUpdating(false);	
		});
	};

	const handleBlurEditor = async () => {
		const editorContent = editorJodit.current.value;
		if(value != editorContent){			
			setValue(editorContent);
			if(autoSave) await updateStatusEmail(emailItem.applicationId, emailItem.emailId,editorContent);
		}		  
	};

	const handleEditorChange = () => {
		/*const editorContent = editorJodit.current.value;
  		if(value != editorContent){
			setValue(editorContent);
		}*/
	};
	
	const handlePreviewMode = async () => {		
		const editorContent = editorJodit.current.value;
		//console.log(editorContent);
		//if(value != editorContent){
			//setValue(editorContent);
			if(!!emailItem.sentAt == false) await handleBlurEditor();
		//}
		await loadPreviewStatusEmail(emailItem.applicationId, editorContent);
		setPreviewMode(true);
	};

	const handleEditorMode = () => {
		/*console.log(editorJodit.current.value);
		setValue(editorJodit.current.value);				
		*/
		setEmailPreviewTemplate('');
		setPreviewMode(false);
	};

	const getStatusTemplate = () => {
		return newStatus === ACTION_NEEDED?tplActionNeeded:tplNotApproved;
	};

	const handleSetBaseTemplate = async () => {
		const __statusTemplate = getStatusTemplate();
		setValue(__statusTemplate);
		await updateStatusEmail(emailItem.applicationId, emailItem.emailId,__statusTemplate);
	};

	const handleCreateNew = async () => {
		setJodItConfig({
			...jodItConfig, 
			readonly: true,
		});		

		if(!!emailItem.sentAt == false) await handleBlurEditor();

		const __statusTemplate = getStatusTemplate();
		setValue(__statusTemplate);
		await updateStatusEmail(emailItem.applicationId, 0,__statusTemplate);

	};

	const handleForward = async () => {
		setValue(editorJodit.current.value);
		await updateStatusEmail(emailItem.applicationId, 0,editorJodit.current.value);
	};
	
	const handleSendEmailAlert = async () => {		
		setJodItConfig({
			...jodItConfig, 
			readonly: true,
		});	

		if(!!emailItem.sentAt == false) await handleBlurEditor();

		toggleAlert();

		setJodItConfig({
			...jodItConfig, 
			readonly: false,
		});	
	};

	const handleSendEmail = async () => {
		setSending(true);						
		setJodItConfig({
			...jodItConfig, 
			readonly: true,
		});		

		toggleAlert();		
		await updateStatusEmail(emailItem.applicationId, emailItem.emailId,editorJodit.current.value,true);		
		
	};

	const handleSaveAndSendEmail = async () => {
		setSending(true);						
		setJodItConfig({
			...jodItConfig, 
			readonly: true,
		});		
		await updateStatusEmail(emailItem.applicationId, emailItem.emailId,editorJodit.current.value,true);		
		
	};

	if(myRef) {
		myRef.current.handlePreviewMode = handlePreviewMode;
		myRef.current.handleEditorMode = handleEditorMode;
		myRef.current.handleSendEmail = handleSaveAndSendEmail;
		myRef.current.handleForward = handleForward;
		myRef.current.handleCreateNew = handleCreateNew;
		myRef.current.handleSendEmailAlert = handleSendEmailAlert;
		myRef.current.handleSetBaseTemplate = handleSetBaseTemplate;
	}

	return (
		<>
			{previewMode?(
				<JoditEditor
					id="preview"
					config={{...jodItPreviewConfig}}
					value={emailPreviewTemplate}
					tabIndex={1}
				/>	
				 ):( 
				<JoditEditor
					id="editor"			
					ref={editorJodit}
					config={{...jodItConfig}}
					value={value}
					tabIndex={1}			
					onBlur={editorContent => handleBlurEditor(editorContent)}
					onChange={editorContent => handleEditorChange(editorContent)}
				/>
			 )} 
			<Alert open={openAlert} title='Send Email' text='Are you sure you want to send this email?, confirm to send it' confirmButtonText='Send' onConfirm={handleSendEmail} onCancel={toggleAlert} />
		</>
	);
}

const mapStateToProps = (s) => {
	return {
		account: s.account
	};
};

export default connect(mapStateToProps)(ApplicationTraceEmailItem);
