/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect, Fragment } from 'react';

import { Grid, Container, Box } from '@material-ui/core';

import { Link, useLocation } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { setAccount } from '../../../redux/actions/account';
import { setFilters } from '../../../redux/actions/filters';

const index = (props) => {
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [isExist, setIsExist] = useState(false);
	const [getToken, setToken] = useState(null);
	const anchorRef = useRef(null);
	const [expand, setExpand] = useState(false);

	const {roles} = props.account || {};
	const logout = () => {
		if (localStorage.getItem('tokenAuth')) {
			localStorage.removeItem('tokenAuth');
			props.dispatch(setAccount({}));
			props.dispatch(setFilters({}, 3));
			props.dispatch(setFilters({}, 4));
			props.dispatch(setFilters({}, 5));
			props.dispatch(setFilters({}, 6));
			props.history.push('/login');
		}
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);

	useEffect(() => {
		if (localStorage.getItem('tokenAuth')) setToken(localStorage.getItem('tokenAuth'));
		if (props.account) setIsExist(isEmpty(props.account));
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const navigationPage = (url) => {
		props.history.push(url);
		setExpand(false);
	};

	return (
		<Grid container className='menu' alignItems='center'>
			<Grid item xs={12}>
				{!isEmpty(getToken) && (
					<Fragment>
						<Hidden only='xs'>
							<Box className='menu-top'>
								{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 3) !== -1 && (
									<Box
										className={`text_menu_top ${location.pathname.toLowerCase().includes('/admin') && !location.pathname.toLowerCase().includes('/admin-processor') ? 'active' : 'inactive'}`}
										onClick={() => props.history.push('/admin/applications')}>
										SITE PROCESSOR
									</Box>
								)}
								{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 4) !== -1 && (
									<Box
										className={`text_menu_top ${location.pathname.toLowerCase().includes('/admin-processor') ? 'active' : 'inactive'}`}
										onClick={() => props.history.push('/admin-processor/applications')}>
										ADMIN PROCESSOR
									</Box>
								)}
								{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 5) !== -1 && (
									<Box className={`text_menu_top ${location.pathname.toLowerCase().includes('/followup') ? 'active' : 'inactive'}`} onClick={() => props.history.push('/followup/applications')}>
										FOLLOW UP PROCESSOR
									</Box>
								)}
								{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 6) !== -1 && (
									<Box className={`text_menu_top ${location.pathname.toLowerCase().includes('/general') ? 'active' : 'inactive'}`} onClick={() => props.history.push('/general/applications')}>
										GENERAL PROCESSOR
									</Box>
								)}
								{/* <Box className='text_menu_top'>SERVICE PROCESSOR</Box> */}
							</Box>
						</Hidden>
						<Hidden only={['sm', 'md', 'lg', 'xl']}>
							<ExpansionPanel onChange={() => setExpand(!expand)} expanded={expand}>
								<ExpansionPanelSummary expandIcon={<MenuIcon />} aria-controls='panel1a-content' id='panel1a-header'></ExpansionPanelSummary>
								<ExpansionPanelDetails className='menu-top'>
									{/* <Box className='text_menu_top' onClick={() => props.history.push('/')}>SITE ADMIN</Box> */}
									{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 3) !== -1 && (
										<Box
											className={`text_menu_top ${location.pathname.toLowerCase().includes('/admin') && !location.pathname.toLowerCase().includes('/admin-processor') ? 'active' : 'inactive'}`}
											onClick={() => navigationPage('/admin/applications')}>
											SITE PROCESSOR
										</Box>
									)}
									{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 4) !== -1 && (
										<Box
											className={`text_menu_top ${location.pathname.toLowerCase().includes('/admin-processor') ? 'active' : 'inactive'}`}
											onClick={() => navigationPage('/admin-processor/applications')}>
											ADMIN PROCESSOR
										</Box>
									)}
									{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 5) !== -1 && (
										<Box className={`text_menu_top ${location.pathname.toLowerCase().includes('/followup') ? 'active' : 'inactive'}`} onClick={() => navigationPage('/followup/applications')}>
											FOLLOW UP PROCESSOR
										</Box>
									)}
									{roles && roles.length > 1 && roles.findIndex((r) => r.roleId === 6) !== -1 && (
										<Box className={`text_menu_top ${location.pathname.toLowerCase().includes('/general') ? 'active' : 'inactive'}`} onClick={() => navigationPage('/general/applications')}>
											GENERAL PROCESSOR
										</Box>
									)}
									{/* <Box className='text_menu_top'>SERVICE PROCESSOR</Box> */}
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Hidden>
					</Fragment>
				)}
			</Grid>

			<Grid item xs={12}>
				<Box display='flex' justifyContent='center' alignItems='center'>
					<Container className='main-container'>
						<Grid container>
							<Grid item xs={12} sm={12} md={4}>
								<Box className='logo' display='flex' flexDirection='row' justifyContent='left'>
									<Link to='/'>
										<img width={224} height={59} alt='' src={'/assets/img/logo.jpg'} />
									</Link>
								</Box>
							</Grid>
							{/* primary menu */}
							<Grid item xs={12} sm={12} md={8}>
								<Box className='auth_menu'>
									<Box className='text_menu_register' display='flex' flexDirection='row' justifyContent='center' alignContent='center' alignItems='center' alignSelf='center'>
										{!isEmpty(getToken) && (
											<Box>
												<PersonIcon fontSize='small' style={{ color: !isEmpty(getToken) ? '#A0201C' : 'null' }} />
											</Box>
										)}
										{/* {isEmpty(getToken) && <Box><a href='/register'>REGISTER</a></Box>} */}
										{!isEmpty(getToken) && (
											<Box style={{ color: !isEmpty(getToken) ? '#111111' : 'null' }}>
												{props.account.firstName} {props.account.lastName}
											</Box>
										)}
									</Box>
									{/* {isEmpty(getToken) && <Box className='text_menu'><a href='/login'>LOGIN</a></Box>} */}
									{!isEmpty(getToken) && (
										<Fragment>
											{/* {!isEmpty(getToken) && <Box className='text_menu'> | </Box>}
                            <Box className='text_menu' onClick={() => props.history.push('/user/profile')} style={{ cursor: 'pointer' }}>EDIT PROFILE</Box> */}
											{!isEmpty(getToken) && <Box className='text_menu'> | </Box>}
											{!isEmpty(getToken) && (
												<Box className='text_menu' style={{ cursor: 'pointer' }} onClick={logout}>
													LOG OUT
												</Box>
											)}
										</Fragment>
									)}
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Grid>
		</Grid>
	);
};

export default connect((s) => ({ account: s.account }))(index);
