import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, TextField, Typography, IconButton, OutlinedInput, Select, InputLabel, InputAdornment, FormControl, FormHelperText, Hidden } from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty, isEqual } from 'lodash';
import Api from '../../config/api';

import Layout from '../../component/partials/Layout';
import MessageBox from '../../component/partials/Alerts/MessageBox';
import Tooltip from '../../component/TooltipCompleteAccount';
import Loader from '../../component/Loader';
import { REGEX } from '../../utils/constants';

import PropTypes from 'prop-types';

const PassSecurity = (props) => {
	const [selectQuestion1Error, setSelectQuestion1Error] = useState(false);
	const [selectQuestion1Message, setSelectQuestion1Message] = useState('');
	const [selectQuestion2Error, setSelectQuestion2Error] = useState(false);
	const [selectQuestion2Message, setSelectQuestion2Message] = useState('');
	const [selectQuestion3Error, setSelectQuestion3Error] = useState(false);
	const [selectQuestion3Message, setSelectQuestion3Message] = useState('');

	const [selectQuestionAnswer1Error, setSelectQuestionAnswer1Error] = useState(false);
	const [selectQuestionAnswer1Message, setSelectQuestionAnswer1Message] = useState('');
	const [selectQuestionAnswer2Error, setSelectQuestionAnswer2Error] = useState(false);
	const [selectQuestionAnswer2Message, setSelectQuestionAnswer2Message] = useState('');
	const [selectQuestionAnswer3Error, setSelectQuestionAnswer3Error] = useState(false);
	const [selectQuestionAnswer3Message, setSelectQuestionAnswer3Message] = useState('');

	const [passwordError, setPasswordError] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [disabledButton, setDisabledButton] = useState(false);
	const [nameButton, setNameButton] = useState('SUBMIT');
	const [questions, setQuestions] = useState([]);
	const [questionsOne, setQuestionsOne] = useState([]);
	const [questionsTwo, setQuestionsTwo] = useState([]);
	const [questionsThree, setQuestionsThree] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [form, setForm] = useState({
		password: '',
		repassword: '',
		selectQuestion1: 1,
		selectQuestion2: 2,
		selectQuestion3: 3,
		answerQuestion1: '',
		answerQuestion2: '',
		answerQuestion3: '',
	});

	const [values, setValues] = React.useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	});

	useEffect(() => {
		getQuestion();
		CheckExpirationDate();
	}, []);

	useEffect(() => {
		questionSelected(questions);
	}, [form.selectQuestion1, form.selectQuestion2, form.selectQuestion3]);

	const questionSelected = (questionsList) => {
		let q1 = [];
		let q2 = [];
		let q3 = [];
		questionsList.map((x, i) => {
			if (x.questionId != form.selectQuestion2 && x.questionId != form.selectQuestion3) q1.push(x);
		});
		setQuestionsOne(q1);
		questionsList.map((x, i) => {
			if (x.questionId != form.selectQuestion1 && x.questionId != form.selectQuestion3) q2.push(x);
		});
		setQuestionsTwo(q2);
		questionsList.map((x, i) => {
			if (x.questionId != form.selectQuestion1 && x.questionId != form.selectQuestion2) q3.push(x);
		});
		setQuestionsThree(q3);
	};

	const getQuestion = async () => {
		if (props.match.params.code) {
			const getQuestions = await Api.GetQuestion()
				.then((res) => res)
				.catch((err) => console.log(err));
			setQuestions(getQuestions.data);
			questionSelected(getQuestions.data);
		}
	};

	const CheckExpirationDate = async () => {
		if (props.match.params.code) {
			const account = await Api.CheckExpirationDate(props.match.params.code)
				.then((res) => res)
				.catch((err) => console.log(err));
			if (!account.data) {
				setShowMessage(true);
			}
		}
	};

	const register = () => {
		setLoading(true);

		if (form.password.length > 32) {
			setPasswordError(true);
			setPasswordMessage('Password must be 8 - 32 characters long');
			setLoading(false);
			return;
		}

		if (isEmpty(form.password)) {
			setPasswordError(true);
			setPasswordMessage('Password is required');
			setLoading(false);
			return;
		}

		if (!isEqual(form.password, form.repassword)) {
			setPasswordError(true);
			setPasswordMessage('The passwords are different');
			setLoading(false);
			return;
		}
		if (!REGEX.passwordRegEx.test(form.password)) {
			setPasswordError(true);
			setPasswordMessage('Password criteria has not been met. Hover over question mark for requirements.');
			setLoading(false);
			return;
		}

		if (isEmpty(form.answerQuestion1)) {
			setSelectQuestionAnswer1Error(true);
			setSelectQuestionAnswer1Message('Security question answer 1 is required');
			setLoading(false);
			return;
		}

		if (isEqual(form.selectQuestion1, form.selectQuestion2)) {
			setSelectQuestion1Error(true);
			setSelectQuestion2Error(true);
			setSelectQuestion1Message('It should not be the same');
			setSelectQuestion2Message('It should not be the same');
			setLoading(false);
			return;
		}

		if (isEmpty(form.answerQuestion2)) {
			setSelectQuestionAnswer2Error(true);
			setSelectQuestionAnswer2Message('Security question answer 2 is required');
			setLoading(false);
			return;
		}

		if (isEqual(form.selectQuestion2, form.selectQuestion3)) {
			setSelectQuestion2Error(true);
			setSelectQuestion3Error(true);
			setSelectQuestion2Message('It should not be the same');
			setSelectQuestion3Message('It should not be the same');
			setLoading(false);
			return;
		}

		if (isEmpty(form.answerQuestion3)) {
			setSelectQuestionAnswer3Error(true);
			setSelectQuestionAnswer3Message('Security question answer 3 is required');
			setLoading(false);
			return;
		}

		if (isEqual(form.selectQuestion1, form.selectQuestion3)) {
			setSelectQuestion1Error(true);
			setSelectQuestion3Error(true);
			setSelectQuestion1Message('It should not be the same');
			setSelectQuestion3Message('It should not be the same');
			setLoading(false);
			return;
		}

		let code = null;
		if (props.match.params.code) code = props.match.params.code;
		const body = { code: code, ...form };

		Api.CompleteAccount(body)
			.then((res) => {
				const { status, message } = res;
				if (status !== 201) {
					setLoading(false);
					return;
				}
				setTimeout(() => {
					props.history.push('/login');
				}, 500);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};

	const onChangeForm = (e) => {
		const { name, value } = e.target || e;
		setPasswordError(false);
		setSelectQuestion1Error(false);
		setSelectQuestion2Error(false);
		setSelectQuestion3Error(false);
		setSelectQuestionAnswer1Error(false);
		setSelectQuestionAnswer2Error(false);
		setSelectQuestionAnswer3Error(false);
		setErrorMessage(null);
		setLoading(false);		
		if(name === 'password' || name === 'repassword') {			
			setForm({ ...form, [name]: value.replace(/\s/g, '') });			
		}else{
			setForm({ ...form, [name]: value });	
		}		
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<Layout title='Register' showBackground={false}>
			<Grid item xs={12}>
				<Box className='pass_security_container'>
					<Box className='pass_security_container_box'>
						{!showMessage ? (
							<Fragment>
								<Typography className='pass_security_container_box_title'>Password and Security</Typography>
								<Hidden only={['md', 'lg', 'xl']}>
									<div
										style={{
											backgroundColor: '#ffffff',
											color: 'black',
											maxWidth: 320,
											fontSize: 12,
											border: '1px solid #9bb43b',
											padding: 10,
										}}>
										<Typography variant='body1'>Criteria:</Typography>
										<br />
										<Typography variant='caption'>
											Must be between 8 - 32 characters long <br />
											Alphabet uppercase letter (A-Z) include at least 1<br /> Alphabet lowercase letter (a-z) include at least 1<br /> Decimal digit number (0-9) include at least 1 <br />
											Special characters include at least 1{' '}
										</Typography>
									</div>
								</Hidden>
								<span className='pass_security_span'></span>
								<span className='pass_security_span'></span>
								<form className='pass_security_form' noValidate autoComplete='off'>
									<span style={{ paddingTop: 10 }}></span>
									<div className='pass_security--help'>
										<FormControl className='login__form--password' variant='outlined' size='small'>
											<InputLabel htmlFor='outlined-adornment-password'>New Password</InputLabel>
											<OutlinedInput
												id='outlined-adornment-password'
												name='password'
												label='New Password'
												size='small'
												type={values.showPassword ? 'text' : 'password'}
												onChange={onChangeForm}
												value={form.password}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
															{values.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												error={passwordError}
											/>
											<FormHelperText id='my-helper-text' style={{ color: 'red' }}>
												{passwordError ? passwordMessage : null}
											</FormHelperText>
										</FormControl>
										<Hidden only={['xs', 'sm']}>
											<Tooltip
												className='tooltip_space'
												tooltipStyle={{
													backgroundColor: '#ffffff',
													color: 'black',
													maxWidth: 280,
													fontSize: 12,
													border: '1px solid #9bb43b',
													padding: 10,
												}}
												title={
													<Fragment>
														<Typography variant='body1'>Criteria:</Typography>
														<br />
														<Typography variant='caption'>
															Must be between 8 - 32 characters long <br />
															Alphabet uppercase letter (A-Z) include at least 1<br /> Alphabet lowercase letter (a-z) include at least 1<br /> Decimal digit number (0-9) include at least 1 <br />
															Special characters include at least 1{' '}
														</Typography>
													</Fragment>
												}
											/>
										</Hidden>
									</div>
									<span style={{ paddingTop: 20 }}></span>
									<FormControl className='login__form--password' variant='outlined' size='small'>
										<InputLabel htmlFor='outlined-adornment-password'>Confirm Password</InputLabel>
										<OutlinedInput
											id='outlined-adornment-password'
											name='repassword'
											label='Confirm Password'
											size='small'
											type={values.showPassword ? 'text' : 'password'}
											onChange={onChangeForm}
											value={form.repassword}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
														{values.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											error={passwordError}
										/>
										<FormHelperText id='my-helper-text' style={{ color: 'red' }}>
											{passwordError ? passwordMessage : null}
										</FormHelperText>
									</FormControl>
									<span style={{ paddingTop: 40 }}></span>
									<FormControl variant='outlined' className='formControl' error={selectQuestion1Error}>
										<InputLabel id='demo-simple-select-outlined-label'>Security Question 1</InputLabel>
										<Select labelId='demo-simple-select-outlined-label' id='demo-simple-select-outlined' label='Security Question 1' name='selectQuestion1' onChange={onChangeForm} defaultValue={1}>
											{questionsOne.map((r, i) => {
												return (
													<MenuItem key={i} value={r.questionId}>
														{r.question}
													</MenuItem>
												);
											})}
										</Select>
										{selectQuestion1Error ? <FormHelperText>{selectQuestion1Message}</FormHelperText> : null}
									</FormControl>
									<span style={{ paddingTop: 20 }}></span>
									<TextField
										label='Security Question Answer 1'
										name='answerQuestion1'
										variant='outlined'
										size='small'
										onChange={onChangeForm}
										error={selectQuestionAnswer1Error}
										helperText={selectQuestionAnswer1Error ? selectQuestionAnswer1Message : null}
										inputProps={{ maxLength: 50 }}
									/>
									<span style={{ paddingTop: 20 }}></span>
									<FormControl variant='outlined' className='formControl' error={selectQuestion2Error}>
										<InputLabel id='demo-simple-select-outlined-label'>Security Question 2</InputLabel>
										<Select labelId='demo-simple-select-outlined-label' id='demo-simple-select-outlined' label='Security Question 2' name='selectQuestion2' onChange={onChangeForm} defaultValue={2}>
											{questionsTwo.map((r, i) => {
												return (
													<MenuItem key={i} value={r.questionId}>
														{r.question}
													</MenuItem>
												);
											})}
										</Select>
										{selectQuestion2Error ? <FormHelperText>{selectQuestion2Message}</FormHelperText> : null}
									</FormControl>
									<span style={{ paddingTop: 20 }}></span>
									<TextField
										label='Security Question Answer 2'
										name='answerQuestion2'
										variant='outlined'
										size='small'
										onChange={onChangeForm}
										error={selectQuestionAnswer2Error}
										helperText={selectQuestionAnswer2Error ? selectQuestionAnswer2Message : null}
										inputProps={{ maxLength: 50 }}
									/>
									<span style={{ paddingTop: 20 }}></span>
									<FormControl variant='outlined' className='formControl' error={selectQuestion3Error}>
										<InputLabel id='demo-simple-select-outlined-label'>Security Question 3</InputLabel>
										<Select labelId='demo-simple-select-outlined-label' id='demo-simple-select-outlined' label='Security Question 3' name='selectQuestion3' onChange={onChangeForm} defaultValue={3}>
											{questionsTwo.map((r, i) => {
												return (
													<MenuItem key={i} value={r.questionId}>
														{r.question}
													</MenuItem>
												);
											})}
										</Select>
										{selectQuestion3Error ? <FormHelperText>{selectQuestion3Message}</FormHelperText> : null}
									</FormControl>
									<span style={{ paddingTop: 20 }}></span>
									<TextField
										label='Security Question Answer 3'
										name='answerQuestion3'
										variant='outlined'
										size='small'
										onChange={onChangeForm}
										error={selectQuestionAnswer3Error}
										helperText={selectQuestionAnswer3Error ? selectQuestionAnswer3Message : null}
										inputProps={{ maxLength: 50 }}
									/>
								</form>
								<Box className='pass_security_button'>
									{!loading ? (
										<Button variant='contained' disabled={disabledButton} onClick={register}>
											SUBMIT
										</Button>
									) : (
										<div style={{ marginTop: 15 }}>
											<Loader loading={true} />
										</div>
									)}
								</Box>
							</Fragment>
						) : (
							<MessageBox
								title='We cannot complete your account'
								subtitle={
									<Fragment>
										<p style={{ textAlign: 'left' }}>The link you received by email has expired or is invalid.</p>
										<p style={{ textAlign: 'left' }}>If the code expired, you must start the registration process again.</p>
									</Fragment>
								}
								// onClick={handleShowMessage}
							/>
						)}
					</Box>
				</Box>
			</Grid>
		</Layout>
	);
};

PassSecurity.propTypes = {};

export default PassSecurity;
