import React, { Fragment, useState, useEffect } from 'react';

import StudentInformationView from '../Application/ApplicationReadOnlyComponent/StudentInformationView';
import FinancialInformationView from '../Application/ApplicationReadOnlyComponent/FinancialInformationView';
import LIAgreementsView from '../Application/ApplicationReadOnlyComponent/LIAgreementsView';
import ApplicationNotFound from '../Application/ApplicationNotFound';

const LIView = (props) => {
	return (
		<Fragment>
			{!props.error ? (
				<Fragment>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>1. Student Information</h3>
						</div>
						<div className='form__section--body'>
							<StudentInformationView 
								applicationId={props.id} 
								students={props.application.students} 
								applicationType={props.applicationType} 
								applicationPeriod={props.application.period}
								applicationPreviousPeriod={props.application.previousPeriod}
								token={props.account.tokenAuth} 
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>2. Household Members and Financial Information</h3>
						</div>
						<div className='form__section--body'>
							<FinancialInformationView
								applicationType={props.applicationType}
								financialInformation={props.application.financialMembers}
								account={props.account}
								token={props.account.tokenAuth}
								files={props.application.files}
								id={props.id}
								previousYear={(props.application.previousPeriod).split('/')[0]}
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>3. Agreement Statements</h3>
						</div>
						<div className='form__section--body'>
							<LIAgreementsView
								submittedAgreements={props.application.submittedAgreements}
								agreements={props.application.agreements}
								blueprintSubmitted={props.application.blueprintSubmitted}
								isAgreeStatements={props.application.isAgreeStatements}
							/>
						</div>
					</div>
				</Fragment>
			) : (
				<ApplicationNotFound message={props.error} />
			)}
		</Fragment>
	);
};

export default LIView;
