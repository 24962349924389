import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, children }) => <div className='panel__header'>{children || <h4>{title}</h4>}</div>;

Header.propTypes = {
	title: PropTypes.string,
};

export default Header;
