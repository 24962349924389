import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, Button, Backdrop, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Api from '../../../config/api';
import Loader from '../../Loader';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const AssignProcessorModal = (props) => {
	const classes = useStyles();
	const [assignationTable, setAssignationTable] = useState(props.assignationTable);
	const [assignValue, setAssignValue] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setAssignationTable(props.assignationTable);
	}, [props.assignationTable]);

	useEffect(() => {
		if (props.showModal) {
			setAssignValue(props.rowData.assignedProcessorId);
			setLoading(false);
		}
	}, [props.showModal]);

	const closeModal = () => {
		props.handleClose();
		setAssignValue('');
		setLoading(false);
	};

	const confirmAssign = (e, applicationId) => {		
		setLoading(true);
		props.confirmAssign(e, applicationId);
		setAssignValue(e.target.value);
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={props.showModal}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<div className='backdrop__modal'>
						<div className='backdrop__title'>
							<h2>{props.rowData && props.rowData.assignedProcessorId != null ? 'Edit Processor' : 'Do you want to add a processor?'}</h2>
						</div>
						<Divider />
						<div className='backdrop__body'>
							<div className='backdrop__body-content'>
								<FormControl variant='outlined' fullWidth size='small'>
									<InputLabel id='demo-simple-select-outlined-label'>Select Processor</InputLabel>
									<Select
										labelId='demo-simple-select-outlined-label'
										label='Select Processor'
										id='demo-simple-select-outlined'
										onChange={(e) => confirmAssign(e, props.rowData.applicationId)}
										value={props.rowData.assignedProcessorId != null && assignValue === '' ? props.rowData.assignedProcessorId : assignValue}>
											<MenuItem key={0} value={null}>
												No assignment
											</MenuItem>
										{assignationTable.map((r, i) => {
											return (
												<MenuItem key={i} disabled={props.rowData.assignedProcessorId === r.accountId ? true : false} value={r.accountId}>
													{r.processorName}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							<Divider />
							<div className='backdrop__buttons'>
								{!loading ? (
									<Button variant='contained' color='secondary' onClick={() => closeModal()}>
										Cancel
									</Button>
								) : (
									<Loader loading />
								)}
							</div>
						</div>
					</div>
				</Grid>
			</Backdrop>
		</>
	);
};

AssignProcessorModal.propTypes = {
	inProcess: PropTypes.any,
};

AssignProcessorModal.defaultProp = {
	inProcess: false,
};

export default AssignProcessorModal;
