import React from 'react';
import Layout from '../component/partials/Layout';
import Api from './api';
import { setAccount } from '../redux/actions/account';
import NotFound from '../component/partials/NotFound';
import NotAllowed from '../component/partials/NotAllowed';
import { isEmpty } from 'lodash';

const auth = {
	redirect: (history, store) => {
		const { account } = store.getState();
		let sortLevel = account.roles.sort((a, b) => parseFloat(a.roleId) - parseFloat(b.roleId))[0];
		let level = sortLevel.roleId;

		// store.dispatch(setAccount({...account, level: level}));

		switch (level) {
			case 2:
			case 3:
				history.push('/');
				break;
			case 4:
				history.push('/admin-processor/applications');
				break;
			case 5:
				history.push('/followup/applications');
				break;
			case 6:
				history.push('/general/applications');
				break;
			default:
				if (!localStorage.tokenAuth) history.push('/login');
				break;
		}
	},

	storeUser: (history, store, redirect = '/') => {
		//storeUser: (history, store) => {
		const { tokenAuth } = localStorage;
		const sendToLogin = (err) => {
			if (!err) localStorage.removeItem('tokenAuth');
			//history.push('/login');
		};

		Api.GetAccountToken(tokenAuth)
			.then((res) => {
				if (res.status === 201) {
					let sortLevel = res.data.roles.sort((a, b) => parseFloat(a.roleId) - parseFloat(b.roleId))[0];
					store.dispatch(setAccount({ ...res.data, tokenAuth, level: sortLevel.roleId }));
					history.push(redirect);
					//auth.redirect(history, store);
				} else sendToLogin();
			})
			.catch(sendToLogin);
	},

	authorize: (Composed, props, store, level) => {
		const { pathname } = props.history.location;
		const { account } = store.getState();

		if (!localStorage.tokenAuth) {
			const redirect = pathname == '/' ? '/login' : `/login?redirect_to=${pathname}`;
			props.history.push(redirect);
		}

		//if (!localStorage.tokenAuth) props.history.push('/login');
		if (!isEmpty(account)) {
			if (typeof level === 'object') {
				const found = account.roles.filter((r) => level.indexOf(r.roleId) !== -1);
				if (found.length > 0) return <Composed {...props} />;
				else return <NotAllowed />;
			} else {
				const found = account.roles.findIndex((r, i) => {
					return r.roleId === level;
				});
				if (found !== -1) return <Composed {...props} />;
				else return <NotAllowed />;
			}
		} else auth.storeUser(props.history, store, pathname);
		return null;
	},

	validate: (Composed, props, store) => {
		const { account } = store.getState();
		if (!localStorage.tokenAuth) return <Composed {...props} />;
		if (isEmpty(account)) auth.storeUser(props.history, store);
		return null;
	},
};

export default auth;
