import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WarningOutlineIcon from '@material-ui/icons/WarningTwoTone';

const Alert = ({ open, title, text, confirmButtonText, cancelButtonText, onConfirm, onCancel, icon, iconContainerClassName, confirmButtonColor }) => {
	return (
		<Modal open={open} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
			<Fade in={open}>
				<div className='app-alert__container'>
					<div className='alert__body'>
						<div className={`alert__icon ${iconContainerClassName}`}>
							{icon === 'error' && <ErrorOutlineIcon />}
							{icon === 'success' && <CheckCircleOutlineIcon />}
							{icon === 'question' && <HelpOutlineIcon />}
							{icon === 'warning' && <WarningOutlineIcon />}							
						</div>
						<h2>{title}</h2>
						<p>{text}</p>
						<div className='alert__buttons'>
							{onCancel && (
								<Button className='cancel' variant='contained' fullWidth disableRipple onClick={onCancel}>
									{cancelButtonText}
								</Button>
							)}
							<Button className='confirm' variant='contained' color={confirmButtonColor} fullWidth disableRipple onClick={onConfirm}>
								{confirmButtonText}
							</Button>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

Alert.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	confirmButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	iconContainerClassName: PropTypes.string,
	confirmButtonColor: PropTypes.string,
};

Alert.defaultProps = {
	confirmButtonText: 'OK',
	cancelButtonText: 'Cancel',
	icon: 'error',
	iconContainerClassName: '',
	confirmButtonColor: 'primary',
};

export default Alert;

