import { combineReducers } from 'redux';
import account from './account';
import settings from './settings';
import filters from './filters';
import income from './income';
import applicationRdx from './application';
import statusEmail from './statusEmail';	

const rootReducer = combineReducers({
	account,
	settings,
	filters,
	income,
	applicationRdx,
	statusEmail,
});

export default rootReducer;

