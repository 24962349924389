import React, { Fragment, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, TextField, Typography, OutlinedInput, FormLabel, FormControl, FormHelperText } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import API from '../../config/api';

import Layout from '../../component/partials/Layout';
import Loader from '../../component/Loader';

const LIMIT_OF_ATTEMPS = 3;
const COOKIE_NAME = 'azlf_recovery_email_attempts';
const COOKIE_EXPIRATION = new Date(new Date().getTime() + 60 * 60 * 1000); // 6O minutes

const ContactBox = ({}) => (
	<Box className='auth-box-message'>
		<Box className='forgot_success_box'>
			<h6>Contact Information</h6>
			<p>
				<b>Phone:</b> (602) 525-7355
			</p>
			<p>
				<b>Fax:</b> (855) 340-9399
			</p>
			<p>apply@arizonaleader.org</p>
		</Box>
		<Box className='button__container'>
			<Link to='/'>
				<Button variant='contained'>Back to login</Button>
			</Link>
		</Box>
	</Box>
);

const SuccessBox = ({ account }) => (
	<Box className='auth-box-message'>
		<Box className='forgot_success_box'>
			<h6>EMAIL RESET SUCCESS</h6>
			<p>Your email for our account is</p>
			{/* <p>“ab******t@gmail.com</p> */}
			<p>
				<b>{account.email}</b>
			</p>
		</Box>
		<Box className='button__container'>
			<Link to='/'>
				<Button variant='contained'>Back to login</Button>
			</Link>
		</Box>
	</Box>
);

const QuestionsForm = ({ account, handleSetStep }) => {
	const [loading, setLoading] = useState(false);
	const [attempts, setAttempts] = useState(Cookies.get(COOKIE_NAME) || 0);
	const [questions, setQuestions] = useState([]);
	const [questionsForm, setQuestionsForm] = useState({});
	const [questionsError, setQuestionsError] = useState([]);

	useEffect(() => getQuestions(), []);

	useEffect(() => {
		if (attempts < LIMIT_OF_ATTEMPS) Cookies.set(COOKIE_NAME, attempts);
		else {
			setTimeout(() => handleSetStep('Fail'), 3000);
			Cookies.remove(COOKIE_NAME);
		}
	}, [attempts]);

	const getQuestions = () => {
		const { accountId } = account;

		API.GetQuestionsByAccountId({ accountId }).then((res) => setQuestions(res.data));
	};

	const handleChange = (securityQuestionId, event) => {
		const { value } = event.target;
		setQuestionsError([]);
		setQuestionsForm({ ...questionsForm, [securityQuestionId]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

		let wrongQuestions = [];
		const entries = Object.entries(questionsForm);

		for (const [securityQuestionId, answer] of entries) {
			const response = await API.ValidateQuestion({ securityQuestionId, answer });
			if (!response.data) wrongQuestions.push(Number(securityQuestionId));
		}

		if (wrongQuestions.length === 0) {
			handleSetStep('StepThree');
			Cookies.remove(COOKIE_NAME);
			return;
		}

		setQuestionsError(wrongQuestions);
		setLoading(false);

		setAttempts(Number(attempts) + 1);
	};

	return (
		<div className='forgot-email__phase-2'>
			<Typography className='forgot_container_box_title'>Forgot Email</Typography>
			<form onSubmit={handleSubmit} className='forgot_form'>
				{questions.map((d) => {
					const key = `question_${d.questionId}`;
					const error = questionsError.includes(d.securityQuestionId);

					return (
						<FormControl key={key} error={error} size='small' fullWidth>
							<FormLabel htmlFor={key}>{d.question}</FormLabel>
							<OutlinedInput id={key} name={key} onChange={(event) => handleChange(d.securityQuestionId, event)} required label={d.question} />
							{error && <FormHelperText>Incorrect answer</FormHelperText>}
						</FormControl>
					);
				})}
				{attempts < 3 ? (
					<Box className='forgot_button' style={{ marginTop: -20 }}>
						{questions.length > 0 && (
							<Fragment>
								{!loading ? (
									<Button type='submit' variant='contained'>
										Submit
									</Button>
								) : (
									<div style={{ marginTop: 15 }}>
										<Loader loading={true} />
									</div>
								)}
							</Fragment>
						)}
					</Box>
				) : (
					<Alert severity='error'>You have reached the limit of attempts</Alert>
				)}
			</form>
		</div>
	);
};

const EmailRecoveryForm = ({ handleSetAccount, handleSetStep }) => {
	const [field, setField] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ error: false, message: '' });

	const handleChange = (event) => {
		const { value } = event.target;

		setField(value);
		setError({ error: false, message: '' });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		API.GetUserByEmailPhone({ search: field }).then((res) => {
			const { status, message, data } = res;

			setLoading(false);

			if (status === 400) return setError({ error: true, message });
			if (!data.hasSecurityQuestions) return handleSetStep('Fail');

			handleSetAccount(data);
			handleSetStep('StepTwo');

			Cookies.set(COOKIE_NAME, 0, { expires: COOKIE_EXPIRATION, path: '' });
		});
	};

	return (
		<div className='forgot-email__phase-1'>
			<Typography className='forgot_container_box_title'>Forgot Email</Typography>
			{/* <span className='forgot_span'>Select recovery method</span> */}
			<form onSubmit={handleSubmit} className='forgot_form'>
				<TextField size='small' label='Phone Number' name='firstname' variant='outlined' error={error.error} helperText={error.message} onChange={handleChange} required />
				<Box className='forgot_button'>
					{!loading ? (
						<Button type='submit' variant='contained'>
							Submit
						</Button>
					) : (
						<div style={{ marginTop: 15 }}>
							<Loader loading={true} />
						</div>
					)}
				</Box>
			</form>
		</div>
	);
};

const ForgotEmail = (props) => {
	const [step, setStep] = useState('StepOne');
	const [account, setAccount] = useState(null);

	return (
		<Layout title='Log In' showBackground={false}>
			<Grid item xs={12}>
				<Box className='forgot_container'>
					<Box className='forgot_container_box'>
						{step == 'StepOne' && <EmailRecoveryForm handleSetStep={setStep} handleSetAccount={setAccount} />}
						{step == 'StepTwo' && <QuestionsForm account={account} handleSetStep={setStep} />}
						{step == 'StepThree' && <SuccessBox account={account} />}
						{step == 'Fail' && <ContactBox />}
					</Box>
				</Box>
			</Grid>
		</Layout>
	);
};

export default ForgotEmail;
