import React from 'react';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
	card: {
		padding: '25px 20px',
		marginBottom: 25,
		backgroundColor: '#f8f8f8',
		boxShadow: 'none',
	},
	cardLessPadding: {
		paddingTop: 15,
		paddingBottom: 15,
	},
	cardCirclesContainer: {
		display: 'flex',
		boxShadow: 'none',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		'@media (min-width: 960px)': {
			flexDirection: 'column',
			'& .MuiSkeleton-root:not(:last-child)': {
				marginBottom: 10,
			},
		},
		'@media (min-width: 1280px)': {
			flexDirection: 'row',
			'& .MuiSkeleton-root:not(:last-child)': {
				marginBottom: 0,
			},
		},
	},
	cardList: {
		boxShadow: 'none',
		backgroundColor: 'transparent',
		'& .MuiSkeleton-root': {
			marginBottom: 15,
			'&:last-child': {
				marginBottom: 0,
			},
		},
	},
	lastCard: {
		flex: 1,
		marginBottom: 0,
	},
	statusCircle: {
		width: 90,
		height: 90,
		'@media (min-width: 600px)': {
			width: 135,
			height: 135,
		},
	},
	pieChartCircle: {
		width: 200,
		height: 200,
		'@media (min-width: 960px)': {
			width: 160,
			height: 160,
		},
		'@media (min-width: 1280px)': {
			width: 200,
			height: 200,
		},
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	marginTop: {
		marginTop: 25,
	},
}));

const Skeleton = ({}) => {
	const classes = useStyles();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={5} className={classes.flexContainer}>
				<Card className={`${classes.card} ${classes.cardLessPadding}`}>
					<MuiSkeleton variant='text' animation='wave' />
				</Card>
				<Card className={`${classes.card} ${classes.lastCard}`}>
					<MuiSkeleton variant='text' animation='wave' width={145} />
					<Card className={`${classes.cardCirclesContainer} ${classes.marginTop}`}>
						<MuiSkeleton variant='circle' animation='wave' className={classes.statusCircle} />
						<MuiSkeleton variant='circle' animation='wave' className={classes.statusCircle} />
						<MuiSkeleton variant='circle' animation='wave' className={classes.statusCircle} />
					</Card>
				</Card>
			</Grid>
			<Grid item xs={12} md={2} className={classes.flexContainer}>
				<Card className={`${classes.card} ${classes.lastCard}`}>
					<MuiSkeleton variant='text' animation='wave' width={80} />
					<Card className={`${classes.cardList} ${classes.marginTop}`}>
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
					</Card>
				</Card>
			</Grid>
			<Grid item xs={12} md={2} className={classes.flexContainer}>
				<Card className={classes.card}>
					<MuiSkeleton variant='text' animation='wave' width={80} />
					<Card className={`${classes.cardList} ${classes.marginTop}`}>
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
					</Card>
				</Card>
				<Card className={`${classes.card} ${classes.lastCard}`}>
					<MuiSkeleton variant='text' animation='wave' width={80} />
					<Card className={`${classes.cardList} ${classes.marginTop}`}>
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
						<MuiSkeleton variant='text' animation='wave' />
					</Card>
				</Card>
			</Grid>
			<Grid item xs={12} md={3} className={classes.flexContainer}>
				<Card className={`${classes.card} ${classes.lastCard}`}>
					<MuiSkeleton variant='text' animation='wave' width={145} />
					<Card className={`${classes.cardCirclesContainer} ${classes.justifyContentCenter} ${classes.marginTop}`}>
						<MuiSkeleton variant='circle' animation='wave' className={classes.pieChartCircle} />
					</Card>
				</Card>
			</Grid>
		</Grid>
	);
};

export default Skeleton;
