import React,{useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import API from '../../config/api';
import ApplicationTraceEmailList from './ApplicationTraceEmailList';
import ApplicationTraceEmailItem from './ApplicationTraceEmailItem';

import {tplActionNeeded, tplNotApproved} from './tpl';

const NEW = 2;
const ACTION_NEEDED = 7;
const NOT_APPROVED = 6;

function ApplicationTraceEmail({traceEmailRef, application, account}) {
	const myRef = React.useRef({});
	const location = useLocation();
	const getAdminType = () =>{
		if(location.pathname.toLowerCase().includes('/admin-processor')) return 'admin-processor';
		if(location.pathname.toLowerCase().includes('/admin')) return 'admin';		
		if(location.pathname.toLowerCase().includes('/followup')) return 'followup';
		if(location.pathname.toLowerCase().includes('/general')) return 'general';		
		return '';
	}
	const adminType = getAdminType();

	const [loading, setLoading] = useState(false);
	const [emailList, setEmailList] = useState([]);
	const [emailItem, setEmailItem] = useState(null);
	
	useEffect(() => {
		loadData(false);
	}, [application]);
	
	const loadData = async () => {
		if(!!application.applicationId){
			await getStatusEmailList(application.applicationId);
		}		
	};

	const getStatusEmailList = async applicationId => {
		setLoading(true);
		const reqData = {applicationId: applicationId};
		return API.GetStatusEmailList(account.tokenAuth,reqData).then((res) => {
			if (res.status !== 201) {
				setLoading(false);
				return;
			}

			setEmailList(res.data);
			if(res.data.length === 0){
				 loadBaseTemplate(); 
			}else{
				setEmailItem(res.data[0]);
			}
			setLoading(false);
		});
	};

	const loadBaseTemplate = () => {
		const __statusTemplate = application.status === ACTION_NEEDED?tplActionNeeded:tplNotApproved;
		const newItem = {
			emailId: 0,
			body: __statusTemplate,
			applicationId: application.applicationId 
		}
		setEmailItem(newItem);
	}

	const handleSelectedEmailItem = item => {
		setEmailItem(item);
	}

	const handleUpdateItem = (itemUpdated, newList) => {		
			setEmailList(newList);
			setEmailItem(itemUpdated);			
	}

	const handleDeletedEmailItem = newList => {
		setEmailList(newList);
		if(newList.length === 0){
			loadBaseTemplate();
		}
	}

	if(traceEmailRef){
		traceEmailRef.current.reload = loadData;
	}
		

	let readonly = false;	
	if(adminType==='admin') return null;	
	if(adminType==='general'){
		 readonly = true;
		 if(emailList.length == 0) return null;
	}
	if(application.status !== ACTION_NEEDED && application.status !== NOT_APPROVED)	{
		readonly = true;
		if(emailList.length == 0) return null;
	}
	/*if(
		(application.status == NEW && emailList.length == 0) ||
		(application.status != ACTION_NEEDED && application.status != NOT_APPROVED)
	) return null;*/
	
	return (		
		<div class='user__email'>
			<hr/>
			<h3 style={{ paddingTop: '20px', paddingBottom: '20px', color: '#9bb43b' }}>Mailbox tracking</h3>
			{emailList.length > 0 && <ApplicationTraceEmailList readOnly={readonly} emailList={emailList} emailItem={emailItem} onSelected={handleSelectedEmailItem} onDeleted={handleDeletedEmailItem}/>}
			{emailItem && <ApplicationTraceEmailItem myRef={myRef} readOnly={readonly} emailData={emailItem} status={application.status} newStatus={application.status} onUpdate={handleUpdateItem} />} 
		</div>
	)
}

const mapStateToProps = (s) => {
	return {
		account: s.account
	};
};

export default connect(mapStateToProps)(ApplicationTraceEmail);

