import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { Button, FormControl, Grid, TextField, OutlinedInput, InputLabel } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import API from '../../config/api';
import { errorField } from '../../utils/helpers';
import configureStore from '../../redux';
// import { setAccount } from '../../redux/actions/account'

import Loader from '../../component/Loader';
// import Snackbar from '../../component/Snackbar'

// const store = configureStore()

const PhoneMaskedInput = (props) => {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => inputRef(ref ? ref.inputElement : null)}
			mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			keepCharPositions={true}
			showMask
		/>
	);
};

const ZipMaskedInput = (props) => {
	const { inputRef, ...other } = props;

	return <MaskedInput {...other} ref={(ref) => inputRef(ref ? ref.inputElement : null)} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />;
};

const ReadOnlyInformation = ({ account, handleEdit }) => {
	const formatPhoneNumber = (phone) => {
		const phoneNumber = phone || '';
		const number = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
		return phoneNumber ? `(${number[1]}) ${number[2]}-${number[3]}` : null;
	};

	return (
		<Fragment>
			<div className='user-information'>
				<p>
					First Name: <span>{account.firstName}</span>
				</p>
				<p>
					Last Name: <span>{account.lastName}</span>
				</p>
				<p>
					Address: <span>{account.address}</span>
				</p>
				<p>
					City: <span>{account.city}</span>
				</p>
				<p>
					State: <span>{account.state}</span>
				</p>
				<p>
					ZIP: <span>{account.postalCode}</span>
				</p>
				<p>
					Phone: <span>{formatPhoneNumber(account.phone)}</span>
				</p>
				<p>
					Email Address: <span>{account.email}</span>
				</p>
				<p>
					Alternate Email Address: <span>{account.recoveryEmail}</span>
				</p>
			</div>
			<Button color='primary' startIcon={<EditIcon />} className='edit-user__button' onClick={handleEdit}>
				Edit user information
			</Button>
		</Fragment>
	);
};

const EditableInformation = ({ account, token, handleEdit, handleUpdateAccountData }) => {
	// const [snackbarState, setSnackbarState] = useState({ open: false, severity: 'success', text: '' })
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formState, setFormState] = useState({
		firstName: account.firstName || '',
		lastName: account.lastName || '',
		address: account.address || '',
		city: account.city || '',
		state: account.state || '',
		postalCode: account.postalCode || '',
		phone: account.phone || '',
		email: account.email || '',
		recoveryEmail: account.recoveryEmail || '',
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		setFormError(null);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

		const phone = formState.phone.replace(/[^\d]/g, '');
		const data = { ...formState, phone };

		await API.UpdateAccount(token, data).then((res) => {
			setLoading(false);

			if (res.status !== 201) return setFormError({ field: res.field, message: res.message });

			// handleEdit()
			// setFormError(null)
			// handleUpdateAccountData(data)

			// store.dispatch(setAccount({ ...data, tokenAuth: token }))
			window.location.reload(true);

			// setSnackbarState({ open: true, severity: success ? 'success' : 'error', text: res.message })
		});
	};

	const errors = errorField(formError);

	return (
		<div className='user-information__form'>
			<form onSubmit={handleSubmit}>
				<FormControl component='fieldset' fullWidth>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField label='First Name' name='firstName' value={formState.firstName} variant='outlined' inputProps={{ maxLength: 120 }} onChange={handleChange} required fullWidth />
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField label='Last Name' name='lastName' value={formState.lastName} variant='outlined' inputProps={{ maxLength: 120 }} onChange={handleChange} required fullWidth />
						</Grid>
						<Grid item xs={12}>
							<TextField label='Address' name='address' value={formState.address} variant='outlined' inputProps={{ maxLength: 320 }} onChange={handleChange} fullWidth />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField label='City' name='city' value={formState.city} variant='outlined' inputProps={{ maxLength: 45 }} onChange={handleChange} fullWidth />
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField label='State' name='state' value={formState.state} variant='outlined' inputProps={{ maxLength: 45 }} onChange={handleChange} fullWidth />
						</Grid>
						<Grid item xs={12} md={4}>
							<FormControl className='masked-input' error={errors.postalCode}>
								<InputLabel htmlFor='zip'>ZIP</InputLabel>
								<OutlinedInput name='postalCode' label='ZIP' value={formState.postalCode} inputComponent={ZipMaskedInput} inputProps={{ maxLength: 11 }} onChange={handleChange} />
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControl className='masked-input' error={errors.phone} fullWidth>
								<InputLabel htmlFor='phone'>Phone</InputLabel>
								<OutlinedInput name='phone' label='Phone' value={formState.phone} inputComponent={PhoneMaskedInput} inputProps={{ maxLength: 30 }} onChange={handleChange} />
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label='Alternate Email Address'
								name='recoveryEmail'
								error={errors.recoveryEmail}
								type='email'
								value={formState.recoveryEmail}
								variant='outlined'
								inputProps={{ maxLength: 254 }}
								onChange={handleChange}
								required
								fullWidth
							/>
						</Grid>
					</Grid>
				</FormControl>

				<span className='warning'>Note: Phone are used as recovery information, it is only possible to have them in one account.</span>

				{formError && (
					<Alert severity='error' onClose={() => setFormError(null)} style={{ marginTop: 20 }}>
						{formError.message}
					</Alert>
				)}

				<div className='form-action-buttons'>
					{!loading ? (
						<Button color='primary' type='submit' startIcon={<SaveIcon />} disableRipple>
							Save
						</Button>
					) : (
						<Loader loading={true} />
					)}
					<Button color='secondary' endIcon={<CloseIcon />} onClick={handleEdit} disableRipple>
						Close
					</Button>
				</div>
			</form>
			{/* <Snackbar open={snackbarState.open} severity={snackbarState.severity} text={snackbarState.text} handleClose={() => setSnackbarState({ ...snackbarState, open: false })} /> */}
		</div>
	);
};

const UserInformation = ({ account, token }) => {
	const [isEdit, setIsEdit] = useState(false);
	const [accountData, setAccountData] = useState({ ...account });

	const handleEdit = () => setIsEdit(!isEdit);

	const handleUpdateAccountData = (data) => setAccountData({ ...data });

	return (
		<div className='profile-page__user-information--tab'>
			<h3 className='section-title'>Profile: User Information</h3>
			<Grid container>
				<Grid item xs={12} lg={7}>
					{!isEdit ? (
						<ReadOnlyInformation account={accountData} handleEdit={handleEdit} />
					) : (
						<EditableInformation account={accountData} token={token} handleEdit={handleEdit} handleUpdateAccountData={handleUpdateAccountData} />
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default withRouter(connect((s) => ({ account: s.account }))(UserInformation));
