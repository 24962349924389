import React, { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { BorderedPanel } from '../../BorderedPanel';
import MembersList from './MembersList';
import MemberForm from './MemberForm';
import MemberFiles from './MemberFiles';
import api from '../../../config/api';

const FinancialInformation = ({ application, account }) => {
	const [members, setMembers] = useState([...application.financialMembers]);
	const [numMembers, setNumMembers] = useState(application.householdMembers || undefined);

	const { tokenAuth } = account;

	const [selectedMember, setSelectedMember] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	const handleSetNumMembers = (e) => {
		const { value } = e.target;
		setNumMembers(value);
		api.SetHouseholdMembers(tokenAuth, { applicationId: application.applicationId, quantityHouseholdMembers: value });
	};
	const handleUpdateMembers = (member) => setMembers([...members, member]);

	const handleUpdateSelectedMember = (member) => {
		let _members = [...members];
		const index = _members.findIndex((d) => d.financialId === member.financialId);
		_members[index] = member;
		setMembers(_members);
	};

	const handleEdit = (member, edit) => {
		setIsEdit(edit);
		setSelectedMember(member);
	};

	const handleDelete = (financialId) => setMembers(members.filter((d) => d.financialId != financialId));

	return (
		<div className='financial-information-v2__component'>
			<BorderedPanel>
				<div className='member-count'>
					<h4>How many TOTAL household members live in your home, including yourself? </h4>
					<div className='member-count__content'>
						<h5>This includes all members that reside at your residence: children, parents, grandparents, guardians, roommates, etc...</h5>
						<Select variant='outlined' fullWidth placeholder='Select number of household members' onChange={handleSetNumMembers} value={numMembers}>
							{Array(14)
								.fill()
								.map((_, i) => (
									<MenuItem key={i} value={i + 2}>
										{i + 2}
									</MenuItem>
								))}
						</Select>

						{numMembers < members.length && (
							<div className='member-count__warning'>
								<WarningIcon />
								<span>									
									The number of household members has been reduced from a previously submitted application. Please confirm the selection of current household members and make any necessary edits to member names or income changes.
								</span>
							</div>
						)}
					</div>
				</div>
				{numMembers && (
					<div className='member-forms'>
						<MembersList account={account} application={application} members={members} isEdit={isEdit} onEdit={handleEdit} onDelete={handleDelete} />
						<MemberForm
							account={account}
							members={members}
							numMembers={numMembers}
							editMode={isEdit}
							application={application}
							selectedMember={selectedMember}
							membersCount={members.length}
							onEdit={handleEdit}
							onUpdateMembers={handleUpdateMembers}
							onUpdateSelectedMember={handleUpdateSelectedMember}
						/>
						{members.length >= numMembers && <MemberFiles account={account} application={application} members={members} />}
					</div>
				)}
			</BorderedPanel>
		</div>
	);
};

FinancialInformation.defaultProps = {
	onSetSelectedParentMember: () => {},
};

export default FinancialInformation;
