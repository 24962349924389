import FetchRequest from './request';
import * as c from './constants';

class ApiModel {
	// Auth
	Login = (credentials) => FetchRequest(c.LOGIN, 'POST', null, credentials);
	//User
	GetAccountToken = (token) => FetchRequest(c.GETCURRENT, 'GET', token);
	CompleteAccount = (data) => FetchRequest(c.COMPLETEACCOUNT, 'POST', null, data);
	CreateAccount = (data) => FetchRequest(c.CREATEACCOUNT, 'POST', null, data); //put token
	RecoveryPassword = (data) => FetchRequest(c.RECOVERYPASSWORD, 'POST', null, data);
	ResetPassword = (data) => FetchRequest(c.RESETPASSWORD, 'POST', null, data);
	GetAllAccount = (token) => FetchRequest(c.GETALLACCOUNT, 'GET', token);
	CheckExpirationDate = (code) => FetchRequest(c.CHECKEXPIRATIONDATE + '/' + code, 'GET', null);
	GetAccount = (token, _id) => FetchRequest(c.GETACCOUNT, 'POST', token, { _id });
	UpdateAccount = (token, data) => FetchRequest(c.UPDATEACCOUNT, 'PUT', token, data);
	DeleteAccount = (token, _id) => FetchRequest(c.DELETEACCOUNT, 'DELETE', token, { _id });
	GetUserByEmailPhone = (data) => FetchRequest(c.GETUSERBYEMAILPHONE, 'POST', null, data);
	UpdatePassword = (token, data) => FetchRequest(c.UPDATEPASSWORDACCOUNT, 'POST', token, data);
	UpdateQuestions = (token, data) => FetchRequest(c.UPDATEQUESTIONS, 'PUT', token, data);

	//Security Question
	GetQuestion = () => FetchRequest(c.GETQUESTION, 'GET', null);
	GetQuestionsByAccountId = (data) => FetchRequest(c.GETRANDOMQUESTIONSBYACCOUNTID, 'POST', null, data);
	GetAllQuestionsByAccountId = (token, data) => FetchRequest(c.GETALLQUESTIONSBYACCOUNTID, 'POST', token, data);
	ValidateQuestion = (data) => FetchRequest(c.VALIDATEQUESTION, 'POST', null, data);

	//Processors
	GetAdminProcessorList = (token) => FetchRequest(c.GETADMINPROCESSORLIST, 'GET', token);
	GetFollowProcessorList = (token) => FetchRequest(c.GETFOLLOWPROCESSORLIST, 'GET', token);
	GetGeneralProcessorList = (token) => FetchRequest(c.GETGENERALPROCESSORLIST, 'GET', token);
	GetSiteAdminList = (token) => FetchRequest(c.GETSITEADMINLIST, 'GET', token);
	GetGeneralProcessors = (token) => FetchRequest(c.GETGENERALPROCESSORS, 'GET', token);
	AssignToGeneralProcessor = (token, data) => FetchRequest(c.ASSIGNTOGENERALPROCESSOR, 'POST', token, data);
	GetStatusByRole = (token, data) => FetchRequest(c.GETSTATUSBYROLE, 'GET', token, data);
	ChangeStatus = (token, data) => FetchRequest(c.CHANGESTATUS, 'POST', token, data);
	UpdateInProcess = (token, data) => FetchRequest(c.UPDATEINPROCESS, 'POST', token, data);

	//Application
	GetCriteria = (token) => FetchRequest(c.GETCRITERIA, 'GET', token);
	GetApplicationById = (token, data) => FetchRequest(c.GETAPPLICATIONBYID, 'GET', token, data);
	CreateLIStudent = (token, data) => FetchRequest(c.CREATELISTUDENT, 'POST', token, data);
	UpdateLIStudent = (token, data) => FetchRequest(c.UPDATELISTUDENT, 'POST', token, data);
	DeleteLIStudent = (token, data) => FetchRequest(c.DELETELISTUDENT, 'POST', token, data);
	CreateApplicationFinancial = (token, data) => FetchRequest(c.CREATEAPPLICATIONFINANCIAL, 'POST', token, data);
	UpdateApplicationFinancial = (token, data) => FetchRequest(c.UPDATEAPPLICATIONFINANCIAL, 'POST', token, data);
	DeleteApplicationFinancial = (token, data) => FetchRequest(c.DELETEAPPLICATIONFINANCIAL, 'POST', token, data);
	SetAgreeStatements = (token, data) => FetchRequest(c.SETAGREESTATEMENTS, 'POST', token, data);
	UpdateDDStudent = (token, data) => FetchRequest(c.UPDATEDDSTUDENT, 'POST', token, data);
	UpdateDDPrerequisites = (token, data) => FetchRequest(c.UPDATEDDPREREQUISITES, 'POST', token, data);
	SetAgreeStatements = (token, data) => FetchRequest(c.SETAGREESTATEMENTS, 'POST', token, data);
	SetSharingPermissions = (token, data) => FetchRequest(c.SETSHARINGPERMISSIONS, 'POST', token, data);
	SendSignatureReminder = (token, data) => FetchRequest(c.SENDSIGNATUREREMINDER, 'POST', token, data);
	IncompletedReasons = (token) => FetchRequest(c.INCOMPLETEDREASONS, 'GET', token);
	RejectedReasons = (token) => FetchRequest(c.REJECTEDREASONS, 'GET', token);
	DeleteParentApplication = (token, data) => FetchRequest(c.DELETEPARENTAPPLICATION, 'POST', token, data);
	SetHouseholdMembers = (token, data) => FetchRequest(c.SETHOUSEHOLDMEMBERS, 'POST', token, data);
	UpdateFinancialQuestions = (token, data) => FetchRequest(c.UPDATEFINANCIALQUESTIONS, 'POST', token, data);
	UpdateInternalComments = (token, data) => FetchRequest(c.UPDATEINTERNALCOMMENTS, 'POST', token, data);

	// School
	GetSchoolNames = (token, query = null) => FetchRequest(`${c.GETSCHOOLNAMES}${query ? query : ''}`, 'GET', token);
	DeleteSchool = (token, { schoolId, setInactive }) => FetchRequest(c.DELETESCHOOL, 'POST', token, { schoolId, setInactive });
	RegisterSchool = (token, name) => FetchRequest(c.CREATESCHOOL, 'POST', token, { name });
	GetStatsPerSchool = (token, data) => FetchRequest(c.GETSTATSPERSCHOOL, 'GET', token, data);
	ReactiveSchool = (token, schoolId) => FetchRequest(c.REACTIVESCHOOL, 'POST', token, { schoolId });


	//FILES
	GetUploadFileData = (token, data) => FetchRequest(c.GETUPLOADFILEDATA, 'POST', token, data);
	GetFilesApplications = (token, data) => FetchRequest(c.GETFILESAPPLICATIONS, 'POST', token, data);
	DeleteFile = (token, data) => FetchRequest(c.DELETEFILE, 'POST', token, data);

	//Sign
	GetSignatureRequestDownload = (token, data) => FetchRequest(c.GETSIGNATUREREQUESTDOWNLOAD, 'GET', token, data);

	//Settings
	UpdateSettings = (token, data) => FetchRequest(c.UPDATESETTINGS, 'POST', token, data);

	// Reports
	GetDefinitions = (token, data) => FetchRequest(c.GETDEFINITIONS, 'GET', token);
	UpdateReport = (token, data) => FetchRequest(c.UPDATEREPORT, 'POST', token, data);
	DeleteReport = (token, data) => FetchRequest(c.DELETEREPORT, 'POST', token, data);

	// Columns
	GetColumns = (token) => FetchRequest(c.GETCOLUMNS, 'GET', token);

	// Filters
	GetFilters = (token, data) => FetchRequest(c.GETFILTERS, 'POST', token, data);
	UpdateFilter = (token, data) => FetchRequest(c.UPDATEFILTER, 'POST', token, data);
	GetFiltersList = (token, data) => FetchRequest(c.GETFILTERSLIST, 'GET', token, data);
	GetDataFilters = (token, data) => FetchRequest(c.GETDATAFILTERS, 'POST', token, data);
	RemoveFilter = (token, data) => FetchRequest(c.REMOVEFILTER, 'POST', token, data);

	// Reporst
	GetDataReport = (token, data) => FetchRequest(c.GETDATAREPORT, 'POST', token, data);
	SendReport = (token, data) => FetchRequest(c.SENDREPORT, 'POST', token, data);

	// History
	GetHistory = (token, data) => FetchRequest(c.GETHISTORY, 'GET', token, data);
	GetHistoryIndex = (token, data) => FetchRequest(c.GETHISTORYINDEX, 'GET', token, data);

	// Metrics
	GetMetrics = (token, data) => FetchRequest(c.GETMETRICS, 'GET', token, data);

	//Site
	GetPeriods = (token) => FetchRequest(c.GETPERIODS, 'GET', token);
	StartPeriod = (token) => FetchRequest(c.STARTPERIOD, 'POST', token);
	GetIncomeTable = (token, data) => FetchRequest(c.GETINCOMETABLE, 'GET', token,data);	
	GetFullIncomeTable = (token, data) => FetchRequest(c.GETFULLINCOMETABLE, 'GET', token,data);	
	UpdateIncomes = (token, data) => FetchRequest(c.UPDATEINCOMES, 'POST', token, data);

	// Media
	GetUploadMediaData = (token, data) => FetchRequest(c.GETUPLOADMEDIADATA, 'POST', token, data);
	GetDownloadMediaUrl = (token, data) => FetchRequest(c.GETDOWNLOADMEDIAURL, 'POST', token, data);
	DeleteMedia = (token, data) => FetchRequest(c.DELETEMEDIA, 'POST', token, data);
	AddMedia = (token, data) => FetchRequest(c.ADDMEDIA, 'POST', token, data);

	// Processing Status
	SetProcessingStatus = (token, data) => FetchRequest(c.SETPROCESSINGSTATUS, 'POST', token, data);

	//Status Email 
	GetStatusEmailList = (token, data) => FetchRequest(c.GETSTATUSEMAILLIST, 'GET', token, data);
	UpdateStatusEmailList = (token, data) => FetchRequest(c.UPDATESTATUSEMAILLIST, 'POST', token, data);
	PreviewEmail = (token, data) => FetchRequest(c.PREVIEWSTATUSEMAIL, 'POST', token, data);
	DeleteStatusEmail = (token, data) => FetchRequest(c.DELETEESTATUSEMAIL, 'POST', token, data);
}

export default new ApiModel();
