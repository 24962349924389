import React, { useState, useEffect } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import StudentInformation from '../../component/StudentInformation';

const LIStudentInformation = ({ application, account, mode, applicationType }) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if (success) setTimeout(() => setSuccess(false), 3000);
	}, [success]);

	return (
		<div className='form__section'>
			<div className='form__section--header'>
				<h3>1. Student Information</h3>
				{success && !loading && (
					<Button size='small' color='primary' endIcon={<SaveIcon />} className='text-transform-none' disableRipple>
						Auto-saved
					</Button>
				)}
				{loading && <CircularProgress size={15} color='primary' />}
			</div>

			<div className='form__section--body'>
				<Grid container>
					<Grid item xs={12} lg={mode === 'view' ? 12 : 7}>
						<div className='section__student-information'>
							<StudentInformation
								application={application}
								account={account}
								mode={mode}
								applicationType={applicationType}
								onAutosaveLoading={(param) => setLoading(param)}
								onAutosaveSuccess={(param) => setSuccess(param)}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default LIStudentInformation;
