import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton, OutlinedInput, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const Toolbar = (props) => {
	const [status, setStatus] = useState(props.status || []);
	const [toolbarState, setToolbarState] = useState({ search: '', status: '' });

	const onSearchRef = useRef(null);

	// handle status state
	useEffect(() => setStatus(props.status || []), [props.status]);

	// handle onSearchChanged
	useEffect(() => {
		const timeout = setTimeout(() => onSearchRef.current && props.onSearchChanged(toolbarState), 1000);
		return () => clearTimeout(timeout);
	}, [toolbarState]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setToolbarState({ ...toolbarState, [name]: value });
		onSearchRef.current = true;
	};

	return (
		<div className='table-toolbar'>
			<div className='table-toolbar__periods'>
				<Grid container spacing={1} xs={12}>
					<Grid item xs={12}>
						{props.componentPeriod}
					</Grid>
				</Grid>
			</div>
			<div className='table-toolbar__right_container'>
				<div className='table-toolbar__container'>
					<Grid container spacing={1} xs={12}>
						<Grid item xs={6}>
							<FormControl variant='outlined' size='small' fullWidth>
								<InputLabel htmlFor='outlined-adornment-password'>Search</InputLabel>
								<OutlinedInput
									label='Search'
									name='search'
									color='primary'
									type='text'
									onChange={handleChange}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton edge='end'>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									}
									fullWidth
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant='outlined' size={'small'} fullWidth>
								<InputLabel id='toolbar-status-label'>Application Status</InputLabel>
								<Select label='Application Status' name='status' onChange={handleChange}>
									<MenuItem value=''>All Application Status</MenuItem>
									{status.map((d) => (
										<MenuItem key={d.statusId} value={d.statusLabel}>
											{d.statusLabel}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</div>

				<div className='table-toolbar__actions'>
					<props.components.Actions actions={props.actions && props.actions.filter((a) => a.position === 'toolbar')} components={props.components} />
				</div>
			</div>
		</div>
	);
};

export default connect((s) => ({ account: s.account }))(Toolbar);
