import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const SectionHeader = ({ title, showButtons, disableButtons, onEdit, onRemove, disableRemove, children }) => (
	<div className='panel-section__header'>
		{children || <h4>{title}</h4>}
		{showButtons && (
			<div className='action-buttons'>
				<IconButton color='primary' size='small' onClick={onEdit} disabled={disableButtons}>
					<EditIcon />
				</IconButton>

				<IconButton color='secondary' size='small' onClick={onRemove} disabled={!!disableButtons || !!disableRemove}>
					<DeleteForeverIcon />
				</IconButton>
			</div>
		)}
	</div>
);

SectionHeader.propTypes = {
	title: PropTypes.string,
	showButtons: PropTypes.bool,
	disableButtons: PropTypes.bool,
	onEdit: PropTypes.func,
	onRemove: PropTypes.func,
};

SectionHeader.defaultProps = {
	showButtons: false,
	disableButtons: false,
};

export default SectionHeader;
