import React, { useState, useRef } from 'react';
import { Grid, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import FinancialInformationComponent from '../../component/FinancialInformation';

const FinancialInformation = ({}) => {
	const financialRef = useRef();

	const [isEdit, setIsEdit] = useState(false);

	const handleEditMode = (bool) => setIsEdit(bool);

	const handleClose = () => {
		financialRef.current.handleClose();
		setIsEdit(false);
	};

	return (
		<div className='profile-page__financial-information--tab'>
			<h3 className='section-title'>Profile: Financial Information</h3>
			<Grid container>
				<Grid item xs={12} lg={8}>
					<div className='financial-panel__container'>
						<FinancialInformationComponent ref={financialRef} onEdit={handleEditMode} onSetSelectedParentMember={() => {}} />
						{isEdit && (
							<div className='financial-panel__action-buttons'>
								<Button color='primary' startIcon={<SaveIcon />} disableRipple>
									Save
								</Button>
								<Button color='secondary' endIcon={<CloseIcon />} onClick={handleClose} disableRipple>
									Close
								</Button>
							</div>
						)}
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default FinancialInformation;
