import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAccount } from '../redux/actions/account';

const IndexRoute = (props) => {
	const { account } = props;
	let sortLevel = account.roles.sort((a, b) => parseFloat(a.roleId) - parseFloat(b.roleId))[0];
	let level = sortLevel.roleId;

	// props.setAccount({...props.account, level: level});

	switch (level) {
		case 3:
			return <Redirect to='/admin/applications' />;
			break;
		case 4:
			return <Redirect to='/admin-processor/applications' />;
			break;
		case 5:
			return <Redirect to='/followup/applications' />;
			break;
		case 6:
			return <Redirect to='/general/applications' />;
			break;
	}
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

const mapDispatchToProps = {
	setAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexRoute);
