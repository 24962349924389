import React, { Fragment, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import StudentInformationView from '../Application/ApplicationReadOnlyComponent/StudentInformationView';
import Prerequisites from '../Application/ApplicationReadOnlyComponent/Prerequisites';
import FinancialInformationView from '../Application/ApplicationReadOnlyComponent/FinancialInformationView';
// import DDAgreementsView from '../Application/ApplicationReadOnlyComponent/DDAgreementsView'
import DDAgreementsView from '../Application/ApplicationReadOnlyComponent/DDAgreementsView';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ApplicationNotFound from '../Application/ApplicationNotFound';

const DDView = (props) => {
	return (
		<Fragment>
			{props.application != null ? (
				<Fragment>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>1. Student Information</h3>
						</div>
						{props.application.students && props.application.students.length > 0 ? (
							<div className='form__section--body'>
								<StudentInformationView 
									applicationId={props.application.applicationId}								
									students={props.application.students} 
									applicationType={props.applicationType} 
									applicationPeriod={props.application.period}
									applicationPreviousPeriod={props.application.previousPeriod}
									token={props.account.tokenAuth} 
								/>
							</div>
						) : (
							<h4>There are no records</h4>
						)}
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>2. Prerequisites</h3>
						</div>
						<div className='form__section--body'>
							<Grid container>
								<Grid item xs={12} lg={7}>
									<Prerequisites data={props.application} prerequisites={props.application.prerequisites} token={props.account.tokenAuth} />
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>3. Household Members and Financial Information</h3>
						</div>
						<div className='form__section--body'>
							<FinancialInformationView
								financialInformation={props.application.financialMembers}
								account={props.account}
								token={props.account.tokenAuth}
								files={props.application.files}
								id={props.application.applicationId}
								previousYear={(props.application.previousPeriod).split('/')[0]}
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>4. Information Release Form for Disabled/Displaced Students</h3>
						</div>
						<div className='form__section--body'>
							<Grid container>
								<Grid item xs={12} lg={7}>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
										<span style={{ marginLeft: 6 }}>{props.application.hasPermitShareInfo ? <CheckIcon /> : <CloseIcon />}</span>
										<p style={{ paddingLeft: 25 }}>
											For purposes of the Disabled/Displaced Scholarship Program, I permit the Arizona Department of Revenue and/or the Arizona Department of Child Safety and /or other School Tuition
											Organizations (STOs) to provide information regarding the eligibility status and scholarship limitation information, including related documents for {props.studentName} to the
											following School Tuition Organization:
										</p>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>5. Agreement Statements</h3>
						</div>
						<div className='form__section--body'>
							<DDAgreementsView
								submittedAgreements={props.application.submittedAgreements}
								agreements={props.application.agreements}
								blueprintSubmitted={props.application.blueprintSubmitted}
								isAgreeStatements={props.application.isAgreeStatements}
							/>
						</div>
					</div>
				</Fragment>
			) : (
				<ApplicationNotFound message={props.error} />
			)}
		</Fragment>
	);
};

export default DDView;
