import { FormControl, InputLabel, MenuItem, Select, Grid, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setFilterId, setFilters } from '../../../redux/actions/filters';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '../Alerts/Alert';

const ButtonSelect = (props) => {
	return (
		<Grid container spacing={1} direction='row' justify='center' alignItems='center'>
			<Grid item xs={props.data ? 10 : 11}>
				<Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.name}</Typography>
			</Grid>
			{props.data && (
				<Grid item xs={1}>
					<IconButton aria-label='delete' size='small' onClick={() => props.removeFilter()}>
						<DeleteIcon className='icon-delete-select' />
					</IconButton>
				</Grid>
			)}
		</Grid>
	);
};

const SelectFilters = (props) => {
	const [selectedFilter, setSelectedFilter] = useState(props.value || '');
	const [disabled, setDisabled] = useState(props.disabled);
	const [data, setData] = useState([]);
	const [showDelete, setShowDelete] = useState(false);

	useEffect(() => {
		setDisabled(props.disabled);
	}, [props.disabled]);

	useEffect(() => {
		const siteAdminFilter = JSON.parse(localStorage.getItem(`SiteAdminFilter_${props.role}`));

		setData(props.data);

		if (props.reportId) {
			setSelectedFilter(props.reportId);
		} else if (siteAdminFilter == null || siteAdminFilter.filterId == null) {
			if (props.data && props.data.length > 0) {
				setSelectedFilter(props.data[0].filterId);
			}
		} else {
			setSelectedFilter(siteAdminFilter.filterId);
		}
	}, [props.role, props.value, props.data]);

	const changeFilter = (e) => {
		const { value } = e.target;
		if (value != null) {
			setSelectedFilter(value);
			props.selectFilter(e);
		}
	};

	const labelSelect = !props.reportId ? 'Saved filters' : 'Report';

	return (
		<Grid container direction='row' justify='center' alignItems='center'>
			<Grid item xs={12}>
				<FormControl className='MuiFormControl__filter-select' disabled={props.data.length > 0 ? false : true} variant='outlined' size='small' fullWidth>
					<InputLabel id='select-filters-label'>{props.data.length > 0 ? labelSelect : 'There are no records'}</InputLabel>
					<Select
						ref={props.selectFilterInput}
						labelId='select-filters-label'
						id='select-filters'
						label={data.length > 0 ? labelSelect : 'There are no records'}
						onChange={(e) => changeFilter(e)}
						value={selectedFilter}
						onOpen={() => setShowDelete(true)}
						onClose={() => setShowDelete(false)}
						disabled={disabled}>
						{data &&
							data.length > 0 &&
							data.map((x, i) => {
								return (
									<MenuItem key={i} value={x.filterId} name={x.name}>
										<ButtonSelect name={x.name} reportId={props.reportId} filters={props.filters} removeFilter={props.removeFilter} data={data[0].filterId !== x.filterId} />
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>
			</Grid>
			{/* {!props.reportId && props.filters && !props.filters.default && (
				<Grid item xs={1} style={{position: 'relative', top: 0, left: -59}}>
					<IconButton aria-label='delete' size='small' color='secondary' onClick={() => props.removeFilter()}>
						<DeleteIcon />
					</IconButton>
				</Grid>
			)} */}
			<Alert
				icon='question'
				open={props.openAlertStatus}
				title='Delete Filter'
				text='Are you sure you want to delete this filter?'
				confirmButtonText='Confirm'
				onConfirm={props.confirmDelete}
				onCancel={props.removeFilter}
			/>
		</Grid>
	);
};

SelectFilters.propTypes = {
	data: PropTypes.array,
	getFilter: PropTypes.func,
};

SelectFilters.defaultProps = {
	data: [],
	disabled: false,
};

const mapStateToProps = (state) => {
	return {
		filters: state.filters,
	};
};

const mapDispatchToProps = {
	setFilterId,
	setFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFilters);
