import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Hidden, Tooltip } from '@material-ui/core';

import Layout from '../../component/partials/Layout';
import LIApplication from './LIApplication';
import DDApplication from './DDApplication';
import Snackbar from '../../component/Snackbar';
import { connect } from 'react-redux';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import InternalComment from '../../component/partials/InternalComment';
import ApplicationTraceEmail from '../../component/ApplicationTraceEmail';

import api from '../../config/api';

const UserInformationButton = (props) => {
	
	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
	}
	const alternateCopyToClipboard = (email) => {
		const textArea = document.createElement("textarea");
		textArea.value = email;
		document.body.appendChild(textArea);
		textArea.focus({ preventScroll: true });
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	};

	const handleCopyToClipboard = (email) => {
		try {

		if (navigator.clipboard) {
      		navigator.clipboard.writeText(email);
		} else {
		  alternateCopyToClipboard(email);
		}
    	setOpenSnackBar(true);
	  } catch (err) {
			console.log("Unable to copy to clipboard",err);
		}
  	};

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return ['(', match[1], ') ', match[2], '-', match[3]].join('');
		}
		return null;
	};

	return (
		<div className='user__information'>			
			<Button variant='contained' size='small' color='primary' onClick={() => props.history.goBack()} disableRipple>
				Back to dashboard
			</Button>		
			{props.parent != null && (
				<Fragment>
					<p className='user-name' style={{ marginTop: 40 }}>
						Parent Information
					</p>
					<div className='user-information'>
						<p style={{ marginTop: 20 }} className='p-name'>
							{props.parent.firstName} {props.parent.lastName}
						</p>
						<p style={{ marginTop: 10 }}>
							<span className='p-street-address'>{props.parent.address}</span>
						</p>
						{(props.parent.city || props.parent.state || props.parent.postalCode) && (
							<p style={{ marginTop: 10 }}>
								<span className='p-locality'>
									{props.parent.city}, {props.parent.state}, {props.parent.postalCode}
								</span>
							</p>
						)}
						{props.parent.phone && (
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<PhoneIcon style={{ color: '#7a9903' }} fontSize='small' /> <span style={{ marginLeft: 10 }}>{formatPhoneNumber(props.parent.phone)}</span>
							</div>
						)}
						<Hidden only={['xs']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<Tooltip title={"Copy to Clipboard"} aria-label='add'>
									<span className='u-email' style={{ marginLeft: 10, cursor: "pointer" }} onClick={()=>{handleCopyToClipboard(props.parent.email)}}>
										{props.parent.email}
									</span>
								</Tooltip>
								<Snackbar open={openSnackBar} severity={'success'} autoHideDuration={3000} text={'Email Successfully Copied to Clipboard'} handleClose={handleCloseSnackBar} />
							</div>
						</Hidden>
						<Hidden only={['sm', 'md', 'lg', 'xl']}>
							<div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
								<EmailIcon style={{ color: '#7a9903' }} fontSize='small' />
								<span className='u-email' style={{ marginLeft: 10 }}>
									{props.parent.email}
								</span>
							</div>
						</Hidden>

					</div>
				</Fragment>
			)}
		</div>
	);
};

const ApplicationForm = (props) => {
	const roles = { 'admin-processor': 4, followup: 5, general: 6 };
	const [titleStatus, setTitleStatus] = useState('');
	const [titleApplication, setTitleApplication] = useState('');
	const [status, setStatus] = useState([]);
	const [content, setContent] = useState([]);
	const [application, setApplication] = useState({});

	const { period: currentPeriod } = props.account.periodSettings;

	const traceEmailRef = React.useRef({});

	useEffect(() => {
		title();
		getTitleApplication();
		getStatus();
	}, []);

	useEffect(() => {
		if(status.length > 0){
			getApplication();
		}
	}, [status]);

	const getApplicationState = async () => {
		const { adminType } = props.match.params || '';
		const { id } = props.match.params || null;
		const token = props.account.tokenAuth;

		const assignationTable = await api.GetGeneralProcessors(token);
		const applicationData = await api.GetApplicationById(token, { id });
		
		setApplication(applicationData.data);
		const params = {
			url: `/${adminType}/application`,
			type: applicationData.data.applicationType,

			statusId: applicationData.data.status,
			status: applicationData.data.statuslabel,
			assignName: assignationTable.data.find((x) => x.accountId === applicationData.data.assignedProcessorId)?.processorName,
			processorId: applicationData.data.assignedProcessorId,
			applicationId: applicationData.data.applicationId,
			assignationTable: assignationTable.data,
			token: props.account.tokenAuth,
			role: roles[adminType],
			mode: 'edit',
		};

		return params;
	};
	const applicationLoaded = async () => {
		const applicationState = await getApplicationState();
		const result = await traceEmailRef.current.reload();		
	};

	const getApplication = async () => {
		const { id } = props.match.params || null;
		const { applicationType } = props.match.params || '';
		const { adminType } = props.match.params || '';
		const applicationState = await getApplicationState();

		switch (applicationType.toUpperCase()) {
			case 'DD':
				setContent(<DDApplication adminType={adminType} pathname={applicationState} history={props.history} status={status} account={props.account} id={id} applicationType='DD' applicationLoaded={applicationLoaded}/>);
				break;
			case 'LI':
				setContent(<LIApplication adminType={adminType} pathname={applicationState} status={status} account={props.account} id={id} applicationType='LI' history={props.history} applicationLoaded={applicationLoaded} />);
				break;

			default:
				break;
		}
	};

	const getTitleApplication = () => {
		const { applicationType } = props.match.params || '';

		switch (applicationType.toUpperCase()) {
			case 'DD':
				setTitleApplication('Disabled/Displaced Application');
				break;
			case 'LI':
				setTitleApplication('Low Income Application');
				break;

			default:
				break;
		}
	};

	const title = () => {
		const { adminType } = props.match.params;
		switch (adminType) {
			case 'admin-processor':
				return setTitleStatus('Admin Processor');
			case 'followup':
				return setTitleStatus('Follow Up Processor');
			case 'general':
				return setTitleStatus('General Processor');
			default:
				props.history.push('/');
				break;
		}
	};

	const getStatus = async () => {
		let role = roles[props.match.params.adminType];

		const response = await api.GetStatusByRole(props.account.tokenAuth, { role: role });
		if (response.data){			
 			setStatus(response.data);			
		}		
	};

	return (
		<Layout showButton={false} history={props.history} title={titleStatus} period={currentPeriod}>
			<div className='application-page application-form-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<div className='user__column'>
							<UserInformationButton history={props.history} parent={application.account} />							
							<InternalComment id={application.applicationId} comment={application.inProcessNote}/>
							<ApplicationTraceEmail application={application} traceEmailRef={traceEmailRef}/>
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<div className='form__column'>
							<h3 className='section-title'>{titleApplication}</h3>
							<div className='application-form__content'>{content}</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

export default connect((s) => ({ account: s.account }))(ApplicationForm);
