import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Api from '../config/api';
import { setFilters } from '../redux/actions/filters';
import Loader from './Loader';
import Modal from './Modal';
import ReportModal from './ReportModal';
import Alert from './partials/Alerts/Alert';

const SaveFilterModal = (props) => {
	const siteAdminFilter = `SiteAdminFilter_${props.role}`;

	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState('');
	const [msg, setMsg] = useState('');
	const [success, setSuccess] = useState('');
	const [title, setTitle] = useState('');
	useEffect(() => {
		// console.log(props.filters.columns);
		if (props.filters && props.filters.filterId) setForm(props.filters.filterId == null || props.filters.default === true ? '' : props.filters.name);
	}, [props]);

	const saveReduxAndLocalStorage = (filter) => {
		props.setFilters(filter);
		localStorage.setItem(siteAdminFilter, JSON.stringify(filter));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const updateFilter = {
			filterId: props.filters && props.filters.default ? null : props.filters.filterId,
			name: form,
			columns: props.filters.columns,
			role: props.role,
			pageSize: props.filters.options.pageSize || null,
		};
		Api.UpdateFilter(props.account.tokenAuth, updateFilter)
			.then((res) => {
				if (res.status !== 201) {
					setLoading(false);
					setMsg(res.message);
					return;
				}

				let message = props.filters.default ? 'New filter was created' : 'The filter was updated';
				let title = props.filters.default ? 'Create Filter' : 'Update Filter';
				setSuccess(message);
				setTitle(title);
				saveReduxAndLocalStorage({
					...props.filters,
					filterId: res.data.filterId,
					name: res.data.name,
					columns: res.data.columns,
					options: { ...props.filters.options, pageSize: res.data.pageSize },
				});
				props.getFilterList();
				setLoading(false);
				setMsg('');
				props.openSaveFilterModal();
			})
			.catch((err) => console.log(err));
	};

	const handleFormStateChange = (e) => {
		let { value } = e.target;
		setForm(value);
		setMsg('');
	};

	const handleClose = () => {
		setMsg('');
		props.openSaveFilterModal();
		props.closeModal();
	};

	const handleCloseAlert = () => {
		setSuccess('');
	};

	return (
		<Fragment>
			<Modal open={props.open} onClose={() => handleClose()} autoHeight>
				<div className='report__modal'>
					<h3 className='section-title'>{`${props.filters.default ? 'Create' : 'Update'} Filters as Preset`}</h3>
					<form onSubmit={handleSubmit}>
						<FormControl component='fieldset' fullWidth>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField label={form} name='name' color='primary' variant='outlined' value={form} onChange={handleFormStateChange} inputProps={{ maxLength: 120 }} fullWidth helperText={msg} />
								</Grid>
							</Grid>
						</FormControl>
						<div className='buttons-container'>
							{!loading ? (
								<Fragment>
									{props.role === 3 ? (
										<Button color='primary' type='button' size='large' startIcon={<SendIcon />} className='close-btn' onClick={() => props.openSaveReportModal()} disableRipple fullWidth>
											SEND AS REPORT
										</Button>
									) : (
										<Button type='button' size='large' startIcon={<CloseIcon />} className='close-btn' onClick={() => handleClose()} disableRipple fullWidth>
											Cancel
										</Button>
									)}
									<Button type='submit' size='large' color='primary' variant='contained' startIcon={<SaveIcon />} disableRipple fullWidth>
										{props.filters.default ? 'Save' : 'Update'}
									</Button>
								</Fragment>
							) : (
								<Loader loading />
							)}
						</div>
					</form>
				</div>
			</Modal>
			<ReportModal
				open={props.openReportModal}
				filterColumns={props.filters.columns}
				selectedReport={props.selectedReport}
				allowSendReport={!!props.selectedReport?.reportId}
				onUpdateSelectedReport={props.onUpdateSelectedReport}
				onClose={() => props.openSaveReportModal()}
			/>
			<Alert icon='success' open={!!success} title={title} text={success} confirmButtonText='Done' onConfirm={() => handleCloseAlert()} />
		</Fragment>
	);
};

SaveFilterModal.propTypes = {
	// open: PropTypes.bool.isRequired,
	selectedReport: PropTypes.object,
	onSetSelectedReport: PropTypes.func,
	onUpdateSelectedReport: PropTypes.func,
	// onClose: PropTypes.func.isRequired,
};

SaveFilterModal.defaultProps = {
	onSetSelectedReport: () => {},
	onUpdateSelectedReport: () => {},
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
		filters: state.filters,
	};
};

const mapDispatchToProps = {
	setFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveFilterModal);
