import React, { Fragment } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MessageBox = (props) => {
	return (
		<Fragment>
			<Box className='auth-box-message' style={{ width: 350 }}>
				<Box className='forgot_success_box'>
					<h4 style={{ textAlign: 'center' }}>{props.title}</h4>
					<br />
					{props.subtitle}
				</Box>
				<Box className='button__container'>
					<Link to={props.url}>
						<Button variant='contained' onClick={props.onClick}>
							{props.buttonText}
						</Button>
					</Link>
				</Box>
			</Box>
		</Fragment>
	);
};

MessageBox.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.any,
	buttonText: PropTypes.string,
	url: PropTypes.string,
};

MessageBox.defaultProps = {
	title: 'PASSWORD RESET',
	subtitle: <p style={{ textAlign: 'center' }}>The new password was updated</p>,
	buttonText: 'Back to login',
	url: '/login',
};

export default MessageBox;
