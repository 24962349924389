import React from 'react';

import ThousandSeparator from './ThousandSeparator';

const List = ({ data }) => {
	const renderItems = () => {
		let items = [];

		if (data.length == 0) return <li className='no-data'>No data</li>;

		data.map((d, i) => {
			const values = Object.values(d);
			items.push(
				<li key={i}>
					{values[0]}{' '}
					<span>
						(<ThousandSeparator value={values[1]} />)
					</span>
				</li>,
			);
		});

		return items;
	};

	return (
		<div className='metrics__list'>
			<ul>{renderItems()}</ul>
		</div>
	);
};

export default List;
