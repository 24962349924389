import React from 'react';
import { connect } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const ProcessingStatusColumn = ({rowData, role, handleOpenProcessingModal, ...props}) => {

	const { period: currentPeriod } = props.account.periodSettings;	

	return (
		<div style={{margin:'10px'}}>
			<Box justifyContent='center'>
				<Box display='flex' flexDirection='row' alignContent='center' alignItems='center'>
					<p class="processing_status-column">{(rowData.processingStatus != '') ? rowData.processingStatus : 'Not established'}</p>
					{(role === 4 && currentPeriod === props.filters.period) && (
						<IconButton aria-label='toggle password visibility' edge='end' onClick={() => handleOpenProcessingModal(rowData)}>
							<SettingsIcon fontSize='small' />
						</IconButton>
					)}
				</Box>
			</Box>
		</div>		

	);
};


const mapStateProps = (state) => {
	return { 
		account: state.account,
		filters: state.filters 
	};
};


export default connect(mapStateProps)(ProcessingStatusColumn);