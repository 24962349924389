import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Layout from '../../component/partials/Layout';
import UserInformation from '../../component/partials/UserInformation';
import ApplicationBox from './ApplicationBox';
import NewApplicationModal from './NewApplicationModal';
import { connect } from 'react-redux';

const applications = [
	{
		id: 1,
		student: {
			name: 'Susan Doe',
		},
		type: 'Low Income Application',
		parent_guardian: 'Peter Parker',
		status: 'Saved / Unsubmited',
		created: '02/02/2020',
		modified: '02/02/2020',
		school: 'School name',
		city: 'City name',
		grade: 'Grade value',
		submitted: false,
	},
	{
		id: 2,
		student: {
			name: 'John Doe',
		},
		type: 'Low Income Application',
		parent_guardian: 'Peter Parker',
		status: 'Saved / Unsubmited',
		created: '02/02/2020',
		modified: '02/02/2020',
		school: 'School name',
		city: 'City name',
		grade: 'Grade value',
		submitted: false,
	},
	{
		id: 3,
		student: {
			name: 'Laura Peters',
		},
		type: 'Low Income Application',
		parent_guardian: 'Peter Parker',
		status: 'Under Review',
		created: '02/02/2020',
		modified: '02/02/2020',
		school: 'School name',
		city: 'City name',
		grade: 'Grade value',
		submitted: true,
	},
	{
		id: 4,
		student: {
			name: 'Laura Peters',
		},
		type: 'Low Income Application',
		parent_guardian: 'Peter Parker',
		status: 'Rejected',
		created: '02/02/2020',
		modified: '02/02/2020',
		school: 'School name',
		city: 'City name',
		grade: 'Grade value',
		submitted: true,
	},
];

const Application = (props) => {
	const [openModal, setOpenModal] = useState(false);

	const [submittedApplications, setSubmittedApplications] = useState([]);
	const [unsubmittedApplications, setUnsubmittedApplications] = useState([]);
	useEffect(() => {
		const submitted = applications.filter((d) => d.submitted);
		const unsubmitted = applications.filter((d) => !d.submitted);

		setSubmittedApplications(submitted);
		setUnsubmittedApplications(unsubmitted);
	}, []);

	const toggleModal = () => {
		setOpenModal(!openModal);
	};

	const renderApplications = (data, type) => {
		if (data.length === 0) return <div className='empty-list'>No applications to display</div>;
		return data.map((d) => <ApplicationBox key={d.id} data={d} type={type} />);
	};

	return (
		<Layout history={props.history}>
			<div className='application-page'>
				<Grid container spacing={3}>
					<Grid xs={12} md={4} lg={3} item>
						<div className='user__column'>
							<Grid container>
								<Grid item xs={12} sm={6} md={12}>
									<UserInformation />
								</Grid>
								<Grid item xs={12} sm={6} md={12}>
									<div className='button__container'>
										<Button variant='contained' color='secondary' className='new-application__button' startIcon={<AddIcon />} fullWidth disableRipple onClick={toggleModal}>
											Start new application
										</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid xs={12} md={8} lg={9} item>
						<div className='applications__column'>
							<div className='unsubmitted-applications application__section'>
								<h3 className='section-title'>Unsubmitted Applications</h3>
								{renderApplications(unsubmittedApplications, 'unsubmitted')}
							</div>

							<div className='submitted-applications application__section'>
								<h3 className='section-title'>Submitted Applications</h3>
								{renderApplications(submittedApplications, 'submitted')}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			<NewApplicationModal open={openModal} onClose={toggleModal} />
		</Layout>
	);
};

export default connect((s) => ({ account: s.account }))(Application);
