import config from '../../config'; // load configurations file

export const BASE_URL = config.api.url;
export const FETCH_TIMEOUT = 20000;

export const LOGIN = 'account/login';
export const GETCURRENT = 'account/token';
export const COMPLETEACCOUNT = 'account/complete';
export const GETALLACCOUNT = 'account/all';
export const GETACCOUNT = 'account';
export const CREATEACCOUNT = 'account/create';
export const UPDATEACCOUNT = 'account/update';
export const DELETEACCOUNT = 'account/delete';
export const UPDATEPASSWORDACCOUNT = 'account/password/update';
export const RECOVERYPASSWORD = 'account/recovery-password';
export const RESETPASSWORD = 'account/reset-password';
export const GETUSERBYEMAILPHONE = 'account/get-user-by-email-phone';
export const UPDATEQUESTIONS = 'account/update-questions';
export const CHECKEXPIRATIONDATE = 'account/complete';

//Security Question
export const GETQUESTION = 'question/all';
export const GETRANDOMQUESTIONSBYACCOUNTID = 'question/get-questions-by-accountid';
export const GETALLQUESTIONSBYACCOUNTID = 'question/get-all-questions-by-accountid';
export const VALIDATEQUESTION = 'question/validate-question';

//Processors
export const GETSITEADMINLIST = 'application/lists/admin';
export const GETADMINPROCESSORLIST = 'application/lists/adminProcessor';
export const GETFOLLOWPROCESSORLIST = 'application/lists/followupProcessor';
export const GETGENERALPROCESSORLIST = 'application/lists/generalProcessor';
export const GETGENERALPROCESSORS = 'account/processors/getGeneralProcessors';
export const ASSIGNTOGENERALPROCESSOR = 'application/assignToGeneralProcessor';
export const GETSTATUSBYROLE = 'application/getStatusByRole';
export const CHANGESTATUS = 'application/changeStatus';
export const SENDSIGNATUREREMINDER = 'application/sendSignatureReminder';
export const UPDATEINPROCESS = 'application/updateInProcess';

//Applications
export const GETCRITERIA = 'application/criteria';
export const GETAPPLICATIONBYID = 'application/get';
export const CREATELISTUDENT = 'application/low-income/addStudent';
export const UPDATELISTUDENT = 'application/low-income/updateStudent';
export const DELETELISTUDENT = 'application/low-income/removeStudent';
export const CREATEAPPLICATIONFINANCIAL = 'application/financial-member/add';
export const UPDATEAPPLICATIONFINANCIAL = 'application/financial-member/update';
export const DELETEAPPLICATIONFINANCIAL = 'application/financial-member/remove';
export const SETAGREESTATEMENTS = 'application/disabled/setAgreeStatements';
export const UPDATEDDSTUDENT = 'application/disabled/updateStudent';
export const UPDATEDDPREREQUISITES = 'application/disabled/updatePrerequisites';
export const SETSHARINGPERMISSIONS = 'application/disabled/setSharingPermission';
export const INCOMPLETEDREASONS = 'application/incompletedReasons';
export const REJECTEDREASONS = 'application/rejectedReasons';
export const DELETEPARENTAPPLICATION = 'application/delete';
export const SETHOUSEHOLDMEMBERS = 'application/setHouseholdMembers';
export const UPDATEFINANCIALQUESTIONS = 'application/updateFinancialQuestions';
export const UPDATEINTERNALCOMMENTS = 'application/updateInternalComments';

// School
export const GETSCHOOLNAMES = 'school/list';
export const DELETESCHOOL = 'school/removeSchool';
export const CREATESCHOOL = 'school/updateSchool';
export const GETSTATSPERSCHOOL = 'school/getStatsPerSchool';
export const REACTIVESCHOOL = 'school/reactive';

//Files
export const GETUPLOADFILEDATA = 'file/getUploadFileData';
export const GETFILESAPPLICATIONS = 'file/getDownloadFileUrl';
export const DELETEFILE = 'file/deleteFile';

//Sign
export const GETSIGNATUREREQUESTDOWNLOAD = 'application/get-signature-request-download';

//Settings
export const UPDATESETTINGS = 'site/updateSettings';

// Reports
export const GETDEFINITIONS = 'report/lists/definitions';
export const UPDATEREPORT = 'report/updateReport';
export const DELETEREPORT = 'report/removeReport';

// Columns
export const GETCOLUMNS = 'filter/lists/columns';

// Filters
export const GETFILTERS = 'filter/getFilter';
export const UPDATEFILTER = 'filter/updateFilter';
export const GETFILTERSLIST = 'filter/lists/filters';
export const GETDATAFILTERS = 'filter/getData';
export const REMOVEFILTER = 'filter/removeFilter';

// Report
export const GETDATAREPORT = 'report/getData';
export const SENDREPORT = 'report/sendReport';

// History
export const GETHISTORY = 'history/get';
export const GETHISTORYINDEX = 'history/getIndex';

// Metrics
export const GETMETRICS = 'metrics/get';

// Site
export const GETPERIODS = 'site/periods';
export const STARTPERIOD = 'site/start-next-period';
export const GETINCOMETABLE = 'site/income-table';
export const GETFULLINCOMETABLE = 'site/full-income-table';
export const UPDATEINCOMES = 'site/updateIncomes';

// Media
export const GETUPLOADMEDIADATA = 'media/getUploadMediaData';
export const GETDOWNLOADMEDIAURL = 'media/getDownloadMediaUrl';
export const DELETEMEDIA = 'media/deleteMedia';
export const ADDMEDIA = 'media/addMedia';

// Processing Status
export const SETPROCESSINGSTATUS = 'application/setProcessingStatus';

//Status Email 
export const GETSTATUSEMAILLIST = 'email/getStatusEmailList';
export const UPDATESTATUSEMAILLIST = 'email/updateStatusEmail';
export const PREVIEWSTATUSEMAIL = 'email/previewStatusEmail';
export const DELETEESTATUSEMAIL = 'email/deleteStatusEmail';